import React from "react";
import { pure } from "recompose";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_DRAWER } from "../helper/constants";
import {
  GROUPS,
  GROUP_CHATS,
  IS_OPEN_BY_OWNER,
  OPEN_BOTTOM_CHATS,
  OPEN_BOTTOM_PROJECTS,
  OPEN_CHATS,
  OPEN_PROJECTS,
  RESET_MARKIN_PROJECT,
  SET_ACTIVITY_MESSAGES,
  SET_IN_ACTIVITY,
} from "../store";

function Layout(props) {
  const dispatch = useDispatch();
  const inActivity = useSelector(e => e.inActivity)
  const { drawerOpen, isOpenByOwner } = useSelector((state) => state);
  const handleCloseDrawer = () => {
    dispatch({ type: TOGGLE_DRAWER, open: !drawerOpen });
    if (inActivity) {
      dispatch({ type: OPEN_PROJECTS, openProjects: [] })
      dispatch({ type: GROUP_CHATS, groupChats: [] })
      dispatch({ type: OPEN_CHATS, openChats: [] })
      dispatch({ type: SET_IN_ACTIVITY, status: false })
    }
    if (isOpenByOwner) {
      dispatch({ type: IS_OPEN_BY_OWNER, open: false });
      dispatch({ type: OPEN_BOTTOM_PROJECTS, openBottomProjects: [] });
      dispatch({ type: OPEN_PROJECTS, openProjects: [] });
      dispatch({ type: OPEN_CHATS, openChats: [] });
      dispatch({ type: OPEN_BOTTOM_CHATS, openBottomChats: [] });
      dispatch({ type: GROUPS, groups: [] });
    }
  };
  return (
    <React.Fragment>
      <div className="main-div">
        <a className="pointer backButton" onClick={handleCloseDrawer}>
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
            alt="Generic placeholder"
          />
        </a>
        {props.children}
      </div>
    </React.Fragment>
  );
}

export default pure(Layout);
