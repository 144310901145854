import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/login";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Register from "./pages/register";
import React, { useEffect, useState } from "react";
import Profile from "./pages/Profile/profile";
import CompanyDetails from "./pages/Company/companydetails";
import Notifications from "./pages/Notification/notification";
import { connect, useDispatch, useSelector } from "react-redux";
import AddCompany from "./pages/Company/addcompany";
import ForgotPassword from "./pages/ForgotPassword";
import { LanguageProvider } from "./helper/LanguageContext";
import Contact from "./pages/contact";
import EditProfile from "./pages/Profile/EditProfile";
import AddProject from "./pages/Project/AddProject";
import Capacities from "./pages/capacities";
import FreeCapacity from "./pages/Company/FreeCapacity";
import Project from "./pages/Project/project";
import Company from "./pages/Company/company";
import ProfileSetting from "./pages/Profile/ProfileSetting";
import Articles from "./pages/Feeds/Articles";
import { ConfirmDialog, NotifySnackbar } from "react-material-crud";
import CreateArticle from "./pages/Feeds/CreateArticle";
import AddPersonalArticle from "./pages/Feeds/AddPersonalArticle";
import ArticleLike from "./pages/Feeds/ArticleLike";
import MyCompany from "./pages/Company/mycompany";
import UserProfile from "./pages/User/UserProfile";
import Followings from "./pages/Feeds/Followings";
import UserFollowings from "./pages/User/UserFollowings";
import Followers from "./pages/Feeds/Followers";
import UserFollowers from "./pages/User/UserFollowers";
import EditCompany from "./pages/Company/EditCompany";
import CompanyUser from "./pages/Company/CompanyUser";
import ForeignCompanyDetails from "./pages/User/ForeignCompanyDetails";
import CompanyFollowers from "./pages/Company/CompanyFollowers";
import CompanySetting from "./pages/Company/CompanySetting";
import ExternalMit from "./pages/Company/Invite";
import CompanyServicesMember from "./pages/Company/CompanyServicesMember";
import AddServices from "./pages/Services/AddServices";
import ProjectDetail from "./pages/Project/ProjectDetail";
import AddProjectUser from "./pages/Project/AddProjectUser";
import InviteProjectUser from "./pages/Project/InviteProjectUser";
import MediaLinksDocs from "./pages/Project/MediaLinksDocs";
import FeedDetail from "./pages/Feeds/FeedDetail";
import EditProject from "./pages/Project/EditProject";
import Companies from "./pages/Company/Companies";
import CreateCompanyArticle from "./pages/Feeds/CreateCompanyArticle";
import EditPersonalArticle from "./pages/Feeds/EditPersonalArticle";
import EditCompanyArticle from "./pages/Feeds/EditCompanyArticle";
import AddOneToOneUser from "./pages/Chat/AddOneToOneUser";
import WeeklyStatistics from "./pages/Notification/WeeklyStatistics";
import AllContact from "./pages/Notification/AllContact";
import _ from "lodash";
import { badgeCountObj, badgeObj, onlineObj } from "./helper/SocketObj";
import {
  ALL_BADGE_COUNT,
  CHAT_BADGE_COUNT,
  GET_COUNTERS,
  GROUPS,
  INTERNAL_PROJECTS,
  IS_NEW_MESSAGE,
  NEWS_BADGE_COUNT,
  PROJECTS,
  PROJECT_BADGE_COUNT,
  RESET_SOCKET,
  SET_ALLGROUPS_PROJECTS,
  SET_AUTO_TRANSLATION,
  SET_IS_UNAUTHORIZED,
  SET_JOIN_REQUEST_EXTERNAL,
  SET_USER_LOGIN_TOKEN,
  UNSEEN_EXTERNAL_BADGE,
  UNSEEN_INTERNAL_BADGE,
  store,
} from "./store";
import Home from "./pages/home";
import { CardonContainer } from "cardon";
import RightBar from "./components/RightBar";
import SideNav from "./SideNav";
import { callAPI, key_pair } from "./helper/apiUtils";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./helper/apiUtils";
import { initializeApp } from "firebase/app";
import FollowOneToOne from "./pages/Chat/FollowOneToOne";
import { SocketContext } from "./context/socket";
import SecurityPIN from "./pages/SecurityPIN";
import SecurityPINMain from "./pages/SecurityPINMain";
import { API_ROOT } from "./helper/constants";
import socketio from "socket.io-client";
import { $user } from "./helper/UserFactory";
import FullScreenImageViewer from "./components/FullScreenImageViewer";
import customParser from "socket.io-msgpack-parser";
import LatestMessages from "./pages/Feeds/LatestMessages";
import { DE } from "./Languages/DE";
import { EN } from "./Languages/EN";
import { Link, useHistory } from "react-router-dom";
import { version } from '../package.json';
import Loading from "./components/Loading";
import { $crud } from "./helper/CrudFactory";
import { compare } from 'compare-versions';


function App(props) {
  let userLang = localStorage.getItem("user");
  let filterLang = JSON.parse(userLang)?.language === "de" ? true : false;
  const {
    drawerOpen,
    unSeenAllCount,
    groupChats,
    projects,
    openProjects,
    openBottomProjects,
    internalProjectsAll,
    joinRequestsExternal,
    groups,
    resetSocket,
    AllGroupsOfProjects,
    selectedCompanyId,
    user,
    userLoginToken
  } = useSelector((state) => state);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [Socket, setSocket] = useState(null);
  let [isGerm, setIsGerm] = useState(localStorage.getItem("switch") === "2");
  const [isChatCountProjects, setIsChatCountProjects] = useState(false);
  const [isChatCount, setIsChatCount] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isVisible, setIsVisible] = useState(!document.hidden);
  const isUnauthorized = useSelector(state => state.isUnauthorized)
  const history = useHistory();
  // const { checkCacheStatus } = useCacheBuster();


  useEffect(() => {
    if (isUnauthorized) {
      _logout()
    }
  }, [isUnauthorized])

  const _logout = async () => {
    dispatch({ type: RESET_SOCKET, payload: true })
    await $user.logout();
    localStorage.removeItem("autoTrans")
    dispatch({ type: SET_AUTO_TRANSLATION, status: false })
    dispatch({ type: SET_USER_LOGIN_TOKEN, status: null })
    dispatch({ type: SET_IS_UNAUTHORIZED, status: false })
    history.push('/')
  }

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  function clearCache() {
    caches.keys().then(function (names) {
      for (let name of names) {
        caches.delete(name);
      }
    });
  }

  useEffect(() => {
    _checkAndSet();
  }, []);

  const _checkAndSet = async (key, value) => {
    let userLang = await localStorage.getItem("autoTrans");
    if (userLang) {
      dispatch({ type: SET_AUTO_TRANSLATION, status: true });
    }
  };

  useEffect(() => {
    clearCache();
  }, []);

  const _setUserOffline = async () => {
    const { data } = await callAPI("/user/offline", {}, "GET");
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setIsGerm(filterLang);
  }, [userLang]);

  useEffect(() => {
    if (!userLoginToken) {
    }
  }, [userLoginToken]);

  //Count API
  const getBadgesCount = async () => {
    if (userLoginToken) {
      const { data } = await callAPI(`/badge/count`, {}, "GET");
      if (data?.status) {
        await props.updateAllBadgeCount(data);
      }
    }
  };
  useEffect(() => {
    getBadgesCount();
    let app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    getToken(messaging, { vapidKey: key_pair }).then((currentToken) => {
      if (currentToken) {
        console.log("Notification permission granted.", currentToken);
        localStorage.setItem("firebaseToken", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    });
    // Notification.requestPermission().then(function (permission) {
    //   if (permission === "granted") {
    //     getToken(messaging, { vapidKey: key_pair }).then((currentToken) => {
    //       if (currentToken) {
    //         console.log("Notification permission granted.", currentToken);
    //         localStorage.setItem("firebaseToken", currentToken);
    //       } else {
    //         console.log(
    //           "No registration token available. Request permission to generate one."
    //         );
    //       }
    //     });
    //     onMessage(messaging, (msg) => {
    //       console.log("forground Message", msg);
    //     });
    //   } else {
    //     alert(isGerm ? DE.allow_noti : EN.allow_noti);
    //   }
    // });
  }, []);

  const lgVal = { isGerm, setIsGerm };
  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    if (localStorage.getItem("switch") === null) {
      if (userLang === "de") {
        localStorage.setItem("switch", "2");
        setIsGerm(true);
      } else {
        localStorage.setItem("switch", "1");
        setIsGerm(false);
      }
    }
  }, []);

  const _removeSocket = async () => {
    await localStorage.removeItem("socketID");
  };

  //set loggedIn
  useEffect(() => {
    user ? setIsLoggedIn(true) : setIsLoggedIn(false);
    if ($user.getToken() && user) {
      _removeSocket();
      let tkn = $user.getToken();
      let firetoken = user.fcmId;
      connectWithSocket(tkn, firetoken);
    } else {
      setSocket(null);
    }
  }, [user]);

  useEffect(() => {
    if (resetSocket) {
      setSocket(null);
      dispatch({ type: RESET_SOCKET, payload: false });
    }
  }, [resetSocket]);

  const connectWithSocket = async (loginToken, firetoken) => {
    let SocketObject = socketio(`${API_ROOT}/?__sails_io_sdk_version=0.12.14`, {
      jsonp: false,
      parser: customParser,
      transports: ["websocket"],
      autoConnect: true,
      reconnection: true,
      headers: {
        token: loginToken,
        fcmId: firetoken,
      },
      extraHeaders: {
        token: loginToken,
        fcmId: firetoken,
      },
      auth: {
        token: loginToken,
        fcmId: firetoken,
      },
    });
    SocketObject.on("connect", () => {
      setSocket(null);
      SocketObject.off("news").on("news", (data) => {
        if (data.user === user?.id) {
          let count = props.unseenNewsCount;
          if (data.action === "increase") {
            count = count + 1;
            props.updateNewsCount(count);
          }
          if (data.action === "decrease") {
            // count = count - 1;
            // props.updateNewsCount(count);
          }
        }
      });
      SocketObject.off("latestMsg").on("latestMsg", async function (data) {
        console.log("-----> Socket", data);
        dispatch({ type: IS_NEW_MESSAGE, status: data });
      });
      SocketObject.off("chat").on("chat", (res) => {
        console.log("-----> Socket chat", res);
        setIsChatCount(res);
      });
      SocketObject.off("building").on("building", (res) => {
        console.log("-----> Socket Building", res);
        setIsChatCountProjects(res);
      });
      SocketObject.off("unreadChatCount").on("unreadChatCount", async (res) => {
        if (res?.building) {
          setIsChatCountProjects(res);
        } else {
          setIsChatCount(res);
        }
      });
      setSocket(SocketObject);
      setUserOnline(SocketObject);
    });
  };

  const _getLatestChat = async () => {
    const { data } = await callAPI("/group", {}, "GET");
    if (data.status) {
      const updateData = data.groups.map((building, i) => {
        return {
          ...building,
          key: i + 1,
          isOpened: !![...props.openChats, ...props.openBottomChats].find(
            (g) => g.id === building.id
          ),
          originalMessage: building.lastMessage,
          messageLng: "en",
          isBlock: building.name.trim()
            ? !!building.users.find((u) => u.id !== user?.id)?.isBlock
            : false,
        };
      });
      dispatch({ type: GROUPS, groups: updateData });
    }
  };

  const _getLatestGroup = async () => {
    if (userLoginToken) {
      const { data } = await callAPI("/building/my", {}, "GET");
      if (data.status) {
        const sortData = await _.sortBy(data.buildings, [
          (comp) => {
            return comp.updatedAt && !comp.isJoin;
          },
        ]);
        const newData = await sortData?.map((building, i) => {
          return {
            ...building,
            key: i + 1,
            isOpened: !![...openProjects, ...openBottomProjects].find(
              (g) => g?.id === building?.id
            ),
          };
        });
        dispatch({ type: PROJECTS, projects: newData });
      }
    }
  };

  const getChats = async (projectInfo) => {
    const { data } = await callAPI(
      `/building/${projectInfo?.id}/chat`,
      {
        userId: user?.id,
        lang: isGerm ? "de" : "en",
      },
      "GET"
    );
    if (data.status) {
      let chatsData = await Promise.all(
        data.groups.map(async (chat) => {
          return {
            ...chat,
            isOpened: false,
            // isLeave: await checkExist(chat.users),
          };
        })
      );

      let chatGroupUsers = [];
      if (projectInfo?.viewByOwner) {
        chatGroupUsers = chatsData.map((group) => {
          if (
            group.isLeave === true &&
            group.users.find((u) => u.id === (projectInfo.ownerId ?? user?.id))
          ) {
            group.disabled = true;
          } else {
            group.disabled = false;
          }
          return group;
        });
      } else {
        chatGroupUsers = chatsData?.map((group) => {
          if (
            group.isLeave === true &&
            group.users.find((u) => u.id === (projectInfo.ownerId ?? user?.id))
          ) {
            return {
              ...group,
              isOpened: !!groupChats.find((g) => g.id === group.id),
              disabled: true,
            };
          } else {
            return {
              ...group,
              isOpened: !!groupChats.find((g) => g.id === group.id),
              disabled: false,
            };
          }
        });
      }
      let userData = JSON.parse(localStorage.getItem("user"));
      let filterData = chatGroupUsers
        .sort((a, b) => (a.isLeave === true) - (b.isLeave === true))
        .filter((item) => {
          if (!projectInfo?.viewByOwner)
            return item.users.find((it) => it.id === userData.id) && item;
          else
            return (
              item.users.find((it) => it.id === projectInfo.ownerId) && item
            );
        });
      dispatch({
        type: SET_ALLGROUPS_PROJECTS,
        payload: {
          projectId: projectInfo?.id,
          newGroups: _sortArray([...filterData]),
        },
      });
    }
  };

  const _sortArray = (res) => {
    let _temp = [...res];
    let _joined = [];
    let _leaved = [];
    for (let obj of _temp) {
      let chatUser = obj.users.filter((u) => u.id == user.id);
      if (chatUser?.length > 0) {
        if (!chatUser[0]?.isLeave) {
          _joined.push(obj);
        } else {
          _leaved.push(obj);
        }
      } else {
        _leaved.push(obj);
      }
    }
    return _joined
      .sort((a, b) => {
        return b.defaultChatGroup - a.defaultChatGroup;
      })
      .concat(_leaved);
  };

  const getKjuupPlusProjects = async () => {
    const { data } = await callAPI("/building/internalProjects", {}, "GET");
    if (data.status) {
      let all = [...data.foundUserCompany];
      if (all.length > 0) {
        let leaved = all.filter((e) => e.isLeave);
        let joined = all.filter((e) => !e.isLeave);
        let _all = [...joined, ...leaved];
        let filteredArticles = _all.map((e, i) => {
          if (selectedCompanyId !== null)
            return { ...e, isSelected: e.id === selectedCompanyId };
          else return { ...e, isSelected: i === 0 };
        });
        dispatch({ type: INTERNAL_PROJECTS, projects: filteredArticles });
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      setUserOnline(Socket);
    } else {
      _setUserOffline(Socket);
    }
  }, [isVisible]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isChatCount) {
      let userDetails = localStorage.getItem("user");
      if (userDetails) {
        const isFind = isChatCount.user.find((item) => item === user.id);
        if (isFind) {
          if (isChatCount.action === "increase") {
            let a = { ...unSeenAllCount };
            let f = { ...a, unseenChatCount: a.unseenChatCount + 1 };
            let tmp = [...groups];
            for (let obj of tmp) {
              if (obj.id === isChatCount.group) {
                let cnt = obj.unseenChatCount;
                obj.unseenChatCount = cnt + 1;
              }
            }
            dispatch({ type: GROUPS, groups: tmp });
            props.updateAllBadgeCount(f);
          }
          if (isChatCount.action === "decrease") {
            getBadgesCount();
          }
        }
        _getLatestChat();
      }
      setIsChatCount(false);
    }
  }, [isChatCount]);
  // console.log(unSeenAllCount)
  useEffect(() => {
    if (isChatCountProjects) {
      let userDetails = localStorage.getItem("user");
      if (userDetails) {
        const isFind = isChatCountProjects.user.find(
          (item) => item === user.id
        );
        if (isFind) {
          if (isChatCountProjects?.requestId) {
            if (
              (isChatCountProjects?.requestId &&
                !isChatCountProjects?.company_id) ||
              (isChatCountProjects?.requestId &&
                (isChatCountProjects?.company_id == "undefined" ||
                  isChatCountProjects?.company_id == "null"))
            ) {
              dispatch({
                type: SET_JOIN_REQUEST_EXTERNAL,
                requests: [...joinRequestsExternal, { ...isChatCountProjects }],
              });
              let a = { ...unSeenAllCount };
              let f = {
                ...a,
                unseenBuildingChatCount: a.unseenBuildingChatCount + 1,
                unseenExternalProject: a.unseenExternalProject + 1,
              };
              props.updateAllBadgeCount(f);
            } else {
              let a = { ...unSeenAllCount };
              let f = {
                ...a,
                unseenBuildingChatCount: a.unseenBuildingChatCount + 1,
                unseenInternalProject: a.unseenInternalProject + 1,
              };
              props.updateAllBadgeCount(f);
              let tmp = [...internalProjectsAll];
              let ind = tmp.findIndex(
                (e) => e.id == isChatCountProjects.company_id
              );
              if (ind !== -1) {
                tmp[ind].totalUnreadChat =
                  tmp[ind].totalUnreadChat || tmp[ind].totalUnreadChat == 0
                    ? tmp[ind].totalUnreadChat + 1
                    : 1;
                tmp[ind].projectJoinRequests = tmp[ind].projectJoinRequests
                  ? [
                    ...tmp[ind].projectJoinRequests,
                    { ...isChatCountProjects },
                  ]
                  : [{ ...isChatCountProjects }];
              }
              console.log("------------------>internal request", tmp);
              dispatch({ type: INTERNAL_PROJECTS, projects: [...tmp] });
            }
          } else {
            let isInternal = -1;
            let isExternal = projects.findIndex(
              (e) => e.id == isChatCountProjects.building
            );
            if (isChatCountProjects.action === "increase") {
              let TXX = [...internalProjectsAll];
              for (let obj of TXX) {
                if (obj.userProjectList && obj.userProjectList.length > 0) {
                  let ind = obj.userProjectList.findIndex(
                    (e) => e.id == isChatCountProjects.building
                  );
                  if (ind !== -1) {
                    isInternal = 1;
                    obj.totalUnreadChat = obj.totalUnreadChat + 1;
                    obj.userProjectList[ind].unseenBuildingChat =
                      obj.userProjectList[ind].unseenBuildingChat + 1;
                  }
                }
              }
              if (isExternal !== -1) {
                let TXXEXTERNAL = [...projects];
                TXXEXTERNAL[isExternal].unseenBuildingChat =
                  TXXEXTERNAL[isExternal].unseenBuildingChat + 1;
                dispatch({ type: PROJECTS, projects: TXXEXTERNAL });
              }
              dispatch({ type: INTERNAL_PROJECTS, projects: TXX });
              let a = { ...unSeenAllCount };
              let unseenInternalProject =
                isInternal !== -1
                  ? a.unseenInternalProject + 1
                  : a.unseenInternalProject;
              let unseenExternalProject =
                isExternal !== -1
                  ? a.unseenExternalProject + 1
                  : a.unseenExternalProject;
              let f = {
                ...a,
                unseenBuildingChatCount: a.unseenBuildingChatCount + 1,
                unseenInternalProject,
                unseenExternalProject,
              };
              let tmp = [...AllGroupsOfProjects];
              let allGrps = tmp.findIndex(
                (e) => e.projectId == isChatCountProjects.building
              );
              if (allGrps !== -1) {
                let currentGroup = [...tmp[allGrps].groups];
                let gIndex = currentGroup.findIndex(
                  (e) => e.id == isChatCountProjects.group
                );
                if (gIndex !== -1) {
                  tmp[allGrps].groups[gIndex].unseenChatCount =
                    tmp[allGrps].groups[gIndex].unseenChatCount + 1;
                  dispatch({
                    type: SET_ALLGROUPS_PROJECTS,
                    payload: {
                      projectId: isChatCountProjects.building,
                      newGroups: tmp[allGrps].groups,
                    },
                  });
                }
              }
              props.updateAllBadgeCount(f);
            }
            if (isChatCountProjects.action === "decrease") {
              getBadgesCount();
              _getLatestGroup();
              const newOpenProjects = [...openProjects, ...openBottomProjects];
              if (newOpenProjects.length > 0) {
                for (const projectInfo of newOpenProjects) {
                  getChats(projectInfo);
                }
              }
              getKjuupPlusProjects();
            }
          }
        }
      }
      setIsChatCountProjects(false);
    }
  }, [isChatCountProjects]);

  // console.log('userLoginToken',userLoginToken)

  const setUserOnline = (SocketObject) => {
    let ttn = $user.getToken()
    let onlineObj1 = {
      url: `${API_ROOT}/user/isOnline`,
      method: 'get',
      headers: {
        Authorization: `jwt ${ttn}`
      },
    }
    dispatch({ type: SET_USER_LOGIN_TOKEN, status: ttn })
    if (SocketObject && SocketObject.emit) {
      SocketObject.emit("get", onlineObj1, async (data) => {
        console.log("============+> Socket isOnline", data);
      });
    }
  };


  useEffect(() => {
    if (Socket && Socket.on) {
      try {
        Socket.emit("get", badgeObj, async (data) => { });
      } catch (e) {
        console.log("Exception:", e);
      }
    }
  }, [Socket]);

  var interVal;

  useEffect(() => {
    //check for new version every 10 minutes
    interVal = setInterval(() => {
      VersionCheckButton()
    }, 600000)
  }, [])



  const checkCacheStatus = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch("/meta.json");
        const { version: metaVersion } = await res.json();
        // console.log('metaVersion', metaVersion,version);
        const shouldForceRefresh = isThereNewVersion(metaVersion, version);
        if (shouldForceRefresh) {
          // console.log(`There is a new version (v${metaVersion}). Should force refresh.`);
          resolve(true); // Return true for new version
        } else {
          // console.log('There is no new version. No cache refresh needed.');
          return false; // Return false for no new version
        }
      } catch (error) {
        // console.log('An error occurred while checking cache status.', true);
        // console.log(error, true);
        return false; // Return false in case of error
      }
    });
  }


  var isThereNewVersion = function isThereNewVersion(metaVersion, currentVersion) {
    return compare(metaVersion, currentVersion, '>');
  };

  const VersionCheckButton = async () => {
    try {
      let isNewVersion = await checkCacheStatus(); // Await the result from checkCacheStatus
      // console.log('-----isNew version', isNewVersion);
      if (isNewVersion) {
        clearInterval(interVal);
        setTimeout(async () => {
          await $crud.confirm({
            title: isGerm ? DE.update_availble : EN.update_availble,
            textContent: isGerm ? DE.update_text : EN.update_text,
            options: {
              ok: isGerm ? DE.restart : EN.restart,
              cancel: ""
            },
          });
          _resetAndReload();
        }, 1000)
      }
    } catch (error) {
      console.error('Error checking version:', error);
    }
  };


  const _resetAndReload = () => {
    var _temp3 = function () {
      var _window;

      if ((_window = window) !== null && _window !== void 0 && _window.caches) {
        var _window2 = window,
          caches = _window2.caches;
        return Promise.resolve(caches.keys()).then(function (cacheNames) {
          var cacheDeletionPromises = cacheNames.map(function (n) {
            return caches["delete"](n);
          });
          return Promise.resolve(Promise.all(cacheDeletionPromises)).then(function () {
            console.log('The cache has been deleted.');
            window.location.reload(true);
          });
        });
      }
    }();

    if (_temp3 && _temp3.then) return _temp3.then(function () { });
  }

  useEffect(() => {
    if (performance.navigation.type === 1) {
      console.log("Page reloaded");
      getProfile()
      // Add your logic here
    }
  }, []);

  const getProfile = async () => {
    const { data } = await callAPI(`/user/profile`, {}, "GET");
    let profile = data?.profile;
    $user.updateUserData({ ...user, avatar: profile?.avatar })
  };

  if (isLoggedIn === undefined || isLoggedIn) {
    return (
      <SocketContext.Provider value={Socket}>
        <LanguageProvider value={lgVal}>
          <div className={`App ${isLoggedIn ? "innerBg" : ""}`}>
            <Router>
              <SideNav />
              <div
                style={{
                  display: "flex",
                  width: drawerOpen ? "100%" : "calc(100% + 420px)",
                  marginLeft: drawerOpen ? "0" : "-420px",
                  transition: "margin-left 800ms",
                }}
              >
                <Switch>
                  <Route
                    exact
                    path="/security-pin-main"
                    component={SecurityPINMain}
                  />
                  <Route exact path="/" component={Notifications} />
                  <Route exact path="/profile" component={Profile} />
                  <Route
                    exact
                    path="/user-profile/:id"
                    component={UserProfile}
                  />
                  <Route exact path="/edit-profile" component={EditProfile} />
                  <Route
                    exact
                    path="/company-details/:id"
                    component={CompanyDetails}
                  />
                  <Route
                    exact
                    path="/company-setting/:id/:deptId"
                    component={CompanySetting}
                  />
                  <Route
                    exact
                    path="/foreign-company-details/:id"
                    component={ForeignCompanyDetails}
                  />
                  <Route exact path="/my-companies" component={MyCompany} />
                  <Route exact path="/companies" component={Companies} />
                  <Route
                    exact
                    path="/company-followers/:id"
                    component={CompanyFollowers}
                  />
                  <Route exact path="/followings" component={Followings} />
                  <Route
                    exact
                    path="/user-followings/:id"
                    component={UserFollowings}
                  />
                  <Route exact path="/followers" component={Followers} />
                  <Route
                    exact
                    path="/user-followers/:id"
                    component={UserFollowers}
                  />
                  <Route exact path="/add-company" component={AddCompany} />
                  <Route
                    exact
                    path="/edit-company/:id"
                    component={EditCompany}
                  />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/articles" component={Articles} />
                  <Route
                    exact
                    path="/LatestMessages"
                    component={LatestMessages}
                  />
                  <Route exact path="/add-article" component={CreateArticle} />
                  <Route
                    exact
                    path="/people-likes/:id"
                    component={ArticleLike}
                  />
                  <Route
                    exact
                    path="/add-company-article/:id"
                    component={CreateCompanyArticle}
                  />
                  <Route
                    exact
                    path="/add-personal-article"
                    component={AddPersonalArticle}
                  />
                  <Route
                    exact
                    path="/edit-personal-article/:id"
                    component={EditPersonalArticle}
                  />
                  <Route
                    exact
                    path="/edit-company-article/:id/:articleId"
                    component={EditCompanyArticle}
                  />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/add-project" component={AddProject} />
                  <Route
                    exact
                    path="/edit-project/:id"
                    component={EditProject}
                  />
                  <Route exact path="/capacities" component={Capacities} />
                  <Route exact path="/free-capacity" component={FreeCapacity} />
                  <Route
                    exact
                    path="/add-user/:id/:departmentId"
                    component={CompanyUser}
                  />
                  <Route exact path="/company" component={Company} />
                  <Route
                    exact
                    path="/profile-setting"
                    component={ProfileSetting}
                  />
                  <Route
                    exact
                    path="/invite-user/:id/:departmentId"
                    component={ExternalMit}
                  />
                  <Route
                    exact
                    path="/service-members/:id"
                    component={CompanyServicesMember}
                  />
                  <Route
                    exact
                    path="/add-service/:id"
                    component={AddServices}
                  />
                  <Route exact path="/project-list" component={Project} />
                  <Route
                    exact
                    path="/project-detail/:id"
                    component={ProjectDetail}
                  />
                  <Route
                    exact
                    path="/add-project-user/:id"
                    component={AddProjectUser}
                  />
                  <Route
                    exact
                    path="/invite-project-user/:id"
                    component={InviteProjectUser}
                  />
                  <Route
                    exact
                    path="/media-links-docs/:id"
                    component={MediaLinksDocs}
                  />
                  <Route exact path="/feed-detail/:id" component={FeedDetail} />
                  <Route
                    exact
                    path="/one-to-one-chat-user"
                    component={AddOneToOneUser}
                  />
                  <Route
                    exact
                    path="/follow-one-to-one"
                    component={FollowOneToOne}
                  />
                  <Route
                    exact
                    path="/weekly-statistics"
                    component={WeeklyStatistics}
                  />
                  <Route exact path="/view-all" component={AllContact} />
                  <Route exact path="/demo" component={Home} />
                </Switch>
                <RightBar />
              </div>
            </Router>
            <ConfirmDialog />
            <CardonContainer />
            <NotifySnackbar autoHideDuration={5000} />
          </div>
          {props.ViewerImages && props.ViewerImages.length > 0 ? (
            <FullScreenImageViewer reduxImages={props.ViewerImages} />
          ) : (
            <></>
          )}
        </LanguageProvider>
      </SocketContext.Provider>
    );
  }

  if (isLoggedIn !== undefined) {
    if (!isLoggedIn) {
      return (
        <SocketContext.Provider value={Socket}>
          <LanguageProvider value={lgVal}>
            <div className="App" style={{ backgroundImage: "white" }}>
              <div className="fronpanel">
                <Router>
                  <Switch>
                    <Route exact path="/">
                      <Login />
                    </Route>
                    <Route exact path="/register">
                      <Register />
                    </Route>
                    <Route exact path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route exact path="/security-pin" component={SecurityPIN} />
                    <Route path="*" component={Login} />
                  </Switch>
                </Router>
              </div>
            </div>
            {/* {isLoading ?
              <Loading isGerm={isGerm} />
              :
              <></>
            } */}
          </LanguageProvider>
        </SocketContext.Provider>
      );
    }
  }


}

const mapStateToProps = (state) => {
  const { unseenNewsCount, openChats, openBottomChats, ViewerImages } = state;
  return {
    unseenNewsCount,
    openChats,
    openBottomChats,
    ViewerImages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateNewsCount: (count) =>
      dispatch({ type: NEWS_BADGE_COUNT, unseenNewsCount: count }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
