import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Badge, Media } from 'react-bootstrap';
import { BsThreeDots } from "react-icons/bs"
import Layout from "../../components/Layout";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import { Animated } from "react-animated-css";
import { callAPI } from "../../helper/apiUtils";
import * as moment from "moment";
import { AgoDateFormat } from "../../helper/AgoDateFormat";
import { BASE_URL, KJUUP_COLORS, capitalizeFirstLetter } from "../../helper/constants";
import { Scrollbars } from "react-custom-scrollbars";
import { GROUPS, GROUP_CHATS, NEWS_BADGE_COUNT, OPEN_CHATS, OPEN_PROJECTS, RESET_MARKIN_PROJECT, RIGHT_TOP, SET_ACTIVITY_MESSAGES, SET_IN_ACTIVITY, UPDATE_PROJECT_ACTION_FIRE } from "../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { $user } from "../../helper/UserFactory";
import { convertPosition } from '../../helper/convertor';
import { AiOutlineFile } from 'react-icons/ai';
import { MdVideocam } from 'react-icons/md';
import { IoMdImage } from 'react-icons/io';
import { Discuss, Rings, Oval } from 'react-loader-spinner'
import { $crud } from '../../helper/CrudFactory';
import { getUsernameFromText, onlyUsername } from '../Project/GroupChat/getUsername';


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

function Notifications(props) {
    const { isGerm } = React.useContext(LanguageContext);
    const [earlierNotifications, setEarlierNotifications] = useState([]);
    const [latestNotifications, setLatestNotifications] = useState([]);
    const [isTabSelect, setIsTabSelect] = useState(false)
    const [AllChats, setAllChats] = useState([])
    const [gettingChats, setGettingChats] = useState(false)
    const [gettingChatsBottom, setGettingChatsBottom] = useState(false)
    const [page, setPage] = useState(1)
    const history = useHistory();
    const isNewMessage = useSelector(e => e.isNewMessage)
    const activityMessages = useSelector(e => e.activityMessages)
    const inActivity = useSelector(e => e.inActivity)
    const dispatch = useDispatch()

    useEffect(() => {
        if (inActivity) {
            setGettingChats(true)
            props.resetMessages()
            dispatch({ type: RESET_MARKIN_PROJECT, status: true })
            props.updateGroupChats([])
            props.updateOpenChats([])
            props.updateOpenProjects([])
            _getMessages(1)
        }
        return () => {
            dispatch({ type: RESET_MARKIN_PROJECT, status: true })
            props.resetMessages()
            props.updateGroupChats([])
            props.updateOpenChats([])
            props.updateOpenProjects([])
        }
    }, [inActivity])


    const _getMessages = async (page) => {
        const { data } = await callAPI(`/group/getUserChats?page=${page}&limit=20`, {}, 'GET');
        if (data.status) {
            data.chats = data.chats.map((e) => {
                return { ...e, id: e.buildingList, name: e.buildingName, building: e.buildingList, group: { ...e.group, building: e.buildingList } }
            })
            if (AllChats.length > 0 && data.chats.length > 0) {
                let tt = data.chats
                let x = AllChats.filter((e) => e.isOpened)
                x = x.map((e) => {
                    return e.group.id
                })
                x = [...new Set(x)]
                tt = tt.map((e) => {
                    return { ...e, isOpened: x.includes(e.group.id) ? true : false }
                })
                let _m = [...AllChats, ...tt]
                dispatch({ type: SET_ACTIVITY_MESSAGES, messages: _m })
                // setAllChats(_m)
            }
            else {
                // setAllChats(data.chats)
                dispatch({ type: SET_ACTIVITY_MESSAGES, messages: data.chats })
            }
            setGettingChats(false)
            setGettingChatsBottom(false)
        }
        else {
            setGettingChats(false)
            setGettingChatsBottom(false)
        }
    }

    const { userLoginToken } = useSelector((state) => state);


    const get = async () => {
        try {
            if (userLoginToken) {
                const { data } = await callAPI('/user/notification', {}, 'GET');
                if (data.status) {
                    let today = moment(new Date()).format('YYYY-MM-DD');
                    let yesterday = moment(new Date().toUTCString()).subtract(1, "days").format('YYYY-MM-DD');
                    let check = false;
                    let news = data.notification;
                    await news.forEach((value, index) => {
                        check = false;
                        value.latest = false;
                        value.earlier = false;
                        value.backgroundCss = 'grey';
                        let date = moment(value.date).format('YYYY-MM-DD');
                        if (index === 0 || date === today) {
                            value.latest = true;
                        }
                        if (!check && (date === yesterday || date < yesterday)) {
                            value.earlier = true;
                            check = true;
                        }
                        if (check) {
                            value.backgroundCss = 'white';
                        }
                    });
                    const LatestNews = news.filter(n => n.latest === true);
                    const EarlierNews = news.filter(n => n.earlier === true);
                    setEarlierNotifications(EarlierNews);
                    setLatestNotifications(LatestNews);
                }
            }
        } catch (e) {
            logout();
        }
    };

    const logout = async () => {
        await $user.logout();
        history.push('/')
    }

    useEffect(() => {
        get();
        props.updateNewsCount(0)
    }, []);

    const ViewStatic = (news) => {
        history.push({
            pathname: '/weekly-statistics',
            state: {
                news: news
            }
        })
    };

    const _renderTabs = () => {
        return (
            <div className='tabBar' style={{ marginTop: 5 }}>
                <div onClick={() => setIsTabSelect(!isTabSelect)} className='tab1' style={{ padding: 10, backgroundColor: isTabSelect ? 'lightgray' : 'transparent' }}>
                    <span style={{ color: isTabSelect ? '#fff' : 'grey', fontWeight: '500' }}>{isGerm ? DE.latest_messages : EN.latest_messages}</span>
                </div>
                <div style={{ width: 20 }} />
                <div onClick={() => setIsTabSelect(!isTabSelect)} className='tab1' style={{ padding: 10, backgroundColor: !isTabSelect ? 'lightgray' : 'transparent' }}>
                    <span style={{ color: !isTabSelect ? '#fff' : 'grey', fontWeight: '500' }}>{isGerm ? DE.news : EN.news}</span>
                </div>
            </div>
        )
    }
    const chat_click = async (group, index) => {
        const chk = props.openChats.find((g) => g.id === group.group.id);
        if (chk) {
            const openGroups = props.openChats.filter((p) => p.id !== group.group.id);
            if (openGroups?.length > 2) {
                $crud.notify({
                    type: "error",
                    message: isGerm ? DE.more_than_two : EN.more_than_two,
                });
            } else {
                let tmp = [...AllChats]
                tmp = tmp.map((e) => {
                    return { ...e, isOpened: (e.group.id == group.group.id) ? false : e.isOpened }
                })
                // setAllChats([...tmp])
                dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp })
                props.updateOpenChats(openGroups);
            }
        } else {
            let tempData = [...props.openChats, { ...group.group }]
            if (tempData?.length > 2) {
                $crud.notify({
                    type: "error",
                    message: isGerm ? DE.more_than_two : EN.more_than_two,
                });
            } else {
                let tmp = [...AllChats]
                tmp = tmp.map((e) => {
                    return { ...e, isOpened: (e.group.id == group.group.id) ? true : e.isOpened }
                })
                // setAllChats([...tmp])
                dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp })
                props.updateOpenChats([...props.openChats, { ...group.group }]);
            }
        }
    };


    const chat_click_group = async (group, index) => {
        // _openGroups(group, index)
        const chk = props?.openProjects?.find((p) => p.id == group.buildingList);
        if (chk) {
            const updateProjects = props.openProjects?.filter(
                (p) => p.id !== group.buildingList
            );
            let tmp = [...AllChats]
            tmp = tmp.map((e) => {
                return { ...e, isOpened: (e.buildingList == group.buildingList) ? false : e.isOpened }
            })
            // setAllChats([...tmp])
            dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp })
            props.updateGroupChats([])
            props.updateOpenProjects(updateProjects);
        } else {
            let tmp = [...AllChats]
            tmp = tmp.map((e) => {
                return { ...e, isOpened: (e.buildingList == group.buildingList) ? true : e.isOpened }
            })
            // setAllChats([...tmp])
            dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp })
            const updateProjects = [...props?.openProjects, group]
            props.updateOpenProjects(updateProjects);
            const chk = props?.groupChats?.find((p) => p.id == group.group.id);
            if (!chk) {
                const updateGrrousp = [...props?.groupChats, group.group]
                props.updateGroupChats(updateGrrousp)
            }

        }
    };

    const _renderOnetToOne = (group, index) => {
        return (
            <div className="company_services m-0 chat_services">
                <div
                    key={index}
                    className={`company_centact ${group.isOpened ? "active-project" : ""}`}
                    onClick={(e) => {
                        // if (!props.forwardMessage)
                        chat_click(group, index)
                    }}
                    style={{ opacity: group?.isDeactivated ? 0.5 : 1, paddingBottom: 0, display: "flex", borderBottom: "1px solid #DFDFDF" }}
                >
                    <a className="website pointer" style={{ flex: 1 }}>
                        <div className="website_img">
                            <img
                                width={40}
                                height={40}
                                style={{ borderRadius: "5px" }}
                                src={
                                    group.avatar
                                        ? BASE_URL + group.avatar
                                        : process.env.PUBLIC_URL +
                                        "/assets/img/profile.png"
                                }
                                alt="Generic placeholder"
                            />
                            {/* {group.unseenChatCount > 0 ? (
                                <Badge pill>{group.unseenChatCount}</Badge>
                            ) : (
                                ""
                            )} */}
                        </div>
                        <div className="details-company forword-checkbox_main" style={{ border: "none" }}>
                            <div className="m-0">
                                <h5>{group.group.name}</h5>
                                <div className="m-0">
                                    {group.lastMessageDeleted ? (
                                        <p className="m-0 text-truncate">
                                            {isGerm
                                                ? DE.message_delete
                                                : EN.message_delete}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    {group.messageType === "simple" && (
                                        <p className="m-0 text-truncate">
                                            {group.message?.replaceAll("<br/>", " ")}
                                        </p>
                                    )}
                                    {group.messageType === "doc" &&
                                        !group.lastMessageDeleted && (
                                            <p className="m-0 text-truncate">
                                                <AiOutlineFile />
                                            </p>
                                        )}
                                    {group.messageType === "video" &&
                                        !group.lastMessageDeleted && (
                                            <p className="m-0 text-truncate">
                                                <MdVideocam /> {group.message}
                                            </p>
                                        )}
                                    {group.messageType === "image" &&
                                        !group.lastMessageDeleted && (
                                            <p className="m-0 text-truncate">
                                                <IoMdImage />
                                            </p>
                                        )}
                                </div>

                            </div>
                        </div>
                    </a>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 10, flexDirection: "column", paddingBottom: 5 }}>
                        <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(group.createdAt, isGerm)}</div>
                    </div>
                </div>
            </div>
        )
    }
    const getProjectName = (name) => {
        if (name) {
            const nameArray = name?.split(" ");
            if (nameArray && nameArray.length) {
                const length = nameArray.length;
                let newName = [];
                newName.push(nameArray[0][0]);
                if (nameArray.length > 1) {
                    newName.push(nameArray[length - 1][0]);
                }
                return newName.join("");
            }
        }
    };
    const _renderGroupChats = (group, index) => {
        return (
            <div className="company_services m-0 chat_services">
                <div
                    key={index}
                    className={`company_centact ${group.isOpened ? "active-project" : ""}`}
                    onClick={(e) => {
                        // if (!props.forwardMessage)
                        chat_click_group(group, index)
                    }}
                    style={{ opacity: group?.isDeactivated ? 0.5 : 1, paddingBottom: 0, display: "flex", borderBottom: "1px solid #DFDFDF" }}
                >
                    <a className="website pointer" style={{ flex: 1 }}>
                        <div className="website_img">
                            <div style={{ height: 40, color: "#fff", fontSize: "16px", fontWeight: '600', width: 40, textAlign: 'center', backgroundColor: group.buildingThemeColor, borderRadius: 5, justifyContent: "center", display: "flex", flexDirection: "column" }}>
                                <div style={{ flex: 1 }} />
                                {getProjectName(group.buildingName)}
                                <div style={{ flex: 1 }} />
                            </div>
                        </div>
                        <div className="details-company forword-checkbox_main" style={{ border: "none" }}>
                            <div className="m-0">
                                <h5 style={{ fontWeight: "bold" }}>{group.buildingName}</h5>
                                <h5>{group.group.name}</h5>
                                <div className="m-0">
                                    {group.lastMessageDeleted ? (
                                        <p className="m-0 text-truncate">
                                            {isGerm
                                                ? DE.message_delete
                                                : EN.message_delete}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    {group.messageType === "simple" && (
                                        group.message ?
                                            <p className="m-0 text-truncate">
                                                {onlyUsername(group.message.replace(/<br\s*[/]?>/gi, '\n') // Replace <br> tags with newline
                                                    .replace(/<[^>]+>/g, '')
                                                    .replace(/&nbsp;/g, ' '))}
                                            </p>
                                            :
                                            <></>
                                    )}
                                    {group.messageType === "doc" &&
                                        !group.lastMessageDeleted && (
                                            <p className="m-0 text-truncate">
                                                <AiOutlineFile />
                                            </p>
                                        )}
                                    {group.messageType === "video" &&
                                        !group.lastMessageDeleted && (
                                            <p className="m-0 text-truncate">
                                                <MdVideocam /> {group.message}
                                            </p>
                                        )}
                                    {group.messageType === "image" &&
                                        !group.lastMessageDeleted && (
                                            <p className="m-0 text-truncate">
                                                <IoMdImage />
                                            </p>
                                        )}
                                </div>
                            </div>
                        </div>
                    </a>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: 10, flexDirection: "column", paddingBottom: 5 }}>
                        <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(group.createdAt, isGerm)}</div>
                    </div>
                </div>
            </div>
        )
    }
    const _renderChats = (item, index) => {
        return (
            item.group ? item.group.isOneToOne ?
                _renderOnetToOne(item, index) : _renderGroupChats(item, index) : <></>
        )
    }
    const handleScroll = (e) => {
        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop) <= parseInt(e.target.clientHeight);
        if (bottom && !gettingChatsBottom) {
            let pg = page + 1
            setPage(pg)
            setGettingChatsBottom(true)
            _getMessages(pg)
        }
    }
    return (
        <React.Fragment>
            <Layout>
                <Animated animationIn="slideInLeft" animationOut="slideOutLeft">
                    <div className="w-300 notification">
                        <div className="news_heading">
                            {isGerm ? DE.news : EN.news}
                        </div>
                        {/* {_renderTabs()} */}
                        {!isTabSelect ?
                            <div className="my-scroll pl-2 pr-2" style={{ height: "93vh" }}>
                                <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}
                                    className="bottom-bar">
                                    {
                                        latestNotifications.length > 0 &&
                                        <div>
                                            <div className="earlier">
                                                {isGerm ? DE.latest : EN.latest}
                                            </div>
                                            {
                                                latestNotifications &&
                                                latestNotifications.map((news, index) => (

                                                    <div key={index}>
                                                        {
                                                            news.action === 'user_follow' &&
                                                            <div className="post_notification">
                                                                <Link className="text-black" to={`/user-profile/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{news.fullName} <span>{isGerm ? DE.is_following_you : EN.is_following_you}</span></h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/user-profile/${news.id}`}> <BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            news.action === 'company_follow' &&
                                                            <div className="post_notification">
                                                                <Link className="text-black" to={`/company-details/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    {
                                                                                        isGerm ?
                                                                                            <h5>{news.fullName} hat das Unternehmen {news.companyName} abonniert.</h5> :

                                                                                            <h5>{news.fullName} is following the {news.companyName} company.</h5>
                                                                                    }
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <a href="#"><BsThreeDots className="float-right" /></a>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            news.action === 'buildingJoinNews' &&
                                                            // <div className="post_notification">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>Sie</span>} {news.id !== user?.id ? 'ist' : 'sind'} dem Raum <span>{news.buildingName}</span> beigetreten.</h5> :
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>You</span>}  {news.id !== user?.id ? 'has' : 'have'} joined the <span>{news.buildingName}</span> room.</h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }
                                                        {
                                                            news.action === 'buildingLeaveNews' &&
                                                            // <div className="post_notification">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5><span>{news.otherUserFullName}</span> hat das Projekt <span>{news.buildingName}</span> verlassen.</h5> :
                                                            //                             <h5><span>{news.otherUserFullName}</span> has left the <span>{news.buildingName}</span> room.</h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }
                                                        {
                                                            news.action === 'buildingAddedToNews' &&
                                                            // <div className="post_notification">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>Sie</span>} {news.id !== user?.id ? 'ist' : 'sind'} dem Raum <span>{news.buildingName}</span> beigetreten.</h5> :
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>You</span>} {news.id !== user?.id ? 'has' : 'have'} joined the <span>{news.buildingName}</span> room.</h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }

                                                        {
                                                            news.action === 'publish' &&
                                                            <div className="post_notification">
                                                                <Link className="text-black" to={`/feed-detail/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{news.fullName}<span> {isGerm ? DE.has_published_a_new_article : EN.has_published_a_new_article}</span></h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/feed-detail/${news.id}`}><BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                        }

                                                        {
                                                            news.action === 'like' &&
                                                            <div className="post_notification">
                                                                <Link className="text-black" to={`/feed-detail/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{news.fullName}<span> {isGerm ? DE.has_liked_your_article : EN.has_liked_your_article}</span></h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/feed-detail/${news.id}`}><BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>


                                                        }
                                                        {
                                                            news.action === 'viewCount' &&
                                                            <div className="post_notification">
                                                                <div className="my_post pointer" onClick={() => ViewStatic(news)}>
                                                                    <Media>
                                                                        <img
                                                                            width={40}
                                                                            height={40}
                                                                            className="mr-3"
                                                                            src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/search.svg"}
                                                                            alt="Generic placeholder"
                                                                        />
                                                                        <Media.Body>
                                                                            <div className="authore_name">
                                                                                {isGerm ?
                                                                                    <h5>Sie sind in der letzten Woche <span>{news.count}</span> Mal in der Suche erschienen.</h5>
                                                                                    :
                                                                                    <h5>You have appeared <span>{news.count} time</span> in searches in the past week.</h5>
                                                                                }
                                                                            </div>
                                                                        </Media.Body>
                                                                    </Media>
                                                                    <div className="right_notification">
                                                                        <BsThreeDots className="float-right" />
                                                                        <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                        {
                                                            news.action === 'view' &&
                                                            <div className="post_notification">
                                                                <div className="my_post">
                                                                    <Media>
                                                                        <img
                                                                            width={40}
                                                                            height={40}
                                                                            className="mr-3"
                                                                            src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                            alt="Generic placeholder"
                                                                        />
                                                                        <Media.Body>
                                                                            <div className="authore_name">
                                                                                <h5>{news.fullName}<span> {isGerm ? DE.has_looked_at_your_profile : EN.has_looked_at_your_profile}</span></h5>
                                                                                <Link to="/view-all" className="pointer theme-color bg-gray-btn">{isGerm ? DE.all_views : EN.all_views}</Link>
                                                                            </div>
                                                                        </Media.Body>
                                                                    </Media>
                                                                    <div className="right_notification">
                                                                        <BsThreeDots className="float-right" />
                                                                        <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        }

                                                        {
                                                            news.action === 'confirmCompanyRequest' &&
                                                            <div className="post_notification">
                                                                <Link className="text-black" to={`/my-companies`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{isGerm ? DE.your_request_at_the_company : EN.your_request_at_the_company} <span>{news.companyName}</span> {isGerm ? DE.as : EN.as} <span>{news.position}</span> {isGerm ? DE.has_been_confirmed : EN.has_been_confirmed}</h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/my-companies`}> <BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            news.action === 'addUserToProject' &&
                                                            // <div className="post_notification bg-white">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5><span>{news.otherUserFullName}</span> wurde zum Raum <span>{news.buildingName}</span> eingeladen.</h5> :
                                                            //                             <h5><span>{news.otherUserFullName}</span> has been invited to the room <span>{news.buildingName + '.'}</span></h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }
                                                        {
                                                            news.action === 'otherUserCompanyJoinNews' &&
                                                            <div className="post_notification">
                                                                <Link className="text-black" to={`/my-companies`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    {isGerm ?
                                                                                        <h5><span>{news.otherUserFullName}</span> {DE.has_joined_the} <span>{news.companyName}</span> mit der Position <span>{capitalizeFirstLetter(convertPosition(isGerm, news.position))}</span> beigetreten.</h5>
                                                                                        :
                                                                                        <h5><span>{news.otherUserFullName}</span> {isGerm ? DE.has_joined_the : EN.has_joined_the} <span>{news.companyName}</span> {isGerm ? DE.as : EN.as} <span>{capitalizeFirstLetter(convertPosition(isGerm, news.position)) + "."}</span></h5>}
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/my-companies`}> <BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                    {
                                        earlierNotifications.length > 0 &&
                                        <div>
                                            <div className="earlier m-0 border-top">
                                                {isGerm ? DE.earlier : EN.earlier}
                                            </div>
                                            {
                                                earlierNotifications &&
                                                earlierNotifications.map((news, index) => (


                                                    <div key={index}>
                                                        {
                                                            news.action === 'user_follow' &&
                                                            <div className="post_notification bg-white">
                                                                <Link className="text-black" to={`/user-profile/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{news.fullName} <span>{isGerm ? DE.is_following_you : EN.is_following_you}</span></h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/user-profile/${news.id}`}> <BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            news.action === 'company_follow' &&
                                                            <div className="post_notification bg-white">
                                                                <Link className="text-black" to={`/company-details/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    {
                                                                                        isGerm ?
                                                                                            <h5>{news.fullName} hat das Unternehmen {news.companyName} abonniert.</h5> :

                                                                                            <h5>{news.fullName} is following the {news.companyName} company.</h5>
                                                                                    }
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <a href="#"><BsThreeDots className="float-right" /></a>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            news.action === 'addUserToProject' &&
                                                            // <div className="post_notification bg-white">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5><span>{news.otherUserFullName}</span> wurde zum Raum <span>{news.buildingName}</span> eingeladen.</h5> :
                                                            //                             <h5><span>{news.otherUserFullName}</span> has been invited to the room <span>{news.buildingName + '.'}</span></h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }
                                                        {
                                                            news.action === 'buildingJoinNews' &&
                                                            // <div className="post_notification bg-white">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>Sie</span>} {news.id !== user?.id ? 'ist' : 'sind'} dem Raum <span>{news.buildingName}</span> beigetreten.</h5> :
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>You</span>}  {news.id !== user?.id ? 'has' : 'have'} joined the <span>{news.buildingName}</span> room.</h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }
                                                        {
                                                            news.action === 'buildingLeaveNews' &&
                                                            // <div className="post_notification bg-white">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5><span>{news.otherUserFullName}</span> hat das Projekt <span>{news.buildingName}</span> verlassen.</h5> :
                                                            //                             <h5><span>{news.otherUserFullName}</span> has left the <span>{news.buildingName}</span> room.</h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }
                                                        {
                                                            news.action === 'buildingAddedToNews' &&
                                                            // <div className="post_notification">
                                                            //     <div className="my_post pointer" onClick={viewProjects}>
                                                            //         <Media>
                                                            //             <img
                                                            //                 width={40}
                                                            //                 height={40}
                                                            //                 className="mr-3"
                                                            //                 src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                            //                 alt="Generic placeholder"
                                                            //             />
                                                            //             <Media.Body>
                                                            //                 <div className="authore_name">
                                                            //                     {
                                                            //                         isGerm ?
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>Sie</span>} {news.id !== user?.id ? 'ist' : 'sind'} dem Raum <span>{news.buildingName}</span> beigetreten.</h5> :
                                                            //                             <h5>{news.id !== user?.id ? <span>{news.otherUserFullName}</span> : <span>You</span>} {news.id !== user?.id ? 'has' : 'have'} joined the <span>{news.buildingName}</span> room.</h5>
                                                            //                     }
                                                            //                 </div>
                                                            //             </Media.Body>
                                                            //         </Media>
                                                            //         <div className="right_notification">
                                                            //             <BsThreeDots className="float-right" />
                                                            //             <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                            //         </div>
                                                            //     </div>
                                                            // </div>
                                                            <></>
                                                        }

                                                        {
                                                            news.action === 'publish' &&
                                                            <div className="post_notification bg-white">
                                                                <Link className="text-black" to={`/feed-detail/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{news.fullName}<span> {isGerm ? DE.has_published_a_new_article : EN.has_published_a_new_article}</span></h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/feed-detail/${news.id}`}><BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                        }

                                                        {
                                                            news.action === 'like' &&
                                                            <div className="post_notification bg-white">
                                                                <Link className="text-black" to={`/feed-detail/${news.id}`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{news.fullName}<span> {isGerm ? DE.has_liked_your_article : EN.has_liked_your_article}</span></h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/feed-detail/${news.id}`}><BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>


                                                        }
                                                        {
                                                            news.action === 'viewCount' &&
                                                            <div className="post_notification bg-white">
                                                                <div className="my_post pointer" onClick={() => ViewStatic(news)}>
                                                                    <Media>
                                                                        <img
                                                                            width={40}
                                                                            height={40}
                                                                            className="mr-3"
                                                                            src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/search.svg"}
                                                                            alt="Generic placeholder"
                                                                        />
                                                                        <Media.Body>
                                                                            <div className="authore_name">
                                                                                {isGerm ?
                                                                                    <h5>Sie sind in der letzten Woche <span>{news.count}</span> in Suchanfragen erschienen.</h5>
                                                                                    :
                                                                                    <h5>You have appeared <span>{news.count} time</span> in searches in past week.</h5>
                                                                                }
                                                                            </div>
                                                                        </Media.Body>
                                                                    </Media>
                                                                    <div className="right_notification">
                                                                        <BsThreeDots className="float-right" />
                                                                        <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                        {
                                                            news.action === 'view' &&
                                                            <div className="post_notification bg-white">
                                                                <div className="my_post">
                                                                    <Media>
                                                                        <img
                                                                            width={40}
                                                                            height={40}
                                                                            className="mr-3"
                                                                            src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                            alt="Generic placeholder"
                                                                        />
                                                                        <Media.Body>
                                                                            <div className="authore_name">
                                                                                <h5>{news.fullName}<span> {isGerm ? DE.has_looked_at_your_profile : EN.has_looked_at_your_profile}</span></h5>
                                                                                <Link to="/view-all" className="pointer theme-color bg-gray-btn">{isGerm ? DE.all_views : EN.all_views}</Link>
                                                                            </div>
                                                                        </Media.Body>
                                                                    </Media>
                                                                    <div className="right_notification">
                                                                        <BsThreeDots className="float-right" />
                                                                        <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        }

                                                        {
                                                            news.action === 'confirmCompanyRequest' &&
                                                            <div className="post_notification bg-white">
                                                                <Link className="text-black" to={`/my-companies`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    <h5>{isGerm ? DE.your_request_at_the_company : EN.your_request_at_the_company} <span>{news.companyName}</span> {isGerm ? DE.as : EN.as} <span>{news.position}</span> {isGerm ? DE.has_been_confirmed : EN.has_been_confirmed}</h5>
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/my-companies`}> <BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }

                                                        {
                                                            news.action === 'otherUserCompanyJoinNews' &&
                                                            <div className="post_notification bg-white">
                                                                <Link className="text-black" to={`/my-companies`}>
                                                                    <div className="my_post">
                                                                        <Media>
                                                                            <img
                                                                                width={40}
                                                                                height={40}
                                                                                className="mr-3"
                                                                                src={news.img ? BASE_URL + news.img : process.env.PUBLIC_URL + "/assets/img/profile.png"}
                                                                                alt="Generic placeholder"
                                                                            />
                                                                            <Media.Body>
                                                                                <div className="authore_name">
                                                                                    {isGerm ?
                                                                                        <h5><span>{news.otherUserFullName}</span> {DE.has_joined_the} <span>{news.companyName}</span> mit der Position <span>{capitalizeFirstLetter(convertPosition(isGerm, news.position))}</span> beigetreten.</h5>
                                                                                        :
                                                                                        <h5><span>{news.otherUserFullName}</span> {isGerm ? DE.has_joined_the : EN.has_joined_the} <span>{news.companyName}</span> {isGerm ? DE.as : EN.as} <span>{capitalizeFirstLetter(convertPosition(isGerm, news.position)) + "."}</span></h5>}
                                                                                </div>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        <div className="right_notification">
                                                                            <Link to={`/my-companies`}> <BsThreeDots className="float-right" /></Link>
                                                                            <div className="small float-right w-100 text-right color-gray">{AgoDateFormat(news.date, isGerm)}</div>

                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </CustomScrollbars>
                            </div>
                            :
                            <div className="my-scroll min_height pl-2 pr-2" style={{ height: "93vh" }}>
                                <div className='scroll' onScroll={handleScroll} style={{ height: "100%", overflowX: "hidden" }}>
                                    {
                                        AllChats.map((item, index) => {
                                            return _renderChats(item, index)
                                        })
                                    }
                                    {
                                        gettingChats ?
                                            <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10, marginBottom: 10, marginTop: 10, display: "flex", height: "100%", flexDirection: "column", alignItems: "center" }}>
                                                <div style={{ flex: 1 }} />
                                                <Oval
                                                    height={30}
                                                    width={30}
                                                    color={KJUUP_COLORS.base_color}
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor={"#fff"}
                                                    strokeWidth={5}
                                                    strokeWidthSecondary={5}
                                                />
                                                <div style={{ flex: 1 }} />
                                            </div>
                                            :
                                            <></>
                                    }
                                    {
                                        gettingChatsBottom ?
                                            <div style={{ alignSelf: "center", marginBottom: 10, marginTop: 10, textAlign: "center", display: "flex" }}>
                                                <div style={{ flex: 1 }} />
                                                <Oval
                                                    height={30}
                                                    width={30}
                                                    color={KJUUP_COLORS.base_color}
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor={"#fff"}
                                                    strokeWidth={5}
                                                    strokeWidthSecondary={5}
                                                />
                                                <div style={{ flex: 1 }} />
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Animated>
            </Layout>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { rightTop, unseenNewsCount, openChats, groups, groupChats, openProjects } = state;
    return {
        rightTop: rightTop,
        unseenNewsCount: unseenNewsCount,
        openChats: openChats,
        groups: groups,
        groupChats: groupChats,
        openProjects
    }
};
const mapDispatchToProps = dispatch => {
    return {
        resetMessages: () => dispatch({ type: SET_ACTIVITY_MESSAGES, messages: [] }),
        updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
        setInActivity: (status) => dispatch({ type: SET_IN_ACTIVITY, status: status }),
        updateRightTop: value => dispatch({ type: RIGHT_TOP, rightTop: value }),
        updateNewsCount: count => dispatch({ type: NEWS_BADGE_COUNT, unseenNewsCount: count }),
        updateOpenChats: (chats) => dispatch({ type: OPEN_CHATS, openChats: chats }),
        updateGroupChats: (chats) => dispatch({ type: GROUP_CHATS, groupChats: chats }),
        updateOpenProjects: (projects) => dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));
