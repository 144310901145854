import React, {useState} from "react";
import LanguageContext from "../../../helper/LanguageContext";
import {BASE_URL} from "../../../helper/constants";
import {Form} from "react-bootstrap";
import {DE} from "../../../Languages/DE";
import {EN} from "../../../Languages/EN";

const style = {
    marginBottom: 0,
    fontSize: '14px',
    backgroundColor: '#f5f5f5',
    padding: "13px 15px",
    fontWeight: 600
}

export default function CompanyListDialog({handleClose, setParam, companies, handleReJoinCompany, requestId = null}) {
    const {isGerm} = React.useContext(LanguageContext);
    const [allCompanies, setAllCompanies] = useState(companies);
    const [search, setSearch] = useState('');
    const [company, setCompany] = useState('');
    const handleChangeValue = async (company) => {
        setParam('companyId', company.id);
        setParam('companyName', company.name);
        setParam('companySelect', true);
        setCompany(company);
        console.log(requestId);
        handleReJoinCompany(company, requestId);
        handleClose();
    }

    const companyFilter = (value) => {
        setSearch(value)
        const filterCompanies = allCompanies.filter(company => {
            if (value === "") {
                return company;
            } else if (company.name.toLowerCase().includes(value.toLowerCase())) {
                return company;
            }
        })
        if (value === "") {
            console.log(companies);
            setAllCompanies(companies)
        } else {
            setAllCompanies(filterCompanies)
        }
    }

    return (
        <div class="backdrop">
            <div className="role_popup role_popup_right theme-border">
                <h6 style={style}>{isGerm ? DE.select_with_which_company_you_want_to_connect_to_the_project : EN.select_with_which_company_you_want_to_connect_to_the_project}</h6>
                <div className="role_data mb-0">
                    <div className="search p-2 m-0">
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control value={search} autocomplete="off" type="search"
                                          placeholder={isGerm ? DE.search : EN.search}
                                          onChange={e => companyFilter(e.target.value)}/>
                        </Form.Group>
                    </div>
                    <ul className="list-group">
                        {
                            allCompanies.map(company =>
                                <>
                                    <li key={company.id} className="list-group-item" style={{color: "black"}}>
                                        <img width={30} height={30} style={{borderRadius: 20, marginTop: 9}}
                                             src={company.logo ? BASE_URL + company.logo : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image-Round.svg"}
                                             alt="company Logo" className="float-left"/>

                                        <div className="list-group">
                                            <label key={company.id} className="role-label">
                                                <span className="font-weight-bold">{company.name}</span>
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                       id={company.id} onChange={e => handleChangeValue(company)}
                                                       value={company.id}/>
                                                <span className="checkmark-label"/>
                                            </label>
                                        </div>
                                    </li>
                                </>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}
