import React, { useEffect, useState } from "react";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { $crud } from "../../helper/CrudFactory";

const QRCode = require("qrcode.react");

function InviteContact(props) {
  const { isGerm } = React.useContext(LanguageContext);
  //   const [company, setCompany] = useState({});
  const [deeplink, setDeepLink] = useState("");
  const [toggleShareLink, setToggleShareLink] = useState(false);
  const [showQrCode, setQrCode] = useState(false);
  let iPhoneText = isGerm
    ? DE.iphone_recivers_message
    : EN.iphone_recivers_message;
  let iphone = isGerm ? DE.iphone : EN.iphone;
  let germenLangDeeplink = `${DE.contact_receivers_message}\n\n${deeplink}`;
  let engLangDeeplink = `${EN.contact_receivers_message}\n\n${deeplink}`;
  // const receiverMessage = deeplink
  //   ? `${
  //       isGerm ? DE.contact_receivers_message : EN.contact_receivers_message
  //     }\n\n${deeplink}\n\n${DE.iphone_recivers_message}\n\n${deeplink}`
  //   : "";
  const receiverMessage = deeplink
    ? isGerm
      ? germenLangDeeplink
      : engLangDeeplink
    : "";
  const getInviteLink = async () => {
    const { data } = await callAPI(
      `/invite-user/deeplink`,
      {
        inviteType: "signUpOnly",
      },
      "POST"
    );
    if (data.status) {
      let splitToken = data?.deeplinkUrl?.split("/");
      let getToken = splitToken[splitToken?.length - 1];
      let webLinkPayload = {
        data: {
          locallyIndex: true,
          title: "Kjuup",
          ratingAverage: 4.2,
          registraTionToken: getToken,
        },
      };
      window.branch.link(webLinkPayload, (err, link) => {
        setDeepLink(link);
      });
    }
  };
  useEffect(() => {
    getInviteLink();
  }, []);

  const handleShareUrl = () => {
    setToggleShareLink(!toggleShareLink);
    setQrCode(false);
  };
  const handleQrCode = () => {
    setToggleShareLink(false);
    setQrCode(!showQrCode);
  };

  const handleCopy = async () => {
    setToggleShareLink(false);
    setQrCode(false);
    navigator.clipboard.writeText(receiverMessage).then((r) => {
      $crud.notify({
        type: "success",
        message: isGerm ? DE.link_copied : EN.link_copied,
      });
    });
  };
  const handleCancel = async () => {
    setToggleShareLink(false);
    setQrCode(false);
    // const id = props.project_id;
    // const { data } = await callAPI(`/building/${id}/deeplink`, {}, "DELETE");
    // if (data.status) {
    //   setDeepLink(data.deeplinkUrl);
    // }
  };

  return (
    <React.Fragment>
      <div className="w-300 p-0 h-100">
        <div className="company_header  company_border">
          <div class="heading_icon m-0">
            <a
              onClick={(e) => props.handleClose()}
              className="main_heading"
              style={{ lineHeight: "27px" }}
            >
              <img
                width={10}
                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
              />
              {isGerm ? DE.invite_with_link : EN.invite_with_link}
            </a>
          </div>
        </div>
        <div className="p-2 company_border">
          {/* <div className=" p-0">
            <div className="profile_name">            
              <p>{deeplink}</p>
            </div>
          </div> */}
          <div>
            {isGerm ? DE.contact_sender_message : EN.contact_sender_message}
          </div>
        </div>
        <div
          className="profile_name row m-0 text-center pt-2"
          style={{ borderTop: "1px solid #bcbcbc", justifyContent:"center" }}
        >
          {/* <div className="col-md-4">
            <div onClick={handleShareUrl} className="pointer">
              <img
                width={20}
                src={process.env.PUBLIC_URL + "/assets/img/Share link.svg"}
              />
              <br />
              <span
                style={{
                  fontSize: "13px",
                  color: "#f18438",
                }}
              >
                {isGerm ? DE.share_link : EN.share_link}
              </span>
            </div>
          </div> */}
          {/* <div className="col-md-4">
            <div onClick={handleQrCode} className="pointer">
              <img
                width={30}
                src={process.env.PUBLIC_URL + "/assets/img/QR Code.svg"}
              />
              <br />
              <span
                style={{
                  fontSize: "13px",
                  color: "#f18438",
                }}
              >
                {isGerm ? DE.qr_code : EN.qr_code}
              </span>
            </div>
          </div> */}
          <div className="col-md-4">
            <div onClick={handleCopy} className="pointer">
              <img
                width={30}
                src={process.env.PUBLIC_URL + "/assets/img/Copy link.svg"}
              />
              <br />
              <span
                style={{
                  fontSize: "13px",
                  color: "#f18438",
                }}
              >
                {isGerm ? DE.copy_link : EN.copy_link}
              </span>
            </div>
          </div>
        
        </div>
        {toggleShareLink && (
          <div className="mt-3 p-2">
            <h6>{isGerm ? DE.share_on : EN.share_on} </h6>
            <WhatsappShareButton className="mr-1" url={receiverMessage}>
              <WhatsappIcon size={30} round={true} />
            </WhatsappShareButton>
            <EmailShareButton className="mr-1" url={receiverMessage}>
              <EmailIcon size={30} round={true} />
            </EmailShareButton>
            <TelegramShareButton className="mr-1" url={receiverMessage}>
              <TelegramIcon size={30} round={true} />
            </TelegramShareButton>
          </div>
        )}
        {showQrCode && (
          <div className="text-center pt-3">
            <QRCode value={receiverMessage} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default InviteContact;
