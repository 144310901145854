import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import LanguageContext from "../../../helper/LanguageContext";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";

function CompanyRolesDropDown(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [display, setDisplay] = useState(null);
  const [search, setSearch] = useState("");
  const handleClass = (index) => {
    console.log(display);
    if (display === index) {
      setDisplay(null);
    } else {
      setDisplay(index);
    }
  };
  const [selectRoles, setSelectRoles] = useState([]);

  const [positions, setPositions] = useState(props.position);
  const allPositions = props.position;
  const sendRoles = (tmp) => {
    let onlySelected = []
    for (let obj of tmp) {
      let ONL = obj.branches.filter(e => e.isSelected)
      onlySelected = [...onlySelected, ...ONL]
    }
    props.selectPosition(onlySelected);
  };

  const searchRole = async (text) => {
    setSearch(text);
    console.log(typeof text);

    if (text !== "") {
      let filterPositions = allPositions;
      let positionIndex = isGerm
        ? await positions.findIndex(
          (item) => item.name_de.toLowerCase() == text.toLowerCase()
        )
        : await positions.findIndex(
          (item) => item.name_en.toLowerCase() == text.toLowerCase()
        );

      if (positionIndex !== -1) {
        console.log(positionIndex);
        filterPositions = [allPositions[positionIndex]];
        console.log(filterPositions);
        setDisplay(null);
      } else {
        filterPositions = await allPositions.map((item) => {
          console.log(positionIndex);
          let filterRoles = isGerm
            ? item.branches.filter((i) =>
              i.name_de.toLowerCase().includes(text.toLowerCase())
            )
            : item.branches.filter((i) =>
              i.name_en.toLowerCase().includes(text.toLowerCase())
            );
          return { ...item, branches: filterRoles };
        });
        filterPositions = filterPositions.filter(
          (position) => position.branches.length > 0
        );
        if (filterPositions.length > 0) {
          setDisplay(filterPositions[0].id);
          console.log(filterPositions[0].id);
        }
      }
      setPositions(filterPositions);
    } else {
      setDisplay(null);
      setPositions(allPositions);
    }
  };

  const addRoles = async (value, RL, mainIndex, subIndex) => {
    let tmp = [...positions]
    tmp[mainIndex].branches[subIndex].isSelected = !tmp[mainIndex].branches[subIndex].isSelected
    setPositions([...tmp])
    sendRoles(tmp)
  };
  useEffect(() => {
    searchRole(props.searchVal)
  }, [props.searchVal]);
  useEffect(()=>{
    let tmp=[...positions]
    for(let obj of tmp){
      for(let objx of obj.branches){
        objx.isSelected=false
      }
    }
    setPositions([...tmp])
  },[props.position])
  return (
    <div style={{ height: 300, overflow: "scroll" }}>
      {positions &&
        positions.map((position, index) => (
          <div>
            <div>
              {position.branches.map((roles, key) => (
                <label key={key} className="role-label" style={{ border: "none" }}>
                  <i>{isGerm ? roles.name_de : roles.name_en}</i>
                  <input
                    value={isGerm ? roles.name_de : roles.name_en}
                    onChange={(e) => {
                      addRoles(e.target.value, roles, index, key);
                    }}
                    type="checkbox"
                  />
                  <span className="checkmark-label" />
                </label>
              ))}
            </div>
          </div>
        ))}
      {positions.length <= 0 && (
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <p className="text-muted" style={{ alignSelf: "center" }}>No Match Record Found!</p>
        </div>
      )}
      <div className="bottom-button" onClick={()=>{
            props.hideRoles();
      }}>
        <a
          className="pointer"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {isGerm ? DE.confirm : EN.confirm}
        </a>
        {/* <a className="pointer" onClick={()=>props.hideRoles()}>
          {isGerm ? DE.ok : EN.ok}
        </a> */}
      </div>
    </div>
  );
}

export default CompanyRolesDropDown;
