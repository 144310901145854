import React, { useEffect, useState } from "react";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import LanguageContext from "../../../helper/LanguageContext";
import { Card, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import _, { set } from "lodash";
import { convertBranchRole } from "../../../helper/convertor";
let getRoles = [];
export default function ArticleRole({
  handleClose,
  setParam,
  rolesData,
  selectSector,
  selectedSector,
  setRollData,
}) {
  const { isGerm } = React.useContext(LanguageContext);
  const [display, setDisplay] = useState(null);
  const [search, setSearch] = useState("");
  const [positions, setPositions] = useState([]);
  const [allPositions, setAllPositions] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const handleClass = (index) => {
    if (display === index) {
      setDisplay(null);
    } else {
      setDisplay(index);
    }
  };
  const [selectRoles, setSelectRoles] = useState([]);
  const addRoles = (role) => {
    const updatePositions = positions.map((p) => {
      p.branches.map((branch) => {
        if (branch.id === role.id && branch.isChecked) {
          branch.isChecked = false;
          let updatedArr = selectRoles.filter((itm) =>
            isGerm ? itm === role.name_de : itm === role.name_en
          );
          // console.log("updatedArr",updatedArr)
          setCheckedData([
            ...checkedData,
            isGerm ? role?.name_de : role?.name_en,
          ]);
          // setSelectRoles(updatedArr)
        } else if (branch.id === role.id && !branch.isChecked) {
          branch.isChecked = true;
          const newRoles = rolesData.filter((role) => role !== role.name_en);
          let updatedArr = selectRoles.filter((itm) =>
            isGerm ? itm === role.name_de : itm === role.name_en
          );
          let a =
            updatedArr?.length < 1
              ? [...selectRoles, isGerm ? role.name_de : role.name_en]
              : selectRoles;
          let tempArr = checkedData?.filter((itm) =>
            isGerm ? itm !== role.name_de : itm !== role.name_en
          );
          setCheckedData(tempArr);
          setSelectRoles(a);
        }
        return branch;
      });
      return p;
    });
    // setRollData(updatePositions)
    // setPositions(updatePositions);
    // setAllPositions(updatePositions);
  };
  const sendRoles = () => {
    let tempData = checkedData?.map((itm) => convertBranchRole(itm, false));
    selectSector(tempData);
    setPositions(rolesData);
    handleClose();
  };
  useEffect(() => {
    const updateRoles = rolesData.map((role) => {
      role.branches.map((branch) => {
        branch.isChecked =
          branch["isChecked"] !== undefined ? branch.isChecked : true;
        return branch;
      });
      let branches = role.branches.sort((a, b) => a.name_en - b.name_en);
      set(role, "branches", branches);
      return role;
    });
    let filterSelectedRole = updateRoles?.map((itm) =>
      itm.branches
        ?.filter((vl) => vl.isChecked === false)
        .map((it) => {
          return isGerm ? it.name_de : it.name_en;
        })
    );
    let finalBranchArry = [].concat.apply([], filterSelectedRole);
    setSelectRoles(finalBranchArry);
    setCheckedData(finalBranchArry);
    // setCheckedData
    setRollData(updateRoles);
    setPositions(updateRoles);
    setAllPositions(updateRoles);
  }, []);

  const searchRole = async (text) => {
    setSearch(text);

    if (text !== "") {
      let filterPositions = allPositions;
      let positionIndex = await positions.findIndex(
        (item) => item.name_en.toLowerCase() == text.toLowerCase()
      );

      if (positionIndex !== -1) {
        filterPositions = [allPositions[positionIndex]];
        setDisplay(null);
      } else {
        filterPositions = await allPositions.map((item) => {
          // let filterRoles = item.branches.filter(i => i.name_en.toLowerCase().includes(text.toLowerCase()));
          let filterRoles = item.branches.filter((i) =>
            isGerm
              ? i.name_de.toLowerCase().includes(text.toLowerCase())
              : i.name_en.toLowerCase().includes(text.toLowerCase())
          );
          // props.isGerms? i.name_de.toLowerCase().includes(text.toLowerCase()) : i.name_en.toLowerCase().includes(text.toLowerCase())
          return { ...item, branches: filterRoles };
        });
        filterPositions = filterPositions.filter(
          (position) => position.branches.length > 0
        );
        if (filterPositions.length > 0) {
          setDisplay(filterPositions[0].id);
        }
      }
      setPositions(filterPositions);
    } else {
      setDisplay(null);
      setPositions(rolesData);
    }
  };

  const deselectAll = (id) => {
    let getPosition = positions.find((p) => p.id === id);
    getPosition.branches = getPosition.branches.map((branch) => {
      getRoles.push(branch.name_en);
      branch.isChecked = false;
      return branch;
    });
    let filterArr = selectRoles.filter((itm) => !getRoles.includes(itm));
    setSelectRoles(filterArr);
    setCheckedData(getRoles);
    const updatePositions = _.uniqBy([...positions, getPosition], "id");
    setRollData(updatePositions);
    setAllPositions(updatePositions);
    setPositions(updatePositions);
  };

  return (
    <div className="backdrop">
      <div className="role_popup shadow-sm">
        <h5 className="p-2 m-0 pb-0 pt-3 pl-3">
          {isGerm
            ? DE.role_in_the_building_industry
            : EN.role_in_the_building_industry}
        </h5>
        <div
          className="role_data"
          style={{ overflow: "hidden", maxHeight: 450 }}
        >
          <div className="search">
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Control
                autocomplete="off"
                value={search}
                type="search"
                placeholder={isGerm ? DE.search : EN.search}
                onChange={(e) => {
                  e.preventDefault();
                  searchRole(e.target.value);
                }}
              />
            </Form.Group>
            <img
              src={process.env.PUBLIC_URL + "assets/img/search.svg"}
              alt="Generic placeholder"
            />
          </div>
          <div className="setting_accrd">
            <Accordion className="articalRoleScroll deselect-role">
              {positions &&
                positions.map((position, index) => (
                  <Card>
                    <Accordion.Toggle
                      key={index}
                      as={Card.Header}
                      variant="link"
                      eventKey={position.id}
                      onClick={(e) => handleClass(position.id)}
                    >
                      {isGerm ? position.name_de : position.name_en}
                      {display === position.id && (
                        <BsChevronDown className="acr_up" />
                      )}
                      {display !== position.id && (
                        <BsChevronRight className="acr_down" />
                      )}
                    </Accordion.Toggle>
                    <p
                      className="text-right pr-3 mb-0 pb-2 pointer"
                      onClick={() => deselectAll(position.id)}
                      style={{ fontSize: 15 }}
                    >
                      {isGerm ? DE.deselect_all : EN.deselect_all}
                    </p>
                    <Accordion.Collapse
                      className={display === position.id ? "show" : ""}
                      eventKey={position.id}
                    >
                      <Card.Body>
                        <div className="role-options">
                          <div className="role-main">
                            {position.branches.map((roles, key) => (
                              <label key={key} className="role-label">
                                <i>{isGerm ? roles.name_de : roles.name_en}</i>
                                <input
                                  checked={roles.isChecked}
                                  value={roles.name_en}
                                  onChange={(e) => {
                                    addRoles(roles);
                                  }}
                                  type="checkbox"
                                />
                                <span className="checkmark-label" />
                              </label>
                            ))}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              {positions.length <= 0 && (
                <Card className="text-center">
                  <p className="text-muted">No Match Record Found!</p>
                </Card>
              )}
            </Accordion>
          </div>
        </div>
        <div className="bottom-button">
          <a className="pointer" onClick={handleClose}>
            {isGerm ? DE.cancel : EN.cancel}
          </a>
          <a className="pointer" onClick={sendRoles}>
            {isGerm ? DE.ok : EN.ok}
          </a>
        </div>
      </div>
    </div>
  );
}
