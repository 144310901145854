import axios from "axios";
import Qs from "qs";
import { $user } from "./UserFactory";
import { SET_IS_UNAUTHORIZED, store } from "../store";

const serializerConfig = {
  arrayFormat: "brackets",
  encode: false,
};

export async function handleRequest(request) {
  return request;
}

export function handleResponse(value) {
  return value;
}

export async function handleApiError(error) {
  if (axios.isCancel(error)) {
    console.log('----> api call canceled');
    throw error;
  }


  if (!error.response) {
    throw error;
  }

  if (error.response.status === 401 || error.response.status === 402) {
    //  Toast.show("Please authorize to proceed");
    //  await logout();
    //  NavigationService.navigate("RequestSignInLink");
    // _clearSession()
    return error.response;
  } else if (error.response.status === 500) {
    // Toast.show("Server error has occurred. Please try again later");
    throw error;
  }
  else if (error.response.status === 400) {
    return error.response;
  }

  throw error;
}


function callAPI(
  path,
  params,
  method,
  data = null,
  options = {},
  headersObj = {}
) {
  return new Promise(async (resolve) => {
    const API_ROOT = process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `jwt ${$user.getToken()}`,
      ...headersObj,
    };

    const clientInstance = axios.create({
      baseURL: API_ROOT,
      headers: headers,
      ...options,
      paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
    });

    clientInstance.interceptors.request.use(handleRequest);
    clientInstance.interceptors.response.use(handleResponse, handleApiError);

    try {
      const response = await clientInstance.request({
        url: path,
        method: method,
        params: params,
        data: data,
      });

      if (response.status === 200) {
        resolve(response);
      } else if (response.status === 401 || response.status === 402) {
        store.dispatch({ type: SET_IS_UNAUTHORIZED, status: true }); 
        // reject(new Error('Unauthorized'));
      } else {
        // reject(new Error(`Unexpected status code: ${response.status}`));
      }
    } catch (error) {
      console.error('API call error:', error);
      // reject(error);
    }
  });
}

function callAPI2(
  path,
  params,
  method,
  data = null,
  options = {},
  headersObj = {}
) {
  return new Promise(async (resolve) => {
    const API_ROOT = process.env.REACT_APP_API_URL;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `jwt ${$user.getToken()}`,
      ...headersObj,
    };

    const clientInstance = axios.create({
      baseURL: API_ROOT,
      headers: headers,
      ...options,
      paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
    });

    clientInstance.interceptors.request.use(handleRequest);
    clientInstance.interceptors.response.use(handleResponse, handleApiError);

    try {
      const response = await clientInstance.request({
        url: path,
        method: method,
        params: params,
        data: data,
      });

      if (response.status === 200) {
        resolve(response);
      } else if (response.status === 401 || response.status === 402) {
        console.log('Unauthorized', store);
        store.dispatch({ type: SET_IS_UNAUTHORIZED, status: true });
      } else {
        // reject(new Error(`Unexpected status code: ${response.status}`));
      }
    } catch (error) {
      console.error('API call error:', error);
      // reject(error);
    }
  });
}
export { callAPI, callAPI2 };

export const firebaseConfig = {
  apiKey: "AIzaSyAUHGq6g1JszSoj3_cGaGspUjyB5Xz4SdQ",
  authDomain: "immosnapp-120783.firebaseapp.com",
  projectId: "immosnapp-120783",
  storageBucket: "immosnapp-120783.appspot.com",
  messagingSenderId: "607380209478",
  appId: "1:607380209478:web:7084867358d3352db96f47",
  measurementId: "G-QEVDVLGCVX"
};

export const key_pair = 'BPvjTgZW4-hxNfyM51UKLlA295QdRBGmeX4gjzh_k08o5g23EUouZxvsu1uAnRDPRuJc-XqqMhTegiIC8OH7Hrk'