import React, { memo, useEffect } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import makeData from './makeData'
import { Badge, Form } from "react-bootstrap";
import { callAPI } from '../../helper/apiUtils'

const DND_ITEM_TYPE = 'ROW'

// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `

const Table = ({ setRecords, data, chat_click, addUserToProject, handleShow, handleJoin }) => {
  const [records, setRecordss] = React.useState([])
  useEffect(() => {
    setRecordss(data)
  }, [data])
  const _updateSq = async (dt) => {
    await callAPI(
      `/building/my/updateSequence`, null, "PUT", { data: dt }
    );
  }
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    let x = update(records, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    })
    setRecords(x)
    setRecordss(x)
    let data = [...x]
    let dt = [...data]
    for (let i = 0; i < data.length; i++) {
      dt[i].seqNumber = i + 1
    }
    let tmp = data.map((x) => {
      return {
        building: x.building,
        seqNumber: x.seqNumber,
        name: x.name,
      }
    })
    _updateSq(tmp)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {(records.length && records.length > 0) &&
        records.map(
          (row, index) =>
          (
            <Row
              index={index}
              row={row}
              moveRow={moveRow}
              chat_click={(x, proj) => chat_click(x, proj)}
              addUserToProject={(x) => addUserToProject(x)}
              handleShow={(x) => handleShow(x)}
              handleJoin={(x) => handleJoin(x)}
            />
          )
        )}
    </DndProvider>
  )
}


const Row = ({ row, index, moveRow, addUserToProject, chat_click, handleJoin, handleShow }) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)
  let project = { ...row }
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)
  const getProjectName = (name) => {
    if (name) {
      const nameArray = name?.split(" ");
      if (nameArray && nameArray.length) {
        const length = nameArray.length;
        let newName = [];
        newName.push(nameArray[0][0]);
        if (nameArray.length > 1) {
          newName.push(nameArray[length - 1][0]);
        }
        return newName.join("");
      }
    }
  };



  return (
    // <></>
    <div
      key={project.id}
      className={`project_info pr-3 ${project.isOpened ? "active-project" : ""
        }`}
      ref={dropRef} style={{ opacity, marginBottom: 5, paddingTop: 5, paddingBottom: 5 }}
    >
      <div
        style={{ flex: 1 }}
        ref={(project.isJoin && !project.isDone) ? dragRef : null}
        onClick={(e) => chat_click(true, project)}
        className="Project_name"
      >
        <div style={{ position: "relative" }}>
          <div
            className="project_icon"
            style={{
              backgroundColor: (project.isJoin && !project.isDone)
                ? project.themeColor
                : "#d9deda",
            }}
          >
            {getProjectName(project.name)}
          </div>
          {Boolean(project.unseenBuildingChat) && (
            <Badge
              className="project-notification pointer message-badge"
              pill
            >
              <span style={{ color: "white", fontWeight: 'bold', fontSize: "12px" }}>{project.unseenBuildingChat}</span>
            </Badge>
          )}
        </div>
        <div
          className="p_name"
          style={{
            color: (project.isJoin && !project.isDone) ? "#737373" : "#d9deda",
          }}
        >
          {project.name}
          <span>{project.city}</span>
        </div>
      </div>
      <div className="project_action">
        {(project.isJoin && !project.isDone) ? (
          // <a
          //   className="pointer"
          //   onClick={() => {
          //     addUserToProject(project);
          //   }}
          // >
          //   {project.isOpened ? (
          //     <img
          //       width={27}
          //       src={
          //         process.env.PUBLIC_URL +
          //         "/assets/img/add-project-deactivated.svg"
          //       }
          //     />
          //   ) : (
          //     <img
          //       width={27}
          //       src={
          //         process.env.PUBLIC_URL +
          //         "/assets/img/add-to-project.svg"
          //       }
          //     />
          //   )}
          // </a>
          <></>
        ) : (
          !project.isDone ?
            <a href="#"
              onClick={(e) => handleJoin(project)}
            >
              <img
                width={22}
                src={
                  process.env.PUBLIC_URL + "/assets/img/Add.svg"
                }
              />
            </a>
            :
            <a href="#"
              onClick={(e) => handleJoin(project)}
            >
              <img
                width={25}
                src={
                  process.env.PUBLIC_URL + "/assets/img/DoneSvg.svg"
                }
              />
            </a>
        )}
        {/* <a href="#"
          onClick={(e) => handleShow(project)}
        >
          <img
            width={16}
            src={
              process.env.PUBLIC_URL +
              "/assets/img/Location.svg"
            }
          />
        </a> */}
      </div>
    </div>
  )
}

const App = ({ projects, chat_click, addUserToProject, handleShow, handleJoin, onChangePosition }) => {
  const [OrigialRecords, setRecords] = React.useState([...projects])
  const [toSort, setToSort] = React.useState([])


  const sortProjectList = (val) => {
    if (val) {
      if (val) {
        const isJoinTrue = val.filter(record => record.isJoin);
        const isJoinFalse = val.filter(record => !record.isJoin);

        // Concatenate arrays so that isJoin false records are at the end
        return [...isJoinTrue, ...isJoinFalse];
      }
      else {
        return []
      }
    }
    else {
      return []
    }
  }

  useEffect(() => {
    setToSort([...projects])
    setRecords([...projects])
    // let projectListState = projects
    // let prj = projectListState
    // let isFirstList = true
    // for (let obj of prj) {
    //   obj.building = obj.id
    //   if (obj.seqNumber) {
    //     isFirstList = false
    //   }
    // }
    // if (isFirstList) {
    //   let joined = []
    //   let leaved = []
    //   for (let i = 0; i < prj.length; i++) {
    //     if (prj[i].isJoin) {
    //       joined.push(prj[i])
    //     }
    //     else {
    //       prj[i]['seqNumber'] = projectListState.length + 1
    //       leaved.push(prj[i])
    //     }
    //   }
    //   for (let i = 0; i < joined.length; i++) {
    //     joined[i]['seqNumber'] = i + 1
    //   }
    //   let final = [...joined]
    //   for (let obj of leaved) {
    //     final.push(obj)
    //   }
    //   setToSort([...projects])
    //   setRecords([...projects])
    // }
    // else {
    //   let joined = []
    //   let leaved = []
    //   for (let i = 0; i < prj.length; i++) {
    //     if (prj[i].isJoin) {
    //       joined.push(prj[i])
    //     }
    //     else
    //       leaved.push(prj[i])
    //   }
    //   for (let i = 0; i < leaved.length; i++) {
    //     leaved[i]['seqNumber'] = projectListState.length + 1
    //   }
    //   let final = [...joined]
    //   for (let obj of leaved) {
    //     final.push(obj)
    //   }
    //   setToSort([...final])
    //   setRecords([...final])
    // }
  }, [projects])

  return (
    <Table
      data={toSort}
      setRecords={(list) => {
        onChangePosition(list)
      }}
      chat_click={(x, proj) => chat_click(x, proj)}
      addUserToProject={(x) => addUserToProject(x)}
      handleShow={(x) => handleShow(x)}
      handleJoin={(x) => handleJoin(x)}
    />

  )
}

export default memo(App)
