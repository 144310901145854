import React, { useEffect, useState } from 'react';
import { GROUPS, OPEN_CHATS } from "../../store";
import { connect, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { $user } from "../../helper/UserFactory";
import { Scrollbars } from "react-custom-scrollbars";
import "./SingleChat.css"
import ExportChat from "./ExportChat";
import { $crud } from "../../helper/CrudFactory";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { TOGGLE_DRAWER } from '../../helper/constants';

const user = $user.get();

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        overflowX: 'hidden',
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);


function UserInfo(props) {

    const [chatDetails, setChatDetails] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [chatMessages, setChatMessages] = useState('');
    const [exportChats, setExportChats] = useState(false);
    const { isGerm } = React.useContext(LanguageContext);
    const dispatch = useDispatch()
    const history = useHistory()

    const getDetails = async () => {
        const id = props.chat.id;
        const { data } = await callAPI(`/group/${id}/info`, {}, 'GET');
        if (data.status) {
            const chatUser = await data.groupInfo.users.filter(u => u.id !== user?.id);
            setChatDetails(chatUser[0]);
            console.log(chatUser[0]);
            setLoaded(true)
        }
    };

    const exportChat = async () => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const id = props.chat.id;
        const { data } = await callAPI(`/group/${id}/export`, {
            timezone: timeZone,
            lang: isGerm ? 'de' : 'en'
        }, 'GET');
        console.log(data);
        if (data.status) {
            setChatMessages(data.messages);
            setExportChats(true);
        }
    };

    const toggleBlockUser = async () => {
        await $crud.confirm({
            title: chatDetails.isBlock ? isGerm ? DE.unblocks : EN.unblocks : isGerm ? DE.blocks : EN.blocks,
            textContent: "",
            options: {
                ok: isGerm ? DE.ok : EN.ok,
                cancel: isGerm ? DE.cancel : EN.cancel
            }
        });
        const id = props.chat.id;
        const user = props.chatDetails.id;
        setViewLoader(true);
        const { data } = await callAPI(`/group/${id}/block`, {}, 'PUT', {
            id: id,
            user: user
        });
        let dt = { ...chatDetails, isBlock: !chatDetails.isBlock }
        setChatDetails(dt);
        setViewLoader(false);
        // getDetails();
    };
    const viewSearch = () => {
        props.setSearch();
        props.handleClose();
    };

    const navigateUserProfile = (userId) => {
        dispatch({ type: TOGGLE_DRAWER, open: true })
        history.push(`/user-profile/${userId}`)
    }


    useEffect(() => {
        getDetails();
    }, []);

    const [viewLoader, setViewLoader] = useState(false);
    return (
        <React.Fragment>
            <div className="w-300  pt-0 h45vh immoanimation-enter-done p-0 chat_user_info" style={{ display: "flex", flex: 1, flexDirection: "column", height: "93vh" }}>
                {viewLoader && (
                    <div className="website_loader">
                        <div className="loader" />
                    </div>
                )}
                <div className="main_heading">
                    <a className="pointer" onClick={props.handleClose}>
                        <img width={10} src={process.env.PUBLIC_URL + "/assets/img/back.svg"} alt="Generic placeholder" />
                    </a>
                    {isGerm ? DE.user_info : EN.user_info}
                    <div />
                </div>
                <div className="user_name chat-user">
                    <h3 className='pointer' onClick={() => navigateUserProfile(props?.chatDetails?.id)}>{props?.chat?.name?.replace("All members of the room", isGerm ? DE.all_member_of_project : EN.all_member_of_project)}</h3>
                </div>
                <div className="wscroll right_wscroll" style={{ flex: 1 }}>
                    <CustomScrollbars autoHide autoHideTimeout={200} autoHideDuration={200}
                        className="bottom-bar">
                        <div className="company_services m-0 chat_services">
                            <div className="click_plus">
                                <ul className="list-group">
                                    <li className="pointer" onClick={props.viewMediaDoc}>
                                        <span><img width="25px" src={process.env.PUBLIC_URL + "/assets/img/add-media.svg"} /></span> {isGerm ? DE.media_link_docs : EN.media_link_docs}
                                    </li>
                                    {/* <li className="pointer" onClick={viewSearch}>
                                        <span><img width="20px" src={process.env.PUBLIC_URL + "/assets/img/search.svg"}/></span> {isGerm ? DE.search_chat:EN.search_chat}
                                    </li> */}
                                    <li className="pointer" onClick={exportChat}>
                                        <span><img width="18px" src={process.env.PUBLIC_URL + "/assets/img/Export.svg"} /></span> {isGerm ? DE.export_chat_group : EN.export_chat_group}
                                    </li>

                                    <li className="pointer" onClick={toggleBlockUser}>
                                        <span><img width="20px" src={process.env.PUBLIC_URL + "/assets/img/Block-one-on-one chat.svg"} /></span>
                                        {chatDetails?.isBlock ? <span className="text-muted">{isGerm ? DE.unblock : EN.unblock}</span> : <span>{isGerm ? DE.block : EN.block}</span>}
                                    </li>
                                </ul>
                            </div>
                            {
                                exportChats &&
                                <div style={{ height: 1 }}>
                                    <ExportChat chat={props.chat} hide={() => setExportChats(false)} chatMessages={chatMessages} />
                                </div>
                            }
                        </div>
                    </CustomScrollbars>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const { openChats, groups } = state;
    return {
        groups: groups,
        openChats: openChats,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateGroups: groups => dispatch({ type: GROUPS, groups: groups }),
        updateOpenChatsList: chats => dispatch({ type: OPEN_CHATS, openChats: chats }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserInfo));
