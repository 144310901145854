import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { $crud } from "../../helper/CrudFactory";
import { KJUUP_COLORS } from "../../helper/constants";

const QRCode = require("qrcode.react");

function Invite(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [company, setCompany] = useState(null);
  const [compDetail, setCompanyDetail] = useState(null);
  const [deeplink, setDeepLink] = useState("");
  const [toggleShareLink, setToggleShareLink] = useState(false);
  const [showQrCode, setQrCode] = useState(false);
  const [depname, setDepname] = useState([])
  const [isKjuup, setIsKjuup] = useState([])
  console.log(props.match.params.departmentId)
  let iPhoneText = isGerm
    ? DE.iphone_recivers_message
    : EN.iphone_recivers_message;
  let iphone = isGerm ? DE.iphone : EN.iphone;
  let germenLangDeeplink =
    `${DE.company_receivers_message}\n\n${deeplink}`.replaceAll(
      "company name",
      company?.name
    ).replaceAll('department', depname)
  let engLangDeeplink =
    `${EN.company_receivers_message}\n\n${deeplink}`.replaceAll(
      "company name",
      company?.name
    ).replaceAll('department', depname)
  let germenLangDeeplinkDep =
    `${DE.company_sender_message_dep}\n\n${deeplink}`.replaceAll(
      "company name",
      company?.name
    ).replaceAll('depname', depname)
  let engLangDeeplinkDep =
    `${EN.company_sender_message_dep}\n\n${deeplink}`.replaceAll(
      "company name",
      company?.name
    ).replaceAll('depname', depname)
  const receiverMessage = deeplink
    ? isGerm
      ? (props.match.params.departmentId && props.match.params.departmentId !== 'null') ? germenLangDeeplinkDep : germenLangDeeplink
      : (props.match.params.departmentId && props.match.params.departmentId !== 'null') ? engLangDeeplinkDep : engLangDeeplink
    : "";

  // const receiverMessage = deeplink
  //   ? `${
  //       isGerm ? DE.company_receivers_message : EN.company_receivers_message
  //     }\n\n${deeplink}`.replaceAll("company name", company?.name)
  //   : "";
  useEffect(() => {
    if (props.location.state) {
      setIsKjuup(props.location.state.isKjuup)
    }
  }, [props])
  useEffect(() => {
    _getDep()
  }, [props.match.params.departmentId])
  const _getDep = async () => {
    const { data } = await callAPI(`/company/getDeptLocation/${props.match.params.id}`, {}, "GET");
    if (data.status) {
      let dd = data.data.getCompanyDept.filter(e => e.id == props.match.params.departmentId)
      console.log(dd, props)
      if (dd.length > 0) {
        let obj = dd[0]
        if (obj.department_name && (obj.location_name && obj.location_name != 'null'))
          setDepname(obj.department_name + "/" + obj.location_name)
        else
          setDepname(obj.department_name)
      }
    }
  }
  const getCompanyDetails = async () => {
    const id = props.match.params.id;
    const datax = await callAPI(`/company/${id}/profile`, {}, "GET");
    await setCompanyDetail(datax.data.profile);
    const { data } = await callAPI(`/company/${id}/companyById`, {}, "GET");
    await setCompany(data.company);
  };
  const getInviteLink = async () => {
    const id = props.match.params.id;
    const { data } = await callAPI(
      `/invite-user/deeplink`,
      (props.match.params.departmentId && props.match.params.departmentId !== 'null') ? {
        inviteType: "company",
        company: id,
        deptLocation: props.match.params.departmentId,
        isImportCompany: compDetail.isImport ? 1 : 0
      } : {
        inviteType: "company",
        company: id,
        isImportCompany: compDetail.isImport ? 1 : 0
      },
      "POST"
    );
    if (data.status) {
      let splitToken = data?.deeplinkUrl?.split("/");
      let getToken = splitToken[splitToken?.length - 1];
      let webLinkPayload = {
        data: {
          locallyIndex: true,
          title: "Kjuup",
          ratingAverage: 4.2,
          registraTionToken: getToken,
          isKjuupPlus: compDetail.isKjuup ? "true" : "false",
          deptLocation: props.match.params.departmentId,
          isImportCompany: compDetail.isImport ? 1 : 0
        },
      };
      window.branch.link(webLinkPayload, (err, link) => {
        setDeepLink(link);
      });
      // setDeepLink(data.deeplinkUrl);
    }
  };
  useEffect(() => {
    if (compDetail) {
      console.log(compDetail)
      getInviteLink()
    }
  }, [compDetail])
  useEffect(() => {
    getCompanyDetails();
  }, [props.match.params.id]);

  const handleShareUrl = () => {
    setToggleShareLink(!toggleShareLink);
    setQrCode(false);
  };
  const handleQrCode = () => {
    setToggleShareLink(false);
    setQrCode(!showQrCode);
  };

  const handleCopy = async () => {
    console.log(receiverMessage)
    setToggleShareLink(false);
    setQrCode(false);
    navigator.clipboard.writeText(receiverMessage).then((r) => {
      $crud.notify({
        type: "success",
        message: isGerm ? DE.link_copied : EN.link_copied,
      });
    });
  };
  const handleCancel = async () => {
    setToggleShareLink(false);
    setQrCode(false);
    const id = props.match.params.id;
    const { data } = await callAPI(`/company/${id}/deeplink`, {}, "DELETE");
    if (data.status) {
      setDeepLink(data.deeplinkUrl);
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <div className=" w-300 p-0">
          <div className="company_header  company_list company_border">
            <div class="heading_icon m-0">
              <Link
                to={`/company-details/${props.match.params.id}`}
                className={isKjuup ? "main_heading main_heading-internal" : "main_heading"}
                style={{ lineHeight: "27px" }}
              >
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + (isKjuup ? "/assets/img/backx.svg" : "/assets/img/back.svg")}
                />
                {isGerm ? DE.invite_with_link : EN.invite_with_link}
              </Link>
            </div>
          </div>
          <div className="p-2 w-300 company_border">
            {company?.name ? (
              <>
                {/* <div className=" p-0">
                  <div className="profile_name">
                    <h3 className="pointer">{company.name}</h3>               
                  </div>
                </div> */}
                <div>
                  {`${isGerm
                    ? DE.company_sender_message
                    : EN.company_sender_message
                    }`.replaceAll("company name", company?.name || "")}
                </div>
              </>
            ) : (
              <span>Loading...</span>
            )}
          </div>

          <div
            className="profile_name row m-0 text-center pt-2"
            style={{ borderTop: "1px solid #bcbcbc", justifyContent: "center" }}
          >
            {/* <div className="col-md-4">
              <div onClick={handleShareUrl} className="pointer">
                {isKjuup ?
                  <img
                    width={20}
                    src={process.env.PUBLIC_URL + "/assets/img/share_green.svg"}
                  />
                  :
                  <img
                    width={20}
                    src={process.env.PUBLIC_URL + "/assets/img/Share link.svg"}
                  />
                }
                <br />
                <span
                  style={{
                    fontSize: "13px",
                    color: isKjuup ? KJUUP_COLORS.internal : "#f18438",
                  }}
                >
                  {isGerm ? DE.share_link : EN.share_link}
                </span>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div onClick={handleQrCode} className="pointer">
                <img
                  width={30}
                  src={process.env.PUBLIC_URL + "/assets/img/QR Code.svg"}
                />
                <br />
                <span
                  style={{
                    fontSize: "13px",
                    color: "#f18438",
                  }}
                >
                  {isGerm ? DE.qr_code : EN.qr_code}
                </span>
              </div>
            </div> */}
            <div className="col-md-4">
              <div onClick={handleCopy} className="pointer">
                {isKjuup ?
                  <img
                    width={30}
                    src={process.env.PUBLIC_URL + "/assets/img/copy_green.svg"}
                  />
                  :
                  <img
                    width={30}
                    src={process.env.PUBLIC_URL + "/assets/img/Copy link.svg"}
                  />
                }
                <br />
                <span
                  style={{
                    fontSize: "13px",
                    color: isKjuup ? KJUUP_COLORS.internal : "#f18438"
                  }}
                >
                  {isGerm ? DE.copy_link : EN.copy_link}
                </span>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20, fontStyle: "italic" }}>
              <span style={{ textAlign: "center" }}>{isGerm ? DE.link_expire : EN.link_expire}</span>
            </div>
            {/* <div className="col-md-3">
              <div onClick={handleCancel} className="pointer">
                <img
                  width={30}
                  src={process.env.PUBLIC_URL + "/assets/img/Cancel link.svg"}
                />
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    color: "#f18438",
                  }}
                >
                  {isGerm ? DE.cancel_link : EN.cancel_link}
                </span>
              </div>
            </div> */}
          </div>
          {toggleShareLink && (
            <div className="mt-3 p-2">
              <h6>{isGerm ? DE.share_on : EN.share_on} </h6>
              <WhatsappShareButton className="mr-1" url={receiverMessage}>
                <WhatsappIcon size={30} round={true} />
              </WhatsappShareButton>
              <EmailShareButton className="mr-1" url={receiverMessage}>
                <EmailIcon size={30} round={true} />
              </EmailShareButton>
              <TelegramShareButton className="mr-1" url={receiverMessage}>
                <TelegramIcon size={30} round={true} />
              </TelegramShareButton>
            </div>
          )}
          {showQrCode && (
            <div className="text-center pt-3">
              <QRCode value={receiverMessage} />
            </div>
          )}
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Invite;
