import React, { useState } from "react";
// import Cropper from "react-cropper";
// import "cropperjs/dist/cropper.css";
// import "./ImageCropper.css";
import { Button } from "react-bootstrap";
import Cropper from 'react-easy-crop'
import getCroppedImg from "./cropImage";

const defaultSrc = "";

export const ImageCropper: React.FC = (props) => {
    const [image, setImage] = useState(defaultSrc);
    const [imageCopped, setImageCroped] = useState('');

    const [file, setFile] = useState("");
    const [cropData, setCropData] = useState("");
    const [cropper, setCropper] = useState();
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                0,
                type
            )
            setImageCroped(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
        setType(files[0].type)
        setName(files[0].name)
    };

    // function dataURLtoFile(dataurl, filename) {
    //     const arr = dataurl?.split(',');
    //     let mime = arr[0].match(/:(.*?);/);
    //     // let mime = "image/png";
    //     // return false;
    //     const bstr = atob(arr[1]);
    //     let n = bstr.length;
    //     const u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // }

    // function base64toFile(base64String, filename, mimeType) {
    //     // Split the base64 string to get the data and mime type
    //     const base64Data = base64String.split(',')[1];
    //     const decodedData = atob(base64Data);
    //     const arrayBuffer = new ArrayBuffer(decodedData.length);
    //     const uint8Array = new Uint8Array(arrayBuffer);

    //     // Convert the binary data to Uint8Array
    //     for (let i = 0; i < decodedData.length; i++) {
    //         uint8Array[i] = decodedData.charCodeAt(i);
    //     }

    //     // Create a Blob object from the Uint8Array
    //     const blob = new Blob([uint8Array], { type: mimeType });

    //     // Create a File object from the Blob
    //     const file = new File([blob], filename, { type: mimeType });

    //     return file;
    // }

    const uploadImage = async () => {
        // if (typeof cropper !== "undefined") {
        //     setCropData(cropper.getCroppedCanvas().toDataURL());
        //     // const file = await dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), 'avatar');
        //     // const file = base64toFile(cropper.getCroppedCanvas().toDataURL(), 'avatar.jpeg', 'image/jpeg');
        //     let img = await cropper.getImageData()
        //     const file = cropper.getCroppedCanvas(
        //         {
        //             height: img.height,
        //             width: img.naturalWidth,
        //         }
        //     ).toBlob(async (blob) => {
        //         // const arr = blob?.split(',');
        //         // const bstr = atob(arr[1]);
        //         // let n = bstr.length;
        //         // const u8arr = new Uint8Array(n);
        //         // while (n--) {
        //         //     u8arr[n] = bstr.charCodeAt(n);
        //         // }
        //         // let files = new File([u8arr], name, { type: type });
        //         console.log(blob)
        //         // const croppedImage = new File([blob], name, { type: blob.type });
        //         // console.log(croppedImage)
        //     }, type);
        //     // await props.changeImage(file)
        //     // setFile(file)
        // }
        fetch(imageCopped)
            .then(response => response.blob()) // Fetch the content and convert it to a Blob
            .then(blob => {
                const file = new File([blob], name, { type: type });
                props.changeImage(file)
            })
            .catch(error => {
                console.error('Error fetching the file:', error);
            });
    }



    return (
        <div>
            <div style={{ width: "100%" }}>
                <input type="file" onChange={onChange} />
                {
                    image &&
                    // <Cropper
                    //     style={{ height: 400, width: "100%", marginTop: '1rem' }}
                    //     preview=".img-preview"
                    //     src={image}
                    //     viewMode={1}
                    //     guides={true}
                    //     minCropBoxHeight={100}
                    //     minCropBoxWidth={100}
                    //     background={false}
                    //     responsive={true}
                    //     autoCropArea={1}
                    //     checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    //     onInitialized={(instance) => {
                    //         setCropper(instance);
                    //     }}
                    // />
                    <div>
                        <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            // aspect={4 / 3}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropSize={{ width: 250, height: 250 }}
                        />
                        <div className="p-2" style={{ position: 'absolute', zIndex: 1000, bottom: 0, right: 0 }}>
                            <Button onClick={(e) => {
                                e.preventDefault();
                                uploadImage();
                            }} variant="primary" className="float-right">Upload</Button>
                        </div>
                    </div>
                }
            </div>
            {
                image &&
                <>
                    <div>
                        <div className="box" style={{ width: "50%", float: "left" }}>
                            <h6 className="p-2">Preview</h6>
                            <div
                                className="img-preview"
                                style={{ width: "100%", float: "left", height: "300px", marginTop: '1rem' }}
                            />
                        </div>
                    </div>
                    <br style={{ clear: "both" }} />
                    {/* <div className="p-2">
                        <Button onClick={(e) => {
                            e.preventDefault();
                            uploadImage();
                        }} variant="primary" className="float-right">Upload</Button>
                    </div> */}
                </>
            }


        </div>
    );
};

export default ImageCropper;
