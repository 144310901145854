import React from "react";
import { convertMonthsFullName } from "../../helper/convertor";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import LanguageContext from "../../helper/LanguageContext";

const ImageSection = ({
  media,
  props,
  handleScroll,
  setImage,
  setShowImage,
  _onCheckBoxPress,
  isSelect,
}) => {
  const { isGerm } = React.useContext(LanguageContext);
  return (
    <div
      onScroll={handleScroll}
      className="wscroll right_wscroll"
      style={{ flex: 1 }}
    >
      <div className="image-content mt-1">
        {media?.map((file, index) => (
          <div className="row" key={index}>
            {file?.items?.length > 0 ?
              <div className="col-12 text-center">
                <div className="date_chat media_date">
                  <p
                    style={{
                      color: props.isInternal
                        ? KJUUP_COLORS.internal
                        : KJUUP_COLORS.base_color,
                    }}
                  >
                    {convertMonthsFullName(file.createdAt, isGerm)}
                  </p>
                </div>
              </div>
              :
              <></>
            }
            {file?.items?.map(
              (item, itemIndex) =>
                item.messageType === "image" && (
                  <div key={itemIndex} className="col-4 p-2">
                    <div className="embed-responsive-1by1 embed-responsive">
                      <img
                        onClick={() => {
                          setImage(
                            typeof item?.fileUrl == "object"
                              ? BASE_URL +
                              (item?.fileUrl ? item?.fileUrl[0] : "")
                              : BASE_URL + item?.fileUrl
                          );
                          setShowImage(true);
                        }}
                        className="embed-responsive-item pointer"
                        style={{ objectFit: "cover" }}
                        src={
                          typeof item?.fileUrl == "object"
                            ? BASE_URL + (item?.fileUrl ? item?.fileUrl[0] : "")
                            : BASE_URL + item?.fileUrl
                        }
                      />
                      {isSelect && (
                        <div
                          style={{
                            bottom: 0,
                            top: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "rgba(0,0,0,0.3)",
                            zIndex: 10,
                            position: "absolute",
                            verticalAlign: "end",
                            textAlign: "center",
                          }}
                          className={"abs_div"}
                        >
                          {!item.isDownloading ? (
                            <label
                              className={
                                props.isInternal
                                  ? "role-label-internal"
                                  : "role-label"
                              }
                              style={{ right: -5, top: -5 }}
                            >
                              <input
                                onClick={(e) =>
                                  _onCheckBoxPress(
                                    e.target.value,
                                    itemIndex,
                                    index
                                  )
                                }
                                value={item.isSelect}
                                type="checkbox"
                              />
                              <span
                                className={
                                  props.isInternal
                                    ? "checkmark-label-internal"
                                    : "checkmark-label"
                                }
                              />
                            </label>
                          ) : (
                            <CircularProgressbar
                              value={item.progress}
                              text={`${item.progress.toString()}%`}
                              className={"p-4 circle_x"}
                              background
                              styles={buildStyles({
                                backgroundColor: "#fff",
                                textColor: props.isInternal
                                  ? KJUUP_COLORS.internal
                                  : KJUUP_COLORS.base_color,
                                textSize: "16px",
                                pathColor: props.isInternal
                                  ? KJUUP_COLORS.internal
                                  : KJUUP_COLORS.base_color,
                              })}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        ))}
        {media?.length <= 0 && (
          <div className="no_media">
            <img
              width={90}
              src={
                process.env.PUBLIC_URL +
                (props.isInternal
                  ? "/assets/img/NoMedia-internal.svg"
                  : "/assets/img/NoMedia.svg")
              }
              alt="Generic placeholder"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSection;
