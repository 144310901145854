import React, {useContext, useEffect, useState} from 'react';
import {Form, Media} from 'react-bootstrap';
import Layout from "../../components/Layout";
import {callAPI} from "../../helper/apiUtils";
import LanguageContext from "../../helper/LanguageContext";
import {DE} from "../../Languages/DE";
import {EN} from "../../Languages/EN";
import {BASE_URL} from "../../helper/constants";
import {Link} from "react-router-dom";
import { convertBranchRole } from '../../helper/convertor';
import { Scrollbars } from "react-custom-scrollbars";
const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "rgba(240, 132, 56, 0.8)",
    };
    return (
      <div
        className="thumb-style"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };
  const CustomScrollbars = (props) => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );
function Followers(props) {
    const {isGerm} = useContext(LanguageContext)
    const [followers, setFollowers] = useState([])
    const [allFollowers,setAllFollowers]=useState([])
  const [search,setSearch]=useState("")
    const getFollowers = async () => {
        const id = props.match.params.id
        const {data} = await callAPI(`/user/${id}/follower`, {}, 'GET')
        console.log(data)
        setAllFollowers(data.followers)
        setFollowers(data.followers)
    }
    useEffect(() => {
        getFollowers();
    }, [])
    const backToUrl = () => {
        window.history.go(-1);
        return false;
    } 
    const searchFollowers = async (value) => {
        setSearch(value);
        const searchResult = await allFollowers.filter((itm) => {
          if (value === "") {
            return itm;
          } else if (itm.firstName.toLowerCase().startsWith(value.toLowerCase()) || itm.lastName.toLowerCase().startsWith(value.toLowerCase())) {
            return itm;
          }
        });
        if (value === "") {
          setFollowers(allFollowers);
        } else {
          setFollowers(searchResult);
        }
      };
    return (
        <React.Fragment>
            <Layout>
                {
                    followers &&
                    <div class="w-300 following follower">
                        <div className="main_heading">
                            <img className="pointer" onClick={backToUrl} width={10}
                                 src={process.env.PUBLIC_URL + "/assets/img/back.svg"}/>
                            {isGerm ? DE.followers : EN.followers}
                        </div>
                        <div className="search">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      autocomplete="off"
                      onChange={(e) => searchFollowers(e.target.value)}
                      value={search}
                      type="search"
                      placeholder={isGerm ? DE.search : EN.search}
                    />
                  </Form.Group>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/search.svg"}
                    alt="Generic placeholder"
                  />
                </div>
                <div>
                <div className="my-scroll min_height_followers_following contact_height">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                className="bottom-bar"
              >
                        {
                            followers &&
                            followers.map(follower => (
                                <div key={follower.id} className="post_notification">
                                    <div className="my_post">
                                        <Media>
                                            <img
                                                width={40}
                                                height={40}
                                                className="mr-3"
                                                src={follower.avatar ? BASE_URL + follower.avatar : process.env.PUBLIC_URL + "/assets/img/user.jpg"}
                                                alt="Generic placeholder"
                                            />
                                            <Media.Body>
                                                <div className="authore_name">
                                                    <Link to={`/user-profile/${follower.id}`}><h5>{follower.fullName}</h5></Link>
                                                    <p>{follower.buildingIndustryRole?.split(",").map((itm,index)=>(index ? ", " : "")+convertBranchRole(itm,isGerm))}</p>
                                                </div>
                                            </Media.Body>
                                        </Media>
                                    </div>
                                </div>
                            ))

                        }
                </CustomScrollbars>
                </div>
                </div>
                    </div>
                }
                {
                    !followers &&
                    <div class="w-300 following follower">
                        <div className="main_heading">
                            <img width={10} src={process.env.PUBLIC_URL + "/assets/img/back.svg"}/>
                            {isGerm ? DE.followers : EN.followers}
                        </div>
                    </div>
                }
            </Layout>
        </React.Fragment>
    );
}

export default Followers;