import React, { memo, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import MapContainer from '../pages/map';
import Carousel from "react-multi-carousel";
import { BASE_URL } from '../helper/constants';
import VideoPlayers from './VideoPlayers';
import ImagesWithVideos from './ImagesWithVideos';
import { BsX } from 'react-icons/bs';

const RCPlayer = ({ Links, article, isC, removeImage, closeModal, initialIndex }) => {
    const [_LNK, setLNK] = useState([])
    const corRef = useRef(null)
    useEffect(() => {
        if (Links && Links.length && Links.length > 0) {
            setLNK([...Links])
        }
    }, [Links])
    const _onChange = (index) => {
        if (!isC) {
            let tmp = [..._LNK]
            tmp[index].play = true
            setLNK([...tmp])
            setTimeout(() => {
                tmp[index].play = false
                setLNK([...tmp])
            }, 1000)
        }
    }

    useEffect(()=>{
        if(corRef.current){
            setTimeout(()=>{
                corRef.current.goToSlide(initialIndex)
            },500)
        }
    },[initialIndex])

    return (
        <div style={{ position: 'absolute', height: "100%", width: "100%", backgroundColor: "#000", zIndex: 100, top: 0, left: 0, right: 0, display: "flex", flexDirection: "column" }}>
            <div onClick={() => {
                closeModal()
            }} style={{ position: "absolute", alignSelf: "flex-end", right: 0 }}>
                <div style={{ marginTop: 20, marginRight: 20 }}>
                    <BsX color='#fff' size={40} />
                </div>
            </div>
            <div style={{ flex: 1 }} />
            {
                _LNK &&
                <Carousel
                    ref={corRef}
                    afterChange={_onChange}
                    additionalTransfrom={0}
                    arrows={_LNK.length > 1 ? true : false}
                    centerMode={false}
                    className="p-0"
                    containerclassName="container"
                    dotListclassName=""
                    draggable={_LNK.length > 1 ? true : false}
                    focusOnSelect={false}
                    infinite={false}
                    itemclassName=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 1,
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1
                        }
                    }}
                    showDots={false}
                    sliderclassName=""
                    slidesToSlide={initialIndex}
                    swipeable={false}
                >
                    {
                        _LNK.map((image, index) => {
                            return (
                                <div key={index} className="profile_slider embed-responsive embed-responsive-16by9">
                                    <VideoPlayers
                                        url={image.href ? image.href : image.fileUrl}
                                        type={image.videoType}
                                        isPlaying={image.play}
                                        fileType={image.fileType}
                                        local={image.url}
                                        article={article}
                                        isC={isC}
                                        index={index}
                                        removeImage={(e) => removeImage(e)}
                                    />
                                </div>
                            )
                        })
                    }
                </Carousel>
            }
            <div style={{ flex: 1 }} />
        </div>
    );
}
export default memo(RCPlayer);