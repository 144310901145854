import React, { useState } from "react";
import LanguageContext from "../../../helper/LanguageContext";
import { Form } from "react-bootstrap";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import { callAPI } from "../../../helper/apiUtils";
import { $crud } from "../../../helper/CrudFactory";

export default function CompanyContactDialog({ handleClose, company }) {
    const { isGerm } = React.useContext(LanguageContext)
    const [companyDetails, setCompanyDetails] = useState(company)

    const setParam = (name, value) => {
        setCompanyDetails(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const updateDetails = async () => {
        const { id, website, email } = companyDetails
        const { data } = await callAPI(`/company/${id}/contact`, { website, email }, 'PUT');
        if (!data.status) {
            $crud.notify({
                type: 'error',
                message: data.message
            })
        } else {
            handleClose();
        }
    }

    return (
        <div style={{ flexDirection: "column", position: 'absolute', zIndex: 100, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: "flex" }}>
            <div style={{ flex: 1 }} />
            <div style={{ backgroundColor: "white" }}>
                <h5 className="p-2 mt-2 mb-0 text-center">{isGerm ? DE.contact : EN.contact}</h5>
                <div className="role_data" style={{ minHeight: "auto", overflow: "hidden" }}>
                    <div className="search p-3 m-0">
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control value={companyDetails.website} autocomplete="off" type="text"
                                placeholder={isGerm ? DE.website : EN.website}
                                onChange={e => setParam('website', e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control value={companyDetails.email} autocomplete="off" type="text"
                                placeholder={isGerm ? DE.email : EN.email}
                                onChange={e => setParam('email', e.target.value)} />
                        </Form.Group>

                    </div>
                </div>
                <div className={company.isKjuup? "bottom-button-internal" : "bottom-button"} style={{position:"initial"}}>
                    <a className="pointer" onClick={handleClose}>{isGerm ? DE.cancel : EN.cancel}</a>
                    <a className="pointer" onClick={updateDetails}>{isGerm ? DE.ok : EN.ok}</a>
                </div>
            </div>
            <div style={{ flex: 1 }} />
        </div>
    )
}