import React, { useContext, useEffect, useState } from "react";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import Layout from "../components/Layout";
import LanguageContext from "../helper/LanguageContext";
import { DE } from "../Languages/DE";
import { EN } from "../Languages/EN";
import { Scrollbars } from "react-custom-scrollbars";
import { callAPI } from "../helper/apiUtils";
import { Link } from "react-router-dom";
import { BASE_URL, KJUUP_COLORS, goBack } from "../helper/constants";
import { $crud } from "../helper/CrudFactory";
import { Animated } from "react-animated-css";
import InviteContact from "./Project/InviteContact";
import { convertBranchRole } from "../helper/convertor";
import { Positions } from "../Languages/vars/Positions";
import { useHistory, useLocation } from "react-router-dom";
import CompanyRoles from "./Company/Dialogs/CompanyRoles";
import { Roles } from "../Languages/vars/Roles";
import CompanyRolesDropDown from "./Company/Dialogs/CompanyRolesDropDown";
import { BsX } from "react-icons/bs";
import { Discuss, Rings } from 'react-loader-spinner'
import { GOOGLE_API_KEY } from "./Project/EditProject";
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import CityListComponent from "../components/CityListComponent";
import { BiDotsHorizontal } from "react-icons/bi";
import { HiDotsHorizontal } from "react-icons/hi";
import InviteExternalComponent from "../components/InviteExternalComponent";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function Contact(props) {
  const [company, setCompany] = useState({})
  const { isGerm } = useContext(LanguageContext);
  const [visible, setVisible] = useState(true);
  const [inviteLink, setInviteLink] = useState(false);
  const [contacts, setContact] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [profile, setProfile] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [employeePosition, setEmployeePosition] = useState();
  const hidePositionDialog = () => setPositionDialog(false);
  const [showPositionDialog, setPositionDialog] = useState(false);
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isBranchFocused, setIsBranchFocused] = useState(false)
  const [roleSelected, setRoleSelected] = useState([]);
  const [citySelected, setCitySelected] = useState([]);
  const [citySelectedAPIParam, setCitySelectedAPIParam] = useState([]);
  const [showDropdpown, setShowDropdpown] = useState(false)
  const [showDropdpownCity, setShowDropdpownCity] = useState(false)
  const [bacupAll, setBackupAll] = useState([])
  const [searchBranch, setSearchBranch] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [isSearching, setIsSeaching] = useState(false)
  const [mainVal, setMainVal] = useState('');
  const [page, setPage] = useState(1)
  const [isSearchingEnd, setIsSeachingENd] = useState(false)


  const getContacts = async () => {
    const { data } = await callAPI(`/user/contact`, {}, "GET");
    setContact(data.contacts);
  };
  const changeRole = (selectedRoles) => {
    console.log(selectedRoles)
    setRoleSelected([...new Set([...roleSelected, ...selectedRoles])])
  };
  const handleSubmit = async (myCompany) => {
    let companyX = myCompany ? myCompany : company
    let { index } = companyX
    history.push({
      pathname: '/add-company', state: {
        ...companyX
      }
    })
    // if (!employeePosition) {
    //   await $crud.notify({
    //     type: "error",
    //     message: "Please select position",
    //   });
    // } else {
    // if (!companyX.email) {
    //   history.push({
    //     pathname: '/add-company', state: {
    //       ...companyX
    //     }
    //   })
    // }
    // else {
    //   const params = {
    //     name: companyX.name,
    //     email: companyX.email,
    //     corporateForm: companyX.corporateForm,
    //     branch: companyX.branch,
    //     street: companyX.street,
    //     postCode: companyX.postCode,
    //     city: companyX.city,
    //     country: companyX.country,
    //     lat: companyX.lat,
    //     long: companyX.long,
    //   };
    //   console.log(params);
    //   const { data } = await callAPI("/company", params, "POST");
    //   setPositionDialog(false);
    //   $crud.notify({
    //     type: data.status ? "success" : "error",
    //     message: data.message,
    //   });
    //   let tmp = [...suggestions]
    //   tmp.splice(index, 1)
    //   setSuggestions([...tmp])
    // }
    // }
  };
  const getSuggestion = async () => {
    const {
      data: { data },
    } = await callAPI(`/user/suggestion`, {}, "GET");
    setProfile({
      followerCount: data.followerCount,
      followingCount: data.followingCount,
    });
    setLoading(true);
  };
  useEffect(() => {
    getIntial();
    getSuggestion()
    getContacts();
  }, []);

  const followUser = async (id, index) => {
    let tmp = [...suggestions]
    const { data } = await callAPI(
      `/user/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      tmp.splice(index, 1)
      setSuggestions([...tmp])
    }
  };
  const removeSuggestions = async (id) => {
    const updatedSuggestion = suggestions.filter(
      (suggestion) => suggestion.id !== id
    );
    setSuggestions(updatedSuggestion);
  };

  const getIntial = async (suggestionSearch) => {
    const { data } = await callAPI(`/user/suggestion`, {}, "POST", null);
    if (data.status) {
      setPage(page + 1)
      setSuggestions(data.data.suggestions);
      setBackupAll(data.data.suggestions)
      setLoading(true);
    }
  };

  const searchUser = async (suggestionSearch, tmp) => {
    setIsSeaching(true)
    setSearch(suggestionSearch);
    let roles = []
    let brchs = []
    if (!tmp) {
      for (let obj of roleSelected) {
        roles.push(obj.name_en)
      }
    }
    else {
      for (let obj of tmp) {
        roles.push(obj.name_en)
      }
    }
    for (let obj of citySelectedAPIParam) {
      brchs.push(obj.name)
    }
    // let finalRoles = roles.join(',');
    // let finalBranches = brchs.join(',');
    let finalRoles = roles
    let finalBranches = brchs
    let dataaa = {
      page: 1,
      limit: 100,
      query: suggestionSearch
    }
    if (finalRoles.length > 0) {
      dataaa.company_branch = finalRoles
    }
    if (finalBranches.length > 0) {
      dataaa.comapny_city = finalBranches
    }
    const { data } = await callAPI(`/user/suggestion`, {}, "POST", dataaa);
    if (data.status) {
      setSuggestions(data.data.suggestions);
      setIsSeaching(false)
    }
    else {
      setIsSeaching(false)
    }
    setPage(page + 1)
  };

  const searchUserEnd = async (suggestionSearch, tmp) => {
    setIsSeachingENd(true)
    setSearch(suggestionSearch);
    let roles = []
    let brchs = []
    if (!tmp) {
      for (let obj of roleSelected) {
        roles.push(obj.name_en)
      }
    }
    else {
      for (let obj of tmp) {
        roles.push(obj.name_en)
      }
    }
    for (let obj of citySelectedAPIParam) {
      brchs.push(obj.name)
    }
    // let finalRoles = roles.join(',');
    // let finalBranches = brchs.join(',');
    let finalRoles = roles
    let finalBranches = brchs
    let dataaa = {
      page,
      limit: 100,
      query: suggestionSearch
    }
    if (finalRoles.length > 0) {
      dataaa.company_branch = finalRoles
    }
    if (finalBranches.length > 0) {
      dataaa.comapny_city = finalBranches
    }
    const { data } = await callAPI(`/user/suggestion`, {}, "POST", dataaa);
    if (data.status) {
      let tmp = [...suggestions, ...data.data.suggestions]
      setSuggestions([...tmp]);
      setIsSeachingENd(false)
    }
    else {
      setIsSeachingENd(false)
    }
    setPage(page + 1)
  };

  const handleInputChange = async (text) => {
    if (text?.length > 0) {
      setShowDropdpownCity(true)
    } else {
      setCitySelected([]);
    }
  };
  useEffect(() => {
    if (!searchCity) {
      setShowDropdpownCity(false)
    }
    handleInputChange(searchCity)
  }, [searchCity])
  useEffect(() => {
    searchUser(search)
  }, [citySelectedAPIParam])
  const _setCityParams = (obj) => {
    console.log(obj)
    let tmp = []
    let alreaddy = [...citySelectedAPIParam]
    tmp.push({ name: obj.formattedSuggestion?.mainText, id: obj.placeId })
    let final = [...alreaddy, ...tmp].reduce((sum, val) => {
      for (let i in sum) {
        if (sum[i].id === val.id) {
          return sum;
        }
      }
      sum.push(val);
      return sum;
    }, []);
    setCitySelectedAPIParam([...final])
  }
  const _selectCity = (item, index) => {
    setMainVal(item.description)
    setShowDropdpownCity(false)
    _setCityParams(item)
  }
  const handleBack = () => {
    setVisible(false);
    setTimeout(() => {
      goBack();
    }, 1000);
  };
  const followCompany = async (id, index) => {
    let tmp = [...suggestions]
    const { data } = await callAPI(
      `/company/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );
    tmp[index].followStatus = !tmp[index].followStatus
    setSuggestions([...tmp])
  };
  const _resetValues = () => {
    setMainVal('')
    setSearchCity('')
    setSearchBranch('')
    setShowDropdpown(false)
    setShowDropdpownCity(false)
  }
  const handleScroll = (e) => {
    const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop) <= parseInt(e.target.clientHeight);
    if (bottom) {
      if (!isSearchingEnd)
        searchUserEnd(search)
    }
  }
  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          {!inviteLink ?
            <div className="w-300" style={{ display: "flex", flexDirection: "column", height: "93vh" }}>
              <h5>
                <img
                  className="pointer"
                  onClick={handleBack}
                  width={10}
                  src={process.env.PUBLIC_URL + "assets/img/back.svg"}
                  alt="Generic placeholder"
                />{" "}
                {isGerm ? DE.contacts : EN.contacts}
              </h5>
              <div>
                {!inviteLink ? (
                <InviteExternalComponent
                  onClick={(e) => setInviteLink(true)}
                  id={'contacts'}
                />
              ) : (
                <></>
              )}
                <div className="search" style={{ marginTop: 0 }}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      autocomplete="off"
                      onChange={(e) => searchUser(e.target.value)}
                      value={search}
                      type="search"
                      placeholder={isGerm ? DE.company_username : EN.company_username}
                      onFocus={() => {
                        _resetValues()
                      }}
                    />
                  </Form.Group>
                  <img
                    src={process.env.PUBLIC_URL + "assets/img/search.svg"}
                    alt="Generic placeholder"
                  />
                </div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="search" style={{ margin: "-5px 0px 0px 0px" }}>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control
                          autocomplete="off"
                          onChange={(e) => {
                            setSearchBranch(e.target.value)
                            if (e.target.value)
                              setShowDropdpown(true)
                            else
                              setShowDropdpown(false)
                          }}
                          value={searchBranch}
                          type="search"
                          placeholder={isGerm ? DE.branch : EN.branch}
                          onFocus={() => {
                            _resetValues()
                          }}
                        />
                      </Form.Group>
                      <img
                        src={process.env.PUBLIC_URL + "assets/img/search.svg"}
                        alt="Generic placeholder"
                      />
                    </div>
                    <div className="search" style={{ margin: "-5px 0px 0px 10px" }}>
                      <CityListComponent
                        custom
                        defaultValue={""}
                        placeHolder={isGerm ? DE.city.replace('*', '') : EN.city.replace('*', '')}
                        setVal={(e) => {
                          setMainVal(e)
                          setSearchCity(e)
                        }}
                        renderSuggesstion={(suggestions) => {
                          if (suggestions?.length > 0) {
                            setCitySelected(suggestions)
                            setShowDropdpownCity(true)
                          }
                          else {
                            // setShowDropdpownCity(false)
                          }
                        }}
                        val={mainVal}
                        setFull={(val) => {
                          setMainVal(val)
                          // setParam('full_address', val)
                        }}
                        onBlur={() => {
                          // _resetValues()
                        }}
                        onFocus={() => {
                          _resetValues()
                        }}
                      />
                    </div>
                  </div>
                  {showDropdpown ?
                    <div style={{ width: "95%", border: "1px solid", position: "absolute", backgroundColor: "#fff", zIndex: 1, left: "2.5%", borderColor: "#DFDFDF" }}>
                      <CompanyRolesDropDown
                        hideRoles={(e) => {
                          setShowDropdpown(false);
                          searchUser(search)
                        }}
                        selectPosition={changeRole}
                        position={Roles}
                        searchVal={searchBranch}
                      />
                    </div>
                    :
                    <></>
                  }
                  {
                    showDropdpownCity ?
                      <div style={{ width: "95%", border: "1px solid", position: "absolute", backgroundColor: "#fff", zIndex: 1, left: "2.5%", borderColor: "#DFDFDF" }}>
                        {citySelected.map((suggestion, index) => {
                          return (
                            <div
                              style={{ padding: 10, cursor: 'pointer', borderBottom: '1px solid #DFDFDF' }}
                              onClick={() => {
                                _selectCity(suggestion, index)
                              }}
                              className="itemdiv"
                            >
                              <span className="small" style={{}}>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                      :
                      <></>

                  }
                  {
                    roleSelected?.length > 0 ?
                      <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                        <span style={{ fontWeight: "600", fontSize: "17px" }}>{`${isGerm ? DE.branch : EN.branch}:`}</span>
                        <div style={{ display: "flex", flexWrap: "wrap", marginTop: 5, }}>
                          {
                            roleSelected.map((e, i) => {
                              return (
                                <div onClick={() => {
                                  let tmp = [...roleSelected]
                                  tmp.splice(i, 1)
                                  setRoleSelected([...tmp])
                                  searchUser('', tmp)
                                }} style={{ marginRight: 10, marginBottom: 10, height: "30px", borderRadius: 100, border: "1.5px solid #DFDFDF", display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, alignItems: "center" }}>
                                  <span style={{ fontWeight: "400", fontSize: "15px" }}>{isGerm ? e.name_de : e.name_en}</span>
                                  <BsX
                                    size={"25px"}
                                    color={KJUUP_COLORS.base_color}
                                  // style={{ marginLeft: 2 }}
                                  />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                      :
                      <></>
                  }
                  {
                    citySelectedAPIParam?.length > 0 ?
                      <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                        <span style={{ fontWeight: "600", fontSize: "17px" }}>{`${isGerm ? DE.city.replace("*", '') : EN.city.replace("*", '')}:`}</span>
                        <div style={{ display: "flex", flexWrap: "wrap", marginTop: 5, }}>
                          {
                            citySelectedAPIParam.map((e, i) => {
                              return (
                                <div onClick={() => {
                                  let tmp = [...citySelectedAPIParam]
                                  tmp.splice(i, 1)
                                  setCitySelectedAPIParam([...tmp])
                                }} style={{ marginRight: 10, marginBottom: 10, height: "30px", borderRadius: 100, border: "1.5px solid #DFDFDF", display: "flex", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10, alignItems: "center" }}>
                                  <span style={{ fontWeight: "400", fontSize: "15px" }}>{e.name}</span>
                                  <BsX
                                    size={"25px"}
                                    color={KJUUP_COLORS.base_color}
                                  // style={{ marginLeft: 2 }}
                                  />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                      :
                      <></>
                  }
                </div>
              </div>
              <div onScroll={handleScroll} className="scroll" style={{ flex: 1, overflowX: "hidden" }}>
                {isSearching ?
                  <div style={{ height: 200, alignItems: "center", display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }} />
                    <div>
                      <Rings
                        height="100"
                        width="100"
                        color={KJUUP_COLORS.base_color}
                        radius="6"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                      />
                    </div>
                    <div style={{ flex: 1 }} />
                  </div>
                  :
                  suggestions?.length > 0 ? (
                    <div className="row m-0">
                      {suggestions &&
                        suggestions.map((suggestion, myiNdex) => (
                          <div className="col-md-6 p-0 border-right border-bottom">
                            <div className="connect_link pt-4" >
                              {suggestion.isComapny ?
                                <img
                                  width={40}
                                  height={40}
                                  style={{ objectFit: 'cover', borderRadius: suggestion.logo ? "4px" : 0 }}
                                  src={
                                    suggestion.logo
                                      ? BASE_URL + suggestion.logo
                                      : process.env.PUBLIC_URL +
                                      "/assets/img/Companies-No-Image.svg"
                                  }
                                  alt="Generic placeholder"
                                />
                                :
                                <img
                                  width={40}
                                  height={40}
                                  style={{ objectFit: "cover" }}
                                  src={
                                    suggestion.img
                                      ? BASE_URL + suggestion.img
                                      : process.env.PUBLIC_URL +
                                      "assets/img/head.svg"
                                  }
                                  alt="Generic placeholder"
                                />
                              }
                              <Link to={suggestion.isComapny ?
                                {
                                  pathname: "/foreign-company-details/" + suggestion.id,
                                  // `/company-details/${suggestion.id}`,
                                  state: { fromContact: true }
                                }
                                : `/user-profile/${suggestion.id}`}>
                                <h4>{suggestion.name}</h4>
                              </Link>
                              <p style={{ marginTop: 10 }}>
                                {convertBranchRole((suggestion.position ? suggestion.position : suggestion.branch), isGerm, suggestion.isComapny ? true : false)}
                              </p>
                              <div>
                                {suggestion.mutualContactCount > 0 ? (
                                  <div className="connection">
                                    <a href="#">
                                      <img
                                        width={16}
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/img/Link-deselected.svg"
                                        }
                                        alt="Generic placeholder"
                                      />
                                      {suggestion.mutualContactCount}
                                      {suggestion.mutualContactCount <= 1
                                        ? isGerm
                                          ? DE.mutual_contact
                                          : EN.mutual_contact
                                        : isGerm
                                          ? DE.mutual_contacts
                                          : EN.mutual_contacts}
                                    </a>
                                  </div>
                                ) : (
                                  <a href="#"></a>
                                )}

                                {suggestion.mutualCompanyCount > 0 ? (
                                  <div className="connection">
                                    <a href="#">
                                      <img
                                        width={12}
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/img/My-companiesS.svg"
                                        }
                                        alt="Generic placeholder"
                                      />
                                      {suggestion.mutualCompanyCount}{" "}
                                      {suggestion.mutualCompanyCount > 1
                                        ? isGerm
                                          ? DE.mutual_companys
                                          : EN.mutual_company
                                        : isGerm
                                          ? DE.mutual_company
                                          : EN.mutual_company}
                                    </a>
                                  </div>
                                ) : (
                                  <a
                                    href="#"
                                    style={{ marginBottom: "40px" }}
                                  ></a>
                                )}
                              </div>
                              {suggestion.isComapny ?
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                  <div>
                                    {suggestion.email ?
                                      <Button
                                        onClick={() => {
                                          if (suggestion.email) {
                                            const recipientEmail = suggestion.email; // Replace with the recipient's email address
                                            const subject = !isGerm ? "Request" : "Anfrage"; // Replace with the desired subject
                                            const body = !isGerm ? "\n\n\nYour company was discovered on Kjuup.\nhttps://www.kjuup.de" : "\n\n\nIhr Unternehmen wurde über Kjuup gefunden.\nhttps://www.kjuup.de"; // Replace with the prefilled text
                                            const mailtoUrl = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                                            window.open(mailtoUrl)
                                          }
                                        }}
                                        className="btn request-btn"
                                        style={{ height: '32px', margin: '10px auto 10px', opacity: suggestion.email ? 1 : 0.7, marginBottom: !suggestion.isMenu ? 20 : 0 }}
                                      >
                                        {isGerm ? DE.contact : EN.contact}
                                      </Button>
                                      :
                                      <Button
                                        className="btn request-btn"
                                        style={{ height: '32px', pointerEvents: 'none', margin: '10px auto 10px', opacity: suggestion.email ? 1 : 0.7, color: suggestion.email ? KJUUP_COLORS.base_color : "grey", marginBottom: !suggestion.isMenu ? 20 : 0 }}
                                      >
                                        {isGerm ? DE.contact : EN.contact}
                                      </Button>
                                    }
                                  </div>
                                  <div
                                    onClick={() => {
                                      let tmp = [...suggestions]
                                      tmp[myiNdex].isMenu = !tmp[myiNdex].isMenu
                                      setSuggestions([...tmp])
                                    }}
                                    style={{ paddingLeft: 10, marginBottom: suggestion.isMenu ? 0 : 10 }}>
                                    <HiDotsHorizontal />
                                  </div>
                                </div>
                                :
                                <></>
                              }
                              {
                                !suggestion.isComapny ?
                                  <Button
                                    onClick={() => {
                                      followUser(suggestion.id, myiNdex)
                                    }}
                                    className="btn request-btn"
                                    style={{ height: '32px', margin: '10px auto 10px' }}
                                  >
                                    {isGerm ? DE.follow : EN.follow}
                                  </Button>
                                  :
                                  <></>
                              }
                              {
                                (suggestion.isComapny && suggestion.isMenu) ?
                                  <div>
                                    <Button
                                      onClick={() => {
                                        followCompany(suggestion.id, myiNdex);
                                      }}
                                      className="btn request-btn"
                                      style={{ height: '32px', margin: '10px auto 10px' }}
                                    >
                                      {suggestion.followStatus ? isGerm ? DE.unfollow : EN.unfollow : isGerm ? DE.follow : EN.follow}
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setCompany({ ...suggestion, index: myiNdex })
                                        handleSubmit({ ...suggestion, index: myiNdex })
                                        // setPositionDialog(true)
                                      }}
                                      className="btn request-btn"
                                      style={{ height: '32px', margin: '10px auto 10px', padding: 5, minWidth: "110px" }}
                                    >
                                      {isGerm ? DE.join_cap : EN.join_cap}
                                    </Button>
                                  </div>
                                  :
                                  <></>
                              }
                              <a
                                className="cancel pointer pt-2"
                                onClick={() => {
                                  removeSuggestions(suggestion.id);
                                }}
                              >
                                X
                              </a>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="text-center">
                      <h6 className="p-2 m-0">
                        {isGerm ? DE.no_user_found : EN.no_user_found}
                      </h6>
                    </div>
                  )}
              </div>
            </div>
            :
            <InviteContact handleClose={(e) => setInviteLink(false)} />
          }
          <Modal
            show={showPositionDialog}
            onHide={hidePositionDialog}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{isGerm ? DE.your_position_within_company : EN.your_position_within_company}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup variant="flush">
                {Positions.map((position) => (
                  <div
                    onClick={(e) => setEmployeePosition(position.name_en)}
                    key={position.id}
                    className="form-check p"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id={position.id}
                      value={position.name_en}
                    />
                    <label
                      className="form-check-label pointer"
                      htmlFor={position.id}
                    >
                      {isGerm ? position.name_de : position.name_en}
                    </label>
                  </div>
                ))}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={hidePositionDialog}>
                {isGerm ? DE.close_modal : EN.close_modal}
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </Animated>
      </Layout>
    </React.Fragment>
  );
}

export default Contact;
