import { useDropzone } from "react-dropzone";
import { $crud } from "../helper/CrudFactory";

export default function Dropzone({ onDrop, children, err }) {
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    noDragEventsBubbling: true,
    noClick: true,
    accept: [
      ".jpg",
      ".png",
      ".jpeg",
      ".JPG",
      ".PNG",
      ".JPEG",
      '.heic',
      // ".mpg",
      // ".mov",
      // ".mp4",
      ".doc",
      ".docx",
      ".txt",
      "application/pdf",
      ".odt",
      ".xls",
      ".xlsx",
      ".xlsb", ".odt", ".xlsm", '.ppt', '.dwg', '.odf', '.ifc','.dwg','.dxf','.xlsm', '.pptx', '.csv', '.txt', '.mpp'
    ],
    multiple: true,
    onDropRejected: (event) => {
      const message = event?.[0]?.errors?.[0]?.message;
      if (message) {
        $crud.notify({
          type: "error",
          message: err ? err : message,
        });
      }
    },
  });

  return (
    <div {...getRootProps({ className: isDragActive && "dropzone" })}>
      {isDragActive ? <p>Drop File Here</p> : children}
    </div>
  );
}
