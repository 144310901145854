import React from "react";
import Autosuggest from "react-autosuggest";
import "./SuggestionInput.css";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { callAPI } from "../../helper/apiUtils";

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <div>
      <h6 className="font-weight-bold">{suggestion.name}</h6>
      <span>
        {suggestion.street} {suggestion.postCode} {suggestion.city}
      </span>
    </div>
  );
}
class SuggestionInput extends React.Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      suggestions: [],
      allSuggestions: [],
    };
  }

  suggestionSelected = async (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    await this.props.setSuggestion(suggestion);
  };

  getList = async () => {
    try {
      const { data } = await callAPI(
        "/company/getRegisterOrUnregisteredCompany",
        "",
        "GET"
      );
      if (data.status) {
        this.setState({ suggestions: data.companies });
        this.setState({ allSuggestions: data.companies });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  componentDidMount() {
    this.getList();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value !== "") {
      this.setState({ value: nextProps.value });
    }
  }
  
  onChange = async (event, { newValue, method }) => {
    await this.setState({
      value: newValue,
    });
    this.props.setParam("name", newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (!value) {
      this.setState({
        suggestions: this.state.allSuggestions,
      });
    } else {
      this.setState({
        suggestions: this.getSuggestions(value),
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.allSuggestions,
    });
  };

  getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === "") {
      return [];
    }
    const regex = new RegExp("^" + escapedValue, "i");
    return this.state.suggestions.filter((company) => regex.test(company.name));
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.context.isGerm
        ? DE.name_of_the_company
        : EN.name_of_the_company,
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        className="w-100"
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={this.suggestionSelected}
        inputProps={inputProps}
        id={this.props.id}
        autoComplete="new-password"
      />
    );
  }
}

export default SuggestionInput;
