export const Positions = [
    {
        "id": "2",
        "name_de": "Unternehmensadmin",
        "name_en": "Company admin"
    },
    {
        "id": "7",
        "name_de": "Mitarbeiter/in",
        "name_en": "Employee"
    },
    // {
    //     "id": "2",
    //     "name_de": "Inhaber/in - Firmenadmin",
    //     "name_en": "Owner - Company admin"
    // },
    // {
    //     "id": "3",
    //     "name_de": "Mitinhaber/in",
    //     "name_en": "Co-owner"
    // },
    // {
    //     "id": "4",
    //     "name_de": "Geschäftsführer/in",
    //     "name_en": "Managing Director"
    // },
    // {
    //     "id": "5",
    //     "name_de": "Partner/in",
    //     "name_en": "Associate"
    // },
    // {
    //     "id": "6",
    //     "name_de": "Sekretär/in",
    //     "name_en": "Secretary"
    // },
    // {
    //     "id": "7",
    //     "name_de": "Mitarbeiter/in",
    //     "name_en": "Employee"
    // },
    // {
    //     "id": "8",
    //     "name_de": "Projektmanager/in",
    //     "name_en": "Project Manager"
    // }
]