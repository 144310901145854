import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { Animated } from "react-animated-css";
import { callAPI } from "../../helper/apiUtils";
import { AgoDateFormat } from "../../helper/AgoDateFormat";
import { BASE_URL, KJUUP_COLORS, formatName } from "../../helper/constants";
import {
  ALL_BADGE_COUNT,
  GROUPS,
  GROUP_CHATS,
  IS_NEW_MESSAGE,
  NEWS_BADGE_COUNT,
  OPEN_CHATS,
  OPEN_PROJECTS,
  RESET_MARKIN_PROJECT,
  RIGHT_TOP,
  SET_ACTIVITY_MESSAGES,
  SET_ACTIVITY_MESSAGES_REMINDERS,
  SET_IN_ACTIVITY,
} from "../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { AiOutlineFile } from "react-icons/ai";
import { MdVideocam } from "react-icons/md";
import { IoMdImage } from "react-icons/io";
import { Oval } from "react-loader-spinner";
import { $crud } from "../../helper/CrudFactory";
import { onlyUsername } from "../Project/GroupChat/getUsername";
import { FiChevronDown } from "react-icons/fi";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as TrashIcon } from "../../Icons/Trash-grey.svg";

function LatestMessages({
  resetMessages,
  updateGroupChats,
  updateOpenChats,
  updateOpenProjects,
  openChats,
  openProjects,
  groupChats,
}) {
  const dispatch = useDispatch();
  const {
    unSeenAllCount,
    isNewMessage,
    activityMessages,
    activityMessagesReminders,
    user,
    inActivity,
  } = useSelector((state) => state);
  const { isGerm } = useContext(LanguageContext);
  const [isTabSelect, setIsTabSelect] = useState(true);
  const [AllChats, setAllChats] = useState([]);
  const [AllChatsReminder, setAllChatsReminders] = useState([]);
  const [gettingChats, setGettingChats] = useState(false);
  const [isChatsOver, setIsChatsOver] = useState(false);
  const [gettingChatsBottom, setGettingChatsBottom] = useState(false);
  const [page, setPage] = useState(1);
  const [pageReminder, setPageReminder] = useState(1);
  const [shouldGet, setShouldGet] = useState(false);
  const [isReminderOver, setIsReminderOver] = useState(false);
  const [latestMessageLength, setLatestMessageLength] = useState(0);

  useEffect(() => {
    if (inActivity) {
      setGettingChats(true);
      resetMessages();
      dispatch({ type: RESET_MARKIN_PROJECT, status: true });
      updateGroupChats([]);
      updateOpenChats([]);
      updateOpenProjects([]);
      _getMessages(1);
    }
    return () => {
      dispatch({ type: RESET_MARKIN_PROJECT, status: true });
      resetMessages();
      updateGroupChats([]);
      updateOpenChats([]);
      updateOpenProjects([]);
    };
  }, [inActivity]);

  useEffect(() => {
    if (isNewMessage) _changeLastMessage();
  }, [isNewMessage]);

  useEffect(() => {
    setAllChats([...activityMessages]);
  }, [activityMessages]);

  useEffect(() => {
    setAllChatsReminders([...activityMessagesReminders]);
  }, [activityMessagesReminders]);

  useEffect(() => {
    if (shouldGet) {
      getReminders(shouldGet);
    }
    setShouldGet(false);
  }, [shouldGet]);

  useEffect(() => {
    if (latestMessageLength !== 0 && latestMessageLength <= 20) {
      const newPage = page + 1
      setPage(newPage);
      setGettingChatsBottom(true)
      _getMessages(page + 1)
      return
    }
  }, [latestMessageLength]);

  const mergeConsecutiveImages = (sections) => {
    let updatedItems = sections?.reverse().reduce((result, current) => {
      if (!current?.isMultiples) {
        const last = result[result?.length - 1];
        if (current?.messageType === "image") {
          if (last?.messageType === "image") {
            const lastImageTime = new Date(
              last.images[last.images.length - 1].createdAt
            ).getTime();
            const currentTime = new Date(current.createdAt).getTime();
            let timeDifference = Math.floor(
              (lastImageTime - currentTime) / (1000 * 60)
            );
            timeDifference = Math.abs(timeDifference);
            if (
              last.senderId === current.senderId &&
              timeDifference <= 1
              && last.message === current.message
            ) {
              last.isMultiples = true;
              last.images.push({ ...current });
              last.count = last.images.length;
            } else {
              result.push({ ...current, images: [{ ...current }], count: 1 });
            }
          } else {
            result.push({ ...current, images: [{ ...current }], count: 1 });
          }
        } else {
          result.push({ ...current });
        }
      } else {
        result.push({ ...current });
      }
      return result;
    }, []);
    return updatedItems.reverse();
  };

  const _changeLastMessage = () => {
    let isExist = isNewMessage.unseenChatCount.findIndex((e) => e.userId === user.id);
    if (isExist != -1) {
      let tmp = [...activityMessages];
      let newMess = { ...isNewMessage, isOpened: tmp.findIndex((e) => e.isOpened && e.group.id === isNewMessage.group.id) != -1 ? true : false };
      let index = tmp.findIndex(e => e.group.id == isNewMessage.group.id)
      if (index != -1) {
        tmp[index] = { ...newMess }
      }
      else
        tmp.unshift(newMess);
      console.log('--index', index, tmp)
      tmp.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      const newData = mergeConsecutiveImages(tmp);
      dispatch({ type: SET_ACTIVITY_MESSAGES, messages: newData });
    }
    dispatch({ type: IS_NEW_MESSAGE, status: false });
  };

  const _getMessages = async (page) => {
    const { data } = await callAPI(
      `/group/getUserChats?page=${page}&limit=20`,
      {},
      "GET"
    );
    if (data.status) {
      if (data?.chats?.length > 0) {
        data.chats = data?.chats?.map((e) => {
          return {
            ...e,
            mainId: e.id,
            id: !e.group.isOneToOne ? e.buildingList : e.id,
            building: e.buildingList,
            group: { ...e.group, building: e.buildingList },
          };
        });
        if (AllChats.length > 0) {
          let tt = data.chats;
          let x = AllChats.filter((e) => e.isOpened);
          x = x.map((e) => {
            return e.group.id;
          });
          x = [...new Set(x)];
          tt = tt.map((e) => {
            return { ...e, isOpened: x.includes(e.group.id) ? true : false };
          });
          const _m = [...AllChats, ...tt];
          const newData = mergeConsecutiveImages(_m);
          dispatch({ type: SET_ACTIVITY_MESSAGES, messages: newData });
          setGettingChatsBottom(false);
        } else {
          const newData = mergeConsecutiveImages(data?.chats);
          dispatch({ type: SET_ACTIVITY_MESSAGES, messages: newData });
          if (newData?.length < 20) {
            setLatestMessageLength(newData?.length || 0);
          }
          setGettingChats(false);
        }
      }
      else {
        setIsChatsOver(true);
        setGettingChats(false);
        setGettingChatsBottom(false);
      }
    } else {
      setGettingChats(false);
      setGettingChatsBottom(false);
    }
  };

  const _getMessagesReminders = async (page) => {
    setShouldGet(page);
  };

  const getReminders = async (page) => {
    const { data } = await callAPI(
      `/group/getUserChats?page=${page}&limit=20&isReminder=true`,
      {},
      "GET"
    );
    if (data.status) {
      data.chats = data.chats.map((e) => {
        return {
          ...e,
          mainId: e.id,
          id: !e.group.isOneToOne ? e.buildingList : e.id,
          building: e.buildingList,
          group: { ...e.group, building: e.buildingList },
        };
      });
      if (data.chats.length === 0) {
        setIsReminderOver(true);
      }
      if (AllChatsReminder.length > 0) {
        let tt = data.chats;
        let x = AllChatsReminder.filter((e) => e.isOpened);
        x = x.map((e) => {
          return e.group.id;
        });
        x = [...new Set(x)];
        tt = tt.map((e) => {
          return { ...e, isOpened: x.includes(e.group.id) ? true : false };
        });
        let _m = [...AllChatsReminder, ...tt];
        dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: _m });
      } else {
        dispatch({
          type: SET_ACTIVITY_MESSAGES_REMINDERS,
          messages: data.chats,
        });
      }
      setGettingChats(false);
      setGettingChatsBottom(false);
    } else {
      setGettingChats(false);
      setGettingChatsBottom(false);
    }
    setShouldGet(false);
  };

  const _resetAndGetLatestMessages = async () => {
    dispatch({ type: RESET_MARKIN_PROJECT, status: true });
    resetMessages();
    updateGroupChats([]);
    updateOpenChats([]);
    updateOpenProjects([]);
    setGettingChats(true);
    dispatch({ type: SET_ACTIVITY_MESSAGES, messages: [] });
    setIsTabSelect(true);
    setPage(1);
    setLatestMessageLength(0)
    _getMessages(1);
  };

  const _resetAndGetLatestReminder = async () => {
    setAllChatsReminders([]);
    dispatch({ type: RESET_MARKIN_PROJECT, status: true });
    resetMessages();
    updateGroupChats([]);
    updateOpenChats([]);
    updateOpenProjects([]);
    setIsTabSelect(false);
    dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: [] });
    setPageReminder(1);
    setGettingChats(true);
    _getMessagesReminders(1);
  };

  const _removeSingleReminder = async (id, group, type) => {
    if (type == "one") {
      if (group.isOpened) chat_click_reminder(group);
    } else {
      if (group.isOpened) chat_click_group_reminder(group);
    }
    let tmp = [...AllChatsReminder];
    tmp = tmp.filter((e) => e.mainId !== id);
    dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp });
    try {
      const { data } = await callAPI("/group/reminderMessage", {}, "POST", {
        chatId: id,
        isReminder: false,
      });
      if (data.status) {
        $crud.notify({
          type: "success",
          message: data.message,
        });
      }
    } catch (e) { }
  };

  const _removeAllReminders = async (id) => {
    dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: [] });
    try {
      const { data } = await callAPI(
        "/group/removeReminders",
        {},
        "DELETE",
        null
      );
      if (data.status) {
        $crud.notify({
          type: "success",
          message: data.message,
        });
      }
    } catch (e) { }
  };

  const _renderTabs = () => {
    return (
      <div className="tabBar" style={{ marginTop: 0 }}>
        <div
          onClick={() => _resetAndGetLatestMessages()}
          className="tab1"
          style={{
            padding: 10,
            backgroundColor: isTabSelect ? "lightgray" : "transparent",
          }}
        >
          <span
            style={{ color: isTabSelect ? "#fff" : "grey", fontWeight: "500" }}
          >
            {isGerm ? DE.latest_messages : EN.latest_messages}
          </span>
        </div>
        <div style={{ width: 20 }} />
        <div
          onClick={() => _resetAndGetLatestReminder()}
          className="tab1"
          style={{
            padding: 10,
            backgroundColor: !isTabSelect ? "lightgray" : "transparent",
          }}
        >
          <span
            style={{ color: !isTabSelect ? "#fff" : "grey", fontWeight: "500" }}
          >
            {isGerm ? DE.reminder : EN.reminder}
          </span>
        </div>
      </div>
    );
  };

  const _updateAllBadges = (item) => {
    let cnt = 0;
    if (item.unseenChatCount && Array.isArray(item.unseenChatCount)) {
      let fll = item.unseenChatCount.filter((e) => e.userId === user.id);
      if (fll.length > 0) {
        cnt = fll[0].unseenChatCount;
      }
    }
    let a = { ...unSeenAllCount };
    let unseenInternalProject = item.isInternal
      ? a.unseenInternalProject - cnt
      : a.unseenInternalProject;
    let unseenExternalProject = !item.isInternal
      ? a.unseenExternalProject - cnt
      : a.unseenExternalProject;
    let f = {
      ...a,
      unseenBuildingChatCount: a.unseenBuildingChatCount - cnt,
      unseenInternalProject,
      unseenExternalProject,
    };
    dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: f });
  };

  const chat_click = async (group, index) => {
    _updateAllBadges(group);
    const chk = openChats?.find((g) => g.id === group.group.id);
    if (chk) {
      const openGroups = openChats?.filter((p) => p.id !== group.group.id);
      if (openGroups?.length > 2) {
        $crud.notify({
          type: "error",
          message: isGerm ? DE.more_than_two : EN.more_than_two,
        });
      } else {
        let tmp = [...AllChats];
        tmp = tmp.map((e) => {
          return {
            ...e,
            isOpened: e.group.id == group.group.id ? false : e.isOpened,
          };
        });
        // setAllChats([...tmp])
        dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
        updateOpenChats(openGroups);
      }
    } else {
      let tempData = [...openChats, { ...group.group }];
      if (tempData?.length > 2) {
        $crud.notify({
          type: "error",
          message: isGerm ? DE.more_than_two : EN.more_than_two,
        });
      } else {
        let tmp = [...AllChats];
        tmp = tmp.map((e) => {
          return {
            ...e,
            isOpened: e.group.id == group.group.id ? true : e.isOpened,
          };
        });
        // setAllChats([...tmp])
        dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
        updateOpenChats([...openChats, { ...group.group }]);
      }
    }
  };

  const chat_click_group = async (group, index) => {
    _updateAllBadges(group);
    const chk = openProjects?.find((p) => p.id == group.buildingList);
    if (chk) {
      const updateProjects = openProjects?.filter(
        (p) => p.id !== group.buildingList
      );
      let tmp = [...AllChats];
      tmp = tmp.map((e) => {
        return {
          ...e,
          isOpened: e.buildingList == group.buildingList ? false : e.isOpened,
        };
      });
      // setAllChats([...tmp])
      dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
      updateGroupChats([]);
      updateOpenProjects(updateProjects);
    } else {
      let tmp = [...AllChats];
      tmp = tmp.map((e) => {
        return {
          ...e,
          isOpened: e.buildingList == group.buildingList ? true : e.isOpened,
        };
      });
      // setAllChats([...tmp])
      dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
      const updateProjects = [...openProjects, group];
      updateOpenProjects(updateProjects);
      const chk = groupChats?.find((p) => p.id == group.group.id);
      if (!chk) {
        const updateGroup = [...groupChats, group.group];
        updateGroupChats(updateGroup);
      }
    }
  };

  const chat_click_reminder = async (group, index) => {
    _updateAllBadges(group);
    const chk = openChats?.find((g) => g.id === group.group.id);
    if (chk) {
      const openGroups = openChats?.filter((p) => p.id !== group.group.id);
      if (openGroups?.length > 2) {
        $crud.notify({
          type: "error",
          message: isGerm ? DE.more_than_two : EN.more_than_two,
        });
      } else {
        let tmp = [...AllChatsReminder];
        tmp = tmp.map((e) => {
          return {
            ...e,
            isOpened: e.group.id == group.group.id ? false : e.isOpened,
          };
        });
        dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp });
        updateOpenChats(openGroups);
      }
    } else {
      let tempData = [...openChats, { ...group.group }];
      if (tempData?.length > 2) {
        $crud.notify({
          type: "error",
          message: isGerm ? DE.more_than_two : EN.more_than_two,
        });
      } else {
        let tmp = [...AllChatsReminder];
        tmp = tmp.map((e) => {
          return {
            ...e,
            isOpened: e.group.id == group.group.id ? true : e.isOpened,
          };
        });
        dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp });
        updateOpenChats([...openChats, { ...group.group }]);
      }
    }
  };

  const chat_click_group_reminder = async (group, index) => {
    _updateAllBadges(group);
    const chk = openProjects?.find((p) => p.id == group.buildingList);
    if (chk) {
      const updateProjects = openProjects?.filter(
        (p) => p.id !== group.buildingList
      );
      let tmp = [...AllChatsReminder];
      tmp = tmp.map((e) => {
        return {
          ...e,
          isOpened: e.buildingList == group.buildingList ? false : e.isOpened,
        };
      });
      // setAllChats([...tmp])
      dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp });
      updateGroupChats([]);
      updateOpenProjects(updateProjects);
    } else {
      let tmp = [...AllChatsReminder];
      tmp = tmp.map((e) => {
        return {
          ...e,
          isOpened: e.buildingList == group.buildingList ? true : e.isOpened,
        };
      });
      // setAllChats([...tmp])
      dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp });
      const updateProjects = [...openProjects, group];
      updateOpenProjects(updateProjects);
      const chk = groupChats?.find((p) => p.id == group.group.id);
      if (!chk) {
        const updateGroup = [...groupChats, group.group];
        updateGroupChats(updateGroup);
      }
    }
  };

  const getProjectName = (name) => {
    if (name) {
      const nameArray = name?.split(" ");
      if (nameArray && nameArray.length) {
        const length = nameArray.length;
        let newName = [];
        newName.push(nameArray[0][0]);
        if (nameArray.length > 1) {
          newName.push(nameArray[length - 1][0]);
        }
        return newName.join("");
      }
    }
  };

  const _renderOneToOne = (group, index) => {
    const isUser = group.senderId === user.id;
    return (
      <div className="company_services m-0 chat_services user_info_chat left_users p-1">
        <div className="contextMenu left-context">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              className="toggleMenu pointer"
              style={{ right: 2, backgroundColor: "rgba(240, 255, 242, 0.8)" }}
            >
              <FiChevronDown size={25} className="angleDownIcon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {isTabSelect ? (
                <Dropdown.Item
                  onClick={async () => {
                    try {
                      const { data } = await callAPI(
                        "/group/reminderMessage",
                        {},
                        "POST",
                        {
                          chatId: group.id,
                          isReminder: true,
                        }
                      );
                      if (data.status) {
                        $crud.notify({
                          type: "success",
                          message: data.message,
                        });
                      }
                    } catch (e) { }
                  }}
                >
                  {isGerm ? DE.move_to_reminder : EN.move_to_reminder}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={async () => {
                    await $crud.confirm({
                      title: isGerm ? DE.confirm : EN.confirm,
                      textContent: isGerm
                        ? DE.remove_reminder
                        : EN.remove_reminder,
                      options: {
                        ok: isGerm ? DE.ok : EN.ok,
                        cancel: isGerm ? DE.cancel : EN.cancel,
                      },
                    });
                    _removeSingleReminder(group.id, group, "one");
                  }}
                >
                  {isGerm ? DE.remove_from_reminder : EN.remove_from_reminder}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          key={index}
          className={`company_centact ${group.isOpened ? "active-project" : ""
            }`}
          onClick={(e) => {
            if (isTabSelect) chat_click(group, index);
            else chat_click_reminder(group, index);
          }}
          style={{
            opacity: group?.isDeactivated ? 0.5 : 1,
            paddingBottom: 0,
            display: "flex",
            borderBottom: "1px solid #DFDFDF",
          }}
        >
          <a className="website pointer" style={{ flex: 1 }}>
            <div className="website_img">
              <img
                width={40}
                height={40}
                style={{ borderRadius: "5px", objectFit: "cover" }}
                src={
                  (isUser ? group.avatar : group.senderAvatar)
                    ? BASE_URL + (isUser ? group.avatar : group.senderAvatar)
                    : process.env.PUBLIC_URL + "/assets/img/profile.png"
                }
                alt="Generic placeholder"
                
              />
            </div>
            <div
              className="details-company forword-checkbox_main"
              style={{ border: "none" }}
            >
              <div className="m-0">
                <h5>{isUser ? group.name : group.senderName}</h5>
                <div className="m-0">
                  {group.lastMessageDeleted && (
                    <p className="m-0 text-truncate">
                      {isGerm ? DE.message_delete : EN.message_delete}
                    </p>
                  )}
                  {group.messageType === "simple" && (
                    <p className="m-0 text-truncate">
                      {group.message?.replaceAll("<br/>", " ")}
                    </p>
                  )}
                  {group.messageType === "doc" && !group.lastMessageDeleted && (
                    <p className="m-0 text-truncate">
                      <AiOutlineFile />
                    </p>
                  )}
                  {group.messageType === "video" &&
                    !group.lastMessageDeleted && (
                      <p className="m-0 text-truncate">
                        <MdVideocam /> {group.message}
                      </p>
                    )}
                  {group.messageType === "image" &&
                    !group.lastMessageDeleted && (
                      <div className="m-0 text-truncate" style={{ display: "flex", alignItems: "center", fontSize: '13px', color: "#737373" }}>
                        <IoMdImage color="#737373" />
                        <span style={{ fontWeight: "bold", }}>
                          {
                            group?.count > 1 ? ` ${group?.count} ${isGerm ? DE.m_image : EN.m_image}` : ` ${isGerm ? DE.s_image : EN.s_image}`
                          }
                          <span style={{ fontWeight: "normal" }}>
                            {group?.message ? ` | ${formatName(onlyUsername(
                              group.message
                                .replace(/<br\s*[/]?>/gi, "\n")
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp;/g, " ")
                            ), 25)}` : <></>}
                          </span>
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </a>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 10,
              flexDirection: "column",
              paddingBottom: 5,
            }}
          >
            <div className="small float-right w-100 text-right color-gray">
              {AgoDateFormat(group.createdAt, isGerm)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderGroupChats = (group, index) => {
    return (
      <div className="company_services m-0 chat_services user_info_chat left_users p-1">
        <div className="contextMenu left-context">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              className="toggleMenu pointer"
              style={{ right: 2, backgroundColor: "rgba(240, 255, 242, 0.8)" }}
            >
              <FiChevronDown size={25} className="angleDownIcon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {isTabSelect ? (
                <Dropdown.Item
                  onClick={async () => {
                    try {
                      const { data } = await callAPI(
                        "/group/reminderMessage",
                        {},
                        "POST",
                        {
                          chatId: group.mainId,
                          isReminder: true,
                        }
                      );
                      if (data.status) {
                        $crud.notify({
                          type: "success",
                          message: data.message,
                        });
                      }
                    } catch (e) { }
                  }}
                >
                  {isGerm ? DE.move_to_reminder : EN.move_to_reminder}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={async () => {
                    await $crud.confirm({
                      title: isGerm ? DE.confirm : EN.confirm,
                      textContent: isGerm
                        ? DE.remove_reminder
                        : EN.remove_reminder,
                      options: {
                        ok: isGerm ? DE.ok : EN.ok,
                        cancel: isGerm ? DE.cancel : EN.cancel,
                      },
                    });
                    _removeSingleReminder(group.mainId, group, "group");
                  }}
                >
                  {isGerm ? DE.remove_from_reminder : EN.remove_from_reminder}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          key={index}
          className={`company_centact ${group.isOpened ? "active-project" : ""
            }`}
          onClick={(e) => {
            // if (!props.forwardMessage)
            if (isTabSelect) chat_click_group(group, index);
            else chat_click_group_reminder(group, index);
          }}
          style={{
            opacity: group?.isDeactivated ? 0.5 : 1,
            paddingBottom: 0,
            display: "flex",
            borderBottom: "1px solid #DFDFDF"
          }}
        >
          <a className="website pointer" style={{ flex: 1 }}>
            <div className="website_img">
              <div
                style={{
                  height: 40,
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "600",
                  width: 40,
                  textAlign: "center",
                  backgroundColor: group.buildingThemeColor,
                  borderRadius: 5,
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                {getProjectName(group.buildingName)}
                <div style={{ flex: 1 }} />
              </div>
            </div>
            <div
              className="details-company forword-checkbox_main"
              style={{ border: "none", marginLeft: 10 }}
            >
              <div className="m-0">
                <div className="mt-0">
                  <h5 style={{ fontWeight: "bold" }}>{group.buildingName}</h5>
                  <h5>
                    {group.defaultChatGroup
                      ? isGerm
                        ? DE.All
                        : EN.All
                      : group.name}
                  </h5>
                </div>
                <div className="m-0">
                  {group.lastMessageDeleted && (
                    <p className="m-0 text-truncate">
                      {isGerm ? DE.message_delete : EN.message_delete}
                    </p>
                  )}
                  {group.messageType === "simple" && group.message && (
                    <p className="m-0 text-truncate">
                      {onlyUsername(
                        group.message
                          .replace(/<br\s*[/]?>/gi, "\n")
                          .replace(/<[^>]+>/g, "")
                          .replace(/&nbsp;/g, " ")
                      )}
                    </p>
                  )}
                  {group.messageType === "doc" && !group.lastMessageDeleted && (
                    <p className="m-0 text-truncate">
                      <AiOutlineFile />
                    </p>
                  )}
                  {group.messageType === "video" &&
                    !group.lastMessageDeleted && (
                      <p className="m-0 text-truncate">
                        <MdVideocam /> {group.message}
                      </p>
                    )}
                  {group.messageType === "image" &&
                    !group.lastMessageDeleted && (
                      <div className="m-0 text-truncate" style={{ display: "flex", alignItems: "center", fontSize: '13px', color: "#737373" }}>
                        <IoMdImage color="#737373" />
                        <span style={{ fontWeight: "bold", }}>
                          {
                            group?.count > 1 ? ` ${group?.count} ${isGerm ? DE.m_image : EN.m_image}` : ` ${isGerm ? DE.s_image : EN.s_image}`
                          }
                          <span style={{ fontWeight: "normal" }}>
                            {group?.message ? ` | ${formatName(onlyUsername(
                              group.message
                                .replace(/<br\s*[/]?>/gi, "\n")
                                .replace(/<[^>]+>/g, "")
                                .replace(/&nbsp;/g, " ")
                            ), 25)}` : <></>}
                          </span>
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </a>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: 10,
              flexDirection: "column"
            }}
          >
            <div style={{ marginBottom: 5 }} className="small float-right w-100 text-right color-gray">
              {AgoDateFormat(group.createdAt, isGerm)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderChats = (item, index) => {
    return item.group ? (
      item.group.isOneToOne ? (
        _renderOneToOne(item, index)
      ) : (
        _renderGroupChats(item, index)
      )
    ) : (
      <></>
    );
  };

  const handleScroll = (e) => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) <=
      parseInt(e.target.clientHeight);
    if (bottom && !gettingChatsBottom && !isChatsOver) {
      const newPage = page + 1;
      setPage(newPage);
      setGettingChatsBottom(true);
      _getMessages(newPage);
    }
  };

  const handleScrollReminder = (e) => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) <=
      parseInt(e.target.clientHeight);
    if (bottom && !gettingChatsBottom && !isReminderOver) {
      const newPage = pageReminder + 1;
      setPageReminder(newPage);
      setGettingChatsBottom(true);
      _getMessagesReminders(newPage);
    }
  };

  return (
    <Layout>
      <Animated animationIn="slideInLeft" animationOut="slideOutLeft">
        <div
          className="w-300"
          style={{
            height: "93vh",
            padding: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {_renderTabs()}
          {!isTabSelect && AllChatsReminder.length > 0 ? (
            <div
              onClick={async () => {
                await $crud.confirm({
                  title: isGerm ? DE.confirm : EN.confirm,
                  textContent: isGerm
                    ? DE.remove_all_confirm
                    : EN.remove_all_confirm,
                  options: {
                    ok: isGerm ? DE.ok : EN.ok,
                    cancel: isGerm ? DE.cancel : EN.cancel,
                  },
                });
                _removeAllReminders();
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 10,
                marginRight: 10,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ flex: 1 }}>
                <span style={{ fontWeight: "bold" }}>
                  {isGerm ? DE.remove_all_reminder : EN.remove_all_reminder}
                </span>
              </div>
              <div>
                <TrashIcon height={20} className="custom-svg active pointer" />
              </div>
            </div>
          ) : !isTabSelect && !gettingChats ? (
            <div style={{ textAlign: "center", paddingTop: 100 }}>
              <span style={{ fontWeight: "600", textAlign: "center" }}>
                {isGerm ? DE.np_reminder : EN.np_reminder}
              </span>
            </div>
          ) : (
            <></>
          )}

          <div
            className="scroll"
            onScroll={isTabSelect ? handleScroll : handleScrollReminder}
            style={{ height: "100%", overflowX: "hidden" }}
          >
            {(isTabSelect ? AllChats : AllChatsReminder).map((item, index) => {
              return _renderChats(item, index);
            })}

            {gettingChats && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  marginBottom: 10,
                  marginTop: 10,
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }} />
                <Oval
                  height={30}
                  width={30}
                  color={KJUUP_COLORS.base_color}
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor={"#fff"}
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <div style={{ flex: 1 }} />
              </div>
            )}

            {gettingChatsBottom && (
              <div
                style={{
                  alignSelf: "center",
                  marginBottom: 10,
                  marginTop: 10,
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <div style={{ flex: 1 }} />
                <Oval
                  height={30}
                  width={30}
                  color={KJUUP_COLORS.base_color}
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor={"#fff"}
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <div style={{ flex: 1 }} />
              </div>
            )}
          </div>
        </div>
      </Animated>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  const {
    rightTop,
    unseenNewsCount,
    openChats,
    groups,
    groupChats,
    openProjects,
  } = state;
  return {
    rightTop: rightTop,
    unseenNewsCount: unseenNewsCount,
    openChats: openChats,
    groups: groups,
    groupChats: groupChats,
    openProjects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetMessages: () =>
      dispatch({ type: SET_ACTIVITY_MESSAGES, messages: [] }),
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    setInActivity: (status) =>
      dispatch({ type: SET_IN_ACTIVITY, status: status }),
    updateRightTop: (value) => dispatch({ type: RIGHT_TOP, rightTop: value }),
    updateNewsCount: (count) =>
      dispatch({ type: NEWS_BADGE_COUNT, unseenNewsCount: count }),
    updateOpenChats: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
    updateGroupChats: (chats) =>
      dispatch({ type: GROUP_CHATS, groupChats: chats }),
    updateOpenProjects: (projects) =>
      dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LatestMessages));
