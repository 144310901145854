import React, { useEffect, useState } from "react";
import LanguageContext from "../../../helper/LanguageContext";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import { BsX } from "react-icons/bs";

const style = {
    marginBottom: 0,
    fontSize: '14px',
    backgroundColor: '#f5f5f5',
    padding: "13px 15px",
    fontWeight: 400
}

export default function DepartLocaDialogue({ articleType, handleClose, setParam, positions, setSelectName, setDepName, multiple, onSelectMultiple }) {
    const { isGerm } = React.useContext(LanguageContext)
    const [myPosition, setMyPositions] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    useEffect(() => {
        setMyPositions(positions)
    }, [positions])
    const handleChangeValue = async (cform, value) => {
        if (!multiple) {
            console.log(cform)
            setParam('deptLocation', cform.id);
            setDepName(value)
            setSelectName(cform)
            handleClose();
        }
        else {

        }
    }

    const onpress = (val, index) => {
        let tmp = [...myPosition]
        tmp[index].isSelected = !tmp[index].isSelected
        let x = tmp.filter((e) => e.isSelected)
        if (x.length == tmp.length) {
            setSelectAll(true)
        }
        else {
            setSelectAll(false)
        }
        let ids = tmp.filter(e => e.isSelected).map((e) => {
            return e.id
        })
        let names = tmp.filter(e => e.isSelected).map((cform) => {
            if (cform.location_name && cform.location_name != 'null')
                return `${cform.department_name}/${cform.location_name}`
            else
                return `${cform.department_name}`

        })
        onSelectMultiple(ids, names.toString())
        setMyPositions([...tmp])
    }
    return (
        <div className={articleType == 1 ? "role_popup shadow-sm theme-border-internal" : "role_popup shadow-sm theme-border"}>
            <div style={{ ...style, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h5>{isGerm ? DE.dep_loc : EN.dep_loc}</h5>
                <div onClick={handleClose}>
                    <BsX
                        size={'30px'}
                    />
                </div>
            </div>
            <div className="role_data mb-0">
                {multiple ?
                    <div
                        onClick={() => {
                            let b = !selectAll
                            let tmp = myPosition.map((e) => {
                                return { ...e, isSelected: b }
                            })
                            let ids = tmp.filter(e => e.isSelected).map((e) => {
                                return e.id
                            })
                            let names = tmp.filter(e => e.isSelected).map((cform) => {
                                if (cform.location_name && cform.location_name != 'null')
                                    return `${cform.department_name}/${cform.location_name}`
                                else
                                    return `${cform.department_name}`
                            })
                            onSelectMultiple(ids, names.toString())
                            setSelectAll(b)
                            setMyPositions([...tmp])
                        }}
                        className="p-1 pl-2 pb-2" style={{ borderBottom: "1px solid #DFDFDF" }}>
                        <label className={articleType == 1 ? "role-label-internal p-1" : "role-label p-1"}>
                            <span
                                className="font-weight-bold">{isGerm ? 'Alle auswählen' : 'Select all'}</span>

                            <input
                                disabled
                                checked={selectAll}
                                className="form-check-input" type={'checkbox'}
                                id={'sd'}
                                onChange={e => { }}
                                value={`ss`} />
                            <span className={articleType == 1 ? "checkmark-label-internal" : "checkmark-label"} />
                        </label>
                    </div>
                    :
                    <></>
                }
                <ul className="list-group">
                    {
                        myPosition.map((cform, i) =>
                            <div key={cform.id} onClick={() => {
                                if (multiple) {
                                    onpress(cform, i)
                                }
                            }}>
                                <li key={cform.id} className="list-group-item p-1 pl-2" style={{ color: "black" }}>
                                    <div className="">
                                        <label className={articleType == 1 ? "role-label-internal p-1" : "role-label p-1"}>
                                            <span
                                                className="font-weight-bold">{(cform.location_name && cform.location_name != 'null') ? `${cform.department_name}/${cform.location_name}` : `${cform.department_name}`}</span>
                                            {multiple ?
                                                <>
                                                    <input
                                                        disabled
                                                        checked={cform.isSelected}
                                                        className="form-check-input" type={multiple ? 'checkbox' : "radio"} name="exampleRadios"
                                                        id={cform.id}
                                                        onChange={e => handleChangeValue(cform, (cform.location_name && cform.location_name != 'null') ? `${cform.department_name}/${cform.location_name}` : `${cform.department_name}`)}
                                                        value={(cform.location_name && cform.location_name != 'null') ? `${cform.department_name}/${cform.location_name}` : `${cform.department_name}`} />
                                                    <span className={articleType == 1 ? "checkmark-label-internal" : "checkmark-label"} />

                                                </>
                                                :
                                                <>
                                                    <input className="form-check-input" type={multiple ? 'checkbox' : "radio"} name="exampleRadios"
                                                        id={cform.id}
                                                        onChange={e => handleChangeValue(cform, (cform.location_name && cform.location_name != 'null') ? `${cform.department_name}/${cform.location_name}` : `${cform.department_name}`)}
                                                        value={(cform.location_name && cform.location_name != 'null') ? `${cform.department_name}/${cform.location_name}` : `${cform.department_name}`} />
                                                    <span className={articleType == 1 ? "checkmark-label-internal" : "checkmark-label"} />

                                                </>
                                            }
                                        </label>
                                    </div>
                                </li>
                            </div>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
