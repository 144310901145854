import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import MapContainer from '../pages/map';
import Carousel from "react-multi-carousel";
import { BASE_URL } from '../helper/constants';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player'
import { withRouter } from "react-router-dom";
import { ARTICLE } from "./../store";
import { connect } from "react-redux";
import { BsFillXCircleFill } from 'react-icons/bs';


function VideoPlayers({ url, type, isPlaying, fileType, viewArticle, article, local, isC, removeImage, index }) {
    let vidRef = useRef(null)
    const viewFullScreen = async () => {
        viewArticle(article)
    }
    return (
        <div style={isC ? {} : { }}>
            {
                !fileType && !local ?
                    <ReactPlayer
                        playing={isPlaying}
                        ref={vidRef}
                        url={url}
                        config={{
                            youtube: {
                                playerVars: {
                                    autoplay: 0,
                                    rel: 0,
                                    muted: 1,
                                    loop: 1,
                                    disablekb: 1,
                                    controls: 1,
                                    modestbranding: 1
                                }
                            },
                            vimeo: {
                                playerOptions: {
                                    autoplay: 0,
                                    muted: 1
                                }
                            }
                        }}
                        controls
                        muted={true}
                    />
                    :
                    <div>
                        {isC &&
                            <BsFillXCircleFill
                                size={20}
                                className="pointer position-absolute"
                                style={{ right: 12, top: 12, zIndex: 1 }}
                                color="red"
                                onClick={() => removeImage(index)}
                            />
                        }
                        <img className="embed-responsive-item pointer"
                            onClick={viewFullScreen}
                            src={!local ? (BASE_URL + url) : local}
                            style={{
                                objectFit: 'cover'
                            }}
                        />
                    </div>
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    const { article, drawerOpen } = state;
    return {
        // article: article,
        drawerOpen: drawerOpen,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        viewArticle: (article) => dispatch({ type: ARTICLE, article: article }),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VideoPlayers));
