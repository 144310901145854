export const BuildingTypes = [
    {
        id: 1,
        name_en: "General Indication",
        name_de: "Allgemeine Indikation",
        types:[
            {
                id: 1,
                name_en: "New Building",
                name_de: "Neubau"
            },
            {
                id: 2,
                name_en: "Old Building",
                name_de: "Altbau"
            },

            {
                id: 3,
                name_en: "Renovation",
                name_de: "Sanierung"
            },
            {
                id: 4,
                name_en: "Conversion",
                name_de: "Umbau"
            },
            {
                id: 5,
                name_en: "Others",
                name_de: "Andere"
            },

        ]
    },
    {
        id: 2,
        name_en: "Special Indication",
        name_de: "Besondere Indikation",
        types:[
            {
                id: 1,
                name_en: "Housing",
                name_de: "Wohnungsbau"
            },
            {
                id: 2,
                name_en: "Office Building",
                name_de: "Bürobau"
            },
            {
                id: 3,
                name_en: "Commercial",
                name_de: "Gewerbebau"
            },
            {
                id: 4,
                name_en: "Industrial",
                name_de: "Industriebau"
            },
            {
                id: 5,
                name_en: "School Construction",
                name_de: "Schulbau"
            },
            {
                id: 6,
                name_en: "Hospital Construction",
                name_de: "Krankenhausbau"
            },
            {
                id: 7,
                name_en: "Daycare Construction",
                name_de: "Kitabau"
            },
            {
                id: 8,
                name_en: "Highway Construction",
                name_de: "Straßenbau"
            },
            {
                id: 9,
                name_en: "Engineering Construction",
                name_de: "Ingenieurbau"
            },
            {
                id: 10,
                name_en: "Civil Engineering",
                name_de: "Tiefbau"
            },
            {
                id: 11,
                name_en: "Other",
                name_de: "Andere"
            },
        ]
    },
]