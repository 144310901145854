import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { FaTimes } from "react-icons/all";
import InputEmoji from 'react-input-emoji'

export default function EmojiPickerDialog({
  handleClose,
  setMessage,
  sendMessage,
}) {
  const [text, setText] = useState("");
  const textAreaRef = useRef();

  const sendEmojiMessage = () => {
    sendMessage();
    handleClose();
  };

  const userTyping = async (e) => {
    setText(e);
    setMessage(e);
  };

  const addEmoji = (emoji) => {
    if ("native" in emoji) {
      let tx = textAreaRef.current.innerText
      const cursor = textAreaRef.current.selectionStart;
      console.log(textAreaRef.current)
      const textx = tx.slice(0, cursor) + emoji.native
      textAreaRef.current.innerText = textx;
      setText(textx);
      setMessage(textx);
    }
  };


  function handleOnEnter(text) {
    console.log("enter", text);
  }

  return (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        backgroundColor:"white",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius:10,
        paddingBottom:10,
        overflow:"hidden",
      }}
    >
      <div>
        <a style={{ zIndex: 100 }} className="pointer top-close" onClick={handleClose}>
          <FaTimes size={20} />
        </a>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 9 }}>
            <InputEmoji
              value={text}
              onChange={userTyping}
              // cleanOnEnter
              onEnter={handleOnEnter}
              placeholder="Type a message"         
            />
          </div>
          <div style={{ flex: 1, flexDirection: "column", justifyContent: "center", display: "flex" }}>
            <a
              href="javascript:void(0)"
              onClick={() => sendEmojiMessage()}
            >
              <img
                className="p-0"
                width={20}
                src={process.env.PUBLIC_URL + "/assets/img/Send.svg"}
                alt="Generic placeholder"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
