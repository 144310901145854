import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import _ from "lodash";
import { Form } from "react-bootstrap";
import { KJUUP_COLORS } from '../helper/constants';
import { Discuss, Rings } from 'react-loader-spinner'
import { Scrollbars } from 'react-custom-scrollbars';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            street: '',
            isSeleted: false
        };
    }


    static getDerivedStateFromProps(props, state) {
        if (state.address !== props.defaultValue) {
            console.log("getDerivedStateFromProps", props.defaultValue)
            return { address: props.defaultValue }
        }
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.address !== this.state.address) {
    //         if(!this.state.isSeleted){
    //             // Geocode.fromAddress(this.state.address).then(
    //             //     (results) => {
    //             //       console.log("results", results);
    //             //       const { lat, lng } = results.results[0].geometry.location;

    //             //       let country;
    //             //       console.log("lat", lat, lng);
    //             //       for (var i = 0; i < results.results[0].address_components.length; i++) {
    //             //         for (
    //             //           var j = 0;
    //             //           j < results.results[0].address_components[i].types.length;
    //             //           j++
    //             //         ) {
    //             //           if (
    //             //             results.results[0].address_components[i].types[j] == "country"
    //             //           ) {
    //             //              country = results.results[0].address_components[i]?.long_name;
    //             //           }
    //             //         }
    //             //       }
    //             //       console.log("country",country)
    //             //       this.props.setParam('lat', lat)
    //             //       this.props.setParam('long', lng)
    //             //       this.props.setParam('country', country)
    //             //     },
    //             //     (error) => {
    //             //       console.error(error);
    //             //     }
    //             //   );
    //         }
    //         console.log("Update called")
    //         // if()
    //       // Now fetch the new data here.
    //     }
    //   }

    handleChange = address => {
        if (this.props.custom) {
            this.props.setVal(address)
            this.props.setSelectedAddress && this.props.setSelectedAddress(false)
        }
        else {
            const add = address?.split(',')
            this.setState({ address: add[0] });
            this.props.setParam('street', add[0])
            if (this.props.isFreeCapacity) {
                console.log(this.props.isFreeCapacity)
                this.props.setParam('city', add[0])
            }
            this.props.setSelectedAddress && this.props.setSelectedAddress(false)
        }
    };

    handleSelect = address => {
        if (this.props.custom) {
            this.setState({
                isSeleted: true
            })
            geocodeByAddress(address)
                .then(res => {
                    this.props.setVal(res[0].formatted_address)
                    this.props.setFull(res[0].formatted_address)
                })
                .catch(error => console.error('Error', error));
        }
        else {
            this.setState({
                isSeleted: true
            })
            this.props.setSelectedAddress && this.props.setSelectedAddress(true)
            const add = address?.split(',')
            this.setState({ address: add[0] })
            this.props.setParam('street', add[0])
            geocodeByAddress(address)
                .then(results => getLatLng(results[0]))
                .then(latLng => {
                    console.log('Success', latLng);
                    this.props.setParam('lat', latLng.lat)
                    this.props.setParam('long', latLng.lng)
                    this.setState({ mapCenter: latLng });
                })

            geocodeByAddress(address)
                .then(res => {
                    console.log(res)
                    this.setAddress(res[0].address_components)
                })
                .catch(error => console.error('Error', error));
        }
    };

    setAddress = (address_components) => {
        console.log(address_components)
        const prop = this.props
        this.props.setParam('country', 'india')
        _.each(address_components, function (component) {
            _.each(component.types, function (type) {
                if (type === 'locality') {
                    prop.setParam('city', component.long_name)
                }
                if (type === 'country') {
                    prop.setParam('country', component.long_name)
                }
                if (type === 'postal_code') {
                    prop.setParam('postCode', component.long_name)
                }
            })
        })
    }

    CustomScrollbars = props => (
        <Scrollbars
            renderThumbHorizontal={this.renderThumb}
            renderThumbVertical={this.renderThumb}
            {...props}
        />
    );

    renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
            borderRadius: 6,
            backgroundColor: 'rgba(240, 132, 56, 0.8)'
        };
        return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
    };


    render() {
        return (
            !this.props.disbled ?
                <PlacesAutocomplete
                    value={this.props.custom ? this.props.val : this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    highlightFirstSuggestion={true}
                    googleCallbackName="myCallbackFunc"
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <Form.Group>
                            <Form.Control
                                {...getInputProps({
                                    placeholder: this.props.placeHolder,
                                    className: 'location-search-input',
                                    disabled: this.props.suggestionLoaded,
                                    autoComplete: 'new-password',
                                    type: 'text'
                                })}
                            />
                            <div className="autocomplete-dropdown-container" style={{ border: suggestions?.length > 0 ? "1px solid #ddd" : 0 }}>
                                <div style={{ borderWidth: suggestions?.length > 0 ? "1px solid #ddd" : 0, overflow: "scroll", height: suggestions?.length > 0 ? "250px" : "0" }}>
                                    {loading &&
                                        <div
                                            style={{ position: "absolute", backgroundColor: "white", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >
                                            <Rings
                                                height="100"
                                                width="100"
                                                color={KJUUP_COLORS.base_color}
                                                radius="6"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel="rings-loading"
                                            />
                                        </div>}
                                    {suggestions.map(suggestion => {
                                        const style = suggestion.active
                                            ? { backgroundColor: KJUUP_COLORS.base_color, cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    // className,
                                                    style,
                                                })}
                                            >
                                                <span className="small" style={{ color: suggestion.active ? "white" : "#000" }}>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Form.Group>
                    )}
                </PlacesAutocomplete>
                :
                <Form.Group controlId="formBasicPassword">
                    <Form.Control
                        value={this.props.custom ? this.props.val : this.state.address}
                        onChange={()=>{}}
                        type="text"
                        disabled
                        placeHolder={this.props.isGerm ? DE.Address : EN.Address}
                    />
                </Form.Group>
        );
    }
}

export default LocationSearchInput;