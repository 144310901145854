import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import { Link, useHistory, useLocation } from "react-router-dom";
import { string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import { $crud } from '../helper/CrudFactory';
import { callAPI } from '../helper/apiUtils';
import { SET_USER, SET_USER_LOGIN_TOKEN, store } from '../store';
import { $user } from '../helper/UserFactory';
import { useDispatch } from 'react-redux';


function SecurityPINMain(props) {
    const [otp, setOtp] = useState('')
    const [cotp, setCOtp] = useState('')
    const [isGerms, setIsGerm] = useState(true);
    const history = useHistory();
    const [isInvalid, setIsInvalid] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState("");
    const user = $user.get();
    const dispatch = useDispatch();

    let state = history.location.state
    const [loading, setLoading] = useState(false);

    const handleChange = (otp) => {
        setInvalidMsg('');
        setIsInvalid(false);
        setOtp(otp)
    };
    const handleChangeC = (otp) => {
        setInvalidMsg('');
        setIsInvalid(false);
        setCOtp(otp)
    };
    useEffect(() => {
        let registerLang = localStorage.getItem("regiLang");
        if (registerLang) {
            setIsGerm(registerLang == 2 ? true : false);
        }
    }, [])
    const _handleConfirm = async () => {
        if (state.isResetPIN) {
            try {
                setLoading(true)
                const data = await $crud.post("/auth/verifyPin", { username: user.username, userPin: otp, language: isGerms ? "de" : "en" });
                if (data.status) {
                    setLoading(false)
                    const datax = await $crud.post("/auth/resetPin", { userPin: cotp });
                    if (datax.status) {
                        $crud.notify({
                            type: datax.status ? "success" : "error",
                            message: datax.message,
                        });
                        history.goBack()
                    }
                }
                else {
                    setLoading(false)
                    $crud.notify({
                        type: data.status ? "success" : "error",
                        message: isGerms ? DE.not_valid_pin : EN.not_valid_pin,
                    });
                }
            } catch (err) {
                setLoading(false)
            }
        }
        else if (state.isResetPassword) {
            try {
                setLoading(true)
                const data = await $crud.post("/auth/verifyPin", { username: user.username, userPin: otp, language: isGerms ? "de" : "en" });
                if (data.status) {
                    setLoading(false)
                    console.log(state)
                    const datax = await $crud.post("/user/resetPassword", {
                        password: state.password,
                        confirmPassword: state.confirmPassword
                    });
                    if (datax.status) {
                        await $crud.confirm({
                            title: "",
                            textContent: data.message,
                            options: {
                                ok: "Ok",
                                cancel: ""
                            },
                        })
                        $crud.get("/user/logout", {});
                        await $user.logout();
                        dispatch({ type: SET_USER_LOGIN_TOKEN, status: null })
                        history.replace('/')
                    }
                }
                else {
                    setLoading(false)
                    $crud.notify({
                        type: data.status ? "success" : "error",
                        message: isGerms ? DE.not_valid_pin : EN.not_valid_pin,
                    });
                }
            } catch (err) {
                setLoading(false)
            }
        }
        else if (state.isResetUsername) {
            try {
                setLoading(true)
                const data = await $crud.post("/auth/verifyPin", { username: user.username, userPin: otp, language: isGerms ? "de" : "en" });
                if (data.status) {
                    setLoading(false)
                    console.log(state)
                    const datax = await $crud.post("/auth/resetUserName", {
                        userName: state.username
                    });
                    if (datax.status) {
                        const data = $crud.get("/user/logout", {});
                        await $user.logout();
                        dispatch({ type: SET_USER_LOGIN_TOKEN, status: null })
                        history.replace('/')
                    }
                }
                else {
                    setLoading(false)
                    $crud.notify({
                        type: data.status ? "success" : "error",
                        message: isGerms ? DE.not_valid_pin : EN.not_valid_pin,
                    });
                }
            } catch (err) {
                setLoading(false)
            }
        }
    }
    return (
        (state?.isPassword || state?.isResetPassword || state?.isResetUsername) ?
            <div className='w-300 border border-2 border-#303030' style={{ height: "93vh", display: "flex", flexDirection: "column", marginTop: '5vh', paddingTop: 0, marginRight: 10, marginLeft: 10 }}>
                <div style={{ flex: 1 }}>
                    <div style={{ display: "flex", flexDirection: "row", backgroundColor: "white", paddingTop: 10, paddingLeft: 10 }}>
                        <div>
                            <a
                                className="pointer"
                                onClick={() => history.goBack()}
                            >
                                <img
                                    width={10}
                                    src={
                                        process.env.PUBLIC_URL + "/assets/img/back.svg"
                                    }
                                    alt="Generic placeholder"
                                />
                            </a>
                        </div>
                        <div style={{ flex: 1, paddingLeft: 10 }}>
                            <p>{isGerms ? state?.isResetUsername ? DE.change_username : DE.reset_password : state?.isResetUsername ? EN.change_username : EN.reset_password}</p>
                        </div>
                    </div>
                </div>
                <div style={{ alignSelf: "center" }}>
                    <div style={{ marginBottom: 40, textAlign: "center" }}>
                        <p style={{ fontSize: '18px' }}>{isGerms ? DE.pin_to_confirm : EN.pin_to_confirm}</p>
                    </div>
                    <OtpInput
                        isInputNum
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span>{` -- `}</span>}
                        inputStyle={{ height: 40, width: 40 }}
                    />
                    {otp.length == 6 ?
                        <Button
                            disabled={loading}
                            className="btn request-btn"
                            onClick={_handleConfirm}
                        >
                            {loading ? (
                                <LoadingDots />
                            ) : isGerms ? (
                                DE.confirm
                            ) : (
                                EN.confirm
                            )}
                        </Button>
                        :
                        <></>
                    }
                    {isInvalid && (
                        <p className="text-center small text-danger">{invalidMsg}</p>
                    )}
                </div>
                <div style={{ flex: 1 }} />
            </div>
            :
            state?.isResetPIN ?
                <div className='w-300 border border-2 border-#303030' style={{ height: "93vh", display: "flex", flexDirection: "column", marginTop: '5vh', paddingTop: 0, marginRight: 10, marginLeft: 10 }}>
                    <div style={{}}>
                        <div style={{ display: "flex", flexDirection: "row", backgroundColor: "white", paddingTop: 10, paddingLeft: 10 }}>
                            <div>
                                <a
                                    className="pointer"
                                    onClick={() => history.goBack()}
                                >
                                    <img
                                        width={10}
                                        src={
                                            process.env.PUBLIC_URL + "/assets/img/back.svg"
                                        }
                                        alt="Generic placeholder"
                                    />
                                </a>
                            </div>
                            <div style={{ flex: 1, paddingLeft: 10 }}>
                                <p>{isGerms ? DE.resetPIN : EN.resetPIN}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 20, paddingLeft: 20, alignSelf: "center" }}>
                        <div style={{ marginBottom: 20 }}>
                            <p style={{ fontSize: '15px', textAlign: "center" }}>{isGerms ? DE.enter_current_pin : EN.enter_current_pin}</p>
                        </div>
                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            separator={<span>{` -- `}</span>}
                            isInputNum
                            inputStyle={{ height: 40, width: 40 }}
                        />
                        <div style={{ marginBottom: 20, marginTop: 20 }}>
                            <p style={{ fontSize: '15px', textAlign: "center" }}>{isGerms ? DE.enter_new : EN.enter_new}</p>
                        </div>
                        <OtpInput
                            value={cotp}
                            onChange={handleChangeC}
                            numInputs={6}
                            isInputNum
                            separator={<span>{` -- `}</span>}
                            inputStyle={{ height: 40, width: 40 }}
                        />
                        {cotp.length == 6 ?
                            <Button
                                disabled={loading}
                                className="btn request-btn"
                                onClick={_handleConfirm}
                            >
                                {loading ? (
                                    <LoadingDots />
                                ) : isGerms ? (
                                    DE.confirm
                                ) : (
                                    EN.confirm
                                )}
                            </Button>
                            :
                            <></>
                        }
                        {isInvalid && (
                            <p className="text-center small text-danger">{invalidMsg}</p>
                        )}
                    </div>
                    <div style={{ flex: 1 }} />
                </div>
                :
                <></>
    )
}

export default SecurityPINMain