export const AppName = "Immosnapp";

// redux actions
export const TOGGLE_DRAWER = Symbol("TOGGLE_DRAWER");
export const TOGGLE_SIDE_NAV = Symbol("TOGGLE_SIDE_NAV");
export const SET_ACTIVE_STATE = Symbol("SET_ACTIVE_STATE");
export const SET_TITLE = Symbol("SET_TITLE");
// redux actions

export const BASE_URL = `${process.env.REACT_APP_API_URL}/getFile?filePath=`;
// export const BASE_URL = "https://immosnapp.teamjft.com/getFile?filePath=";
export const API_ROOT = process.env.REACT_APP_API_URL;
// export const API_ROOT = 'https://immosnapp.teamjft.com';
export const successSnackBarOptions = {
  position: "bottom-center",
  style: {
    backgroundColor: "#4caf50",
  },
  closeStyle: {
    color: "white",
    fontSize: "14px",
  },
};
export const failSnackBarOptions = {
  position: "bottom-center",
  style: {
    backgroundColor: "#f44336",
  },
  closeStyle: {
    color: "white",
    fontSize: "14px",
  },
};

export function formatName(name, customlength, position, BranchLength) {
  if (name && !customlength) {
    if (name?.length > 20) {
      if (position) {
        return '...' + name.slice((BranchLength > 15 ? (name?.length - 10) : (name?.length - 24)), name?.length).trim()
      }
      else
        return name.slice(0, 20).concat('...').trim()
    }
    else {
      return name?.trim()
    }
  }
  else {
    if (name?.length > customlength) {
      if (position) {
        return '...' + name.slice(0, customlength).trim()
      }
      else
        return name.slice(0, customlength).concat('...').trim()
    }
    else {
      return name?.trim()
    }
  }
}

export function escapeHtml(unsafe) {
  return unsafe
       .replace(/&/g, "&amp;")
       .replace(/</g, "&lt;")
       .replace(/>/g, "&gt;")
       .replace(/"/g, "&quot;")
       .replace(/'/g, "&#039;");
}

export function capitalizeFirstLetter(string) {
  var splitStr = string.toLowerCase()?.split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const goBack = () => window.history.go(-1);



export const KJUUP_COLORS = {
  base_color: "#f08438",
  internal: "#288f6c",
  internalLight: "#e8f8f3"
}

export function getLightColor(baseColor, lightnessIncrease) {
  // Convert the base color from hex to RGB
  const baseRgb = hexToRgb(baseColor);

  // Increase the RGB values to make the color lighter
  const lightRgb = {
    r: Math.min(255, baseRgb.r + lightnessIncrease),
    g: Math.min(255, baseRgb.g + lightnessIncrease),
    b: Math.min(255, baseRgb.b + lightnessIncrease)
  };

  // Convert the new RGB values back to hex
  const lightHex = rgbToHex(lightRgb.r, lightRgb.g, lightRgb.b);

  return lightHex;
}

export function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

function hexToRgb(hex) {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
