import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory, withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import { connect, useSelector } from "react-redux";
import { callAPI } from "../../helper/apiUtils";
import { BASE_URL, KJUUP_COLORS, goBack } from "../../helper/constants";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { Scrollbars } from "react-custom-scrollbars";
import Carousel from "react-multi-carousel";
import { MyArticle } from "../Feeds/MyArticle";
import { $crud } from "../../helper/CrudFactory";
import { ARTICLE } from "../../store";
import FeedViewDialog from "../Feeds/Dialogs/FeedViewDialog";
import { ReactComponent as ArrowLeft } from "../../Icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../Icons/ArrowRight.svg";
import { Animated } from "react-animated-css";
import { convertBranchRole, convertPosition, convertRole } from "../../helper/convertor";
import { ReactComponent as ContactIcon } from '../../Icons/Contacts-Icon.svg';
import { ReactComponent as AddcompanyIconNew } from '../../Icons/Add company-deactivated.svg';

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function Profile(props) {
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const CarouselEl = useRef();
  const [slideNo, setSlideNo] = useState(0);
  const [profile, setProfile] = useState({});
  const { isGerm } = React.useContext(LanguageContext);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const getProfile = async () => {
    const { data } = await callAPI(`/user/profile`, {}, "GET");
    let profile = data?.profile;
    if (profile.articleCount > 0) {
      profile.articles = await profile.articles.map((article) => {
        article.expand = false;
        return article;
      });
      setSlideNo(1);
    }
    await setProfile(profile);
  };
  useEffect(() => {
    getProfile();
  }, [user]);

  const getCompanyDetails = (id) => {
    history.push({
      pathname: "/company-details/" + id,
      state: { id: id },
    });
  };
  const expandArticle = async (key) => {
    const articles = profile?.articles;
    let article = articles[key];
    article.expand = true;
    articles[key] = article;
    profile.articles = articles;
    await setProfile(profile);
  };
  const handleDeleteArticle = async (article) => {
    await $crud.confirm({
      title: "Confirm",
      textContent: isGerm ? 'Sind Sie sicher, dass Sie diesen Artikel löschen wollen?' : "Are you sure you want to delete this article?",
      options: {
        ok: "ok",
        cancel: "cancel",
      },
    });
    const { data } = await callAPI(`/article/${article.id}`, {}, "DELETE");
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    let articles = profile.articles;
    articles = articles.filter((a) => a.id !== article.id);
    setProfile((prev) => {
      return {
        ...prev,
        articles: articles,
      };
    });
  };

  const CustomRightArrow = () => {
    CarouselEl.current.next();
    if (profile.articles.length > slideNo) {
      setSlideNo(slideNo + 1);
    }
  };

  const CustomLeftArrow = () => {
    CarouselEl.current.previous();
    if (slideNo !== 1) {
      setSlideNo(slideNo - 1);
    }
  };

  const handleBack = () => {
    console.log("History", history)
    setVisible(false);
    if (history.location.state === undefined) {
      setTimeout(() => {
        goBack();
      }, 1000);
    }
    else {
      // history.push(`/feed-detail/${history.location.state}`)
      history.push({ pathname: "/articles", state: history.location.state })
    }
  };

  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          <div class="profile_section w-300">
            <div className="row">
              <div className="col-md-5 pr-0">
                <div className="user_profile">
                  <div className="user_img">
                    <a className="pointer" onClick={handleBack}>
                      <img
                        className="img-fluid"
                        src={process.env.PUBLIC_URL + "assets/img/back.svg"}
                        alt="Generic placeholder"
                      />
                    </a>
                    <img
                      width={55}
                      height={55}
                      variant="primary"
                      onClick={handleShow}
                      className="profile_user"
                      src={
                        profile.avatar
                          ? BASE_URL + profile.avatar
                          : process.env.PUBLIC_URL + "assets/img/head.svg"
                      }
                      alt="Generic placeholder"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="user_seeting">
                    <Link to="/profile-setting">
                      <img
                        width={22}
                        height={22}
                        src={process.env.PUBLIC_URL + "assets/img/settings.svg"}
                        alt="Generic placeholder"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-7 pl-0">
                <div className="follow">
                  <Link to="/followers" className="follow_item text-center">
                    <h3>{profile?.followerCount}</h3>
                    <p>{isGerm ? DE.followers : EN.followers}</p>
                  </Link>
                  <Link to="/followings" className="follow_item text-center">
                    <h3>{profile?.followingCount}</h3>
                    <p>{isGerm ? DE.followings : EN.followings}</p>
                  </Link>
                  <Link
                    to="/add-personal-article"
                    className="follow_item text-center"
                  >
                    <h3>{profile?.articleCount}</h3>
                    <p>{isGerm ? DE.article : EN.article}</p>
                  </Link>
                  <Link to="/my-companies" className="follow_item text-center">
                    <h3>{profile?.companiesCount}</h3>
                    <p>{isGerm ? DE.companies : EN.companies}</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="profile-data">
              <div className="profile-flied">
                <div className="profile_name">
                  <h3>{profile?.fullName}</h3>
                  <p>{convertBranchRole(profile?.buildingIndustryRole, isGerm)}</p>
                </div>
                <div className="icon_right">
                  <Link to="/edit-profile">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/img/edit-pen-s.svg"
                      }
                    />
                  </Link>
                </div>
              </div>
              <div className="my-scroll profile_height">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  className="bottom-bar"
                >
                  <div className="profile-flied">
                    <div className="profile_name font-orange">
                      <h3>
                        {isGerm ? DE.search_contacts : EN.search_contacts}
                      </h3>
                    </div>
                    <div className="icon_right">
                      <Link to="/contact">
                        {/* <img
                          src={
                            process.env.PUBLIC_URL + "/assets/img/Contacts.svg"
                          }
                        /> */}
                        <ContactIcon height={'20px'} width={'20px'} />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-flied">
                    <div className="profile_name font-orange">
                      <h3>
                        {isGerm
                          ? DE.add_or_join_a_company
                          : EN.add_or_join_a_company}
                      </h3>
                    </div>
                    <div className="icon_right">
                      <Link to="/add-company">
                        <AddcompanyIconNew height={'25px'} width={'25px'} stroke={'grey'} fill={'grey'}/>
                      </Link>
                    </div>
                  </div>
                  {profile.companies &&
                    profile.companies.map((company, index) => (
                      <div className="profile-flied profile-gray" key={index}>
                        <div
                          onClick={(e) => getCompanyDetails(company.id)}
                          className="profile_name font-orange pointer"
                        >
                          <h3>{company.name}</h3>
                          <p>Position: {convertPosition(isGerm, company.position)}</p>
                        </div>
                      </div>
                    ))}

                  {/* <div className="article">
                    <div className="profile-flied">
                      <div className="profile_name">
                        <h3>
                          <p>{isGerm ? DE.article : EN.article}</p>
                        </h3>
                      </div>                     
                    </div>
                    <div className="slider-icon">
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => CustomLeftArrow()}
                      >
                        <ArrowLeft
                          className={slideNo > 1 ? "svg_active" : "svg_gray"}
                          width={9}
                          height={17}
                        />
                      </a>
                      <p> 
                        {slideNo} {isGerm ? DE.of : EN.of} {profile.articleCount}{" "}
                        {isGerm ? DE.articlen : EN.articlen}
                      </p>
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => CustomRightArrow()}
                      >
                        <ArrowRight
                          className={
                            profile.articleCount > slideNo
                              ? "svg_active"
                              : "svg_gray"
                          }
                          width={9}
                          height={17}
                        />
                      </a>
                    </div>
                  </div>

                  {profile?.articles && profile?.articles?.length > 0 ? (
                    <div>
                      <Carousel
                        afterChange={(
                          previousSlide,
                          { currentSlide, onMove }
                        ) => {
                          setSlideNo(CarouselEl.current.state.currentSlide + 1);
                        }}
                        ref={CarouselEl}
                        additionalTransfrom={0}
                        arrows={false}
                        centerMode={false}
                        className="p-0"
                        containerClass="container"
                        draggable={profile?.articles.length > 1}
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1024,
                            },
                            items: 1,
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0,
                            },
                            items: 1,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464,
                            },
                            items: 1,
                          },
                        }}
                        slidesToSlide={1}
                        swipeable={true}
                      >
                        {profile.articles &&
                          profile.articles.map((article, key) => (
                            <div key={key} className="media-desc">
                              {
                                <MyArticle
                                  viewArticleDetail={props.viewArticle}
                                  profile={profile}
                                  reloadArticle={() => getProfile()}
                                  index={key}
                                  article={article}
                                  expandArticle={expandArticle}
                                  removeArticle={handleDeleteArticle}
                                />
                              }
                            </div>
                          ))}
                      </Carousel>
                    </div>
                  ) : (
                    ""
                  )} */}
                  <div className="company_services">
                    <h5>
                      {isGerm ? DE.profile_company_services : EN.company_services}
                    </h5>
                    {profile.services &&
                      profile.services.map((service) => (
                        <Link
                          key={service.id}
                          to={`/service-members/${service.id}`}
                        >
                          <p>
                            {service?.service?.split(",").map((item, index) => (index ? ", " : "") + convertRole(item, isGerm))}{" "}
                            {service.usersCount > 0 ? (
                              <span> | {service.usersCount}</span>
                            ) : (
                              ""
                            )}
                          </p>
                        </Link>
                      ))}
                  </div>
                  <div className="company_services">
                    <h5>
                      {isGerm ? DE.profile_company_contacts : EN.company_contacts}
                    </h5>
                    <div className="company_centact">
                      <div className="website">
                        <div className="website_img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/img/Website-grey.svg"
                            }
                            alt="Generic placeholder"
                          />
                        </div>
  
                        <div className="details-company" style={{  }}>
                          <h5>{isGerm ? DE.website : EN.website}</h5>
                          <div>
                            {profile.companies &&
                              profile.companies.map((company) => (
                                company.website?
                                <div style={{marginBottom:3}}>
                                  <p style={{color:"#000" }}>{company?.name}</p>
                                  <a style={{ fontSize:"13px", color: company.isKjuup ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }} href={'https://'+company?.website} target="_blank">{company?.website}</a>
                                </div>
                                :
                                <></>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      <div className="website">
                        <div className="website_img">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/img/e-mail-grey.svg"
                            }
                            alt="Generic placeholder"
                          />
                        </div>
                        <div className="details-company">
                          <h5>{isGerm ? DE.email : EN.email}</h5>
                          {profile.companies &&
                            profile.companies.map((company) => (
                              <div key={company.id}>
                                <h6>{company.name}</h6>
                                <p>{company.email}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomScrollbars>
              </div>
            </div>
          </div>
        </Animated>
        <Modal
          className="profile_image"
          show={show}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="profile_pic_profile">
              <img
                variant="primary"
                onClick={handleShow}
                className="profile_user"
                src={
                  profile.avatar
                    ? BASE_URL + profile.avatar
                    : process.env.PUBLIC_URL + "assets/img/head.svg"
                }
                alt="Generic placeholder"
              />
            </div>
          </Modal.Body>
        </Modal>
        {props.article && <FeedViewDialog article={props.article} />}
      </Layout>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { article, drawerOpen } = state;
  return {
    article: article,
    drawerOpen: drawerOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    viewArticle: (article) => dispatch({ type: ARTICLE, article: article }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
