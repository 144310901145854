import { Media } from "react-bootstrap";
import { BASE_URL } from "../../helper/constants";
import { Skeleton } from "@material-ui/lab";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import React, { useEffect, useState } from "react";
import LanguageContext from "../../helper/LanguageContext";
import moment from "moment";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useHistory } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { useSelector } from "react-redux";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyDeselected.svg";
import {
  convertBranchRole,
  convertHtmlString,
  tempFunction,
} from "../../helper/convertor";
import PlayerFeed from "../../components/PlayerFeed";
import ReadMoreReact from "read-more-react";
import ReadMores from "../../components/ReadMores";
const extractUrls = require("extract-urls");

export function UserFeed({
  article,
  index,
  expandArticle,
  reloadArticle,
  handleFollow,
  viewArticleDetail,
  handleLike,
  isDetails = false,
}) {
  const { isGerm } = React.useContext(LanguageContext);
  const [likeStatus, setLikeStatus] = useState(false);
  const [followStatus, setFollowStatus] = useState(false);
  const [_Link, _setLink] = useState([]);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const likeArticle = async (article) => {
    setLikeStatus(true);
    if (user?.id !== article.postedById) {
      const { data } = await callAPI(
        `/article/${article.id}/like`,
        {
          id: article.id,
          emoji: 6,
          lang: "en",
        },
        "GET"
      );
      handleLike(article.id);
    } else {
    }
    setTimeout(() => {
      setLikeStatus(false);
    }, 1300);
  };
  const followArticleUser = async ({ id, followStatus, postedById }) => {
    setFollowStatus(true);
    if (user?.id !== postedById) {
      const { data } = await callAPI(
        `/article/${id}/follow`,
        {
          id: id,
        },
        "GET"
      );
      await handleFollow(id, followStatus);
      console.log(data);
    } else {
    }
    setTimeout(() => {
      setFollowStatus(false);
    }, 1300);
  };

  const getProfile = (postedById, articleId) => {
    if (postedById === user?.id) {
      history.push({
        pathname: `/profile`,
        state: articleId,
      });
    } else {
      history.push({
        pathname: `/user-profile/${postedById}`,
        state: articleId,
      });
    }
  };

  const viewFullScreen = async () => {
    viewArticleDetail(article);
  };
  const [articleBranch, setArticleBranch] = React.useState([]);
  React.useEffect(() => {
    let tempRole = article.buildingIndustryRole?.split(",");
    let temoVal = tempRole?.map(
      (itm, index) => (index ? ", " : "") + convertBranchRole(itm, isGerm)
    );
    setArticleBranch(temoVal);
  }, [article]);

  useEffect(() => {
    if (article?.description && typeof article?.description === "string") {
      let dec = article?.description;
      let datax = extractUrls(dec);
      datax =
        datax && Array.isArray(datax)
          ? datax.map((e) => {
              return e.replace("&quot", "");
            })
          : datax;
      let data = [...new Set(datax)];
      let all = [];
      console.log("here", data);
      if (data && data.length > 0) {
        for (let obj of data) {
          let x = {
            href: obj,
          };
          let lnk = obj;
          if (lnk.match("vimeo")) {
            x.videoType = "vimeo";
            all.push(x);
          }
          if (lnk.match("youtube") || lnk.match("youtu")) {
            x.videoType = "youtube";
            all.push(x);
          }
        }
        if (all.length > 0) {
          if (article.uploadUrl && article.uploadUrl.length > 0)
            _setLink([...all, ...article.uploadUrl]);
          else _setLink([...all]);
        } else if (article.uploadUrl && article.uploadUrl.length > 0) {
          _setLink([...article.uploadUrl]);
        }
      } else {
        if (article.uploadUrl && article.uploadUrl.length > 0) {
          _setLink([...article.uploadUrl]);
        }
      }
    } else if (article.uploadUrl && article.uploadUrl.length > 0) {
      _setLink([...article.uploadUrl]);
    }
  }, [article]);
  return (
    <div className="feed-details">
      <Media>
        {article.avatar && (
          <img
            width={50}
            height={50}
            className="mr-3 news_profile"
            src={
              article.avatar
                ? BASE_URL + article.avatar
                : process.env.PUBLIC_URL + "assets/img/profile.png"
            }
            alt="Generic placeholder"
          />
        )}
        {!article.avatar && (
          <div className="svg_icon mr-3 news_profile">
            <svg width="50px" height="50px" viewBox="0 0 99.84 99.83">
              <path
                className="prf1"
                d="M318.68,147.82l2.2-2.17c-6.17-2.82-12.74-4.33-15.94-5.74S293,134.24,293,134.24s-.42-6.86-3-7.26l.43-4.64s3-6.45,3-10.48c0,0,3,2.42,4.05-3s2.56-15.51-2.13-13.1c0,0,1-8,.11-12.89s-5.22-19.74-24.82-19.74-24,14.91-24.81,19.74.1,12.89.1,12.89c-4.68-2.41-3.19,7.66-2.13,13.1s4,3,4,3c0,4,3,10.48,3,10.48l.43,4.64c-2.56.4-3,7.26-3,7.26s-8.72,4.26-11.92,5.67c-2.76,1.22-3.54,3.29-9.82,6.16a13.32,13.32,0,0,0,6.9,4.52,16.83,16.83,0,0,0,4.41.6h34l36.59-.22c1.8,0,5.46-.25,7.59-1.24.33-.21,2.27-1.67,2.58-1.91"
                transform="translate(-223.59 -52.87)"
              />
              <rect
                className="prf2"
                x="1.5"
                y="1.5"
                width="96.84"
                height="96.83"
                rx="10.49"
                ry="10.49"
              />
            </svg>
          </div>
        )}
        <Media.Body>
          <div className="w-100 authore_name">
            {article.userFullName ? (
              <h5
                style={{ whiteSpace: "pre-wrap" }}
                onClick={(e) => getProfile(article.postedById, article.id)}
              >
                {article.userFullName ? (
                  article.userFullName
                ) : (
                  <Skeleton variant="text" />
                )}
                {article.id ? (
                  <span className="user_position">
                    {" "}
                    | {article.buildingIndustryRole ? articleBranch : ""}
                  </span>
                ) : (
                  <Skeleton variant="text" />
                )}
              </h5>
            ) : (
              <Skeleton variant="text" />
            )}
          </div>
          <div className="w-100">
            {article.product ? (
              <h6 className="product_user">
                Product: <span>{article.product}</span>
              </h6>
            ) : (
              ""
            )}
          </div>
        </Media.Body>
      </Media>
      <p className="m-0 mt-2 mb-2">
        <Link
          style={{ color: "#212529", fontSize: 13 }}
          to={`/feed-detail/${article.id}`}
          className="font-weight-bold"
        >
          {article.title}
        </Link>
      </p>
      <p className="small" style={{ color: "#212529" }}>
        <ReadMores>{article?.description}</ReadMores>
      </p>
      {/* {_Link && _Link.length > 0 && */}
      <PlayerFeed Links={_Link} article={article} />
      {/* } */}
      <div className="date-follow">
        <p>{moment(article.createdAt).format("D MMMM HH:MM")}</p>
        <Link to={`/people-likes/${article.id}`} className="follow-img">
          {article.likes &&
            article.likes.map((like, i) =>
              i >= 3 ? (
                <></>
              ) : like.avatar ? (
                <img
                  key={i}
                  src={BASE_URL + like.avatar}
                  alt="Generic placeholder"
                />
              ) : (
                <div
                  key={i}
                  className="svg_icon news_profile"
                  style={{ display: "inline-block" }}
                >
                  <svg
                    width="14px"
                    height="14px"
                    id="Layer_4"
                    data-name="Layer 4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 39.54 39.54"
                  >
                    <path
                      className="cls-1"
                      d="M271.14,229.57l-3.12,1.5a19.26,19.26,0,0,0,13.16,5.26h.17a19.28,19.28,0,0,0,12.32-4.53c-1.32-.59-4.69-2.23-4.69-2.23s-.17-2.74-1.19-2.9l.17-1.85a13.45,13.45,0,0,0,1.19-4.18s1.19,1,1.62-1.21,1-6.19-.85-5.23a22.92,22.92,0,0,0,0-5.14c-.34-1.93-2.08-7.88-9.9-7.88s-9.57,5.95-9.91,7.88a22.69,22.69,0,0,0,.05,5.14c-1.87-1-1.28,3.06-.85,5.23s1.61,1.21,1.61,1.21a13.45,13.45,0,0,0,1.19,4.18l.17,1.85C271.3,226.83,271.14,229.57,271.14,229.57Z"
                      transform="translate(-261.48 -197.24)"
                    />
                    <circle className="cls-2" cx="19.77" cy="19.77" r="19.32" />
                  </svg>
                </div>
              )
            )}

          <span>{article.likeCount ? article.likeCount : ""}</span>
        </Link>
        {article.isEmojiUsed && (
          <SmilyIcon width={20} className="smily-icon active float-right" />
        )}
      </div>
      <div
        className={
          user?.id === article.postedById
            ? `date-follow no-border like_folo no-hover-effect`
            : `date-follow no-border`
        }
      >
        <a
          onClick={(e) => {
            likeArticle(article);
          }}
          className={`svg_icon pointer ${
            article.likeStatus ? "social-icon-active" : ""
          } ${
            likeStatus && user?.id !== article.postedById
              ? "bouncing-animation"
              : ""
          }`}
        >
          <svg
            className="mr-1"
            width="17px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.26 15.9"
          >
            <path
              class="cls-1"
              d="M146.53,100H144.2a.59.59,0,0,0-.6.59v6.92a.59.59,0,0,0,.6.59h2.33a.59.59,0,0,0,.6-.59v-6.92A.59.59,0,0,0,146.53,100Z"
              transform="translate(-143.15 -92.63)"
            />
            <path
              class="cls-1"
              d="M156.94,98.05h-3.27V94.72c0-.58-.64-1.64-1.15-1.64H151.3c-.23,0-.51.45-.51,1.19v2a6.17,6.17,0,0,1-1.06,2.21c-.45.61-1.35,1.15-1.35,2v5.9c0,.57.48,1.31,1.73,1.31h5.58c.77,0,1.38-.13,2.15-1.86a8.5,8.5,0,0,0,1.12-3.91V99.56C159,98.82,158.32,98.05,156.94,98.05Z"
              transform="translate(-143.15 -92.63)"
            />
          </svg>
          {isGerm ? DE.like : EN.like}
        </a>
        <a
          onClick={(e) => {
            followArticleUser(article);
          }}
          className={`svg_icon pointer ${
            article.followStatus ? "social-icon-active" : ""
          } ${
            followStatus && user?.id !== article.postedById
              ? "bouncing-animation"
              : ""
          }`}
        >
          <svg
            className="mr-1"
            width="17px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.08 15.9"
          >
            <path
              class="cls-1"
              d="M280.29,283.57a1,1,0,0,0-1-.92h-.13a4.85,4.85,0,0,1-3.83,1.85,4.75,4.75,0,0,1-3.78-1.85h-.19a1,1,0,0,0-1,.92l-1,6.72a1,1,0,0,0,1,.93h9.9a1,1,0,0,0,1-.93Z"
              transform="translate(-268.95 -275.77)"
            />
            <path
              class="cls-1"
              d="M275.34,282.85a3.32,3.32,0,1,0-3.31-3.32A3.32,3.32,0,0,0,275.34,282.85Z"
              transform="translate(-268.95 -275.77)"
            />
            <line class="cls-2" x1="12.65" y1="0.67" x2="12.65" y2="2.71" />
            <line class="cls-2" x1="12.65" y1="2.71" x2="12.65" y2="4.75" />
            <line class="cls-2" x1="12.65" y1="2.71" x2="10.67" y2="2.71" />
            <line class="cls-2" x1="14.63" y1="2.71" x2="12.65" y2="2.71" />
          </svg>
          {article.followStatus ? (
            <span> {isGerm ? DE.following : EN.following}</span>
          ) : (
            <span> {isGerm ? DE.follow : EN.follow}</span>
          )}
        </a>
      </div>
    </div>
  );
}
