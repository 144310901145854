import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { callAPI } from "../../helper/apiUtils";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { corporateForms } from "../../Languages/vars/Corporate";
import { Positions } from "../../Languages/vars/Positions";
import LocationSearchInput from "../../components/LocationSearchInput";
import SuggestionInput from "./SuggestionInput";
import { $crud } from "../../helper/CrudFactory";
import MapComponent from "../../components/MapComponent";
import { useHistory } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import CompanyCorporateDialog from "./Dialogs/CompanyCorporateDialog";
import CompanyPositionDialog from "./Dialogs/CompanyPositionDialog";
import { Roles } from "../../Languages/vars/Roles";
import CompanyRoles from "./Dialogs/CompanyRoles";
import { $user } from "../../helper/UserFactory";
import { Animated } from "react-animated-css";
import { goBack } from "../../helper/constants";
import Geocode from "react-geocode";
import { convertBranchRole, convertPosition } from "../../helper/convertor";
import { Discuss, Rings } from 'react-loader-spinner'
import DepartLocaDialogue from "./Dialogs/DepartLocaDialogue";

Geocode.setApiKey("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q");
function AddCompany(props) {
  const [searchCity, setSearchCity] = useState('');
  const [mainVal, setMainVal] = useState('');

  const [suggestionOver, setSuggestionOver] = useState(false)
  const [isGettingSuggestion, setIsGettingSuggestions] = useState(false)
  const [visible, setVisible] = useState(true);
  const [params, setParams] = useState({
    // position: "",
    name: "",
    email: "",
    corporateForm: "",
    branch: "",
    street: "",
    postCode: "",
    city: "",
    country: "",
    lat: "",
    long: "",
    houseNo: "",
    full_address: "",
    deptLocation: "",
    deptLocationName: ""
  });
  const [paramsLoaded, setParamsLoaded] = useState({
    name: false,
    email: false,
    corporateForm: false,
    branch: false,
  });
  const user = $user.get();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    // position: "",
    name: "",
    email: "",
    corporateForm: "",
    branch: "",
    street: "",
    postCode: "",
    city: "",
    country: "",
    lat: "",
    long: "",
    houseNo: "",
    full_address: "",
    deptLocation: '',
    deptLocationName: ""
  });
  const { isGerm } = React.useContext(LanguageContext);
  const [show, setShow] = useState(false);
  const [showRoleDialog, setRoleDialog] = useState(false);
  const [showCorporateForm, setCorporateForm] = useState(false);
  const [showPositionDialog, setPositionDialog] = useState(false);
  const [roleSelected, setRoleSelected] = useState(false);
  const [selectName, setSelectName] = useState()
  const [companies, setCompanies] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [searchSuggestion, setSearchSuggestion] = useState('');
  const [companiesSuggestion, setCompaniesSuggestion] = useState([]);
  const [suggestionPage, setSuggestionPage] = useState(1)
  const [deptloc, setdeptloc] = useState([])
  const [showDepartModal, setShowDepartModal] = useState(null)


  useEffect(() => {
    if (!searchSuggestion) {
      setShowSuggestion(false)
      setCompaniesSuggestion([])
    }
    else {
      console.log('sds')
      setShowSuggestion(true)
      _searchCompnies(searchSuggestion)
    }
  }, [searchSuggestion])

  const _searchCompnies = async (e) => {
    setIsGettingSuggestions(true)
    const { data } = await callAPI(`/company/getRegisterOrUnregisteredCompany?page=${1}&limit=200&query=${e}`, null, "GET");
    setIsGettingSuggestions(false)
    if (data.companies && data.companies?.length > 0) {
      setSuggestionPage(2)
      setCompaniesSuggestion(data.companies)
    }
    else {
      setShowSuggestion(false)
    }
  }

  const _onEndOfSuggestions = async () => {
    if (!suggestionOver && !isGettingSuggestion) {
      setIsGettingSuggestions(true)
      const { data } = await callAPI(`/company/getRegisterOrUnregisteredCompany?page=${suggestionPage}&limit=200&query=${searchSuggestion}`, null, "GET");
      if (data.companies && data.companies?.length > 0) {
        setSuggestionOver(false)
        setSuggestionPage(suggestionPage + 1)
        setCompaniesSuggestion([...companiesSuggestion, ...data.companies])
      }
      else {
        setSuggestionOver(true)
        // setCompaniesSuggestion([])
      }
      setIsGettingSuggestions(false)
    }
  }

  const setParam = (name, value) => {
    console.log(name, value)
    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };
  const [suggestionLoaded, setSuggestionLoaded] = useState(false);
  const [ownerId, setOwnerId] = useState(null);
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //Role
    // if (!params.position) {
    //   formIsValid = false;
    //   errors["position"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    // }
    //Firstname
    if (!params.name) {
      formIsValid = false;
      errors["name"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!params.email) {
      formIsValid = false;
      errors["email"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    }

    if (!params.branch) {
      formIsValid = false;
      errors["branch"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    }

    if (!params.full_address) {
      formIsValid = false;
      errors["full_address"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    }

    if (!params.deptLocation && deptloc?.length > 0) {
      formIsValid = false;
      errors["deptLocation"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
    }

    setErrors(errors);
    return formIsValid;
  };
  const corporateForm = corporateForms;
  const [isInvalid, setIsInvalid] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(false)
  const history = useHistory();
  const requestConfirmation = async () => {
    if (handleValidation()) {
      // let filterData = { ...params, position: selectName?.name_en };
      let filterData = { ...params };
      // filterData={...para}
      setLoading(true);
      try {
        const { data } = await callAPI("/company", null, "POST", filterData);
        if (!data.status) {
          setInvalidMsg(data.message);
          setIsInvalid(true);
          await $crud.notify({
            type: "error",
            message: data.message,
          });
        } else {
          await $crud.notify({
            type: "success",
            message: data.message,
          });
          await $user.getProfile();
          history.push("/my-companies");
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    } else {
      console.log(errors);
    }
  };
  const getList = async () => {
    try {
      const { data } = await callAPI(
        "/company/getRegisterOrUnregisteredCompany",
        {},
        "GET"
      );
      if (data.status) {
        setCompanies(data.companies);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // getList();
    if (props && props.location && props.location.state) {
      let suggestion = props.location.state
      let add = `${suggestion.houseNo ? suggestion.houseNo + ", " : ""}${suggestion.street ? suggestion.street + ", " : ''}${suggestion.city ? suggestion.city + ", " : ""}${suggestion.postCode ? suggestion.postCode + ", " : ""}${suggestion.country ? suggestion.country : ''}`
      setMainVal(suggestion.full_address ? suggestion.full_address : add)
      setParams((prev) => {
        return {
          ...prev,
          ...props.location.state,
          position: convertPosition(isGerm, props.location.state?.position),
          full_address: suggestion.full_address ? suggestion.full_address : add
        };
      });
      _getDepartmentAndLocations(suggestion.id)
    }
  }, []);

  useEffect(() => {
    if (!selectedAddress) {
      let fullAddress = `${params.street} ${params.city ? `, ${params.city}` : ""}`
      Geocode.fromAddress(fullAddress).then(
        (results) => {
          const { lat, lng } = results.results[0].geometry.location;
          let country;
          for (var i = 0; i < results.results[0].address_components?.length; i++) {
            for (
              var j = 0;
              j < results.results[0].address_components[i].types?.length;
              j++
            ) {
              if (
                results.results[0].address_components[i].types[j] == "country"
              ) {
                country = results.results[0].address_components[i]?.long_name;
              }
            }
          }
          setParam('lat', lat)
          setParam('long', lng)
          setParam('country', country)
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [params.city, params.street])

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const getSuggestion = async (suggestion) => {
    let add = `${suggestion.houseNo ? suggestion.houseNo + ", " : ""}${suggestion.street ? suggestion.street + ", " : ''}${suggestion.city ? suggestion.city + ", " : ""}${suggestion.postCode ? suggestion.postCode + ", " : ""}${suggestion.country ? suggestion.country : ''}`
    setMainVal(suggestion.full_address ? suggestion.full_address : add)
    setParams((prev) => {
      return {
        ...prev,
        position: "",
        name: suggestion.name,
        email: suggestion.email,
        corporateForm: suggestion.corporateForm,
        branch: suggestion.branch,
        street: suggestion.street,
        postCode: suggestion.postCode,
        city: suggestion.city,
        country: suggestion.country ? suggestion.country : "germany",
        lat: suggestion.lat,
        long: suggestion.long,
        houseNo: suggestion.houseNo,
        full_address: suggestion.full_address ? suggestion.full_address : add
      };
    });
    setOwnerId(suggestion.ownerId);
    setParamsLoaded((prev) => {
      return {
        ...prev,
        corporateForm: !!suggestion.corporateForm,
        branch: !!suggestion.branch,
        email: !!suggestion.email,
      };
    });
    await setSuggestionLoaded(true);
  };
  const changeRole = (roles) => {
    setRoleSelected(false);
    let tempData = roles?.map(itm => convertBranchRole(itm, false))
    const selectedRoles = tempData.join(",");
    setParam("branch", selectedRoles);
  };
  const handleBack = () => {
    setVisible(false);
    setTimeout(() => {
      goBack();
    }, 1000);
  };

  const _getDepartmentAndLocations = async (id) => {
    const { data } = await callAPI(`/company/getDeptLocation/${id}`, {}, "GET");
    console.log('--------------------Dep Loc', data)
    if (data.status) {
      setdeptloc([...data.data.getCompanyDept])
    }
  }
  const _onClickOfCompnay = (comp) => {
    setShowSuggestion(false)
    setParam('name', comp.name)
    _getDepartmentAndLocations(comp.id)
    getSuggestion(comp)
  }
  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          <div className="w-300 add_company" style={{ height: "93vh" }}>
            {loading && (
              <div className="website_loader">
                <div className="loader" />
              </div>
            )}
            <div className="heading">
              <h2>
                {isGerm ? DE.add_or_join_company : EN.add_or_join_company}
              </h2>

              <a className="pointer" onClick={handleBack}>
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
              </a>
            </div>
            {/* <div className="map text-center">
              <a onClick={handleShow}>
                <img
                  width={21}
                  src={process.env.PUBLIC_URL + "/assets/img/Map.svg"}
                />
                {isGerm ? DE.map : EN.map}
              </a>
              <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                animation={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isGerm ? DE.add_or_join_company : EN.add_or_join_company}
                    <br />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{ height: "80vh", width: "100%", padding: "0rem" }}
                >
                  <MapComponent
                    handleClose={handleClose}
                    setSuggestion={getSuggestion}
                    companies={companies}
                  />
                </Modal.Body>
              </Modal>
            </div>
            <div className="or">
              <span>{isGerm ? DE.or : EN.or}</span>
            </div> */}
            <div
              className="form-bottom"
              onSubmit={(e) => {
                e.preventDefault();
                requestConfirmation();
              }}
            >
              <h3 className="details">{isGerm ? DE.details : EN.details}</h3>
              <Form className="add_company_from" autoComplete="new-password">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    // disabled={ff}
                    value={params.name}
                    onChange={(e) => {
                      setSearchSuggestion(e.target.value)
                      setParam('name', e.target.value)
                    }}
                    type="text"
                    placeholder={isGerm ? DE.name_of_the_company : EN.name_of_the_company}
                  />
                  {errors.name && (
                    <small className="pl-2 text-danger">{errors.name}</small>
                  )}
                </Form.Group>
                {
                  showSuggestion &&
                  <div
                    onScroll={(e) => {
                      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
                      if (bottom) {
                        _onEndOfSuggestions()
                      }
                    }}
                    style={{ overflow: "scroll", overflowX: "hidden", height: 280, width: "95%", border: "1px solid", position: "absolute", backgroundColor: "#fff", zIndex: 1, borderColor: "#DFDFDF" }}>
                    {companiesSuggestion.map((suggestion, index) => {
                      let add = `${suggestion.houseNo ? suggestion.houseNo + ", " : ""}${suggestion.street ? suggestion.street + ", " : ''}${suggestion.city ? suggestion.city + ", " : ""}${suggestion.postCode ? suggestion.postCode + ", " : ""}${suggestion.country ? suggestion.country : ''}`
                      return (
                        <div
                          style={{ cursor: 'pointer', borderBottom: '1px solid #DFDFDF', padding: 10 }}
                          onClick={() => {
                            setdeptloc(null)
                            _onClickOfCompnay(suggestion)
                          }}
                          className="itemdiv"
                        >
                          <span style={{ marginBottom: 0 }}>{suggestion.name}</span>
                          <p className="itemdiv small" style={{ marginBottom: 0 }}>{add}</p>
                        </div>
                      );
                    })}
                    {isGettingSuggestion &&
                      <div style={{ alignSelf: "center" }}>
                        <Rings
                          height="100"
                          width="100"
                          color="#f08438"
                          radius="6"
                          wrapperStyle={{ paddingLeft: "35%" }}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="rings-loading"
                        />
                      </div>
                    }
                  </div>
                }

                <div className=" pr-1">
                  <Form.Group controlId="formBasicPassword">
                    <LocationSearchInput
                      custom
                      defaultValue={params.full_address}
                      placeHolder={isGerm ? DE.Address : EN.Address}
                      setVal={(e) => {
                        setMainVal(e)
                        setSearchCity(e)
                      }}
                      val={mainVal}
                      setFull={(val) => {
                        setMainVal(val)
                        setParam('full_address', val)
                      }}
                    />
                    {errors.full_address && (
                      <small className="pl-2 text-danger">
                        {errors.full_address}
                      </small>
                    )}
                  </Form.Group>
                </div>
                {/* <div className="col-md-4 pl-1">
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        value={params.houseNo}
                        autoComplete="new-password"
                        onChange={(e) => setParam("houseNo", e.target.value)}
                        placeholder={isGerm ? DE.house_number : EN.house_number}
                      />
                      {errors.houseNo && (
                        <small className="pl-2 text-danger">
                          {errors.houseNo}
                        </small>
                      )}
                    </Form.Group>
                  </div> */}

                <div className="row">
                  {/* <div className="col-md-5 pr-1">
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        disabled={suggestionLoaded && user?.id !== ownerId}
                        value={params.postCode}
                        autoComplete="new-password"
                        onChange={(e) => setParam("postCode", e.target.value)}
                        type="text"
                        placeholder={isGerm ? DE.postal_code : EN.postal_code}
                      />
                      {errors.postCode && (
                        <small className="pl-2 text-danger">
                          {errors.postCode}
                        </small>
                      )}
                    </Form.Group>
                  </div> */}
                  {/* <div className="col-md-7 pl-1">
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        disabled={suggestionLoaded && user?.id !== ownerId}
                        value={params.city}
                        autoComplete="new-password"
                        onChange={(e) => setParam("city", e.target.value)}
                        type="text"
                        placeholder={isGerm ? DE.city : EN.city}
                      />
                      {errors.city && (
                        <small className="pl-2 text-danger">
                          {errors.city}
                        </small>
                      )}
                    </Form.Group>
                  </div> */}
                </div>
                <Form.Group
                  className={
                    showRoleDialog
                      ? "select_coustom position-relative theme-border"
                      : "select_coustom position-relative gray-border"
                  }
                >
                  {(!paramsLoaded.branch || user?.id === ownerId) && (
                    <a
                      className="register_role pointer text-truncate"
                      onClick={(e) => {
                        e.preventDefault();
                        setRoleDialog(!showRoleDialog);
                      }}
                    >
                      {!params.branch && (
                        <span>
                          {" "}
                          {isGerm
                            ? DE.role_in_the_building_industry
                            : EN.role_in_the_building_industry}{" "}
                        </span>
                      )}
                      {params.branch && <span>{params.branch?.split(",")?.map(itm => convertBranchRole(itm, isGerm) + ", ")}</span>}
                    </a>
                  )}
                  {paramsLoaded.branch && user?.id !== ownerId && (
                    <a
                      className="register_role pointer text-truncate"
                      style={{ backgroundColor: "#e9ecef" }}
                    >
                      {!params.branch && (
                        <span>
                          {" "}
                          {isGerm
                            ? DE.role_in_the_building_industry
                            : EN.role_in_the_building_industry}{" "}
                        </span>
                      )}
                      {params.branch && <span>{params.branch?.split(",")?.map((itm, index) => (index ? ", " : "") + convertBranchRole(itm, isGerm))}</span>}
                    </a>
                  )}
                  <BsChevronDown size={12} />
                  {errors.branch && (
                    <small className="pl-2 text-danger">{errors.branch}</small>
                  )}
                  <div className="profile-edit-roles">
                    {showRoleDialog && (
                      <CompanyRoles
                        hideRoles={(e) => {
                          setRoleDialog(false)
                        }} setRoles={setRoleSelected} selectPosition={changeRole} position={Roles}
                      />
                    )}
                  </div>
                </Form.Group>
                {deptloc && deptloc?.length > 0 ?
                  <Form.Group className="select_coustom border-0 hello">
                    <a
                      className="register_role pointer text-truncate position-relative"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowDepartModal(true);
                      }}
                    >
                      {!params.deptLocationName && (
                        <span>
                          {isGerm
                            ? DE.dep_loc
                            : EN.dep_loc}{" "}
                        </span>
                      )}
                      {params.deptLocationName && <span>{params.deptLocationName}</span>}
                      <BsChevronDown />
                    </a>
                    {errors.deptLocation && (
                      <small className="pl-2 text-danger">
                        {errors.deptLocation}
                      </small>
                    )}
                  </Form.Group>
                  :
                  <></>
                }
                {/* <Form.Group className="select_coustom border-0 hello">
                  {(!paramsLoaded.corporateForm || user?.id === ownerId) && (
                    <a
                      className="register_role pointer text-truncate position-relative"
                      onClick={(e) => {
                        e.preventDefault();
                        setCorporateForm(true);
                      }}
                    >
                      {!params.corporateForm && (
                        <span>
                          {isGerm ? DE.corporate_form : EN.corporate_form}{" "}
                        </span>
                      )}
                      {params.corporateForm && (
                        <span>{params.corporateForm}</span>
                      )}
                      <BsChevronDown />
                    </a>
                  )}
                  {paramsLoaded.corporateForm && user?.id !== ownerId && (
                    <a
                      className="register_role pointer text-truncate position-relative"
                      style={{ background: "#e9ecef" }}
                    >
                      {!params.corporateForm && (
                        <span>
                          {isGerm ? DE.corporate_form : EN.corporate_form}{" "}
                        </span>
                      )}
                      {params.corporateForm && (
                        <span>{params.corporateForm}</span>
                      )}
                      <BsChevronDown />
                    </a>
                  )}
                  {errors.corporateForm && (
                    <small className="pl-2 text-danger">
                      {errors.corporateForm}
                    </small>
                  )}
                </Form.Group> */}
                {/* <Form.Group className="select_coustom border-0">
                  <a
                    className="register_role pointer text-truncate position-relative"
                    onClick={(e) => {
                      e.preventDefault();
                      setPositionDialog(true);
                    }}
                  >
                    {!params.position && (
                      <span>
                        {isGerm
                          ? DE.position_within_company
                          : EN.position_within_company}{" "}
                      </span>
                    )}
                    {params.position && <span>{params.position}</span>}
                    <BsChevronDown />
                  </a>
                  {errors.position && (
                    <small className="pl-2 text-danger">
                      {errors.position}
                    </small>
                  )}
                </Form.Group> */}
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    disabled={paramsLoaded.email && user?.id !== ownerId}
                    value={params.email}
                    onChange={(e) => setParam("email", e.target.value)}
                    type="text"
                    placeholder={isGerm ? DE.company_e_mail : EN.company_e_mail}
                  />
                  {errors.email && (
                    <small className="pl-2 text-danger">{errors.email}</small>
                  )}
                </Form.Group>
                <Button className="btn request-btn" type="submit">
                  {isGerm ? DE.add_join : EN.add_join}
                </Button>
              </Form>
            </div>
          </div>
          {showCorporateForm && (
            <CompanyCorporateDialog
              handleClose={(e) => setCorporateForm(false)}
              setParam={setParam}
              corporateForm={corporateForm}
            />
          )}
          {showPositionDialog && (
            <CompanyPositionDialog
              handleClose={(e) => setPositionDialog(false)}
              setParam={setParam}
              setSelectName={setSelectName}
              positions={Positions}
            />
          )}
          {showDepartModal && (
            <DepartLocaDialogue
              handleClose={(e) => setShowDepartModal(false)}
              setParam={setParam}
              setSelectName={setSelectName}
              positions={deptloc}
              setDepName={(name) => {
                setParam('deptLocationName', name)
              }}
            />
          )}
        </Animated>
      </Layout>
    </React.Fragment>
  );
}

export default AddCompany;
