import React, { useEffect, useState } from "react";
import { EN } from '../../../Languages/EN';
import { DE } from '../../../Languages/DE';
import { BuildingTypes } from "../../../Languages/vars/BuildingTypes";
import LanguageContext from "../../../helper/LanguageContext";
import { $crud } from "../../../helper/CrudFactory";


export default function BuildingTypesDialog({ handleClose, setParam, defaultBuildings, ctom, setTypesToDisplay }) {
    const { isGerm } = React.useContext(LanguageContext)
    const [selectTypes, setSelectTypes] = useState([]);
    const [toggleBorder, setToggleBorder] = useState(false)
    const [BuildingTypesState, setBuildingTypesState] = useState(BuildingTypes);

    const setBuildingTypes = () => {
        // if (selectTypes.length === 0) {
        //     $crud.notify({
        //         type: "error",
        //         message: "Please select building type."
        //     })
        // } else {
        if (!ctom) {
            setParam('areaOfActivity', selectTypes.join(', '))
            setParam('buildingType', selectTypes.join(', '))
            handleClose()
        }
        else {
            let e_de = []
            let e_en = []
            for (let obj of BuildingTypesState) {
                for (let objx of obj.types) {
                    if (objx.isSelected) {
                        e_de.push(objx.name_de)
                        e_en.push(objx.name_en)
                    }
                }
            }
            setParam('areaOfActivity', e_en)
            setParam('buildingType', e_en)
            setTypesToDisplay(isGerm ? e_de.join(', ') : e_en.join(', '))
            handleClose()
        }
        // }
    }

    useEffect(() => {
        if (defaultBuildings && defaultBuildings.length > 0) {
            let tmp = [...BuildingTypesState]
            for (let obj of defaultBuildings) {
                for (let nObj of tmp) {
                    console.log('--------->defalt build', defaultBuildings)
                    let ind = nObj.types.findIndex(e => (e.name_en == obj || e.name_de == obj))
                    if (ind !== -1) {
                        nObj.types[ind].isSelected = true
                    }
                }
            }
            console.log('--------->defalt build', tmp)
            setBuildingTypesState([...tmp])
        }
    }, [defaultBuildings])
    // useEffect(() => {
    //     if (!defaultBuildings) {
    //         let tmp = [...BuildingTypesState]
    //         for (let obj of tmp) {
    //             for (let objx of obj.types) {
    //                 objx.isSelected = false
    //             }
    //         }
    //         setBuildingTypesState([...tmp])
    //     }
    // }, [])
    const addType = (value, mi, index) => {
        let types = [];
        types = selectTypes;
        const exist = types.includes(value);
        if (selectTypes.length <= 0 || !exist) {
            types.push(value);
            if (types.length > 0) {
                setToggleBorder(true)
            }
            setSelectTypes(types)
        } else {
            const newTypes = types.filter(type => type !== value);
            if (newTypes.length < 1) {
                setToggleBorder(false)
            }
            setSelectTypes(newTypes)
        }
        let tmp = [...BuildingTypesState]
        tmp[mi].types[index].isSelected = !tmp[mi].types[index].isSelected
        setBuildingTypesState([...tmp])
    }

    return (
        <div className="role_popup shadow-sm theme-border">
            <h4>{isGerm ? DE.building_types : EN.building_types}</h4>
            <div className="role_data">
                <ul className="list-group">
                    {
                        BuildingTypesState.map((type, mi) =>
                            <>
                                <li key={type.id} className="list-group-item" style={{ color: "black" }}>
                                    <span className="font-weight-bold">{isGerm ? type.name_de : type.name_en}</span>
                                    <div className="list-group">
                                        {
                                            type.types.map((t, i) =>
                                                <label key={t.id} className="role-label">
                                                    <i>{isGerm ? t.name_de : t.name_en}</i>
                                                    <input checked={t.isSelected} value={t.name_en} onClick={e => addType(e.target.value, mi, i)}
                                                        type="checkbox" />
                                                    <span className="checkmark-label" />
                                                </label>
                                            )
                                        }
                                    </div>
                                </li>
                            </>
                        )
                    }
                </ul>
            </div>
            <div className="bottom-button">
                <a className="pointer" onClick={handleClose}>{isGerm ? DE.cancel : EN.cancel}</a>
                <a className="pointer" onClick={setBuildingTypes}>{isGerm ? DE.ok : EN.ok}</a>
            </div>
        </div>
    )
}
