import React from "react";
import LanguageContext from "../../../helper/LanguageContext";
import {Scrollbars} from "react-custom-scrollbars";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";


const renderThumb = ({style, ...props}) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div style={{...style, ...thumbStyle}} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

const style = {
    marginBottom: 0,
    fontSize: '16px',
    backgroundColor: '#f5f5f5',
    padding: "13px 15px",
    fontWeight: '700',
    textAlign:"center"

}

export default function CompanyCorporateDialog({handleClose, setParam, corporateForm}) {
    const {isGerm} = React.useContext(LanguageContext)
    const handleChangeValue = async (value) => {
        setParam('corporateForm', value);
        handleClose();
    }
    return (
        <div className="role_popup shadow-sm b-orange theme-border">
            <h6 style={style}>{isGerm ? DE.corporate_form : EN.corporate_form}</h6>
            <div className="wscroll popup_scroll">
                    <CustomScrollbars autoHide autoHideTimeout={200} autoHideDuration={200}
                                      className="bottom-bar">
                <div className="role_data mb-0">
                    <ul className="list-group">
                        {
                            corporateForm.map(cform =>
                                <div key={cform.id}>
                                    <li key={cform.id} className="list-group-item p-1 pl-2" style={{color: "black"}}>
                                        <div className="">
                                            <label className="role-label p-1">
                                                <span
                                                    className="">{isGerm ? cform.name_de : cform.name_en}</span>
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                    id={cform.id}
                                                    onChange={e => handleChangeValue(isGerm ? cform.name_de : cform.name_en)}
                                                    value={isGerm ? cform.name_de : cform.name_en}/>
                                                <span className="checkmark-label"/>
                                            </label>
                                        </div>
                                    </li>
                                </div>
                            )
                        }
                    </ul>
                </div>
            </CustomScrollbars>
            </div>
        </div>
    )
}
