import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Media } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import { callAPI } from "../../helper/apiUtils";
import { BASE_URL } from "../../helper/constants";
import { useSelector } from "react-redux";
import { convertBranchRole } from '../../helper/convertor';

function CompanyServicesMember(props) {

    const { isGerm } = React.useContext(LanguageContext)
    const history = useHistory();
    const user = useSelector(state => state.user);
    const [users, setUsers] = useState([])
    const [isKjuup, setIsKjuup] = useState([])

    useEffect(() => {
        if (props.location.state) {
            setIsKjuup(props.location.state.isKjuup)
        }
    }, [props])
    const getUserList = async () => {
        const id = props.match.params.id
        const { data } = await callAPI(`/service/${id}/member`, {}, 'GET')
        console.log(data)
        await setUsers(data.members)
    }
    useEffect(() => {
        getUserList();

    }, [props.match.params.id])
    const getUserDetails = (id) => {
        if (id === user?.id) {
            history.push({
                pathname: `/profile`,
            })
        } else {
            history.push({
                pathname: `/user-profile/${id}`,
            })
        }

    }

    return (
        <React.Fragment>
            <Layout>
                <div class="w-300 following article_like">
                    <div onClick={() => {
                        history.goBack()
                    }} className={isKjuup ? "main_heading main_heading-internal" : "main_heading"}>
                        <img width={10}
                            src={process.env.PUBLIC_URL + (isKjuup ? "/assets/img/backx.svg" : "/assets/img/back.svg")} />
                        {isGerm ? DE.who_has_confirmed_services : EN.who_has_confirmed_services}
                    </div>
                    {
                        users &&
                        users.map(user => (
                            <div key={user?.id} className="post_notification">
                                <div className="my_post">
                                    <Media>
                                        <img
                                            width={40}
                                            height={40}
                                            className="mr-3"
                                            src={
                                                user.avatar ? BASE_URL + user.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"
                                            }
                                            alt="Generic placeholder"
                                            style={{ objectFit: 'cover' }}
                                        />
                                        <Media.Body>
                                            <div className="authore_name">
                                                <h5 className="pointer"
                                                    onClick={e => getUserDetails(user?.id)}> {user.fullName}</h5>
                                                <p>{convertBranchRole(user.buildingIndustryRole, isGerm)}</p>
                                            </div>
                                        </Media.Body>
                                    </Media>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </Layout>
        </React.Fragment>
    );
}

export default CompanyServicesMember;
