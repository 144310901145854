import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { callAPI } from "../helper/apiUtils";
import { Link } from "react-router-dom";
import { DE } from "../Languages/DE";
import { EN } from "../Languages/EN";
import LanguageContext from "../helper/LanguageContext";
import { LoadingDots } from "../LoadingDots/LoadingDots";

function ForgotPassword(props) {
  const [params, setParams] = useState({
    email: "",
  });
  const [paramsForgot, setParamsForgot] = useState({
    username: "",
    userPin: ""
  });
  const [loading, setLoading] = useState(false);
  const [loadingBelow, setLoadingBelow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState("Invalid Credentials.");
  const [invalidMsgX, setInvalidMsgX] = useState('');
  const { isGerm } = React.useContext(LanguageContext);
  const [isGerms, setIsGerm] = useState(true);
  useEffect(() => {
    let registerLang = localStorage.getItem("regiLang");
    if (registerLang) {
      setIsGerm(registerLang == 2 ? true : false);
    }
  }, []);
  console.log(isGerms);

  const submit = async () => {
    setLoading(true);
    setIsInvalid(false);
    if (handleValidation()) {
      try {
        const { data } = await callAPI(
          "/auth/forgotPassword/",
          {},
          "POST",
          {
            email: params.email
          }
        );
        if (!data.status) {
          setInvalidMsg(data.messages);
          setIsInvalid(true);
        } else {
          setInvalidMsg(data.message);
          setIsInvalid(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setLoading(false);
  };

  const submitF = async () => {
    setLoadingBelow(true)
    setIsInvalid(false);
    if (handleValidationD()) {
      try {
        const { data } = await callAPI(
          "/auth/forgotPassword/",
          null,
          "POST",
          {
            "username": paramsForgot.username,
            "userPin": paramsForgot.userPin
          }
        );
        if (!data.status) {
          setInvalidMsgX(data.message);
        } else {
          window.open(data.message);
        }
      } catch (e) {
        setInvalidMsgX(e);
      }
    }
    setLoadingBelow(false)
  };

  const handleValidationD = () => {
    let errors = {};
    let formIsValid = true;
    //Name
    if (!paramsForgot.username) {
      formIsValid = false;
      errors["username"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!paramsForgot.userPin) {
      formIsValid = false;
      errors["userPin"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //Name
    if (!params.email) {
      formIsValid = false;
      errors["email"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    setErrors(errors);
    return formIsValid;
  };

  const setParam = async (name, value) => {
    console.log(value);
    await setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setIsInvalid(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };

  const setParamF = async (name, value) => {
    console.log(value);
    setInvalidMsgX('')
    await setParamsForgot((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setIsInvalid(false);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };

  return (
    <React.Fragment>
      <div className="main-div">
        <div className="w-300 add_company login_singup">
          <div className="heading">
            <h2>
              <img
                height="47px"
                src={
                  process.env.PUBLIC_URL + "/assets/img/KJUUP-Schrift-Icon.png"
                }
              />
            </h2>
          </div>
          <div className="sub_heading">
            <h3>{isGerms ? DE.forgot_password : EN.forgot_password}</h3>
          </div>

          <div className="form-bottom">
            <Form
              className="add_company_from"
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  value={params.email}
                  onChange={(e) => setParam("email", e.target.value)}
                  type="text"
                  placeholder={
                    isGerms ? DE.enter_your_email_add : EN.enter_your_email_add
                  }
                />
                {errors.email && (
                  <small className="pl-2 text-danger">{errors.email}</small>
                )}
              </Form.Group>
              {isInvalid && (
                <div className="p-2 text-center">
                  <small className="text-danger">{invalidMsg}</small>
                </div>
              )}
              <Button
                disabled={loading}
                type="submit"
                className="btn request-btn"
              >
                {loading ? <LoadingDots /> : isGerms ? DE.submit : EN.submit}
              </Button>
            </Form>
          </div>
          <div className="or">
            <span>{isGerms ? DE.or : EN.or}</span>
          </div>

          <div className="form-bottom">
            <Form
              className="add_company_from"
              onSubmit={(e) => {
                e.preventDefault();
                submitF();
              }}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  value={paramsForgot.username}
                  onChange={(event) => {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    // If the entered text matches the email regex, prevent further input
                    if (emailRegex.test(event.target.value)) {
                      // Optionally, you can provide feedback to the user that email address is not allowed
                      setErrors({ ...errors, username: isGerm ? DE.prevent_email : EN.prevent_email });
                      event.preventDefault();
                    }
                    else {
                      setErrors({ ...errors, username: null });
                      setParamF("username", event.target.value)
                    }
                  }}
                  type="text"
                  placeholder={
                    isGerms ? DE.username : EN.username
                  }
                />
                {errors.username && (
                  <small className="pl-2 text-danger">{errors.username}</small>
                )}
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  maxLength={6}
                  value={paramsForgot.userPin}
                  onChange={(e) => {
                    if (!/[0-9]/.test(e.target.value)) {
                      e.preventDefault();
                    }
                    else {
                      setParamF("userPin", e.target.value)
                    }
                  }}
                  type="text"
                  placeholder={
                    "Pin*"
                  }
                />
                {errors.userPin && (
                  <small className="pl-2 text-danger">{errors.userPin}</small>
                )}
              </Form.Group>
              {invalidMsgX && (
                <div className="p-2 text-center">
                  <small className="text-danger">{invalidMsgX}</small>
                </div>
              )}
              <div className="register_link" style={{ textAlign: "center" }}>
                <p style={{ color: "grey", marginLeft: 10, marginRight: 10, fontStyle: "italic", fontSize: "1.5vh" }}>{isGerms ? DE.only_for_kjuup : EN.only_for_kjuup}</p>
              </div>
              <Button
                disabled={loadingBelow}
                type="submit"
                className="btn request-btn"
              >
                {loadingBelow ? <LoadingDots /> : isGerms ? DE.submit : EN.submit}
              </Button>
            </Form>
          </div>
          <div className="register_link" style={{display:"flex",justifyContent: "center"}}>
            <Link to="/">{isGerms ? DE.sign_in : EN.sign_in}</Link>
            <p style={{ color:"gray",padding:"0 5px"}}>Or</p>
            <Link to="/register">{isGerms ? DE.register : EN.register}</Link>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 15 }}>
        <a
          href="https://www.kjuup.de/impressum-agb"
          target="_blank"
          style={{ color: "#737373" }}
        >
          {isGerms ? DE.imprint_gtc : EN.imprint_gtc}
        </a>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
