export const BranchPosition = [
    {
        "id": "1",
        "name_de": "Handwerker/in",
        "name_en": "Craftsman",
        Roles: [
            {
                "id": "2",
                "name_de": "Bauleiter/in",
                "name_en": "Construction manager"
            },
            {
                "id": "3",
                "name_de": "Bauarbeiter/in",
                "name_en": "Construction worker"
            },
            {
                "id": "4",
                "name_de": "Bauunternehmer/in",
                "name_en": "Builder"
            },
            {
                "id": "5",
                "name_de": "Artenschutz-Gutachter/in",
                "name_en": "Species protection expert"
            },
            {
                "id": "6",
                "name_de": "Aufzugsmonteur/in",
                "name_en": "Elevator fitter"
            },
            {
                "id": "7",
                "name_de": "Bauphysiker/in",
                "name_en": "Building physicist"
            },
            {
                "id": "8",
                "name_de": "Baureiniger/in",
                "name_en": "Construction cleaner"
            },
            {
                "id": "9",
                "name_de": "Bautenschutz-Techniker/in",
                "name_en": "Building protection technician"
            },
            {
                "id": "10",
                "name_de": "Beleuchter/in",
                "name_en": "Lighting technician"
            },
            {
                "id": "11",
                "name_de": "Betonbauer/in",
                "name_en": "Concrete worker"
            },
            {
                "id": "12",
                "name_de": "Betonsanierungs-Techniker/in",
                "name_en": "Concrete rehabilitation technician"
            },
            {
                "id": "13",
                "name_de": "Betonstahlbieger und -flechter/in",
                "name_en": "Reinforcing steel benders and braiders"
            },
            {
                "id": "14",
                "name_de": "Blitzschutz-Techniker/in",
                "name_en": "Lightning protection technician"
            },
            {
                "id": "15",
                "name_de": "Bodenleger/in",
                "name_en": "Floor layers"
            },
            {
                "id": "16",
                "name_de": "Brandschutz-Techniker/in",
                "name_en": "Fire protection technician"
            },
            {
                "id": "17",
                "name_de": "Brandmeldeanlagen-Techniker/in",
                "name_en": "Fire alarm technician"
            },
            {
                "id": "18",
                "name_de": "Brunnenbauer/in",
                "name_en": "Well builder"
            },
            {
                "id": "19",
                "name_de": "Dachdecker/in",
                "name_en": "Roofer"
            },
            {
                "id": "20",
                "name_de": "Datentechniker/in",
                "name_en": "Data technician"
            },
            {
                "id": "21",
                "name_de": "Einschaler/in",
                "name_en": "Concrete scarf worker"
            },
            {
                "id": "22",
                "name_de": "Elektriker/in",
                "name_en": "Electrician"
            },
            {
                "id": "23",
                "name_de": "Elektroakustischer Anlagentechniker/in",
                "name_en": "Electroacoustic system technician"
            },
            {
                "id": "24",
                "name_de": "Kanalbau-Monteur/in",
                "name_en": "Sewer construction fitter"
            },
            {
                "id": "25",
                "name_de": "Eisenbieger/in",
                "name_en": "Concrete reinforcement worker"
            },
            {
                "id": "26",
                "name_de": "Erdbauer/in",
                "name_en": "Earthworker"
            },
            {
                "id": "27",
                "name_de": "Estrichleger/in",
                "name_en": "Screed layer"
            },
            {
                "id": "28",
                "name_de": "Fassadenbauer/in",
                "name_en": "Facade builder"
            },
            {
                "id": "29",
                "name_de": "Fenstermonteur/in",
                "name_en": "Window fitter"
            },
            {
                "id": "30",
                "name_de": "Feuerlöschanlagen-Techniker/in",
                "name_en": "Fire extinguishing system technician"
            },
            {
                "id": "31",
                "name_de": "Fliesenleger/in",
                "name_en": "Tiler"
            },
            {
                "id": "32",
                "name_de": "Garten- und Landschaftsbauer/in",
                "name_en": "Gardeners and landscapers"
            },
            {
                "id": "33",
                "name_de": "Gefahrmeldeanlagen-Techniker/in",
                "name_en": "Alarm system technician"
            },
            {
                "id": "34",
                "name_de": "Gerüstbauer/in",
                "name_en": "Scaffolder"
            },
            {
                "id": "35",
                "name_de": "Glaser/in",
                "name_en": "Glaziers"
            },
            {
                "id": "36",
                "name_de": "Heizungsbauer/in",
                "name_en": "Heating technician"
            },
            {
                "id": "37",
                "name_de": "Holzschutz-Techniker/in",
                "name_en": "Wood protection technician"
            },
            {
                "id": "38",
                "name_de": "Kältetechniker/in",
                "name_en": "Refrigeration technician"
            },
            {
                "id": "39",
                "name_de": "Kaminbauer/in",
                "name_en": "Chimney construction technician"
            },
            {
                "id": "40",
                "name_de": "Kläranlagen-Techniker/in",
                "name_en": "Sewage treatment plants technician"
            },
            {
                "id": "41",
                "name_de": "Klempner/in",
                "name_en": "Plumber"
            },
            {
                "id": "42",
                "name_de": "Korrosionsschutz-Techniker/in",
                "name_en": "Corrosion protection technician"
            },
            {
                "id": "43",
                "name_de": "Lüftungsbauer/in",
                "name_en": "Ventilation construction technician"
            },
            {
                "id": "44",
                "name_de": "Maler*in und Lackierer/in",
                "name_en": "Painter"
            },
            {
                "id": "45",
                "name_de": "Maurer/in",
                "name_en": "Bricklayer"
            },
            {
                "id": "46",
                "name_de": "Metallbauer/in",
                "name_en": "Metal worker"
            },
            {
                "id": "47",
                "name_de": "Natursteinleger/in",
                "name_en": "Natural stone layer"
            },
            {
                "id": "48",
                "name_de": "Ofenbauer/in",
                "name_en": "Furnace maker"
            },
            {
                "id": "49",
                "name_de": "Parkettleger/in",
                "name_en": "Parquet layer"
            },
            {
                "id": "50",
                "name_de": "Putzer/in",
                "name_en": "Plasterer"
            },
            {
                "id": "51",
                "name_de": "Rolladenbauer/in",
                "name_en": "Roller shutter builder"
            },
            {
                "id": "52",
                "name_de": "Sanitär-Installateur/in",
                "name_en": "Plumbing installer"
            },
            {
                "id": "53",
                "name_de": "Schließanlagen-Monteur/in",
                "name_en": "Locking system fitter"
            },
            {
                "id": "54",
                "name_de": "Schlosser/in",
                "name_en": "Locksmith"
            },
            {
                "id": "55",
                "name_de": "Schreiner/in/Tischler/in",
                "name_en": "Carpenter"
            },
            {
                "id": "56",
                "name_de": "Schwimmbadbauer/in",
                "name_en": "Swimming pool builder"
            },
            {
                "id": "57",
                "name_de": "Sicherheitsbeleuchtungs-Techniker/in",
                "name_en": "Emergency lighting technician"
            },
            {
                "id": "58",
                "name_de": "Sonnenschutzanlagen-Techniker/in",
                "name_en": "Sun protection system technician"
            },
            {
                "id": "59",
                "name_de": "Stahlbauer/in",
                "name_en": "Steel constructor"
            },
            {
                "id": "61",
                "name_de": "Steinmetz/in",
                "name_en": "Stonemason"
            },
            {
                "id": "62",
                "name_de": "Straßenbauer/in",
                "name_en": "Road builder"
            },
            {
                "id": "63",
                "name_de": "Stuckateur/in",
                "name_en": "Plasterer"
            },
            {
                "id": "64",
                "name_de": "Tiefbauer/in",
                "name_en": "Civil engineer"
            },
            {
                "id": "66",
                "name_de": "Treppenbauer/in",
                "name_en": "Stair builder"
            },
            {
                "id": "67",
                "name_de": "Trockenbauer/in",
                "name_en": "Drywall builder"
            },
            {
                "id": "68",
                "name_de": "Verbauarbeiten",
                "name_en": "Sheeting work"
            },
            {
                "id": "69",
                "name_de": "Vermessungs-Techniker/in",
                "name_en": "Measurement technician"
            },
            {
                "id": "70",
                "name_de": "Wärmedämm-Techniker/in",
                "name_en": "Thermal insulation technician"
            },
            {
                "id": "71",
                "name_de": "Wintergartenbauer/in",
                "name_en": "Winter garden builder"
            },
            {
                "id": "72",
                "name_de": "Zimmermann/-frau",
                "name_en": "Carpenter"
            },
        ]
    },

    {
        "id": "73",
        "name_de": "Ingenieur/in",
        "name_en": "Engineer",
        Roles: [
            {
                "id": "60",
                "name_de": "Statiker/in",
                "name_en": "Structural engineer / Statics"
            },
            {
                "id": "65",
                "name_de": "Tragwerksplaner/in",
                "name_en": "Structural engineer"
            },
            {
                "id": "74",
                "name_de": "DGNB-Auditor/in",
                "name_en": "Building certification auditor"
            },
            {
                "id": "75",
                "name_de": "DGNB-Consultant",
                "name_en": "Building certification consultant"
            },
            {
                "id": "76",
                "name_de": "Gutachter/in",
                "name_en": "Appraiser"
            },
            {
                "id": "77",
                "name_de": "Akustik-Ingenieur/in",
                "name_en": "Acoustic engineer"
            },
            {
                "id": "78",
                "name_de": "Architekt/in",
                "name_en": "Architect"
            },
            {
                "id": "79",
                "name_de": "Bauingenieur/in",
                "name_en": "Civil engineer"
            },
            {
                "id": "80",
                "name_de": "BIM-Koordinator/in",
                "name_en": "BIM-coordinator"
            },
            {
                "id": "81",
                "name_de": "Blitzschutz-Ingenieur/in",
                "name_en": "Lightning protection engineer"
            },
            {
                "id": "82",
                "name_de": "Bodengutachter/in",
                "name_en": "Soil surveyor"
            },
            {
                "id": "83",
                "name_de": "Brandmeldeanlagen-Ingenieur/in",
                "name_en": "Fire alarm system engineer"
            },
            {
                "id": "84",
                "name_de": "Brandschutz-Ingenieur/in",
                "name_en": "Fire protection engineer"
            },
            {
                "id": "85",
                "name_de": "Elektro-Ingenieur/in",
                "name_en": "Electrical engineer"
            },
            {
                "id": "86",
                "name_de": "Haustechnik-Ingenieur/in",
                "name_en": "Building services engineer"
            },
            {
                "id": "87",
                "name_de": "Holzbau-Ingenieur/in",
                "name_en": "Timber construction engineer"
            },
            {
                "id": "88",
                "name_de": "Holzschutz-Ingenieur/in",
                "name_en": "Wood protection engineer"
            },
            {
                "id": "89",
                "name_de": "Kläranlagen-Ingenieur/in",
                "name_en": "Sewage plant engineer"
            },
            {
                "id": "90",
                "name_de": "Prüf-Ingenieur/in",
                "name_en": "Testing engineer"
            },
            {
                "id": "91",
                "name_de": "Prüfstatiker/in",
                "name_en": "Test engineer"
            },
            {
                "id": "92",
                "name_de": "Sachverständiger/in",
                "name_en": "Expert"
            },
            {
                "id": "93",
                "name_de": "SiGeKo-Ingenieur/in",
                "name_en": "Safety and health coordinator"
            },
            {
                "id": "94",
                "name_de": "Spezialtiefbau-Ingenieur/in",
                "name_en": "Special civil engineering engineer"
            },
            {
                "id": "95",
                "name_de": "Straßenbau-Ingenieur/in",
                "name_en": "Road construction engineer"
            },
            {
                "id": "96",
                "name_de": "Tiefbau-Ingenieur/in",
                "name_en": "Civil engineer"
            },
            {
                "id": "97",
                "name_de": "Vermessungs-Ingenieur/in",
                "name_en": "Surveyor"
            },
            {
                "id": "98",
                "name_de": "Innenarchitekt/in",
                "name_en": "Interior architect"
            }
        ]
    },

    {
        "id": "99",
        "name_de": "Immobiliensektor",
        "name_en": "Real-estate sector",
        Roles: [
            {
                "id": "100",
                "name_de": "Immobilienkaufmann/-frau",
                "name_en": "Real estate agent"
            },
            {
                "id": "101",
                "name_de": "Makler/in",
                "name_en": "Estate agent"
            }
        ]
    },

    {
        "id": "102",
        "name_de": "Lieferant/in",
        "name_en": "Supplier",
        Roles: [
            {
                "id": "103",
                "name_de": "Spediteur/in",
                "name_en": "Freight forwarder"
            }
        ]
    },

    {
        "id": "104",
        "name_de": "Andere",
        "name_en": "Others",
        Roles: [
            {
                "id": "105",
                "name_de": "Andere",
                "name_en": "Others"
            },
            {
                "id": "106",
                "name_de": "Fotograf/in",
                "name_en": "Photographer"
            },
            {
                "id": "107",
                "name_de": "Designer/in",
                "name_en": "Designer"
            },
            {
                "id": "108",
                "name_de": "3D Designer/in",
                "name_en": "3D Designer"
            },
            {
                "id": "109",
                "name_de": "Marktforschung",
                "name_en": "Market research"
            },
            {
                "id": "110",
                "name_de": "Politiker/in",
                "name_en": "Politician"
            },
            {
                "id": "111",
                "name_de": "Programmierer/in",
                "name_en": "Programmer"
            },
            {
                "id": "112",
                "name_de": "Öffentliche Hand",
                "name_en": "Public administration"
            },
            {
                "id": "113",
                "name_de": "Investor/in",
                "name_en": "Investor"
            },
            {
                "id": "114",
                "name_de": "Finanzkaufmann/-frau",
                "name_en": "Financial clerk"
            },
            {
                "id": "115",
                "name_de": "Bauherr",
                "name_en": "Client"
            },
            {
                "id": "116",
                "name_de": "Bankkaufmann/-frau",
                "name_en": "Bank clerk"
            },
            {
                "id": "117",
                "name_de": "Groß- und Einzelhandelskaufmann/-frau",
                "name_en": "Wholesaler and retailer"
            },
            {
                "id": "118",
                "name_de": "Verwaltung",
                "name_en": "Administration"
            },
            {
                "id": "119",
                "name_de": "Versicherungskaufmann/-frau",
                "name_en": "Insurance salesman"
            }
        ]
    },

]