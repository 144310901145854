import React from "react";
import { DE } from '../../../Languages/DE';
import { EN } from '../../../Languages/EN';
import LanguageContext from "../../../helper/LanguageContext";

const style = {
    marginBottom: 0,
    fontSize: '14px',
    backgroundColor: '#f5f5f5',
    padding: "13px 15px",
    fontWeight: 600,
}

export default function ProjectListDialog({ handleClose, setParam, buildings, isIntenal }) {
    const { isGerm } = React.useContext(LanguageContext)
    const handleChangeValue = async (company) => {
        setParam('buildingId', company.id)
        setParam('buildingName', company.name)
    }
    const chooseCompany = (e) => {
        e.preventDefault();
        handleClose();
    }
    return (
        <div className={isIntenal ? "role_popup shadow-sm theme-border-internal" : "role_popup shadow-sm theme-border"}>
            <h6 style={style}>{isGerm ? DE.projectss : EN.projectss}</h6>
            <div className="role_data">
                <ul className="list-group">
                    {
                        buildings.map(company =>
                            <>
                                <li key={company.id} className="list-group-item" style={{ color: "black" }}>
                                    <div className="list-group">
                                        {!isIntenal ?
                                            <label key={company.id} className="role-label">
                                                <span>{company.name}</span>
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                    id={company.id} onChange={e => handleChangeValue(company)}
                                                    value={company.id} />
                                                <span className="checkmark-label" />
                                            </label>
                                            :
                                            <label key={company.id} className="role-label-internal">
                                                <span>{company.name}</span>
                                                <input className="form-check-input" type="radio" name="exampleRadios"
                                                    id={company.id} onChange={e => handleChangeValue(company)}
                                                    value={company.id} />
                                                <span className="checkmark-label-internal" />
                                            </label>
                                        }
                                    </div>
                                </li>
                            </>
                        )
                    }
                </ul>
            </div>
            <div className={isIntenal ? "bottom-button-internal" : "bottom-button"}>
                <a className="pointer" onClick={handleClose}>{isGerm ? DE.cancel : EN.cancel}</a>
                <a className="pointer" onClick={e => chooseCompany(e)}>{isGerm ? DE.ok : EN.ok}</a>
            </div>
        </div>
    )
}
