import { API_ROOT } from "./constants";
import { $user } from "./UserFactory";

export const badgeObj = {
    url: `${API_ROOT}/badge/subscribe`,
    method: 'get',
    headers: {
        Authorization: `jwt ${$user.getToken()}`
    }
};
export const badgeUnsubscribeObj = {
    url: `${API_ROOT}/badge/unsubscribe`,
    method: 'get',
    headers: {
        Authorization: `jwt ${$user.getToken()}`
    }
};
export const badgeCountObj = {
    url: `${API_ROOT}/badge/count`,
    method: 'get',
    headers: {
        Authorization: `jwt ${$user.getToken()}`
    }
};

export const onlineObj = {
    url: `${API_ROOT}/user/isOnline`,
    method: 'get',
    headers: {
        Authorization: `jwt ${$user.getToken()}`
    },
};

export const sumTotalChatHistory = arr => arr.reduce((sum, { items }) => sum + items?.length, 0)
