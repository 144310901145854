// MyGoogleMaps.js
import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import {DE} from "../../../Languages/DE";
import {EN} from "../../../Languages/EN";
import LanguageContext from "../../../helper/LanguageContext";
import {Modal, Form} from "react-bootstrap";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import ShareMarker from "./ShareMarker";
import {ReactComponent as Sendicon} from "../../../Icons/Send.svg";

class ChatShareMap extends Component {
    static contextType = LanguageContext;

    state = {
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 14,
        address: '',
        formattedAddress: '',
        draggable: true,
        lat: null,
        lng: null,
        urlType: 'location'
    };

    componentWillMount() {
        this.setCurrentLocation();
    }


    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({draggable: true});
        this._generateAddress();
    }

    _onChange = ({center, zoom}) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    }

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        this._generateAddress()
    };

    _generateAddress() {
        const {
            mapApi
        } = this.state;

        const geocoder = new mapApi.Geocoder;

        geocoder.geocode({'location': {lat: this.state.lat, lng: this.state.lng}}, (results, status) => {
            console.log(results);
            this.setState({formattedAddress: results[0].formatted_address});
            console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 14;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }
    }

    handleChange = address => {
        this.setState({address: address})
    };

    handleSelect = address => {
        this.setState({address: address});
        this.setState({formattedAddress: address});
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);

                this.setState({
                    center: [latLng.lat, latLng.lng],
                    lat: latLng.lat,
                    lng: latLng.lng
                });
            })
            .catch(error => console.error('Error', error));
    };

    shareLocation = e => {
        const url = encodeURI(`https://maps.google.com/?daddr=${this.state.formattedAddress}_${this.state.urlType === 'meeting' ? 'Meeting Point' : 'Location'}`);
        this.props.sendMapMessage(url,this.props.chat.id);
        this.props.handleClose();
    }


    render() {


        return (
            <Modal size="md" show={this.props.show} onHide={this.props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.context.isGerm ? DE.send_location : EN.send_location}<br/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "400px", width: "100%", padding: "0rem"}}>
                    <div style={{height: '400px', width: '100%'}}>
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {
                                ({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                    <div className="map_div">
                                        <Form.Control
                                            {...getInputProps({
                                                placeholder: this.context.isGerm ? DE.search : EN.search,
                                                className: 'location-search-input',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span className="small">{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                        </PlacesAutocomplete>
                        <div style={{height: '350px', width: '100%'}}>
                            {
                                this.state.urlType === 'meeting' ?
                                    <GoogleMapReact
                                        bootstrapURLKeys={{key: "AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q"}}
                                        center={this.state.center}
                                        zoom={this.state.zoom}
                                        draggable={this.state.draggable}
                                        onChange={this._onChange}
                                        onChildMouseDown={this.onMarkerInteraction}
                                        onChildMouseUp={this.onMarkerInteractionMouseUp}
                                        onChildMouseMove={this.onMarkerInteraction}
                                        onChildClick={() => console.log('child click')}
                                        onClick={this._onClick}
                                    >

                                        <ShareMarker
                                            lat={this.state.lat}
                                            lng={this.state.lng}
                                        />


                                    </GoogleMapReact> :
                                    <GoogleMapReact

                                        center={this.state.center}
                                        zoom={this.state.zoom}
                                        bootstrapURLKeys={{
                                            key: 'AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q',
                                            libraries: ['places'],
                                        }}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({map, maps}) => this.apiHasLoaded(map, maps)}
                                    >

                                        <ShareMarker
                                            lat={this.state.lat}
                                            lng={this.state.lng}
                                        />


                                    </GoogleMapReact>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0 model_input">
                    <div className="send_content">
                        <div className="radius">
                            <div className="form-check">
                                <label className="radio_inner">
                                    <input type="radio" name="urlType" onChange={() => {
                                        this.setState({urlType: 'location'})
                                    }}/>
                                    <span className="checkmark_inner"></span>
                                </label>
                            </div>
                            <div className="radio_cont">
                                <h5 className="m-0">{this.context.isGerm ? DE.send_location : EN.send_location}</h5>
                                <small>{this.context.isGerm ? DE.up_to_50m_exact : EN.up_to_50m_exact}</small>
                            </div>

                        </div>
                        <div className="text-center">
                            {
                                this.state.urlType === 'location' &&
                                <Sendicon class="pointer" onClick={this.shareLocation} width="20px"/>
                            }
                        </div>
                    </div>
                    <div className="send_content">
                        <div className="radius">
                            <div className="form-check">
                                <label className="radio_inner">
                                    <input type="radio" name="urlType" onChange={() => {
                                        this.setState({urlType: 'meeting'})
                                    }}/>
                                    <span className="checkmark_inner"></span>
                                </label>
                            </div>
                            <div className="radio_cont">
                                <h5 className="m-0">Send meeting point</h5>
                                <small>Up to 10m exact</small>
                            </div>
                        </div>
                        <div className="text-center">
                            {
                                this.state.urlType === 'meeting' &&
                                <Sendicon class="pointer" onClick={this.shareLocation} width="20px"/>
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ChatShareMap;
