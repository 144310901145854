import React, {useEffect} from "react";
import htmlToPdfMake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default function (props) {
    const ref = React.createRef();
    const btn = React.createRef();
    useEffect(() => {
        exportData();
    }, []);
    const exportData = async () => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs
        const html = htmlToPdfMake(props.chatMessages);

        const docDefinition = {
            content: [
                html
            ]
        };
        const pdfDocGenerator = await pdfMake.createPdf(docDefinition);
        await pdfDocGenerator.getDataUrl((dataUrl) => {
            const targetElement = document.querySelector('#iframeContainer');
            const anchor = document.createElement('a');
            anchor.href = dataUrl;
            anchor.text = 'demo';
            anchor.target = '_blank';
            anchor.setAttribute('download', `${props.chat.name}-chat.pdf`)
            anchor.setAttribute('targetRef', ref)
            anchor.setAttribute('id', 'export-group-chat')
            targetElement.appendChild(anchor);
            // window.location.href = dataUrl
            document.querySelector('#export-group-chat').click()
            document.querySelector('#export-group-chat').remove();
        });
    }
    return (
        <div id="iframeContainer">
        </div>
    )
}
