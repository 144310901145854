import React from 'react';
import Layout from "../../components/Layout";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import { CompanyFeed } from "./CompanyFeed";
import { callAPI } from "../../helper/apiUtils";
import { UserFeed } from "./UserFeed";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import { ARTICLE } from "../../store";
import { connect } from "react-redux";
import FeedViewDialog from "./Dialogs/FeedViewDialog";
import { $user } from "../../helper/UserFactory";
import { KJUUP_COLORS } from '../../helper/constants';

const user = $user.get();

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);


class FeedDetail extends React.Component {

    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {

            hasMore: 'true',
            articles: [],

        }
    }


    handleLike = async (id) => {
        const articleDetails = await this.state.articles.find(article => article.id === id)
        const articlesList = this.state.articles.map(article => {
            if (article.id === articleDetails.id) {
                article.likeStatus = !articleDetails.likeStatus;
                const existUser = article.likes.find(like => like.id === user?.id)
                if (existUser) {
                    article.likes = article.likes.filter(like => like.id !== user?.id);
                    article.likeCount = articleDetails.likeCount - 1
                } else {
                    article.likes = [...article.likes, { id: user?.id, avatar: user.avatar }]
                    article.likeCount = articleDetails.likeCount + 1
                }
            }
            return article;
        })
        this.setState({ articles: articlesList })
    }


    get = async () => {
        const id = this.props.match.params.id;

        const { data: { article } } = await callAPI('/article/' + id, {}, 'GET')

        const updatedArticles = article.map((article) => {
            article.expand = false;
            return article;
        })
        console.log(article)
        await this.setState({ articles: updatedArticles })
    }

    componentDidMount() {
        this.get()
    }

    expandArticle = key => {
        this.setState(state => {
            const articles = state.articles
            let article = articles[key];
            article.expand = true
            articles[key] = article;
            return { articles: articles }
        })
    }
    handleFollow = async (id, followStatus) => {
        const articleDetails = await this.state.articles.find(article => article.id === id)
        console.log(followStatus)
        const articlesList = this.state.articles.map(article => {
            if (article.postedById === articleDetails.postedById) {
                article.followStatus = followStatus ? false : true;
            }
            return article;
        })
        this.setState({ articles: articlesList })

    }


    render() {
        return (
            <React.Fragment>
                <Layout>
                    <div className="w-300 add_company pt-0" style={{height:"93vh", display:"flex", flexDirection:"column"}}>
                        {/* <div className="main_heading">
                            <Link to="/articles"><img width={10}
                                src={process.env.PUBLIC_URL + "/assets/img/back.svg"} /></Link>
                            {this.context.isGerm ? DE.feed_details : EN.feed_details}
                        </div> */}
                        <div className={(this.props.location.state && this.props.location.state.isInternal) ? "main_heading main_heading-internal" : "main_heading"} style={{ display: "flex" }}>
                            <a className="pointer" style={{}}>
                                <Link to="/articles">
                                    {!(this.props.location.state && this.props.location.state.isInternal) ?
                                        <img
                                            width={10}
                                            src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                                        />
                                        :
                                        <img
                                            width={10}
                                            className="custom-svg"
                                            src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                                        />
                                    }
                                </Link>
                            </a>
                            <Link to="/articles">

                            <div style={{ flex: 1, color: (this.props.location.state && this.props.location.state.isInternal) ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}>
                                    {this.context.isGerm ? DE.feed_details : EN.feed_details}
                            </div>
                            </Link>

                            <div />
                        </div>
                        <div className="wscroll" style={{overflowX:"hidden", flex:1}}>
                                {
                                    this.state.articles &&
                                    this.state.articles.map((article, key) => (
                                        <div key={key} className="media-desc p-2">
                                            {
                                                (article.createdBy === 'user') ?
                                                    <UserFeed handleFollow={this.handleFollow}
                                                        handleLike={this.handleLike}
                                                        reloadArticle={() => this.get()} index={key}
                                                        article={article}
                                                        isDetails={true}
                                                        viewArticleDetail={this.props.viewArticle}
                                                        expandArticle={this.expandArticle} />
                                                    :
                                                    <CompanyFeed handleLike={this.handleLike}
                                                        handleFollow={this.handleFollow}
                                                        reloadArticle={() => this.get()} index={key}
                                                        article={article}
                                                        isDetails={true}
                                                        isInternal={(this.props.location.state && this.props.location.state.isInternal) ? true : false}
                                                        viewArticleDetail={this.props.viewArticle}
                                                        expandArticle={this.expandArticle} />

                                            }
                                        </div>
                                    ))
                                }
                        </div>
                    </div>
                    {
                        this.props.article &&
                        <FeedViewDialog article={this.props.article} />
                    }
                </Layout>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const { article } = state;
    console.log(article);
    return {
        article: article
    }
}
const mapDispatchToProps = dispatch => {
    return {
        viewArticle: article => dispatch({ type: ARTICLE, article: article })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedDetail));
