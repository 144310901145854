export const FilterRoles = [
    {
        id: 1,
        "name_de": "Bauindustrie",
        "name_en": "Building industry",
        branches: [
            {
                "id": 1,
                "name_de": "Bauchemie",
                "name_en": "Construction chemistry"
            },
            {
                "id": 2,
                "name_de": "Beschichtung",
                "name_en": "Coating"
            },
            {
                "id": 3,
                "name_de": "Beschlagstechnik",
                "name_en": "Fittings"
            },
            {
                "id": 4,
                "name_de": "Bewehrungssysteme",
                "name_en": "Reinforcing"
            },
            {
                "id": "6",
                "name_de": "Betonfertigteile",
                "name_en": "Precast concrete plants"
            },
            {
                "id": "7",
                "name_de": "Deckensysteme",
                "name_en": "Flooring structural systems"
            },
            {
                "id": "8",
                "name_de": "Fertigteile",
                "name_en": "Prefab compound units"
            },
            {
                "id": "9",
                "name_de": "Holzwerkstoffe",
                "name_en": "Wood materials"
            },
            {
                "id": "10",
                "name_de": "Möbel",
                "name_en": "Furnitures"
            },
            {
                "id": "11",
                "name_de": "Raumsysteme",
                "name_en": "Spatial structural systems"
            },
            {
                "id": "12",
                "name_de": "Sicherheitssysteme",
                "name_en": "Safety systems"
            },
            {
                "id": "13",
                "name_de": "Produzierendes Gewerbe",
                "name_en": "Manufacturing plant"
            },
            {
                "id": "14",
                "name_de": "Türen",
                "name_en": "Doors"
            },
            {
                "id": "15",
                "name_de": "Tore",
                "name_en": "Gates"
            },
        ]
    },
    {
        id: 2,
        "name_de": "Handwerk",
        "name_en": "Craft",
        branches: [{
            "id": "113",
            "name_de": "Gefahrmeldeanlagen-Techniker/in",
            "name_en": "Alarm system technician"
        },
        {
            "id": "34",
            "name_de": "Gefahrmeldeanlagen",
            "name_en": "Alarm systems"
        },
        {
            "id": "4",
            "name_de": "Asbestsanierung",
            "name_en": "Asbestos removal"
        },
        {
            "id": "5",
            "name_de": "Asphaltbau",
            "name_en": "Asphaltic work"
        },
        {
            "id": "125",
            "name_de": "Maurer/in",
            "name_en": "Bricklayer"
        },
        {
            "id": "49",
            "name_de": "Mauerarbeiten",
            "name_en": "Brickwork"
        },
        {
            "id": "84",
            "name_de": "Bauunternehmer/in",
            "name_en": "Builder"
        },
        {
            "id": "21",
            "name_de": "DGNB-Beratung",
            "name_en": "Building certification"
        },
        {
            "id": "87",
            "name_de": "Bauphysiker/in",
            "name_en": "Building physicist"
        },
        {
            "id": "89",
            "name_de": "Bautenschutz-Techniker/in",
            "name_en": "Building protection technician"
        },
        {
            "id": "59",
            "name_de": "Schreiner / Tischler",
            "name_en": "Carpenter"
        },
        {
            "id": "135",
            "name_de": "Schreiner/in/Tischler/in",
            "name_en": "Carpenter"
        },
        {
            "id": "152",
            "name_de": "Zimmermann/-frau",
            "name_en": "Carpenter"
        },
        {
            "id": "79",
            "name_de": "Zimmerei",
            "name_en": "Carpentry"
        },
        {
            "id": "80",
            "name_de": "Zimmerei und Holzbau",
            "name_en": "Carpentry and timber construction"
        },
        {
            "id": "15",
            "name_de": "Betonwerkstein",
            "name_en": "Cast stone work"
        },
        {
            "id": "43",
            "name_de": "Kaminbau",
            "name_en": "Chimney construction"
        },
        {
            "id": "119",
            "name_de": "Kaminbauer/in",
            "name_en": "Chimney construction technician"
        },
        {
            "id": "144",
            "name_de": "Tiefbauer/in",
            "name_en": "Civil engineer"
        },
        {
            "id": "70",
            "name_de": "Tiefbau",
            "name_en": "Civil engineering"
        },
        {
            "id": "9",
            "name_de": "Baureinigung",
            "name_en": "Cleansing"
        },
        {
            "id": "13",
            "name_de": "Betonbau",
            "name_en": "Concete construction"
        },
        {
            "id": "23",
            "name_de": "Einschalarbeiten",
            "name_en": "Concrete formwork"
        },
        {
            "id": "92",
            "name_de": "Betonsanierungs-Techniker/in",
            "name_en": "Concrete rehabilitation technician"
        },
        {
            "id": "105",
            "name_de": "Eisenbieger/in",
            "name_en": "Concrete reinforcement worker"
        },
        {
            "id": "14",
            "name_de": "Betonsanierung",
            "name_en": "Concrete reintegration"
        },
        {
            "id": "101",
            "name_de": "Einschaler/in",
            "name_en": "Concrete scarf worker"
        },
        {
            "id": "91",
            "name_de": "Betonbauer/in",
            "name_en": "Concrete worker"
        },
        {
            "id": "88",
            "name_de": "Baureiniger/in",
            "name_en": "Construction cleaner"
        },
        {
            id: '23',
            name_de: 'Bauträger',
            name_en: 'Property developer',
        },
        {
            id: '24',
            name_de: 'Generalbauunternehmung',
            name_en: 'General construction company',
        },
        {
            id: '25',
            name_de: 'Generalübernehmung',
            name_en: 'General contracting',
        },
        {
            "id": "11",
            "name_de": "Bauunternehmen",
            "name_en": "Construction company"
        },
        {
            "id": "82",
            "name_de": "Bauleiter/in",
            "name_en": "Construction manager"
        },
        {
            "id": "83",
            "name_de": "Bauarbeiter/in",
            "name_en": "Construction worker"
        },
        {
            "id": "46",
            "name_de": "Korrosionsschutz",
            "name_en": "Corrosion protection"
        },
        {
            "id": "122",
            "name_de": "Korrosionsschutz-Techniker/in",
            "name_en": "Corrosion protection technician"
        },
        {
            "id": "100",
            "name_de": "Datentechniker/in",
            "name_en": "Data technician"
        },
        {
            "id": "1",
            "name_de": "Abbrucharbeiten",
            "name_en": "Demolition work"
        },
        {
            "id": "26",
            "name_de": "Entwässerungskanalbau",
            "name_en": "Drainage system work"
        },
        {
            "id": "22",
            "name_de": "Dränarbeiten",
            "name_en": "Drainage work "
        },
        {
            "id": "147",
            "name_de": "Trockenbauer/in",
            "name_en": "Drywall builder"
        },
        {
            "id": "73",
            "name_de": "Trockenbau",
            "name_en": "Drywall work"
        },
        {
            "id": "27",
            "name_de": "Erdarbeiten",
            "name_en": "Earthwork"
        },
        {
            "id": "106",
            "name_de": "Erdbauer/in",
            "name_en": "Earthworker"
        },
        {
            "id": "25",
            "name_de": "Elektroarbeiten",
            "name_en": "Electrical installation"
        },
        {
            "id": "102",
            "name_de": "Elektriker/in",
            "name_en": "Electrician"
        },
        {
            "id": "24",
            "name_de": "Elektroakustische Anlagen",
            "name_en": "Electro-acoustic installation"
        },
        {
            "id": "103",
            "name_de": "Elektroakustischer Anlagentechniker/in",
            "name_en": "Electroacoustic system technician"
        },
        {
            "id": "86",
            "name_de": "Aufzugsmonteur/in",
            "name_en": "Elevator fitter"
        },
        {
            "id": "137",
            "name_de": "Sicherheitsbeleuchtungs-Techniker/in",
            "name_en": "Emergency lighting technician"
        },
        {
            "id": "37",
            "name_de": "Gutachter-Büro",
            "name_en": "Expert office"
        },
        {
            "id": "7",
            "name_de": "Außenputz",
            "name_en": "Exterior plaster"
        },
        {
            "id": "108",
            "name_de": "Fassadenbauer/in",
            "name_en": "Facade builder"
        },
        {
            "id": "29",
            "name_de": "Fassadenbau",
            "name_en": "Facade construction"
        },
        {
            "id": "18",
            "name_de": "Brandmeldeanlagen",
            "name_en": "Fire alarm system"
        },
        {
            "id": "97",
            "name_de": "Brandmeldeanlagen-Techniker/in",
            "name_en": "Fire alarm technician"
        },
        {
            "id": "31",
            "name_de": "Feuerlöschanlagen",
            "name_en": "Fire extinguishing system"
        },
        {
            "id": "110",
            "name_de": "Feuerlöschanlagen-Techniker/in",
            "name_en": "Fire extinguishing system technician"
        },
        {
            "id": "17",
            "name_de": "Brand-/Rauchschutz",
            "name_en": "Fire protection / smoke prevention"
        },
        {
            "id": "96",
            "name_de": "Brandschutz-Techniker/in",
            "name_en": "Fire protection technician"
        },
        {
            "id": "95",
            "name_de": "Bodenleger/in",
            "name_en": "Floor layers"
        },
        {
            "id": "16",
            "name_de": "Bodenbelagsarbeiten",
            "name_en": "Flooring work"
        },
        {
            "id": "51",
            "name_de": "Ofenbau",
            "name_en": "Furnace construction"
        },
        {
            "id": "128",
            "name_de": "Ofenbauer/in",
            "name_en": "Furnace maker"
        },
        {
            "id": "112",
            "name_de": "Garten- und Landschaftsbauer/in",
            "name_en": "Gardeners and landscapers"
        },
        {
            "id": "33",
            "name_de": "Garten- und Landschaftsbau",
            "name_en": "Gardening and landscaping"
        },
        {
            "id": "35",
            "name_de": "Geologe",
            "name_en": "Geologist"
        },
        {
            "id": "115",
            "name_de": "Glaser/in",
            "name_en": "Glaziers"
        },
        {
            "id": "75",
            "name_de": "Verglasung",
            "name_en": "Glazing"
        },
        {
            "id": "38",
            "name_de": "Heizungsbau",
            "name_en": "Heating construction"
        },
        {
            "id": "116",
            "name_de": "Heizungsbauer/in",
            "name_en": "Heating technician"
        },
        {
            "id": "12",
            "name_de": "Beleuchtung",
            "name_en": "Illumination"
        },
        {
            "id": "41",
            "name_de": "Innenputz",
            "name_en": "Interior plaster"
        },
        {
            "id": "90",
            "name_de": "Beleuchter/in",
            "name_en": "Lighting technician"
        },
        {
            "id": "81",
            "name_de": "Blitzschutz",
            "name_en": "Lightning protection"
        },
        {
            "id": "94",
            "name_de": "Blitzschutz-Techniker/in",
            "name_en": "Lightning protection technician"
        },
        {
            "id": "133",
            "name_de": "Schließanlagen-Monteur/in",
            "name_en": "Locking system fitter"
        },
        {
            "id": "57",
            "name_de": "Schließanlagen",
            "name_en": "Locking systems"
        },
        {
            "id": "58",
            "name_de": "Schlosser",
            "name_en": "Locksmith"
        },
        {
            "id": "134",
            "name_de": "Schlosser/in",
            "name_en": "Locksmith"
        },
        {
            "id": "149",
            "name_de": "Vermessungs-Techniker/in",
            "name_en": "Measurement technician"
        },
        {
            "id": "50",
            "name_de": "Metallbau",
            "name_en": "Metal construction"
        },
        {
            "id": "126",
            "name_de": "Metallbauer/in",
            "name_en": "Metal worker"
        },
        {
            "id": "52",
            "name_de": "Naturstein",
            "name_en": "Natural stone"
        },
        {
            "id": "127",
            "name_de": "Natursteinleger/in",
            "name_en": "Natural stone layer"
        },
        {
            "id": "48",
            "name_de": "Maler und Lackierer",
            "name_en": "Painter"
        },
        {
            "id": "124",
            "name_de": "Maler*in und Lackierer/in",
            "name_en": "Painter"
        },
        {
            "id": "53",
            "name_de": "Parkett",
            "name_en": "Parquet"
        },
        {
            "id": "129",
            "name_de": "Parkettleger/in",
            "name_en": "Parquet layer"
        },
        {
            "id": "667",
            "name_de": "Aufzugsbau",
            "name_en": "Passenger lift installation"
        },
        {
            "id": "8",
            "name_de": "Bauphysik",
            "name_en": "Physics relating to construction"
        },
        {
            "id": "69",
            "name_de": "Stuckateur",
            "name_en": "Plasterer"
        },
        {
            "id": "130",
            "name_de": "Putzer/in",
            "name_en": "Plasterer"
        },
        {
            "id": "143",
            "name_de": "Stuckateur/in",
            "name_en": "Plasterer"
        },
        {
            "id": "45",
            "name_de": "Klempner",
            "name_en": "Plumber"
        },
        {
            "id": "121",
            "name_de": "Klempner/in",
            "name_en": "Plumber"
        },
        {
            "id": "132",
            "name_de": "Sanitär-Installateur/in",
            "name_en": "Plumbing installer"
        },
        {
            "id": "63",
            "name_de": "Spenglerarbeiten",
            "name_en": "Plumbing work"
        },
        {
            "id": "10",
            "name_de": "Bautenschutz",
            "name_en": "Preservation of construction"
        },
        {
            "id": "42",
            "name_de": "Kältetechnik",
            "name_en": "Refrigeration"
        },
        {
            "id": "118",
            "name_de": "Kältetechniker/in",
            "name_en": "Refrigeration technician"
        },
        {
            "id": "93",
            "name_de": "Betonstahlbieger und -flechter/in",
            "name_en": "Reinforcing steel benders and braiders"
        },
        {
            "id": "142",
            "name_de": "Straßenbauer/in",
            "name_en": "Road builder"
        },
        {
            "id": "68",
            "name_de": "Straßenbau",
            "name_en": "Road construction"
        },
        {
            "id": "131",
            "name_de": "Rolladenbauer/in",
            "name_en": "Roller shutter builder"
        },
        {
            "id": "55",
            "name_de": "Rolladenbau",
            "name_en": "Roller shutter construction"
        },
        {
            "id": "99",
            "name_de": "Dachdecker/in",
            "name_en": "Roofer"
        },
        {
            "id": "20",
            "name_de": "Dachdecker / Dachdichtung",
            "name_en": "Roofing work / roof sealing "
        },
        {
            "id": "56",
            "name_de": "Sanitär",
            "name_en": "Sanitary"
        },
        {
            "id": "114",
            "name_de": "Gerüstbauer/in",
            "name_en": "Scaffolder"
        },
        {
            "id": "36",
            "name_de": "Gerüstbau",
            "name_en": "Scaffolding"
        },
        {
            "id": "107",
            "name_de": "Estrichleger/in",
            "name_en": "Screed layer"
        },
        {
            "id": "28",
            "name_de": "Estrichleger",
            "name_en": "Screed work"
        },
        {
            "id": "61",
            "name_de": "Sicherheitsbeleuchtung",
            "name_en": "Security lighting"
        },
        {
            "id": "44",
            "name_de": "Kläranlagen",
            "name_en": "Sewage treatment plants"
        },
        {
            "id": "120",
            "name_de": "Kläranlagen-Techniker/in",
            "name_en": "Sewage treatment plants technician"
        },
        {
            "id": "104",
            "name_de": "Kanalbau-Monteur/in",
            "name_en": "Sewer construction fitter"
        },
        {
            "id": "74",
            "name_de": "Verbauarbeiten",
            "name_en": "Sheeting work"
        },
        {
            "id": "148",
            "name_de": "Verbauarbeiten",
            "name_en": "Sheeting work"
        },
        {
            "id": "64",
            "name_de": "Spezialtiefbau",
            "name_en": "Special civil engineering"
        },
        {
            "id": "85",
            "name_de": "Artenschutz-Gutachter/in",
            "name_en": "Species protection expert"
        },
        {
            "id": "146",
            "name_de": "Treppenbauer/in",
            "name_en": "Stair builder"
        },
        {
            "id": "72",
            "name_de": "Treppenbau",
            "name_en": "Stair construction"
        },
        {
            "id": "65",
            "name_de": "Stahlbau",
            "name_en": "Steel construction"
        },
        {
            "id": "139",
            "name_de": "Stahlbauer/in",
            "name_en": "Steel constructor"
        },
        {
            "id": "67",
            "name_de": "Steinmetzarbeiten",
            "name_en": "Stone carvings"
        },
        {
            "id": "141",
            "name_de": "Steinmetz/in",
            "name_en": "Stonemason"
        },
        {
            "id": "66",
            "name_de": "Statiker",
            "name_en": "Structural engineer"
        },
        {
            "id": "71",
            "name_de": "Tragwerksplanung",
            "name_en": "Structural engineer"
        },
        {
            "id": "140",
            "name_de": "Statiker/in",
            "name_en": "Structural engineer"
        },
        {
            "id": "145",
            "name_de": "Tragwerksplaner/in",
            "name_en": "Structural engineer"
        },
        {
            "id": "138",
            "name_de": "Sonnenschutzanlagen-Techniker/in",
            "name_en": "Sun protection system technician"
        },
        {
            "id": "62",
            "name_de": "Sonnenschutzanlagen",
            "name_en": "Sun protection systems"
        },
        {
            "id": "136",
            "name_de": "Schwimmbadbauer/in",
            "name_en": "Swimming pool builder"
        },
        {
            "id": "60",
            "name_de": "Schwimmbadbau",
            "name_en": "Swimming pool construction"
        },
        {
            "id": "54",
            "name_de": "Prüfer",
            "name_en": "Tester"
        },
        {
            "id": "76",
            "name_de": "Wärmedämmung",
            "name_en": "Thermal insulation"
        },
        {
            "id": "77",
            "name_de": "Wärmedämmverbundsystem",
            "name_en": "Thermal insulation composite system"
        },
        {
            "id": "150",
            "name_de": "Wärmedämm-Techniker/in",
            "name_en": "Thermal insulation technician"
        },
        {
            "id": "32",
            "name_de": "Fliesenleger",
            "name_en": "Tiler"
        },
        {
            "id": "111",
            "name_de": "Fliesenleger/in",
            "name_en": "Tiler"
        },
        {
            "id": "39",
            "name_de": "Holzbau",
            "name_en": "Timber construction"
        },
        {
            "id": "47",
            "name_de": "Lüftungsbau",
            "name_en": "Ventilation construction"
        },
        {
            "id": "123",
            "name_de": "Lüftungsbauer/in",
            "name_en": "Ventilation construction technician"
        },
        {
            "id": "3",
            "name_de": "Abfallentsorgung",
            "name_en": "Waste disposal"
        },
        {
            "id": "2",
            "name_de": "Abdichtungsarbeiten",
            "name_en": "Waterproofing"
        },
        {
            "id": "98",
            "name_de": "Brunnenbauer/in",
            "name_en": "Well builder"
        },
        {
            "id": "19",
            "name_de": "Brunnenbau",
            "name_en": "Well construction work"
        },
        {
            "id": "109",
            "name_de": "Fenstermonteur/in",
            "name_en": "Window fitter"
        },
        {
            "id": "30",
            "name_de": "Fensterbau",
            "name_en": "Window work"
        },
        {
            "id": "151",
            "name_de": "Wintergartenbauer/in",
            "name_en": "Winter garden builder"
        },
        {
            "id": "78",
            "name_de": "Wintergärten",
            "name_en": "Winter gardens"
        },
        {
            "id": "40",
            "name_de": "Holzschutz",
            "name_en": "Wood protection"
        },
        {
            "id": "117",
            "name_de": "Holzschutz-Techniker/in",
            "name_en": "Wood protection technician"
        }
        ]

    },
    {
        id: 4,
        name_de: "Ingenieur",
        name_en: "Engineer",
        branches: [
            {
                "id": "1",
                "name_de": "Akustik-Ingenieur/in",
                "name_en": "Acoustic engineer"
            },
            {
                "id": "2",
                "name_de": "Akustik-Ingenieurbüro",
                "name_en": "Acoustics engineering office"
            },
            {
                "id": "3",
                "name_de": "Gutachter/in",
                "name_en": "Appraiser"
            },
            {
                "id": "4",
                "name_de": "Architekt/in",
                "name_en": "Architect"
            },
            {
                "id": "5",
                "name_de": "Architekturbüro",
                "name_en": "Architecture office"
            },
            {
                "id": "666",
                "name_de": "BIM-Koordinator",
                "name_en": "BIM-coordinator"
            },
            {
                "id": "7",
                "name_de": "BIM-Koordinator/in",
                "name_en": "BIM-coordinator"
            },
            {
                "id": "8",
                "name_de": "DGNB-Auditor/in",
                "name_en": "Building certification auditor"
            },
            {
                "id": "9",
                "name_de": "DGNB-Consultant",
                "name_en": "Building certification consultant"
            },
            {
                "id": "10",
                "name_de": "Haustechnik-Ingenieur/in",
                "name_en": "Building services engineer"
            },
            {
                "id": "11",
                "name_de": "Haustechnik-Ingenieurbüro",
                "name_en": "Building services engineering office"
            },
            {
                "id": "12",
                "name_de": "Bauingenieur/in",
                "name_en": "Civil engineer"
            },
            {
                "id": "13",
                "name_de": "Tiefbau-Ingenieur/in",
                "name_en": "Civil engineer"
            },
            {
                "id": "14",
                "name_de": "Tiefbau-Ingenieurbüro",
                "name_en": "Civil engineering company"
            },
            {
                "id": "15",
                "name_de": "Datentechnik",
                "name_en": "Data technology"
            },
            {
                "id": "16",
                "name_de": "Elektro-Ingenieur/in",
                "name_en": "Electrical engineer"
            },
            {
                "id": "17",
                "name_de": "Elektro-Ingenieurbüro",
                "name_en": "Electrical engineering office"
            },
            {
                "id": "18",
                "name_de": "Ingenieurbüro",
                "name_en": "Engineering office"
            },
            {
                "id": "19",
                "name_de": "Sachverständiger/in",
                "name_en": "Expert"
            },
            {
                "id": "20",
                "name_de": "Sachverständigen-Büro",
                "name_en": "Expert office"
            },
            {
                "id": "21",
                "name_de": "Brandmeldeanlagen-Ingenieur/in",
                "name_en": "Fire alarm system engineer"
            },
            {
                "id": "22",
                "name_de": "Brandschutz-Ingenieur/in",
                "name_en": "Fire protection engineer"
            },
            {
                "id": "23",
                "name_de": "Brandschutz-Ingenieurbüro",
                "name_en": "Fire protection engineering office"
            },
            {
                "id": "24",
                "name_de": "Behindertengerechtes Bauen",
                "name_en": "Handicapped accessible building"
            },
            {
                "id": "25",
                "name_de": "Gartenbau-Ingenieurbüro",
                "name_en": "Horticultural engineering company"
            },
            {
                "id": "26",
                "name_de": "Innenarchitekt/in",
                "name_en": "Interior architect"
            },
            {
                "id": "27",
                "name_de": "Innenarchitektur",
                "name_en": "Interior design"
            },
            {
                "id": "28",
                "name_de": "Blitzschutz-Ingenieur/in",
                "name_en": "Lightning protection engineer"
            },
            {
                "id": "29",
                "name_de": "Prozessmanagement",
                "name_en": "Process management"
            },
            {
                "id": "30",
                "name_de": "Projektsteuerung",
                "name_en": "Project management"
            },
            {
                "id": "31",
                "name_de": "Straßenbau-Ingenieur/in",
                "name_en": "Road construction engineer"
            },
            {
                "id": "32",
                "name_de": "Straßenbau-Ingenieurbüro",
                "name_en": "Road construction engineering office"
            },
            {
                "id": "33",
                "name_de": "SiGeKo",
                "name_en": "Safety and health coordinator"
            },
            {
                "id": "34",
                "name_de": "SiGeKo-Ingenieur/in",
                "name_en": "Safety and health coordinator"
            },
            {
                "id": "35",
                "name_de": "Kläranlagen-Ingenieur/in",
                "name_en": "Sewage plant engineer"
            },
            {
                "id": "36",
                "name_de": "Bodengutachter/in",
                "name_en": "Soil surveyor"
            },
            {
                "id": "37",
                "name_de": "Spezialtiefbau-Ingenieur/in",
                "name_en": "Special civil engineering engineer"
            },
            {
                "id": "38",
                "name_de": "Spezialtiefbau-Ingenieurbüro",
                "name_en": "Special civil engineering office"
            },
            {
                "id": "39",
                "name_de": "Artenschutz",
                "name_en": "Species protection"
            },
            {
                "id": "40",
                "name_de": "Vermessungs-Ingenieurbüro",
                "name_en": "Surveying engineering office"
            },
            {
                "id": "41",
                "name_de": "Vermessungs-Ingenieur/in",
                "name_en": "Surveyor"
            },
            {
                "id": "42",
                "name_de": "TGA-Ingenieurbüro",
                "name_en": "Technical building equipment engineer"
            },
            {
                "id": "43",
                "name_de": "Prüfstatiker/in",
                "name_en": "Test engineer"
            },
            {
                "id": "44",
                "name_de": "Prüfstatik-Ingenieurbüro",
                "name_en": "Test statics engineering office"
            },
            {
                "id": "45",
                "name_de": "Prüf-Ingenieur/in",
                "name_en": "Testing engineer"
            },
            {
                "id": "46",
                "name_de": "Holzbau-Ingenieur/in",
                "name_en": "Timber construction engineer"
            },
            {
                "id": "47",
                "name_de": "Holzbau-Ingenieurbüro",
                "name_en": "Timber engineering office"
            },
            {
                "id": "48",
                "name_de": "Holzschutz-Ingenieur/in",
                "name_en": "Wood protection engineer"
            }
        ]
    },
    {
        id: 5,
        name_de: "Immobiliensektor",
        name_en: "Real-estate sector",
        branches: [
            {
                "id": "123",
                "name_de": "Immobilienbüro",
                "name_en": "Real estate agency"
            },
            {
                "id": "124",
                "name_de": "Maklerbüro",
                "name_en": "Estate agent"
            },
            {
                "id": "100",
                "name_de": "Immobilienkaufmann/-frau",
                "name_en": "Real estate agent"
            },
            {
                "id": "101",
                "name_de": "Makler/in",
                "name_en": "Estate agent"
            }
        ]
    },
    {
        id: 6,
        name_de: "Lieferant",
        name_en: "Supplier",
        branches: [
            {
                id: '129',
                name_de: 'Elektrogroßhandel',
                name_en: 'Electrical wholesale',
            },
            {
                "id": "126",
                "name_de": "Spedition",
                "name_en": "Carrier"
            },
            {
                "id": "127",
                "name_de": "Großhandel",
                "name_en": "Wholesale"
            },
            {
                "id": "128",
                "name_de": "Einzelhandel",
                "name_en": "Retail trade"
            },
            {
                "id": "103",
                "name_de": "Spediteur/in",
                "name_en": "Freight forwarder"
            }
        ]
    },
    {
        idL: 7,
        name_de: "Andere",
        name_en: "Others",
        branches: [
            {
                "id": "130",
                "name_de": "Sonstiges",
                "name_en": "Others"
            },
            {
                "id": "131",
                "name_de": "Fotostudio",
                "name_en": "Photography studio"
            },
            {
                "id": "132",
                "name_de": "Designstudio",
                "name_en": "Design studio"
            },
            {
                "id": "133",
                "name_de": "Agentur",
                "name_en": "Agency"
            },
            {
                "id": "134",
                "name_de": "Marktforschung",
                "name_en": "Market research"
            },
            {
                "id": "135",
                "name_de": "Bank",
                "name_en": "Bank"
            },
            {
                "id": "136",
                "name_de": "Finanzbranche",
                "name_en": "Financial sector"
            },
            {
                "id": "137",
                "name_de": "Software Planung und Bau",
                "name_en": "Software planning and construction"
            },
            {
                "id": "138",
                "name_de": "Versicherer",
                "name_en": "Insurer"
            },
            {
                "id": "106",
                "name_de": "Fotograf/in",
                "name_en": "Photographer"
            },
            {
                "id": "107",
                "name_de": "Designer/in",
                "name_en": "Designer"
            },
            {
                "id": "108",
                "name_de": "3D Designer/in",
                "name_en": "3D Designer"
            },
            {
                "id": "109",
                "name_de": "Marktforschung",
                "name_en": "Market research"
            },
            {
                "id": "110",
                "name_de": "Politiker/in",
                "name_en": "Politician"
            },
            {
                "id": "111",
                "name_de": "Programmierer/in",
                "name_en": "Programmer"
            },
            {
                "id": "112",
                "name_de": "Öffentliche Hand",
                "name_en": "Public administration"
            },
            {
                "id": "113",
                "name_de": "Investor/in",
                "name_en": "Investor"
            },
            {
                "id": "114",
                "name_de": "Finanzkaufmann/-frau",
                "name_en": "Financial clerk"
            },
            {
                "id": "115",
                "name_de": "Bauherr",
                "name_en": "Client"
            },
            {
                "id": "116",
                "name_de": "Bankkaufmann/-frau",
                "name_en": "Bank clerk"
            },
            {
                "id": "117",
                "name_de": "Groß- und Einzelhandelskaufmann/-frau",
                "name_en": "Wholesaler and retailer"
            },
            {
                "id": "118",
                "name_de": "Verwaltung",
                "name_en": "Administration"
            },
            {
                "id": "119",
                "name_de": "Versicherungskaufmann/-frau",
                "name_en": "Insurance salesman"
            }
        ]
    }

]
