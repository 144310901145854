import React, {useEffect} from 'react'
import $ from "jquery"

function Home() {

    useEffect(() => {
        $('.App').css('backgroundImage', 'none')
    }, [])

    return <div className="row w-100 justify-content-center align-items-center">
        <div className="col-lg-6">
            <div className="text-center">
                <img src={process.env.PUBLIC_URL + "/assets/img/error-image.png"}/>
            </div>
            <div className="text-center p-3">
                <h1>Aaaah! Something went wrong...</h1>
                <h5 className="text-muted">Sorry, something went wrong there. Try again</h5>
            </div>
        </div>
    </div>;

}

export default Home;
