import { replaceMentionValues } from "./replaceUsernames";

export const getUsernameFromText = (text) => {
  if (text) {
    const replacedText = replaceMentionValues(
      replaceMentionValues(text, ({ name }) => `<strong>@${name}</strong>`),
      ({ id }) => `${""}`
    );
    return replacedText;
  }
  return text;
};

export const onlyUsername = (text) => {
  const replacedText = replaceMentionValues(
    replaceMentionValues(text, ({ name }) => `@${name}`),
    ({ id }) => `${""}`
  );

  return replacedText;
};
