import ProgressBar from "@ramonak/react-progress-bar";
import React from "react";
import { KJUUP_COLORS } from "../../helper/constants";
import moment from "moment";
import { AiOutlineFile } from "react-icons/ai";

const DocsSection = ({
  handleScroll,
  media,
  props,
  isSelect,
  setDoc,
  setViewDoc,
  _onCheckBoxPress,
  isChat,
}) => {
  return (
    <div
      onScroll={handleScroll}
      className="wscroll right_wscroll"
      style={{ flex: 1 }}
    >
      <div className="docs-content mt-1" style={{ marginRight: 5 }}>
        {media?.map((file, index) => (
          <div className="row" key={index}>
            {file?.items?.length > 0 ?

              <div className="col-12 text-center">
                <div className="date_chat media_date">
                  <p
                    style={{
                      color: props.isInternal
                        ? KJUUP_COLORS.internal
                        : KJUUP_COLORS.base_color,
                    }}
                  >
                    {moment(file.createdAt).format("MMMM Y")}
                  </p>
                </div>
              </div>
              :
              <></>
            }
            {file?.items?.map(
              (item, itemIndex) =>
                item.messageType === "doc" && (
                  <div
                    onClick={() => {
                      if (!isSelect) {
                        setDoc(item);
                        setViewDoc(true);
                      }
                    }}
                    key={itemIndex}
                    className="col-12 px-2"
                    style={{ marginBottom: 5 }}
                  >
                    <div
                      className="theme-color pointer p-2"
                      style={{ background: "#f1f1f1", marginRight: 5 }}
                    >
                      <AiOutlineFile /> {item.attachedFileName}
                    </div>
                    {isSelect ? (
                      !item.isDownloading ? (
                        <div
                          style={{
                            bottom: 0,
                            top: 0,
                            left: 0,
                            right: 10,
                            backgroundColor: "rgba(0,0,0,0.3)",
                            zIndex: 10,
                            position: "absolute",
                            verticalAlign: "end",
                            textAlign: "center",
                            // borderRadius: 10, overflow: "hidden"
                          }}
                          className={"abs_div"}
                        >
                          <label
                            className={
                              props.isInternal
                                ? "role-label-internal"
                                : "role-label"
                            }
                            style={
                              isChat ? { zIndex: 10 } : { right: -5, top: -5 }
                            }
                          >
                            <input
                              onClick={(e) =>
                                _onCheckBoxPress(
                                  e.target.value,
                                  itemIndex,
                                  index
                                )
                              }
                              value={item.isSelect}
                              type="checkbox"
                            />
                            <span
                              className={
                                props.isInternal
                                  ? "checkmark-label-internal"
                                  : "checkmark-label"
                              }
                            />
                          </label>
                        </div>
                      ) : (
                        <div
                          style={{
                            bottom: 0,
                            top: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "rgba(0,0,0,0.3)",
                            zIndex: 10,
                            position: "absolute",
                          }}
                        >
                          <ProgressBar
                            margin="5px"
                            bgColor={
                              props.isInternal
                                ? KJUUP_COLORS.internal
                                : KJUUP_COLORS.base_color
                            }
                            completed={item.progress}
                            height={"50%"}
                            width={"100%"}
                          />
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                )
            )}
          </div>
        ))}
        {media.length <= 0 && (
          <div className="no_media">
            <img
              width={90}
              src={
                process.env.PUBLIC_URL +
                (props.isInternal
                  ? "/assets/img/NoDocs-internal.svg"
                  : "/assets/img/NoDocs.svg")
              }
              alt="Generic placeholder"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocsSection;
