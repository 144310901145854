import React, { useState } from 'react';

const ImageWithFallback = ({ src, alt, fallbackSrc, ...props }) => {
    const [imgSrc, setImgSrc] = useState(src);
    const handleError = () => {
        setImgSrc(fallbackSrc);
    };

    return (
        <img
            src={imgSrc}
            alt={alt}
            onError={handleError}
            {...props}
        />
    );
};

// Usage Example
const ImageWithPlaceholder = ({ src, style, className, width, height, fallbackSrc }) => {
    return (
        <div>
            <ImageWithFallback
                src={src}
                fallbackSrc={fallbackSrc}
                width={height}
                height={width}
                className={className}
                style={style}
            />
        </div>
    );
};

export default ImageWithPlaceholder;
