export const Languages = [
    {
        lng: 'bs',
        name: 'Bosnien'
    },
    {
        lng: 'bg',
        name: 'Bulgarian'
    },
    {
        lng: 'zh',
        name: 'Chinese'
    },
    {
        lng: 'hr',
        name: 'Croatian'
    },
    {
        lng: 'cs',
        name: 'Czech'
    },
    {
        lng: 'en',
        name: 'English'
    },
    {
        lng: 'fr',
        name: 'French'
    },
    {
        lng: 'de',
        name: 'German'
    },
    {
        lng: 'el',
        name: 'Greek'
    },
    {
        lng: 'hu',
        name: 'Hungarian'
    },
    {
        lng: 'en',
        name: 'Indien'
    },
    {
        lng: 'it',
        name: 'Italien'
    },
    {
        lng: 'lt',
        name: 'Lithuania'
    },
    {
        lng: 'mk',
        name: 'Macedonian'
    },
    {
        lng: 'pl',
        name: 'Polish'
    },
    {
        lng: 'pt',
        name: 'Portuguese'
    },
    {
        lng: 'ro',
        name: 'Romanian'
    },
    {
        lng: 'ru',
        name: 'Russian'
    },
    {
        lng: 'sr',
        name: 'Serbien'
    },
    {
        lng: 'sl',
        name: 'Slovenian'
    },
    {
        lng: 'sr',
        name: 'Serbien'
    },
    {
        lng: 'sl',
        name: 'Slovenian'
    },
    {
        lng: 'es',
        name: 'Spanish'
    },
    {
        lng: 'tr',
        name: 'Turkish'
    },
]
