import {useSelector} from "react-redux";
import {callAPI} from "./apiUtils";
import {RESET, SET_PROFILE, SET_USER, store} from "../store";
import {$crud} from "./CrudFactory";

export class UserFactory {

    constructor() {
        const user = this.get();
        store.dispatch({
            type: SET_USER,
            user: user
        })
    }

    async login(params) {
        let resData = {
            status: false,
            message: ''
        }
        await $crud.post('/auth/login', params).then(data => {
            console.log(data);
            if (data.status) {
                this.setToken(data.token);
                this.set(data);
                this.getProfile();
            }
            resData = data
        }).catch(err => {
            console.log(err)
            resData = err
        });
        return resData
    }

    async logout() {
        this.removeToken();
        this.remove();
        store.dispatch({
            type: RESET
        })
    }

    async current() {
        return this.get();
    }

    setToken(token) {
        localStorage.setItem("token", token);
    }

    remove() {
        localStorage.removeItem("profile");
        localStorage.removeItem("user");
        localStorage.removeItem("badge");
        store.dispatch({
            type: SET_USER,
            user: false
        })
    }

    removeToken() {
        localStorage.removeItem("token");
    }

    getToken() {
        return localStorage.getItem("token");
    }

    async updateUser(data) {
        localStorage.setItem("user", JSON.stringify(data));
    }

    async set({user, badge}) {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("badge ", JSON.stringify(badge));
        store.dispatch({
            type: SET_USER,
            user: user
        })
    }

    async getProfile() {
        const {data} = await callAPI('/user/profile', {}, 'GET')
        if (data.status) {
            this.setProfile(data)
        }
    }

    async setProfile({profile}) {
        localStorage.setItem("profile", JSON.stringify(profile));
        store.dispatch({
            type: SET_PROFILE,
            profile: profile
        })
    }

    async updateUserData(user) {
        localStorage.setItem("user", JSON.stringify(user));
        store.dispatch({
            type: SET_USER,
            user: user
        })
    }

    get() {
        try {
            return JSON.parse(localStorage.getItem("user"));
        } catch (e) {
            console.warn(e);
            return null;
        }
    }

    getUserProfile() {
        try {
            return JSON.parse(localStorage.getItem("profile"));
        } catch (e) {
            console.warn(e);
            return null;
        }
    }
}

export const $user = new UserFactory();

export function useCurrentUser() {
    return useSelector(state => state.user);
}

export function useCurrentProfile() {
    return useSelector(state => state.profile)
}

export function useCurrentUserData() {
    return $user.get();
}

export function useCurrentUserProfileData() {
    return $user.getUserProfile();
}

