import React from 'react';
import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import {Form} from 'react-bootstrap';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {DE} from '../Languages/DE';
import {EN} from '../Languages/EN';
import LanguageContext from '../helper/LanguageContext';

class MapContainer extends React.Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            address: '',
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},

            mapCenter: {
                lat: 28.626440,
                lng: 77.394814
            }
        }
    }

    onMarkerClick = (props, marker, e) => {

        console.log('sdfsfds')
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }
    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    handleChange = address => {
        this.setState({address: address})
    };

    handleSelect = address => {
        this.setState({address: address})
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);

                // update center state
                this.setState({mapCenter: latLng});
            })
            .catch(error => console.error('Error', error));
    };

    render() {
        return (
            <React.Fragment>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                >
                    {
                        ({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                            <div>
                                <Form.Control
                                    {...getInputProps({
                                        placeholder: this.context ? DE.search : EN.search,
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                            : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    }
                </PlacesAutocomplete>
                <Map
                    google={this.props.google}
                    initialCenter={{
                        lat: this.state.mapCenter,
                        lng: this.state.mapCenter
                    }}
                    center={{
                        lat: this.state.mapCenter,
                        lng: this.state.mapCenter
                    }}
                >
                    {
                        this.props.companies.map((marker, index) => (
                            <Marker key={index} lat={marker.lat} lng={marker.long} onClick={e => this.onMarkerClick}
                                    name={'Current location'}/>
                        ))
                    }

                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}>
                        <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow>
                </Map>
            </React.Fragment>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q"),
})(MapContainer)