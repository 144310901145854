export const corporateForms=[
            {
                "id": "1",
                "name_de": "Einzelunternehmen",
                "name_en": "Sole Proprietorship"
            },
            {
                "id": "2",
                "name_de": "GbR",
                "name_en": "GbR"
            },
            {
                "id": "3",
                "name_de": "GmbH",
                "name_en": "GmbH"
            },
            {
                "id": "4",
                "name_de": "GmbH & Co. KG",
                "name_en": "GmbH & Co. KG"
            },
            {
                "id": "5",
                "name_de": "KG",
                "name_en": "KG"
            },
            {
                "id": "6",
                "name_de": "OGH",
                "name_en": "OGH"
            },
            {
                "id": "7",
                "name_de": "AG",
                "name_en": "AG"
            },
            {
                "id": "8",
                "name_de": "UG",
                "name_en": "UG"
            },
            {
                "id": "9",
                "name_de": "EG",
                "name_en": "EG"
            },
            {
                "id": "10",
                "name_de": "PartGmbB",
                "name_en": "PartGmbB"
            },
            {
                "id": "11",
                "name_de": "PartG",
                "name_en": "PartG"
            },
            {
                "id": "12",
                "name_de": "Andere",
                "name_en": "Others"
            }
]