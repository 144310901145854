import React from 'react';
import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';

class CreateArticle extends React.Component {
    static contextType = LanguageContext;
    
    render() {
       
        return (
            <React.Fragment>
                <Layout>
                    <div class="w-300 following articles">
                        <div className="main_heading">
                            <Link to="/profile">
                                <img width={10}
                                     src={process.env.PUBLIC_URL + "/assets/img/back.svg"}/>
                                <span className="theme-color">{this.context.isGerm ? DE.cancel : EN.cancel }</span>
                            </Link>
                        </div>
                        {/* <div className="post_notification">
                            <div className="my_post">
                                <Link to="/add-personal-article">
                                    <img style={{color:"#f08438"}} src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"}/>{this.context.isGerm ? DE.personal_article : EN.personal_article }
                                </Link>
                            </div>
                        </div> */}
                        <div className="post_notification">
                            <div className="my_post">
                                <Link to="/companies">
                                    <img color="#f08438" src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"}/> {this.context.isGerm ? DE.comapny_article : EN.company_article } 
                                    </Link>
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default CreateArticle;