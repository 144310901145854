import React, {useEffect, useState} from 'react';
import {Form, Media} from 'react-bootstrap';
import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
import {callAPI} from "../../helper/apiUtils";
import {BASE_URL} from "../../helper/constants";
import LanguageContext from '../../helper/LanguageContext';
import { convertBranchRole } from '../../helper/convertor';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { Scrollbars } from "react-custom-scrollbars";
const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "rgba(240, 132, 56, 0.8)",
    };
    return (
      <div
        className="thumb-style"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };
  const CustomScrollbars = (props) => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );
function Followings(props) {
    const {isGerm} = React.useContext(LanguageContext);
    const [followings, setFollowings] = useState([])
    const [allFollowing,setAllFollowing]=useState([])
    const [search,setSearch]=useState("")
    const getFollowings = async () => {
        const id = props.match.params.id;
        const {data} = await callAPI(`/user/${id}/following`, {}, 'GET');
        setAllFollowing(data.followings)
        setFollowings(data.followings)
    }
    useEffect(() => {
        getFollowings();
    }, [])
    
    const backToUrl = () => {
        window.history.go(-1);
        return false;
    } 
    const searchFollowing = async (value) => {
        setSearch(value);
        const searchResult = await allFollowing?.filter((itm) => {
          if (value === "") {
            return itm;
          } else if (itm?.firstName?.toLowerCase().startsWith(value?.toLowerCase()) || itm?.lastName?.toLowerCase().startsWith(value?.toLowerCase()) ||  (itm?.isCompany && 
            itm?.fullName?.split(" ")?.find(itm=>itm?.toLowerCase()?.startsWith(value?.toLowerCase())))) {
            return itm;
          }
        });
        if (value === "") {
          setFollowings(allFollowing);
        } else {
          setFollowings(searchResult);
        }
      };
    return (
        <React.Fragment>
            <Layout>
               { followings && <div className="w-300 following">
                    <div className="main_heading">
                        <img width={10} onClick={backToUrl}
                                                 src={process.env.PUBLIC_URL + "/assets/img/back.svg"}/>
                        {isGerm?DE.followings:EN.followings}
                    </div>
                    <div className="search">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      autocomplete="off"
                      onChange={(e) => searchFollowing(e.target.value)}
                      value={search}
                      type="search"
                      placeholder={isGerm ? DE.search : EN.search}
                    />
                  </Form.Group>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/search.svg"}
                    alt="Generic placeholder"
                  />
          </div>
          <div className="my-scroll min_height_followers_following contact_height">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                className="bottom-bar"
              >
                    {
                        
                        followings &&
                        followings.map(following => (
                            <div key={following.id} className="post_notification">
                                <div className="my_post">
                                    <Media>
                                        <img
                                            width={40}
                                            height={40}
                                            className="mr-3"
                                            src={following.avatar ? BASE_URL + following.avatar : process.env.PUBLIC_URL + "/assets/img/head.svg"}
                                            alt="Generic placeholder"
                                        />
                                        <Media.Body>
                                            <div className="authore_name">
                                                <Link to={`/user-profile/${following.id}`}><h5>{following.fullName}</h5>
                                                </Link>
                                                <p>{following.buildingIndustryRole?.split(",").map((itm,index)=>(index ? ", " : "")+convertBranchRole(itm,isGerm))}</p>
                                            </div>
                                        </Media.Body>
                                    </Media>
                                </div>
                            </div>
                        ))
                    }
                </CustomScrollbars>
                </div>
                </div>}
            </Layout>
        </React.Fragment>
    );
}

export default Followings;