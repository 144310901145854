import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Layout from "../../components/Layout";
import LanguageContext from '../../helper/LanguageContext';
import {Animated} from "react-animated-css";
import * as moment from "moment";
import { goBack} from "../../helper/constants";
import Button from "@material-ui/core/Button";
import {EN} from '../../Languages/EN';
import {DE} from '../../Languages/DE';
import { convertMonths } from '../../helper/convertor';


function WeeklyStatistics(props) {
    const {isGerm} = React.useContext(LanguageContext);
    const [visible, setVisible] = useState(true);
    const [news, setNews] = useState({});
    const handleBack = () => {
        setVisible(false);
        setTimeout(() => {
            goBack();
        }, 1000)
    };
    useEffect(() => {
        const {news} = props.location.state;
        console.log(news);
        setNews(news)
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <Animated animationIn="slideInLeft" animationOutDuration={800} animationOut="slideOutLeft" isVisible={visible}>
                    <div className="w-300 capacities_content-page contact_page pb-0">
                        <h5 className="pb-2">
                            <img className="pointer" onClick={handleBack} width={10} src={process.env.PUBLIC_URL + "assets/img/back.svg"}
                                 alt="Generic placeholder"/> {isGerm ? DE.weekly_statistics : EN.weekly_statistics}</h5>
                        <div className="my-scroll min_height">
                            <div className="row m-0">
                                <div className="col-12">
                                    <h2 className="m-0">{news.count}</h2>
                                </div>
                                <div className="col-12">
                                    <p className="m-0">{isGerm ? DE.times_you_have_appeared_in_searches : EN.times_you_have_appeared_in_searches}</p>
                                    <p className="m-0">({convertMonths(news.fromDate,isGerm)} - {convertMonths(news.date,isGerm)})</p>
                                </div>
                                <div className="col-12 mt-4 text-center">
                                    <img
                                        width={50}
                                        height={50}
                                        className="mr-3"
                                        src={process.env.PUBLIC_URL + "/assets/img/search.svg"}
                                        alt="Generic placeholder"
                                    />
                                    <p className="font-weight-bold">{isGerm ? DE.appear_more_often_in_searches : EN.appear_more_often_in_searches}</p>
                                    <p>
                                        {isGerm ? DE.you_have_been_found : EN.you_have_been_found} {news.count} {isGerm ? DE.times_in_searches_in_the_last_week : EN.times_in_searches_in_the_last_week}
                                    </p>
                                </div>
                                <div className="col-12 text-center" style={{marginTop: '6rem'}}>
                                    <Link to="/profile"><Button variant="contained" className="theme-color">{isGerm ? DE.improve_profile : EN.improve_profile}</Button></Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </Animated>
            </Layout>
        </React.Fragment>
    );
}

export default withRouter(WeeklyStatistics);
