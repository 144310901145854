import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import { callAPI } from "../../helper/apiUtils";
import Tabs from "@material-ui/core/Tabs";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import { Modal } from "react-bootstrap";
import DocViewer from "./DocViewer";
import _ from "lodash";
import * as moment from "moment";
import axios from "axios";
import "react-circular-progressbar/dist/styles.css";
import ImageSection from "../../components/mediaSection/ImageSection";
import LinkSection from "../../components/mediaSection/LinkSection";
import DocsSection from "../../components/mediaSection/DocsSection";

export default function MediaDocs(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [value, setValue] = React.useState(0);
  const [media, setMedia] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState("");
  const [viewDoc, setViewDoc] = useState(false);
  const [doc, setDoc] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [urls, setUrls] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [SelectedItems, setSelectedItems] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isGettingImages, setIsGettingImages] = useState(false);
  const [isDataOver, setIsDataOver] = useState(false);
  const [page, setPage] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      overflowX: "hidden",
      backgroundColor: "rgba(240, 132, 56, 0.8)",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const handleScroll = (e) => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) <=
      parseInt(e.target.clientHeight);
    if (bottom) {
      if (!isGettingImages && !isDataOver) {
        setPage(page + 1);
        get(page + 1);
      }
    }
  };

  const get = async (page) => {
    setIsGettingImages(true);
    const { data } = await callAPI(
      `/group/${
        props.chat.id
      }/media?viewByCompanyOwner=${false}&page=${page}&limit=30&messageType=image,doc`,
      {},
      "GET"
    );
    // const { data } = await callAPI(`/group/${props.chat.id}/media?messageType=image`, {}, 'GET');
    if (data.status) {
      if (data.media?.length > 0) {
        const grouping = await _.groupBy(data.media, (element) =>
          moment(element.createdAt).format("MMMM, Y")
        );
        const sections = await _.map(grouping, (items, createdAt) => ({
          createdAt: createdAt,
          items: items,
        })).sort((a, b) => {
          const c = new Date(a.createdAt);
          const d = new Date(b.createdAt);
          return d - c;
        });

        if (media?.length === 0) setMedia(sections);
        else {
          setMedia([...media, ...sections]);
        }
        setIsGettingImages(false);
      } else {
        setIsDataOver(true);
        setIsGettingImages(false);
      }
    } else {
      setIsDataOver(true);
      setIsGettingImages(false);
    }
  };

  useEffect(() => {
    get(1);
  }, []);

  useEffect(() => {
    getAllLinks();
  }, []);

  const getAllLinks = async () => {
    let links = [];
    await props.chatHistory.map((chat) => {
      chat.items.map((item) => {
        if (
          new RegExp(
            "(http|ftp|https)://([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-])?"
          ).test(item.message)
        ) {
          const getLink = anchorify(item.message);
          let ch = {
            ...item,
            message: getLink,
          };

          links.push(ch);
        }
        return item;
      });
    });
    const grouping = await _.groupBy(links, (element) =>
      moment(element.createdAt).format("MMMM, Y")
    );
    let sections = await _.map(grouping, (items, createdAt) => ({
      createdAt: createdAt,
      items: items,
    }));
    setUrls(sections);
  };

  function anchorify(string) {
    const urls = string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);
    if (urls) {
      urls.forEach(function (url) {
        string = string.replace(
          url,
          '<a target="_blank" href="' + url + '">' + url + "</a>"
        );
      });
    }
    return string.replace("(", "<br/>(");
  }

  const _downloadFile = async (index) => {
    if (index < SelectedItems.length) {
      setIsDownloading(true);

      let obj = SelectedItems[index];

      axios({
        url: BASE_URL + obj?.fileUrl[0],
        method: "GET",
        responseType: "blob", // important
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          ); // you can use this to show user percentage of file downloaded
          let _tt = [...media];
          for (let objx of _tt) {
            for (let objxx of objx.items) {
              if (obj.id == objxx.id) {
                objxx.isDownloading = true;
                objxx.progress = percentCompleted;
              }
            }
          }
          if (percentCompleted == 100) {
            for (let objx of _tt) {
              for (let objxx of objx.items) {
                if (obj.id == objxx.id) {
                  objxx.isDownloading = false;
                  objxx.progress = 0;
                }
              }
            }
          }
          setMedia([..._tt]);
        },
      })
        .then((response) => {
          let _url = BASE_URL + obj?.fileUrl[0];
          function getFile(filePath) {
            return filePath?.split("/").pop();
          }
          function getoutput() {
            let name = getFile(_url);
            let extension = _url?.split(".").pop();
            if (obj.attachedFileName)
              return (
                obj.attachedFileName.replace(extension, "").replace(".", "") +
                "." +
                extension
              );
            else {
              return name + "." + extension;
            }
          }
          const href = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", getoutput());
          document.body.appendChild(link);
          link.click();
          _downloadFile(index + 1);
        })
        .catch((err) => {
          _downloadFile(index + 1);

          console.log(err);
        });
    } else {
      setIsSelect(false);
      setSelectedItems([]);
      setIsDownloading(false);
    }
  };

  const download = (e) => {
    _downloadFile(0);
  };

  const _onCheckBoxPress = (value, index, mainIndex) => {
    let _tmp = [...media];
    _tmp[mainIndex].items[index].isSelect =
      !_tmp[mainIndex].items[index].isSelect;
    setMedia([..._tmp]);
    for (let obj of _tmp) {
      for (let objx of obj.items) {
        if (objx.isSelect) {
          let ind = SelectedItems.findIndex((e) => e.id == objx.id);
          if (ind == -1) {
            let __ttm = [...SelectedItems];
            __ttm.push(objx);
            setSelectedItems([...__ttm]);
          }
        } else {
          let ind = SelectedItems.findIndex((e) => e.id == objx.id);
          if (ind !== -1) {
            let __ttm = [...SelectedItems];
            __ttm.splice(ind, 1);
            setSelectedItems([...__ttm]);
          }
        }
      }
    }
  };

  return (
    <div
      className="no_bg"
      style={{ height: "93vh", display: "flex", flexDirection: "column" }}
    >
      {!viewDoc && (
        <div
          className="company_section w-300 profile_section border-0 pt-0 media_section chat_user_info border-radius-0 pr-0"
          style={{ display: "flex", flexDirection: "column", flex: 1 }}
        >
          <div className="media_tabbing" style={{}}>
            <div className="heading_icon ML-0">
              <a
                className="main_heading pointer"
                onClick={(e) => {
                  props.closeMediaDoc();
                }}
              >
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
              </a>
              <div>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#f08438",
                    },
                  }}
                  centered
                  fontSize="20px"
                >
                  <Tab label={isGerm ? DE.media : EN.media} />
                  <Tab label={isGerm ? DE.links : EN.links} />
                  <Tab label={isGerm ? DE.docs : EN.docs} />
                </Tabs>
              </div>
            </div>
            <div className="mb-1">
              <div className="select_files img_div mt-1">
                <p
                  onClick={() => {
                    setSelectedItems([]);
                    setIsDownloading(false);
                    setIsSelect(!isSelect);
                    let _tmp = [...media];
                    for (let obj of _tmp) {
                      for (let objx of obj.items) {
                        objx.isSelect = false;
                      }
                    }
                    setMedia([..._tmp]);
                  }}
                >
                  {!isSelect
                    ? isGerm
                      ? DE.select_files
                      : EN.select_files
                    : isGerm
                    ? DE.cancel
                    : EN.cancel}
                </p>
              </div>
              {isSelect && SelectedItems.length > 0 && !isDownloading && (
                <div
                  className="img_div"
                  style={{ textAlign: "end", justifyContent: "center" }}
                >
                  <img
                    width={20}
                    src={process.env.PUBLIC_URL + "/assets/img/download.svg"}
                    style={{}}
                    onClick={() => {
                      if (!isDownloading) {
                        download();
                      }
                    }}
                    color={KJUUP_COLORS.base_color}
                  />
                </div>
              )}
            </div>
          </div>
          <>
            {value === 0 && (
              <ImageSection
                media={media}
                props={props}
                handleScroll={handleScroll}
                setImage={setImage}
                setShowImage={setShowImage}
                _onCheckBoxPress={_onCheckBoxPress}
                isSelect={isSelect}
              />
            )}
            {value === 1 && (
              <LinkSection
                handleScroll={handleScroll}
                urls={urls}
                props={props}
              />
            )}
            {value === 2 && (
              <DocsSection
                handleScroll={handleScroll}
                media={media}
                props={props}
                isSelect={isSelect}
                setDoc={setDoc}
                setViewDoc={setViewDoc}
                _onCheckBoxPress={_onCheckBoxPress}
                isChat={true}
              />
            )}
          </>

          <Modal
            className="profile_image"
            show={showImage}
            onHide={() => {
              setImage("");
              setShowImage(false);
            }}
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="profile_pic_profile">
                <img
                  className="chat-image-preview"
                  src={image}
                  alt="Generic placeholder"
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {viewDoc && (
        <DocViewer chat={doc} handleClose={() => setViewDoc(false)} />
      )}
    </div>
  );
}
