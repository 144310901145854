import * as moment from "moment";
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
export const AgoDateFormat = (time, isGerm) => {
    let type=null
    switch (typeof time) {
        case 'number':
            break;
        case 'string':
            time = +new Date(time);
            break;
        case 'object':
            if (time.constructor === Date) {
                time = time.getTime();
            }
            break;
        default:
            time = +new Date();
    }
    var time_formats = [
        [60, isGerm ? DE.seconds : EN.seconds, 1], // 60
        [120, `1 ${isGerm?DE.minute:EN.minute}`, `1 ${isGerm ? DE.minute : EN.minute}`], // 60*2
        [3600, isGerm ? DE.minute : EN.minute, 60], // 60*60, 60
        [7200, `1 ${isGerm ? DE.hours : EN.hours}`, `1 ${isGerm ? DE.hours : EN.hours}`], // 60*60*2
        [86400, isGerm ? DE.hours : EN.hours, 3600], // 60*60*24, 60*60
        [172800, isGerm ? DE.yesterday : EN.yesterday, 'Tomorrow'], // 60*60*24*2
        [604800, isGerm ? DE.days : EN.days, 86400], // 60*60*24*7, 60*60*24
        [1209600, isGerm ? DE.last_week : EN.last_week, isGerm ? DE.next_week : EN.next_week], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, isGerm ? DE.Last_month : EN.Last_month, 'Next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
        token = type ? 'منذ' : '',
        list_choice = 1;

    if (seconds == 0) {
        return type ? 'الآن' : '';
    }
    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = type ? 'من الان فصاعدا' : '';
        list_choice = 2;
    }
    var i = 0,
        format;
    while ((format = time_formats[i++])) {
        if (seconds < format[0]) {
            if (typeof format[2] === 'string') {
                return format[list_choice];
            } else {
                return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
            }
        }
    }
    return time;
}
