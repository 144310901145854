import React from 'react'
import './RemoveUserFromInfo.css'
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import { KJUUP_COLORS } from '../helper/constants';

function RemoveUserFromInfo({ chatDetails }) {
    const { isGerm } = React.useContext(LanguageContext);
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: '13px', color: chatDetails.isOnline ? KJUUP_COLORS.base_color : 'gray' }}>
            {(isGerm ? DE.remove_user_l : EN.remove_user_l)}
            <img
                src={
                    process.env.PUBLIC_URL +
                    "/assets/img/Remove-chats.svg"
                }
                style={{ width: '15px', height: '15px', marginLeft:5} }
            />
        </div>
    )
}

export default RemoveUserFromInfo