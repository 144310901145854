import React, { useContext, useState } from "react";
import { BASE_URL } from "../../helper/constants";
import { emojiDecode } from "../../utils/encryptDecryptFunction";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";

const EmojiReaction = ({
  userList,
  handleRemoveReaction,
  handleClose,
  isInternal,
  chatMessage,
}) => {
  const { isGerm } = useContext(LanguageContext);
  const me = useSelector(({ user }) => user);
  const [key, setKey] = useState("all");

  const emojiSection = Object.values(
    userList.reduce((result, user) => {
      result[user.emoji] = result[user.emoji] || [];
      result[user.emoji].push(user);
      return result;
    }, {})
  );

  const removeEmoji = (userInfo) => {
    if (userInfo?.chatId) {
      handleRemoveReaction(
        { sender: userInfo?.sender, emoji: userInfo?.emoji },
        userInfo?.chatId
      );
    }
  };

  return (
    <>
      <div
        className={
          userList.length > 0
            ? "message_ready_by tabs_main_div row m-0 p-2"
            : "message_ready_by tabs_main_div row m-0 p-0"
        }
      >
        <div className="close_icon" onClick={() => handleClose()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="50px"
            height="50px"
          >
            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
          </svg>
        </div>
        {userList.length > 0 && (
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} fill>
            <Tab
              eventKey="all"
              title={`${isGerm ? DE.All : EN.All} ${userList?.length}`}
            >
              {userList?.map((user, index) => (
                <div className="" key={user.sender}>
                  <div className="record_flex_div">
                    <div className="record_left_div">
                      <div className="img_box">
                        <img
                          style={{ borderRadius: "50%", height: 34, width: 34 }}
                          src={
                            user.avatar
                              ? BASE_URL + user.avatar
                              : process.env.PUBLIC_URL +
                                "/assets/img/user-circle.png"
                          }
                          alt="user-avatar"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="text-black para_text">
                          {me?.id === user?.sender
                            ? isGerm
                              ? "Du"
                              : EN.you
                            : user.fullName}
                        </div>
                        {me?.id === user?.sender && (
                          <div
                            className="text-gray para_text"
                            onClick={() => removeEmoji(user)}
                          >
                            {isGerm ? DE.removeMsg : EN.removeMsg}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="record_right_div">
                      {emojiDecode(user.emoji)}
                    </div>
                  </div>
                </div>
              ))}
            </Tab>
            {emojiSection.map((user) => (
              <Tab
                eventKey={user?.[0]?.emoji}
                title={`${emojiDecode(user?.[0]?.emoji)} ${user?.length}`}
                style={{ width: "100%" }}
              >
                {user?.map((user, index) => (
                  <div className="" key={user.sender}>
                    <div className="record_flex_div">
                      <div className="record_left_div">
                        <div className="img_box">
                          <img
                            style={{
                              borderRadius: "50%",
                              height: 34,
                              width: 34,
                            }}
                            src={
                              user.avatar
                                ? BASE_URL + user.avatar
                                : process.env.PUBLIC_URL +
                                  "/assets/img/user-circle.png"
                            }
                            alt="user-avatar"
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="text-black para_text">
                            {me?.id === user?.sender
                              ? isGerm
                                ? "Du"
                                : EN.you
                              : user.fullName}
                          </div>
                          {me?.id === user?.sender && (
                            <div
                              className="text-gray para_text"
                              onClick={() => removeEmoji(user)}
                            >
                              {isGerm ? DE.removeMsg : EN.removeMsg}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="record_right_div">
                        {emojiDecode(user.emoji)}
                      </div>
                    </div>
                  </div>
                ))}
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
    </>
  );
};

export default EmojiReaction;
