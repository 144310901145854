import React from 'react'
import './CommonHeader.css'
import { DE } from '../Languages/DE'
import { EN } from '../Languages/EN'
import { KJUUP_COLORS } from '../helper/constants'
import { FiChevronsDown } from 'react-icons/fi'
import { FaChevronDown } from 'react-icons/fa'
import { Form, Card } from "react-bootstrap";
import { BsChevronDown, BsFillPlusCircleFill, BsSearch } from 'react-icons/bs'


function CommonHeader({ value, onChange, isGerm, searchQuery, onSearch, showPlus, onAddClick, type }) {
    let vll = value == 0 ? isGerm ? DE.external_projects : EN.external_projects : isGerm ? DE.internal_project : EN.internal_project
    return (
        <div className='maindiv' style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, backgroundColor: type == 'feed' ? '#fff' : 'none' }}>
            <div className='subdiv' onClick={() => onChange(value == 0 ? 1 : 0)}>
                <span className='label' style={{ color: value == 0 ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal }}>{vll}</span>
                <div style={{ marginTop: -7 }}>
                    <BsChevronDown size={15} color={value == 0 ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal} />
                </div>
            </div>
            <div style={{ flex: 1, margin: 0 }} >
                <div style={{ margin: 0, marginLeft: 10 }} className='search'>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Control
                            autocomplete="off"
                            value={searchQuery}
                            type="search"
                            placeholder={isGerm ? DE.search : EN.search}
                            onChange={(e) => onSearch(e.target.value)}
                            style={{ height: 35 }}
                        />
                    </Form.Group>
                    <BsSearch color='gray' style={{ top: 10 }} />
                </div>
            </div>
            {showPlus ?
                <div onClick={() => onAddClick()} style={{ paddingLeft: 10, paddingTop: 3 }}>
                    <BsFillPlusCircleFill size={25} color={value == 0 ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal} />
                </div>
                :
                <></>
            }
        </div>
    )
}

export default CommonHeader