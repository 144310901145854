import React from 'react'
import './ActiveInactiveLable.css'
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import { KJUUP_COLORS } from '../helper/constants';

function ActiveInactiveLable({ chatDetails }) {
    const { isGerm } = React.useContext(LanguageContext);
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: '13px', color: chatDetails.isOnline ? KJUUP_COLORS.base_color : 'gray' }}>
            {chatDetails.isOnline ? (isGerm ? DE.is_active : EN.is_active) : (isGerm ? DE.inactive : EN.inactive)}
            <div
                className={
                    chatDetails?.isOnline
                        ? "userStatusNew online"
                        : "userStatusNew offline"
                }
            />
        </div>
    )
}

export default ActiveInactiveLable