import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Layout from "../../components/Layout";
import { callAPI } from '../../helper/apiUtils';
import LanguageContext from '../../helper/LanguageContext';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { corporateForms } from '../../Languages/vars/Corporate';
import { Positions } from '../../Languages/vars/Positions';
import LocationSearchInput from "../../components/LocationSearchInput";
import { Roles } from "../../Languages/vars/Roles";
import SuggestionInput from "./SuggestionInput";
import { $crud } from "../../helper/CrudFactory";
import { Link, useHistory } from 'react-router-dom';
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import ImageCropper from "../Profile/ImageCropper";
import CompanyRoles from "./Dialogs/CompanyRoles";
import CompanyCorporateDialog from "./Dialogs/CompanyCorporateDialog";
import CompanyPositionDialog from "./Dialogs/CompanyPositionDialog";
import { $user } from "../../helper/UserFactory";
import { BsChevronDown } from "react-icons/bs";
import { convertBranchRole, convertCorporateForm, convertRole } from '../../helper/convertor';
import Geocode from "react-geocode";
import { Discuss, Rings, Oval } from 'react-loader-spinner'

Geocode.setApiKey("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q");
function EditCompany(props) {

    const [params, setParams] = useState({
        logo: '',
        position: "",
        name: "",
        email: "",
        corporateForm: "",
        branch: "",
        street: "",
        postCode: "",
        city: "",
        country: "",
        lat: "",
        long: "",
        houseNo: "",
        full_address: ""
    });
    const [paramsLoaded, setParamsLoaded] = useState({
        name: false,
        email: false,
        corporateForm: false,
        branch: false,
    })
    const user = $user.get();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        position: "",
        name: "",
        email: "",
        corporateForm: "",
        branch: "",
        street: "",
        postCode: "",
        city: "",
        country: "",
        lat: "",
        long: "",
        houseNo: "",
        full_address: ""
    })
    const { isGerm } = React.useContext(LanguageContext)
    const [show, setShow] = useState(false)
    const setParam = (name, value) => {
        console.log(value)
        setParams(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
        setErrors(prev => {
            return {
                ...prev,
                [name]: ''
            }
        });
    }
    const [suggestionLoaded, setSuggestionLoaded] = useState(false)
    const [ownerId, setOwnerId] = useState(null)
    const [selectedAddress, setSelectedAddress] = useState(false)
    const [mainVal, setMainVal] = useState('');
    const [full_address, setFullAddress] = useState('');
    const [searchCity, setSearchCity] = useState('');

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        //Role
        if (!params.position) {
            formIsValid = false;
            errors["position"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        }
        //Firstname
        if (!params.name) {
            formIsValid = false;
            errors["name"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        }
        if (!params.email) {
            formIsValid = false;
            errors["email"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        }

        if (!params.branch) {
            formIsValid = false;
            errors["branch"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        }

        if (!params.full_address) {
            formIsValid = false;
            errors["full_address"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        }

        setErrors(errors);
        return formIsValid;
    }
    const corporateForm = corporateForms;
    const [isInvalid, setIsInvalid] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState("")
    const [showRoleDialog, setRoleDialog] = useState(false)
    const [showCorporateForm, setCorporateForm] = useState(false)
    const [showPositionDialog, setPositionDialog] = useState(false)
    const [roleSelected, setRoleSelected] = useState(false)
    const [companies, setCompanies] = useState([])
    const history = useHistory();
    const [isUploadingFile, setIsUploadFile] = useState(false)
    const [mcomp, setMcomp] = useState({})

    const requestConfirmation = async () => {
        setLoading(true);
        console.log(params)
        if (handleValidation()) {
            try {
                const id = props.match.params.id
                const { data } = await callAPI('/company/' + id, null, 'PUT', params)
                if (!data.status) {
                    setInvalidMsg(data.message);
                    setIsInvalid(true)
                    await $crud.notify({
                        type: 'error',
                        message: data.message
                    })
                } else {
                    history.push('/company-details/' + id)
                }
            } catch (e) {
                console.log(e)
            }
            setLoading(false);
        } else {
            console.log(errors)
        }
    }

    const changeImage = async (file) => {

        console.log(file)
        if (file) {
            setIsUploadFile(true)
            const id = props.match.params.id
            let formData = new FormData()
            formData.append('file', file);
            formData.append('id', id);
            $crud.put(`/company/${id}/logo`, formData).then(res => {
                console.log(res)
                getCompanyDetails()
            }).catch(() => {
                setIsUploadFile(false)
            })
            setShow(false);
        } else {
            $crud.notify({
                type: 'error',
                message: "Please select a file to upload"
            })
        }

    }

    console.log(mcomp)

    const getCompanyDetails = async () => {
        const id = props.match.params.id
        const { data: { profile } } = await callAPI(`/company/${id}/profile`, {}, 'GET')
        const company = profile
        setMcomp(company)
        let suggestion = company
        let add = `${suggestion.houseNo ? suggestion.houseNo + "," : ""} ${suggestion.street}, ${suggestion.city}, ${suggestion.postCode ? suggestion.postCode + "," : ""} ${suggestion.country ? suggestion.country : ''}`
        setFullAddress(company.full_address ? company.full_address : add)
        setMainVal(company.full_address ? company.full_address : add)
        await setParams(prev => {
            return {
                ...prev,
                name: company.companyName,
                logo: company.avatar,
                email: company.email,
                corporateForm: company.corporateForm,
                position: company.position,
                branch: company.branch,
                street: company.street,
                postCode: company.postCode,
                city: company.city,
                country: company.country,
                lat: company.lat,
                long: company.long,
                houseNo: company.houseNo,
                full_address: company.full_address ? company.full_address : add
            }
        })
        setIsUploadFile(false)
    }
    useEffect(() => {
        getCompanyDetails();
    }, [props.match.params.id]);

    const getList = async () => {
        try {
            const { data } = await callAPI('/company/getRegisterOrUnregisteredCompany', {}, 'GET')
            console.log(data)
            if (data.status) {
                setCompanies(data.companies)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        if (!selectedAddress) {
            let fullAddress = `${params.street} ${params.city ? `, ${params.city}` : ""}`
            console.log("fullAddress", fullAddress)
            Geocode.fromAddress(fullAddress).then(
                (results) => {
                    console.log("results", results);
                    const { lat, lng } = results.results[0].geometry.location;

                    let country;
                    console.log("lat", lat, lng);
                    for (var i = 0; i < results.results[0].address_components.length; i++) {
                        for (
                            var j = 0;
                            j < results.results[0].address_components[i].types.length;
                            j++
                        ) {
                            if (
                                results.results[0].address_components[i].types[j] == "country"
                            ) {
                                country = results.results[0].address_components[i]?.long_name;
                            }
                        }
                    }
                    console.log("country", country)
                    setParam('lat', lat)
                    setParam('long', lng)
                    setParam('country', country)
                },
                (error) => {
                    console.error(error);
                }
            );
        }
        console.log("params", params.city, "street", params.street, "selectedAddress", selectedAddress)
    }, [params.city, params.street])

    const getSuggestion = async suggestion => {
        setParams(prev => {
            return {
                ...prev,
                position: '',
                name: suggestion.name,
                email: suggestion.email,
                corporateForm: suggestion.corporateForm,
                branch: suggestion.branch,
                street: suggestion.street,
                postCode: suggestion.postCode,
                city: suggestion.city,
                country: suggestion.country ? suggestion.country : 'germany',
                lat: suggestion.lat,
                long: suggestion.long,
                houseNo: suggestion.houseNo
            }
        })
        setOwnerId(suggestion.ownerId)
        setParamsLoaded(prev => {
            return {
                ...prev,
                corporateForm: !!suggestion.corporateForm,
                branch: !!suggestion.branch,
                email: !!suggestion.email,
            }
        })
        await setSuggestionLoaded(true)
    }
    const changeRole = roles => {
        setRoleSelected(false);
        let tempData = roles?.map(itm => convertBranchRole(itm, false))
        console.log("roles========>", tempData)
        const selectedRoles = tempData.join(",");
        setParam('branch', selectedRoles)
    }
    return (
        <React.Fragment>
            <Layout>
                <div className="w-300 add_company">
                    <div className="heading">
                        <h2>{isGerm ? DE.edit_company_details : EN.edit_company_details}</h2>
                        <Link to={`/company-details/${props.match.params.id}`}>
                            <img width={10} src={process.env.PUBLIC_URL + mcomp.isKjuup ? "/assets/img/backx.svg" : "/assets/img/back.svg"} />
                        </Link>
                    </div>
                    <div className="">
                        <div className="heading border-0 text-center">
                            <Link to="/profile"><img width={10}
                                src={process.env.PUBLIC_URL + mcomp.isKjuup ? "/assets/img/backx.svg" : "/assets/img/back.svg"} /></Link>
                            <a onClick={e => {
                                setShow(true)
                            }} className="h_edit pointer c_edit">
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/img/edit-pen-s.svg"} />
                            </a>
                        </div>
                        <div className="user_image text-center">
                            {!isUploadingFile ?
                                <img onClick={e => {
                                    setShow(true)
                                    // changeImage()
                                }}
                                    src={params.logo ? BASE_URL + params.logo : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"} />
                                :
                                <div style={{ display: "flex", textAlign: "center", flexDirection: "row" }}>
                                    <div style={{ flex: 1 }} />
                                    <div style={{
                                        height: '100px',
                                        // width: "100px",
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "center",
                                        border: "1px solid #DFDFDF",
                                        borderRadius: 10,
                                        justifyContent: "center",
                                        padding: 10,
                                    }}>
                                        <Oval
                                            height={40}
                                            width={40}
                                            color={KJUUP_COLORS.base_color}
                                            wrapperStyle={{ alignSelf: "center" }}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel='oval-loading'
                                            secondaryColor={"#fff"}
                                            strokeWidth={5}
                                            strokeWidthSecondary={5}
                                        />
                                        {/* <p>Please wait...</p> */}
                                    </div>
                                    <div style={{ flex: 1 }} />
                                </div>
                            }
                        </div>
                        <div className="form-bottom" onSubmit={(e) => {
                            e.preventDefault();
                            requestConfirmation();
                        }}>
                            <Form className="add_company_from">
                                <Form.Group controlId="formBasicEmail">
                                    <SuggestionInput value={params.name} setParam={setParam} setSuggestion={getSuggestion} />
                                    {
                                        errors.name &&
                                        <small className="pl-2 text-danger">{errors.name}</small>
                                    }
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword11" style={{ marginTop: 10 }}>
                                    <LocationSearchInput
                                        custom
                                        defaultValue={full_address}
                                        placeHolder={isGerm ? DE.Address : EN.Address}
                                        setVal={(val) => {
                                            setMainVal(val)
                                            setSearchCity(val)
                                        }}
                                        val={mainVal}
                                        setFull={(val) => {
                                            setMainVal(val)
                                            setParam('full_address', val)
                                            setFullAddress(val)
                                        }}
                                    />
                                    {
                                        errors.full_address &&
                                        <small className="pl-2 text-danger">{errors.full_address}</small>
                                    }
                                </Form.Group>
                                <Form.Group className={roleSelected ? "select_coustom position-relative theme-border" : "select_coustom position-relative gray-border"}>
                                    {
                                        (!paramsLoaded.branch || user?.id === ownerId) &&
                                        <a className="register_role pointer text-truncate" onClick={(e) => {
                                            e.preventDefault();
                                            setRoleDialog(!showRoleDialog);
                                        }}>
                                            {!params.branch && <span>Role in the building industry* </span>}
                                            {params.branch &&
                                                <span>{params.branch?.split(",")?.map(itm => convertBranchRole(itm, isGerm) + ", ")}</span>}
                                        </a>
                                    }
                                    {
                                        (paramsLoaded.branch && user?.id !== ownerId) &&
                                        <a className="register_role pointer text-truncate"
                                            style={{ backgroundColor: "#e9ecef" }}>
                                            {!params.branch && <span>Role in the building industry* </span>}
                                            {params.branch &&
                                                <span>{params.branch?.split(",")?.map((itm, index) => (index ? ", " : "") + convertBranchRole(itm, isGerm))}</span>}
                                        </a>
                                    }
                                    <BsChevronDown size={12} />
                                    {
                                        errors.branch &&
                                        <small className="pl-2 text-danger">{errors.branch}</small>
                                    }
                                    <div className="profile-edit-roles">
                                        {
                                            showRoleDialog &&
                                            <CompanyRoles isKjuup={mcomp.isKjuup} hideRoles={(e) => {
                                                setRoleDialog(false)
                                            }} setRoles={setRoleSelected} selectPosition={changeRole} position={Roles} />
                                        }
                                    </div>
                                </Form.Group>
                                {/* <Form.Group className="select_coustom border-0">
                                    <a className="register_role pointer text-truncate position-relative"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           setPositionDialog(true)
                                       }}>
                                        {!params.position &&
                                        <span>{isGerm ? DE.position_within_company : EN.position_within_company} </span>}
                                        {params.position &&
                                        <span>{convertPosition(isGerm,params.position)}</span>}
                                        <BsChevronDown/>
                                    </a>
                                    {
                                        errors.position &&
                                        <small className="pl-2 text-danger">{errors.position}</small>
                                    }
                                </Form.Group> */}
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control disabled={paramsLoaded.email && user?.id !== ownerId} value={params.email}
                                        onChange={e => setParam('email', e.target.value)}
                                        type="text" placeholder={isGerm ? DE.company_e_mail : EN.company_e_mail} />
                                    {
                                        errors.email &&
                                        <small className="pl-2 text-danger">{errors.email}</small>
                                    }
                                </Form.Group>
                                <Button className={mcomp.isKjuup ? "btn request-btnx" : "btn request-btn"}
                                    type="submit">
                                    {isGerm ? DE.update : EN.update}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
                {
                    showPositionDialog &&
                    <CompanyPositionDialog isKjuup={mcomp.isKjuup} handleClose={e => setPositionDialog(false)} setParam={setParam}
                        positions={Positions} />
                }
            </Layout>
            <>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {isGerm ? DE.select_profile_image : EN.select_profile_image}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ImageCropper changeImage={changeImage} />
                    </Modal.Body>
                </Modal>
            </>
        </React.Fragment>
    );
}

export default EditCompany;
