export const Roles = [
    {
      id: 1,
      name_de: 'Bauindustrie',
      name_en: 'Building industry',
      branches: [
        {
          id: 1,
          name_de: 'Bauchemie',
          name_en: 'Construction chemistry',
        },
        {
          id: 2,
          name_de: 'Beschichtung',
          name_en: 'Coating',
        },
        {
          id: 3,
          name_de: 'Beschlagstechnik',
          name_en: 'Fittings',
        },
        {
          id: 4,
          name_de: 'Bewehrungssysteme',
          name_en: 'Reinforcing',
        },
        {
          id: '6',
          name_de: 'Betonfertigteile',
          name_en: 'Precast concrete plants',
        },
        {
          id: '7',
          name_de: 'Deckensysteme',
          name_en: 'Flooring structural systems',
        },
        {
          id: '8',
          name_de: 'Fertigteile',
          name_en: 'Prefab compound units',
        },
        {
          id: '9',
          name_de: 'Holzwerkstoffe',
          name_en: 'Wood materials',
        },
        {
          id: '10',
          name_de: 'Möbel',
          name_en: 'Furnitures',
        },
        {
          id: '11',
          name_de: 'Raumsysteme',
          name_en: 'Spatial structural systems',
        },
        {
          id: '12',
          name_de: 'Sicherheitssysteme',
          name_en: 'Safety systems',
        },
        {
          id: '13',
          name_de: 'Produzierendes Gewerbe',
          name_en: 'Manufacturing plant',
        },
        {
          id: '14',
          name_de: 'Türen',
          name_en: 'Doors',
        },
        {
          id: '15',
          name_de: 'Tore',
          name_en: 'Gates',
        },
      ],
    },
    {
      id: 2,
      name_de: 'Handwerk',
      name_en: 'Craft',
      branches: [
        {
          id: '17',
          name_de: 'Abbrucharbeiten',
          name_en: 'Demolition work',
        },
        {
          id: '18',
          name_de: 'Abdichtungsarbeiten',
          name_en: 'Waterproofing',
        },
        {
          id: '19',
          name_de: 'Abfallentsorgung',
          name_en: 'Waste disposal',
        },
        {
          id: '20',
          name_de: 'Asbestsanierung',
          name_en: 'Asbestos removal',
        },
        {
          id: '21',
          name_de: 'Asphaltbau',
          name_en: 'Asphaltic work',
        },
        {
          id: '22',
          name_de: 'Aufzugsbau',
          name_en: 'Passenger lift installation',
        },
        {
          id: '23',
          name_de: 'Außenputz',
          name_en: 'Exterior plaster',
        },
        {
          id: '25',
          name_de: 'Baureinigung',
          name_en: 'Cleansing',
        },
        {
          id: '26',
          name_de: 'Bautenschutz',
          name_en: 'Preservation of construction',
        },
      ],
    },
    {
      id: 3,
      name_de: 'Bauunternehmen',
      name_en: 'Construction company',
      branches: [
        {
          id: '23',
          name_de: 'Bauträger',
          name_en: 'Property developer',
        },
        {
          id: '24',
          name_de: 'Generalbauunternehmung',
          name_en: 'General construction company',
        },
        {
          id: '25',
          name_de: 'Generalübernehmung',
          name_en: 'General contracting',
        },
        {
          id: '26',
          name_de: 'Fenster/Aussentüren/Rolläden',
          name_en: 'Windows/exterior doors/shutters',
        },
        {
          id: '27',
          name_de: 'Bauunternehmen',
          name_en: 'Construction company',
        },
        {
          id: '28',
          name_de: 'Beleuchtung',
          name_en: 'Illumination',
        },
        {
          id: '29',
          name_de: 'Betonbau',
          name_en: 'Concete construction',
        },
        {
          id: '30',
          name_de: 'Betonsanierung',
          name_en: 'Concrete reintegration',
        },
        {
          id: '31',
          name_de: 'Betonwerkstein',
          name_en: 'Cast stone work',
        },
        {
          id: '32',
          name_de: 'Bodenbelagsarbeiten',
          name_en: 'Flooring work',
        },
        {
          id: '33',
          name_de: 'Brand-/Rauchschutz',
          name_en: 'Fire protection / smoke prevention',
        },
        {
          id: '34',
          name_de: 'Brandmeldeanlagen',
          name_en: 'Fire alarm system',
        },
        {
          id: '35',
          name_de: 'Brunnenbau',
          name_en: 'Well construction work',
        },
        {
          id: '36',
          name_de: 'Dachdecker / Dachdichtung',
          name_en: 'Roofing work / roof sealing ',
        },
        {
          id: '38',
          name_de: 'Dränarbeiten',
          name_en: 'Drainage work ',
        },
        {
          id: '39',
          name_de: 'Einschalarbeiten',
          name_en: 'Concrete formwork',
        },
        {
          id: '40',
          name_de: 'Elektroakustische Anlagen',
          name_en: 'Electro-acoustic installation',
        },
        {
          id: '41',
          name_de: 'Elektroarbeiten',
          name_en: 'Electrical installation',
        },
        {
          id: '42',
          name_de: 'Entwässerungskanalbau',
          name_en: 'Drainage system work',
        },
        {
          id: '43',
          name_de: 'Erdarbeiten',
          name_en: 'Earthwork',
        },
        {
          id: '44',
          name_de: 'Estrichleger',
          name_en: 'Screed work',
        },
        {
          id: '45',
          name_de: 'Fassadenbau',
          name_en: 'Facade construction',
        },
        {
          id: '46',
          name_de: 'Fensterbau',
          name_en: 'Window work',
        },
        {
          id: '47',
          name_de: 'Feuerlöschanlagen',
          name_en: 'Fire extinguishing system',
        },
        {
          id: '48',
          name_de: 'Fliesenleger',
          name_en: 'Tiler',
        },
        {
          id: '49',
          name_de: 'Garten- und Landschaftsbau',
          name_en: 'Gardening and landscaping',
        },
        {
          id: '50',
          name_de: 'Gefahrmeldeanlagen',
          name_en: 'Alarm systems',
        },
        {
          id: '52',
          name_de: 'Gerüstbau',
          name_en: 'Scaffolding',
        },
        {
          id: '54',
          name_de: 'Heizungsbau',
          name_en: 'Heating construction',
        },
        {
          id: '55',
          name_de: 'Holzbau',
          name_en: 'Timber construction',
        },
        {
          id: '56',
          name_de: 'Holzschutz',
          name_en: 'Wood protection',
        },
        {
          id: '57',
          name_de: 'Innenputz',
          name_en: 'Interior plaster',
        },
        {
          id: '58',
          name_de: 'Kältetechnik',
          name_en: 'Refrigeration',
        },
        {
          id: '59',
          name_de: 'Kaminbau',
          name_en: 'Chimney construction',
        },
        {
          id: '60',
          name_de: 'Kläranlagen',
          name_en: 'Sewage treatment plants',
        },
        {
          id: '61',
          name_de: 'Klempner',
          name_en: 'Plumber',
        },
        {
          id: '62',
          name_de: 'Korrosionsschutz',
          name_en: 'Corrosion protection',
        },
        {
          id: '63',
          name_de: 'Lüftungsbau',
          name_en: 'Ventilation construction',
        },
        {
          id: '64',
          name_de: 'Maler und Lackierer',
          name_en: 'Painter',
        },
        {
          id: '65',
          name_de: 'Mauerarbeiten',
          name_en: 'Brickwork',
        },
        {
          id: '66',
          name_de: 'Metallbau',
          name_en: 'Metal construction',
        },
        {
          id: '67',
          name_de: 'Ofenbau',
          name_en: 'Furnace construction',
        },
        {
          id: '68',
          name_de: 'Naturstein',
          name_en: 'Natural stone',
        },
        {
          id: '69',
          name_de: 'Parkett',
          name_en: 'Parquet',
        },
        {
          id: '70',
          name_de: 'Prüfer',
          name_en: 'Tester',
        },
        {
          id: '71',
          name_de: 'Rolladenbau',
          name_en: 'Roller shutter construction',
        },
        {
          id: '72',
          name_de: 'Sanitär',
          name_en: 'Sanitary',
        },
        {
          id: '73',
          name_de: 'Schließanlagen',
          name_en: 'Locking systems',
        },
        {
          id: '74',
          name_de: 'Schlosser',
          name_en: 'Locksmith',
        },
        {
          id: '75',
          name_de: 'Schreiner / Tischler',
          name_en: 'Carpenter',
        },
        {
          id: '76',
          name_de: 'Schwimmbadbau',
          name_en: 'Swimming pool construction',
        },
        {
          id: '77',
          name_de: 'Sicherheitsbeleuchtung',
          name_en: 'Security lighting',
        },
        {
          id: '78',
          name_de: 'Sonnenschutzanlagen',
          name_en: 'Sun protection systems',
        },
        {
          id: '79',
          name_de: 'Spenglerarbeiten',
          name_en: 'Plumbing work',
        },
        {
          id: '80',
          name_de: 'Spezialtiefbau',
          name_en: 'Special civil engineering',
        },
        {
          id: '81',
          name_de: 'Stahlbau',
          name_en: 'Steel construction',
        },
        {
          id: '82',
          name_de: 'Statiker',
          name_en: 'Structural engineer',
        },
        {
          id: '83',
          name_de: 'Steinmetzarbeiten',
          name_en: 'Stone carvings',
        },
        {
          id: '84',
          name_de: 'Straßenbau',
          name_en: 'Road construction',
        },
        {
          id: '85',
          name_de: 'Stuckateur',
          name_en: 'Plasterer',
        },
        {
          id: '86',
          name_de: 'Tiefbau',
          name_en: 'Civil engineering',
        },
        {
          id: '87',
          name_de: 'Tragwerksplanung / Statik',
          name_en: 'Structural engineer / Statics',
        },
        {
          id: '88',
          name_de: 'Treppenbau',
          name_en: 'Stair construction',
        },
        {
          id: '89',
          name_de: 'Trockenbau',
          name_en: 'Drywall work',
        },
        {
          id: '90',
          name_de: 'Verbauarbeiten',
          name_en: 'Sheeting work',
        },
        {
          id: '91',
          name_de: 'Verglasung',
          name_en: 'Glazing',
        },
        {
          id: '92',
          name_de: 'Wärmedämmung',
          name_en: 'Thermal insulation',
        },
        {
          id: '93',
          name_de: 'Wärmedämmverbundsystem',
          name_en: 'Thermal insulation composite system',
        },
        {
          id: '94',
          name_de: 'Wintergärten',
          name_en: 'Winter gardens',
        },
        {
          id: '95',
          name_de: 'Zimmerei',
          name_en: 'Carpentry',
        },
        {
          id: '96',
          name_de: 'Zimmerei und Holzbau',
          name_en: 'Carpentry and timber construction',
        },
        {
          id: '97',
          name_de: 'Blitzschutz',
          name_en: 'Lightning protection',
        },
      ],
    },
    {
      id: 4,
      name_de: 'Ingenieur',
      name_en: 'Engineer',
      branches: [
        {
          id: '1111',
          name_de: 'Baugrunduntersuchungen',
          name_en: 'Subsoil investigations',
        },
        {
          id: '11111',
          name_de: 'Garten- und Landschaftsarchitektur',
          name_en: 'Garden and landscape architecture',
        },
        {
          id: '117',
          name_de: 'Bausachverständiger',
          name_en: 'Surveyor',
        },
        {
          id: '24',
          name_de: 'Bauphysik',
          name_en: 'Physics relating to construction',
        },
        {
          id: '99',
          name_de: 'Behindertengerechtes Bauen',
          name_en: 'Handicapped accessible building',
        },
        {
          id: '100',
          name_de: 'BIM-Koordination',
          name_en: 'BIM-coordination',
        },
        {
          id: '101',
          name_de: 'Datentechnik',
          name_en: 'Data technology',
        },
        {
          id: '102',
          name_de: 'Innenarchitektur',
          name_en: 'Interior design',
        },
        {
          id: '103',
          name_de: 'SiGeKo',
          name_en: 'Safety and health coordinator',
        },
        {
          id: '104',
          name_de: 'Projektsteuerung',
          name_en: 'Project management',
        },
        {
          id: '105',
          name_de: 'Prozessmanagement',
          name_en: 'Process management',
        },
        {
          id: '106',
          name_de: 'Fachplaner TGA',
          name_en: 'Technical planner TGA',
        },
        {
          id: '107',
          name_de: 'Akustik-Ingenieurbüro',
          name_en: 'Acoustics engineering office',
        },
        {
          id: '108',
          name_de: 'Architekturbüro',
          name_en: 'Architecture office',
        },
        {
          id: '109',
          name_de: 'Artenschutz',
          name_en: 'Species protection',
        },
        {
          id: '110',
          name_de: 'Brandschutzgutachter',
          name_en: 'Fire safety assessor',
        },
        {
          id: '111',
          name_de: 'Elektro-Ingenieurbüro',
          name_en: 'Electrical engineering office',
        },
        {
          id: '113',
          name_de: 'Haustechnik-Ingenieurbüro',
          name_en: 'Building services engineering office',
        },
        {
          id: '114',
          name_de: 'Holzbau-Ingenieurbüro',
          name_en: 'Timber engineering office',
        },
        {
          id: '115',
          name_de: 'Ingenieurbüro',
          name_en: 'Engineering office',
        },
        {
          id: '116',
          name_de: 'Prüfstatiker',
          name_en: 'Structural engineer',
        },
  
        {
          id: '118',
          name_de: 'Spezialtiefbau-Ingenieurbüro',
          name_en: 'Special civil engineering office',
        },
        {
          id: '119',
          name_de: 'Straßenbau-Ingenieurbüro',
          name_en: 'Road construction engineering office',
        },
        {
          id: '120',
          name_de: 'Tiefbau-Ingenieurbüro',
          name_en: 'Civil engineering company',
        },
        {
          id: '121',
          name_de: 'Vermessungs-Ingenieurbüro',
          name_en: 'Surveying engineering office',
        },
      ],
    },
    {
      id: 5,
      name_de: 'Immobiliensektor',
      name_en: 'Real-estate sector',
      branches: [
        {
          id: '123',
          name_de: 'Immobilienmakler',
          name_en: 'Real estate agent',
        },
        {
          id: '124',
          name_de: 'Maklerbüro',
          name_en: 'Estate agent',
        },
        {
          id: '125',
          name_de: 'Immobilien Verwaltung',
          name_en: 'Property Management',
        },
        {
          id: '126',
          name_de: 'Facility Management',
          name_en: 'Facility Management',
        },
        {
          id: '127',
          name_de: 'Vermietung',
          name_en: 'Rental',
        },
      ],
    },
    {
      id: 6,
      name_de: 'Lieferant',
      name_en: 'Supplier',
      branches: [
        {
          id: '129',
          name_de: 'Elektrogroßhandel',
          name_en: 'Electrical wholesale',
        },
        {
          id: '126',
          name_de: 'Spedition',
          name_en: 'Carrier',
        },
        {
          id: '127',
          name_de: 'Großhandel',
          name_en: 'Wholesale',
        },
        {
          id: '128',
          name_de: 'Einzelhandel',
          name_en: 'Retail trade',
        },
      ],
    },
    {
      idL: 7,
      name_de: 'Andere',
      name_en: 'Others',
      branches: [
        {
          id: '130',
          name_de: 'Andere',
          name_en: 'Others',
        },
        {
          id: '131',
          name_de: 'Fotostudio',
          name_en: 'Photography studio',
        },
        {
          id: '132',
          name_de: 'Designstudio',
          name_en: 'Design studio',
        },
        {
          id: '133',
          name_de: 'Agentur',
          name_en: 'Agency',
        },
        {
          id: '134',
          name_de: 'Marktforschung',
          name_en: 'Market research',
        },
        {
          id: '135',
          name_de: 'Bank',
          name_en: 'Bank',
        },
        {
          id: '136',
          name_de: 'Finanzbranche',
          name_en: 'Financial sector',
        },
        {
          id: '137',
          name_de: 'Software Planung und Bau',
          name_en: 'Software planning and construction',
        },
        {
          id: '138',
          name_de: 'Versicherer',
          name_en: 'Insurer',
        },
      ],
    },
  ];
  