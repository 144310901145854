import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Layout from '../../components/Layout';
import LanguageContext from '../../helper/LanguageContext';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { Scrollbars } from 'react-custom-scrollbars';
import { callAPI } from "../../helper/apiUtils";
import { Link } from "react-router-dom";
import { BASE_URL, goBack } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { Animated } from "react-animated-css";

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

function AllContact(props) {
    const { isGerm } = useContext(LanguageContext);
    const [visible, setVisible] = useState(true)
    const [contacts, setContact] = useState([])
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState({})
    const [suggestions, setSuggestions] = useState([])
    const getContacts = async () => {
        setLoading(true)
        const { data } = await callAPI(`/user/visitor`, {}, 'GET');
        setSuggestions(data.profileVisitors)
        setLoading(false)
    }
    useEffect(() => {
        getContacts();
    }, [])

    const followUser = async (id) => {
        const { data } = await callAPI(`/user/${id}/follow`, {
            id: id,
        }, 'GET');
        if (data.status) {
            $crud.notify({
                type: 'success',
                message: data.message
            })
        }
    }
    const handleBack = () => {
        setVisible(false)
        setTimeout(() => {
            goBack();
        }, 1000)
    }
    return (
        <React.Fragment>
            <Layout>
                <Animated animationIn="slideInLeft" animationOut="slideOutLeft" animationOutDuration={800} isVisible={visible}>
                    <div className="w-300 capacities_content-page contact_page pb-0">
                        <h5 className="pb-2">
                            <img className="pointer" onClick={handleBack} width={10} src={process.env.PUBLIC_URL + "assets/img/back.svg"}
                                alt="Generic placeholder" /> {isGerm ? DE.all_profile_views : EN.all_profile_views}</h5>
                        <div className="my-scroll min_height contact_height">
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200} className="bottom-bar">
                                <div className="suggestion">
                                    {
                                        !loading && (suggestions.length > 0) ?
                                            <div className="row m-0">
                                                {
                                                    suggestions &&
                                                    suggestions.map(suggestion => (
                                                        <div className="col-md-6 p-0 border-right">
                                                            <div className="connect_link">
                                                                <img width={40} height={40} style={{ objectFit: 'cover' }}
                                                                    src={
                                                                        suggestion.avatar ? BASE_URL + suggestion.avatar : process.env.PUBLIC_URL + "assets/img/head.svg"
                                                                    }
                                                                    alt="Generic placeholder" />
                                                                <Link to={`/user-profile/${suggestion.id}`}>
                                                                    <h4>{suggestion.fullName}</h4></Link>
                                                                <p>{suggestion.position}</p>
                                                                <div className="connection">
                                                                    <a href="#">
                                                                        <img width={16}
                                                                            src={process.env.PUBLIC_URL + "assets/img/Link-deselected.svg"}
                                                                            alt="Generic placeholder" />
                                                                        {suggestion.mutualContactCount} {isGerm ? DE.mutual_contact : EN.mutual_contact}
                                                                    </a>
                                                                    <a href="#">
                                                                        <img width={12}
                                                                            src={process.env.PUBLIC_URL + "assets/img/My-companiesS.svg"}
                                                                            alt="Generic placeholder" />
                                                                        {suggestion.mutualCompanyCount} {isGerm ? DE.mutual_company : EN.mutual_company}
                                                                    </a>
                                                                </div>
                                                                <Button onClick={() => {
                                                                    followUser(suggestion.id)
                                                                }} className="btn request-btn" type="submit">
                                                                    {isGerm ? DE.follow : EN.follow}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            : <div className="text-center">
                                                <h6 className="p-2 m-0">No user found!</h6>
                                            </div>
                                    }
                                </div>
                            </CustomScrollbars>
                        </div>
                    </div>
                </Animated>
            </Layout>
        </React.Fragment>
    );
}

export default AllContact;
