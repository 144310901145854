import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import MapContainer from '../pages/map';
import Carousel from "react-multi-carousel";
import { BASE_URL } from '../helper/constants';
import VideoPlayers from './VideoPlayers';
import ImagesWithVideos from './ImagesWithVideos';

const PlayerFeed = ({ Links, article, isC, removeImage, onPress, prev }) => {
    const [_LNK, setLNK] = useState([])
    useEffect(() => {
        setLNK(Array.isArray(Links) ? [...Links] : [])
    }, [Links])
    return (
        (_LNK && isC && !prev) ?
            <div className="article-img">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        _LNK.map((image, index) => {
                            return (
                                <div style={{ marginLeft: 10, height: 200, marginBottom: 10, width: "47%" }} key={index} className="profile_slider embed-responsive embed-responsive-16by9">
                                    <VideoPlayers
                                        url={image.href ? image.href : image.fileUrl}
                                        type={image.videoType}
                                        isPlaying={image.play}
                                        fileType={image.fileType}
                                        local={image.url}
                                        article={article}
                                        isC={isC}
                                        index={index}
                                        removeImage={(e) => removeImage(e)}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            :
            <div className="article-img">
                <ImagesWithVideos isC={isC} data={_LNK} onPress={(data, index) => onPress(data, index)} />
            </div>


    );
}
export default memo(PlayerFeed);