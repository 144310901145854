import React, {useState} from 'react';
import './Marker.css';


const Marker = (props) => {
    const {name} = props;
    const [showInfo, setShowInfo] = useState(false);
    const showInfoWindow = e => {
        e.preventDefault();
        setShowInfo(true)
    }

    return (
        <div className="position-relative">

            <div className="map-pin share_pin pointer" onClick={showInfoWindow}  title={name}>
                <svg width="30" viewBox="0 0 20.04 30.53">
                    <path className="cls-1map"
                          d="M301,242.88c.95,0,5.29-7.29,7.79-13.47a20.39,20.39,0,0,0,1.78-6.59,9.57,9.57,0,1,0-19.14,0,20.58,20.58,0,0,0,1.8,6.59C295.7,235.58,300,242.88,301,242.88Z"
                          transform="translate(-290.95 -212.8)"/>
                    <path className="cls-2map" d="M301,230a7.43,7.43,0,1,0-7.43-7.43A7.43,7.43,0,0,0,301,230Z"
                          transform="translate(-290.95 -212.8)"/>
                    <path className="cls-3map" d="M302.24,218.07h-4.86a.9.9,0,0,0-.9.9h0v4.83h2.76v-3h3Z"
                          transform="translate(-290.95 -212.8)"/>
                    <path className="cls-3map"
                          d="M302.69,218.07v6.21h-6.21v1.86a.9.9,0,0,0,.9.9h7.18a.89.89,0,0,0,.89-.9h0V219a.89.89,0,0,0-.89-.9h-1.87Z"
                          transform="translate(-290.95 -212.8)"/>
                    <path className="cls-4map"
                          d="M302.06,222.43l-1.91-1.11a.18.18,0,0,0-.23.07.11.11,0,0,0,0,.08v2.2a.16.16,0,0,0,.17.17.11.11,0,0,0,.08,0l1.91-1.1a.17.17,0,0,0,.06-.23A.09.09,0,0,0,302.06,222.43Z"
                          transform="translate(-290.95 -212.8)"/>
                </svg>
            </div>
        </div>
    );
};

export default Marker;