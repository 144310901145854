import React, { Component, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { Modal } from "react-bootstrap";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import ShareMarker from "./ShareMarker";
import { AiFillCopy, BsCursorFill, BsXSquare } from "react-icons/all";
import { EmailIcon, EmailShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { $crud } from "../../helper/CrudFactory";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q");
Geocode.setLocationType("ROOFTOP");


const ShareProjectMapDialog = ({ company, show, handleClose }) => {
    const { isGerm } = React.useContext(LanguageContext)
    const [showingInfoWindow, setShowingInfoWindow] = useState(false)
    const [activeMarker, setActiveMarker] = useState({})
    const [selectedPlace, setSelectedPlace] = useState({})
    const [markers, setMarkers] = useState([])
    const [address, setAddress] = useState('')
    const [mapCenter, setMapCenter] = useState({})
    const [openShareWindow, setOpenShareWindow] = useState(false)
    const [shareUrl, setShareUrl] = useState('')


    const onMarkerClick = (marker) => {
        console.log(marker)
    }
    const onMapClicked = (props) => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false)
            setActiveMarker(null)
        }
    };
    const handleShowMarker = async () => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false)
            setActiveMarker({})
        }
    }

    const handleChange = address => {
        setAddress(address)
    };


    const handleSelect = address => {
        setAddress(address)
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);
                setMapCenter(latLng)
            })
            .catch(error => console.error('Error', error));
    };
    const shareLocation = e => {
        setOpenShareWindow(true)
        const company = company;
        const url = encodeURI(`https://maps.google.com/?q=${company.name + ' ' + company.street + ' ' + company.city + ' ' + company.country}`)
        setShareUrl(url)
    }

    const closeShareLocation = e => {
        setOpenShareWindow(false)
    }

    useEffect(() => {
        if (company) {
            if (company.full_address) {
                Geocode.fromAddress(company.full_address).then(
                    (results) => {
                        const { lat, lng } = results.results[0].geometry.location;
                        let ll = {
                            lat: lat,
                            lng: lng
                        }
                        setMapCenter(ll)
                    })
            }
            else {
                setMapCenter({
                    lat: company.lat,
                    lng: company.lng ? company.lng : company.long
                })
            }
        }
    }, [company])


    const copyLink = async () => {
        setOpenShareWindow(true)
        let cmp = company;
        const Url = encodeURI(`https://maps.google.com/?q=${cmp.street + ' ' + cmp.city + ' ' + cmp.country}`)
        navigator.clipboard.writeText(Url).then(r => {
            $crud.notify({
                type: 'success',
                message: 'Map Url Copied!'
            })
        })
    }
    return (
        <Modal size="md" show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{isGerm ? DE.send_location : EN.send_location}<br />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "300px", width: "100%", padding: "0rem" }}>
                <div style={{ height: '300px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q" }}
                        defaultCenter={mapCenter}
                        defaultZoom={11}
                        center={{
                            lat: mapCenter.lat,
                            lng: mapCenter.lng
                        }}
                    >
                        <ShareMarker hideMarker={handleShowMarker}
                            lat={mapCenter.lat} lng={mapCenter.lng}
                            marker={company}
                            name={company.name} />

                    </GoogleMapReact>
                </div>
            </Modal.Body>
            <Modal.Footer className="p-0">
                {
                    !openShareWindow &&
                    <div className="row w-100 align-items-center">
                        <div className="col-10 p-2">
                            <h5 className="m-0">{isGerm ? DE.send_location : EN.send_location}</h5>
                            <small>{isGerm ? DE.up_to_50m_exact : EN.up_to_50m_exact}</small>
                        </div>
                        <div className="col-2 p-2 text-center">
                            <BsCursorFill onClick={shareLocation} className="pointer" size={30} />
                        </div>
                    </div>
                }
                {
                    openShareWindow &&
                    <div className="row w-100 align-items-center">
                        <div className="col-10 p-2">
                            <div className="float-left">

                                <WhatsappShareButton className="mr-1" url={shareUrl}>
                                    <WhatsappIcon size={30} round={true} />
                                </WhatsappShareButton>
                                <EmailShareButton className="mr-1" url={shareUrl}>
                                    <EmailIcon size={30} round={true} />
                                </EmailShareButton>
                                <TelegramShareButton className="mr-1" url={shareUrl}>
                                    <TelegramIcon size={30} round={true} />
                                </TelegramShareButton>
                            </div>

                            <div onClick={e => copyLink()} title="Copy link" className="text-center pointer"
                                style={{
                                    height: 30,
                                    width: 30,
                                    float: 'left',
                                    border: "1px solid lightgrey",
                                    borderRadius: '100%'
                                }}>
                                <AiFillCopy className="theme-color" />
                            </div>
                        </div>
                        <div className="col-2 p-2 text-center">
                            <BsXSquare onClick={closeShareLocation} size={25} className="pointer" />
                        </div>
                    </div>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ShareProjectMapDialog;
