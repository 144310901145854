import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import "./ProjectSuggestionInput.css"

class AutoComplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: ""
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.userInput === '' && nextProps.value !== '') {
            this.setState({userInput: nextProps.value})
        }
    }

    onChange = (e) => {
        const {suggestions} = this.props;
        const userInput = e.currentTarget.value;
        this.props.setParam('name', userInput)
        // Filter our suggestions that don't contain the user's input
        const searchTermLower = userInput.toLowerCase().trim();
        const filteredSuggestions = suggestions.filter(company => 
          company.name
            .toLowerCase()
            .trim()
            .split(/\s+/) // Split by whitespace (spaces, tabs, etc.)
            .some(word => word.startsWith(searchTermLower)) // Check if any word starts with the search term
        );
        console.log('---filteredSuggestions', filteredSuggestions);
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    onClick = (e) => {
        this.props.setSuggestion(e);
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.name
        });
    };

    onKeyDown = (e) => {
        const {activeSuggestion, filteredSuggestions} = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            console.log(filteredSuggestions)
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion].name || ''
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({activeSuggestion: activeSuggestion - 1});
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({activeSuggestion: activeSuggestion + 1});
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            },
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active react-autosuggest__suggestion";
                            }

                            return (
                                <li className={className} key={suggestion.id} onClick={(e) => {
                                    e.preventDefault();
                                    onClick(suggestion)
                                }} value={suggestion}>
                                    <h6 className="suggestion-name">{suggestion.name}</h6>
                                    <span
                                        className="suggestion-address">{suggestion.street} {suggestion.postCode} {suggestion.city}</span>
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        }

        return (
            <Fragment>
                <input
                    readOnly={this.props.locked}
                    placeholder="Name*"
                    className="react-autosuggest__input"
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={userInput}
                    style={{backgroundColor: this.props.locked ? '#e9ecef' : '#f7f7f7'}}
                />
                <div className="react-autosuggest__container">
                    {suggestionsListComponent}
                </div>
            </Fragment>
        );
    }
}

export default AutoComplete;
