import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { callAPI } from "../../helper/apiUtils";
import LocationSearchInput from "../../components/LocationSearchInput";
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as EditIcon } from "../../Icons/Edit-grey.svg";
import { ReactComponent as TrashIcon } from "../../Icons/Trash-grey.svg";
import moment from "moment";
import { BASE_URL, goBack } from "../../helper/constants";
import CompanyRoles from "./Dialogs/CompanyRoles";
import { Roles } from "../../Languages/vars/Roles";
import { a, useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { useCurrentUserProfileData } from "../../helper/UserFactory";
import { Animated } from "react-animated-css";
import { convertBranchRole } from "../../helper/convertor";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function FreeCapacity() {
  const { isGerm } = React.useContext(LanguageContext);
  const [display, setDisplay] = useState("0");
  const [showRoleDialog, setRoleDialog] = useState(false);
  const [followStatus, setFollowStatus] = useState(false);
  const [reportParams, setReportParams] = useState({
    id: "",
    services: "",
    country: "",
    city: "",
    lat: "",
    long: "",
    circuit: "100",
    buildingIndustryRole: "",
    companyId: "",
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  });
  const [searchParams, setSearchParams] = useState({
    country: "",
    city: "",
    lat: "",
    long: "",
    circuit: "100",
    buildingRole: "",
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  });
  const [suggestionLoaded, setSuggestionLoaded] = useState(false);
  const [capsFound, setCapFound] = useState(false);
  const [capacities, setCapacities] = useState([]);
  const [searchCapacities, setSearchCapacities] = useState([]);
  const [companiesObject, setCompaniesObject] = useState({
    companies: [],
    isOnlyOneCompany: false,
  });
  const handleClass = async (index) => {
    if (index === "2") {
      if (companiesObject.companies.length > 0) {
        if (display === index) {
          await setDisplay(null);
        } else {
          await setDisplay(index);
        }
      } else {
        await setDisplay(null);
        await $crud.notify({
          type: "warning",
          message: !isGerm ? "Please register for a company with a position that allows to report capacities" : 'Bitte ihr Profil mit einem Unternehmen verknüpfen, um Leistungen anzubieten.',
        });
      }
    } else {
      if (display === index) {
        await setDisplay(null);
      } else {
        await setDisplay(index);
      }
    }
    console.log(display);
  };
  const getCompanies = async () => {
    const { data } = await callAPI("/user/company", {}, "GET");
    if (!data.isOnlyOneCompany) {
      setCompaniesObject(data);
      await getCompanyDetails(data?.companies?.[0]?.id);
    } else {
      setCompaniesObject(data);
      await getCompanyDetails(data?.companies?.[0]?.id);
    }
  };

  const getCompanyDetails = async (id) => {
    console.log(id);
    const { data } = await callAPI(`/company/${id}/companyById`, {}, "GET");
    console.log(data);
    setReportParam("companyId", data?.company?.id);
    setReportParam("buildingIndustryRole", data?.company?.branch);
  };
  const getCapacities = async () => {
    const { data } = await callAPI(`/capacity`, {}, "GET");
    setCapacities(data.capacities);
  };

  const setReportParam = (name, value) => {
    setReportParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const setSearchParam = (name, value) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const deleteReportCapacity = async (id) => {
    await $crud.confirm({
      title: "Are you sure?",
      textContent: "Do you really want to delete the reported capacity?",
      options: {
        ok: "Yes",
        cancel: "No",
      },
    });
    const { data } = await callAPI(
      "/capacity/" + id,
      {
        id: id,
      },
      "DELETE"
    );
    if (data.status) {
      $crud.notify({
        type: "success",
        message: data.message,
      });
      const updatedCapacities = capacities.filter(
        (capacity) => capacity.id !== id
      );
      setCapacities(updatedCapacities);
    }
    console.log(data);
  };

  useEffect(() => {
    getCapacities();
    getCompanies();
  }, []);

  const reportCapacities = async () => {
    console.log(reportParams);
    const { data } = await callAPI("/capacity", reportParams, "POST");
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    if (data.status) {
      setReportParams({
        id: "",
        services: "",
        country: "",
        city: "",
        lat: "",
        long: "",
        circuit: "100",
        buildingIndustryRole: "",
        companyId: "",
        from: "",
        to: "",
      });
      getCapacities();
      setDisplay("0");
    }
  };
  const updateReportCapacities = async () => {
    const { data } = await callAPI(
      "/capacity/" + reportParams.id,
      reportParams,
      "PUT"
    );
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });

    if (data.status) {
      setReportParams({
        id: "",
        services: "",
        country: "",
        city: "",
        lat: "",
        long: "",
        circuit: "100",
        buildingIndustryRole: "",
        companyId: "",
        from: "",
        to: "",
      });
      getCapacities();
      setDisplay("0");
    }
  };
  const updateCapacityStatus = async (id) => {
    const { data } = await callAPI("/capacity/" + id + "/status", {}, "PUT", {
      id: id,
    });
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    const updatedCapacityStatusList = capacities.map((capacity) => {
      if (capacity.id === id) {
        capacity.isActive = !capacity.isActive;
      }
      return capacity;
    });
    setCapacities(updatedCapacityStatusList);
  };

  const setCapacityValue = async (capacity) => {
    console.log(capacity);
    setDisplay("2");
    setReportParams((prev) => {
      return {
        ...prev,
        id: capacity.id,
        services: capacity.services,
        country: capacity.country,
        city: capacity.city,
        lat: capacity.lat,
        long: capacity.long,
        circuit: capacity.circuit,
        buildingIndustryRole: capacity.buildingIndustryRole,
        companyId: capacity.companyId,
        from: moment(capacity.from).format("YYYY-MM-DD"),
        to: moment(capacity.to).format("YYYY-MM-DD"),
      };
    });
    console.log(reportParams);
  };
  const changeRole = (roles) => {
    const selectedRoles = roles.join(",");
    console.log(roles);
    setSearchParam("buildingRole", selectedRoles);
  };

  const searchCapacity = async () => {
    console.log("searchParams=>", searchParams)
    let tempData = { ...searchParams, buildingRole: convertBranchRole(searchParams?.buildingRole, false) }
    const { data } = await callAPI(`/capacity/find`, {}, "POST", tempData);
    if (data.status) {
      setSearchCapacities(data.capacities);
      setCapFound(true);
    } else {
      $crud.notify({
        type: "error",
        message: data.message,
      });
    }
  };
  const followCompany = async (id) => {
    setFollowStatus(true);
    const { data } = await callAPI(
      `/company/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    setFollowStatus(false);
    searchCapacity();
  };
  const history = useHistory();
  const getCompany = (companyId) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const profile = useCurrentUserProfileData();
    const { companies } = profile;
    if (companies.length > 0) {
      const getId = companies.find((c) => c.id === companyId);
      if (getId) {
        history.push({
          pathname: `/company-details/${getId.id}`,
        });
      } else {
        history.push({
          pathname: `/foreign-company-details/${companyId}`,
        });
      }
    } else {
      history.push({
        pathname: `/foreign-company-details/${companyId}`,
      });
    }
  };
  const [visible, setVisible] = useState(true);
  const handleBack = () => {
    setVisible(false);
    setTimeout(() => {
      goBack();
    }, 1000);
  };
  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          {!capsFound && (
            <div className="w-300 capacities_content-page pb-0" style={{ height: "93vh" }}>
              <h5>
                <img
                  className="pointer"
                  onClick={handleBack}
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                  alt="Generic placeholder"
                />{" "}
                {isGerm ? DE.free_capacity : EN.free_capacity}
              </h5>
              <div className="row">
                <div className="setting_accrd col-12 pl-0 pr-0">
                  <Accordion>
                    <Card>
                      <Card.Header
                        variant="link"
                        eventKey="0"
                        onClick={(e) => handleClass("0")}
                        className="pl-3"
                      >
                        {isGerm
                          ? DE.current_status_overview
                          : EN.current_status_overview}
                        {display === "0" && <BsChevronUp className="acr_up" />}
                        {display !== "0" && (
                          <BsChevronDown className="acr_down" />
                        )}
                      </Card.Header>
                      {display === "0" && (
                        <Card.Body>
                          {capacities &&
                            capacities.map((capacity) => (
                              <div key={capacity.id} className="row m-0">
                                <div className="col-2 p-2">
                                  <img
                                    className="b-r-4"
                                    width={55}
                                    height={55}
                                    style={{ objectFit: "cover" }}
                                    src={
                                      capacity.logo
                                        ? BASE_URL + capacity.logo
                                        : process.env.PUBLIC_URL +
                                        "/assets/img/Companies-No-Image.svg"
                                    }
                                    alt="Generic placeholder"
                                  />
                                </div>
                                <div className="col-7 p-2">
                                  <p className="theme-color m-0 small">
                                    {capacity.companyName}
                                  </p>
                                  <p className="m-0 small">{capacity.city}</p>
                                  <p className="m-0 small">
                                    {capacity.services}
                                  </p>
                                </div>
                                <div className="col-3 p-2 text-right">
                                  <EditIcon
                                    onClick={(e) => setCapacityValue(capacity)}
                                    height={30}
                                    className="custom-svg active mr-3 pointer"
                                    style={{ fill: "black" }}
                                  />
                                  <TrashIcon
                                    onClick={(e) =>
                                      deleteReportCapacity(capacity.id)
                                    }
                                    height={20}
                                    className="custom-svg active pointer"
                                  />
                                </div>
                                <div className="col-9 p-2">
                                  <p className="small">
                                    {moment(capacity.from).format(
                                      "DD. MMMM YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(capacity.to).format(
                                      "DD. MMMM YYYY"
                                    )}
                                  </p>
                                </div>
                                <div className="col-3 p-2 text-right project-checkbox">
                                  <Form.Check
                                    onChange={(e) =>
                                      updateCapacityStatus(capacity.id)
                                    }
                                    checked={capacity.isActive}
                                    type="switch"
                                    id={capacity.id}
                                  />
                                </div>
                              </div>
                            ))}
                        </Card.Body>
                      )}
                    </Card>
                    <Card>
                      <Card.Header
                        variant="link"
                        eventKey="1"
                        onClick={(e) => handleClass("1")}
                        className="pl-3"
                      >
                        {isGerm ? DE.search_capacities : EN.search_capacities}
                        {display === "1" && <BsChevronUp className="acr_up" />}
                        {display !== "1" && (
                          <BsChevronDown className="acr_down" />
                        )}
                      </Card.Header>
                      {display === "1" && (
                        <Card.Body className="p-3">
                          <Form
                            className="add_company_from"
                            onSubmit={(e) => {
                              e.preventDefault();
                              searchCapacity();
                            }}
                          >
                            <Form.Group
                              className={
                                showRoleDialog
                                  ? "select_coustom position-relative theme-border"
                                  : "select_coustom position-relative gray-border"
                              }
                            >
                              <a
                                className="register_role pointer text-truncate xyz"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setRoleDialog(!showRoleDialog);
                                }}
                              >
                                {!searchParams.buildingRole && (
                                  <span>
                                    {isGerm
                                      ? DE.role_in_the_building_industry
                                      : EN.role_in_the_building_industry}
                                    {"*"}
                                  </span>
                                )}
                                {searchParams.buildingRole && (
                                  <span>{searchParams.buildingRole}</span>
                                )}
                              </a>
                              <BsChevronDown size={12} />
                              <div className="profile-edit-roles">
                                {showRoleDialog && (
                                  <CompanyRoles
                                    hideRoles={(e) => {
                                      setRoleDialog(false);
                                    }}
                                    selectPosition={changeRole}
                                    position={Roles}
                                  />
                                )}
                              </div>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                              <Form.Control
                                type="text"
                                placeholder={isGerm ? DE.services : EN.services}
                              />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                              <LocationSearchInput
                                suggestionLoaded={
                                  suggestionLoaded && searchParams.city
                                    ? true
                                    : false
                                }
                                defaultValue={searchParams.city}
                                placeHolder={
                                  isGerm ? DE.city : EN.city
                                }
                                isFreeCapacity={true}
                                setParam={setSearchParam}
                              />
                            </Form.Group>

                            <div className="row">
                              <div className="col-md-5 pr-1">
                                <Form.Group controlId="formBasicPassword">
                                  <Form.Control
                                    value={searchParams.circuit}
                                    onChange={(e) =>
                                      setSearchParam("circuit", e.target.value)
                                    }
                                    as="select"
                                    custom
                                  >
                                    <option value={30}>30 Km</option>
                                    <option value={50}>50 Km</option>
                                    <option value={100}>100 Km</option>
                                    <option value={200}>200 Km</option>
                                    <option value={500}>500 Km</option>
                                    <option value={1000}>National</option>
                                    <option value={2000}>International</option>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-8">
                                <Form.Group controlId="formBasicPassword">
                                  <Form.Control
                                    value={searchParams.from}
                                    onChange={(e) =>
                                      setSearchParam("from", e.target.value)
                                    }
                                    type="date"
                                    placeholder="Start date* "
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-8">
                                <Form.Group controlId="formBasicPassword">
                                  <Form.Control
                                    type="date"
                                    value={searchParams.to}
                                    onChange={(e) =>
                                      setSearchParam("to", e.target.value)
                                    }
                                    placeholder="End date* "
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <Button className="btn request-btn" type="submit">
                              {isGerm ? DE.find : EN.find}
                            </Button>

                            <p className="m-0 small" style={{ color: "gray" }}>
                              {isGerm ? DE.mandetory : EN.mandetory}
                            </p>

                          </Form>
                        </Card.Body>
                      )}
                    </Card>
                    <Card>
                      <Card.Header
                        variant="link"
                        eventKey="2"
                        onClick={(e) => handleClass("2")}
                        className="pl-3"
                      >
                        {isGerm ? DE.report_capacities : EN.report_capacities}
                        {display === "2" && <BsChevronUp className="acr_up" />}
                        {display !== "2" && (
                          <BsChevronDown className="acr_down" />
                        )}
                      </Card.Header>
                      {display === "2" && (
                        <Card.Body className="p-3">
                          {display === "2" ? (
                            <Form
                              className="add_company_from"
                              onSubmit={(e) => {
                                e.preventDefault();
                                reportParams.id
                                  ? updateReportCapacities()
                                  : reportCapacities();
                              }}
                            >
                              {!companiesObject.isOnlyOneCompany &&
                                companiesObject.companies.length > 0 && (
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                      as="select"
                                      value={reportParams.companyId}
                                      custom
                                      onChange={(e) =>
                                        getCompanyDetails(e.target.value)
                                      }
                                    >
                                      {companiesObject.companies &&
                                        companiesObject.companies.map(
                                          (company) => (
                                            <option
                                              key={company.id}
                                              value={company.id}
                                            >
                                              {company.name}
                                            </option>
                                          )
                                        )}
                                    </Form.Control>
                                  </Form.Group>
                                )}
                              <Form.Group controlId="formBasicEmail">
                                <Form.Control
                                  value={reportParams.services}
                                  onChange={(e) =>
                                    setReportParam("services", e.target.value)
                                  }
                                  type="text"
                                  placeholder={
                                    isGerm ? DE.services : EN.services
                                  }
                                />
                              </Form.Group>

                              <Form.Group controlId="formBasicEmail">
                                <LocationSearchInput
                                  suggestionLoaded={
                                    suggestionLoaded && reportParams.city
                                      ? true
                                      : false
                                  }
                                  defaultValue={reportParams.city}
                                  placeHolder={
                                    isGerm ? DE.city : EN.city
                                  }
                                  isFreeCapacity={true}
                                  setParam={setReportParam}
                                />
                              </Form.Group>

                              <div className="row">
                                <div className="col-md-5 pr-1">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Control
                                      value={reportParams.circuit}
                                      onChange={(e) =>
                                        setReportParam(
                                          "circuit",
                                          e.target.value
                                        )
                                      }
                                      as="select"
                                      custom
                                    >
                                      <option value={30}>30 Km</option>
                                      <option value={50}>50 Km</option>
                                      <option value={100}>100 Km</option>
                                      <option value={200}>200 Km</option>
                                      <option value={500}>500 Km</option>
                                      <option value={1000}>National</option>
                                      <option value={2000}>
                                        International
                                      </option>
                                    </Form.Control>
                                  </Form.Group>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-8">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Control
                                      value={reportParams.from}
                                      onChange={(e) =>
                                        setReportParam("from", e.target.value)
                                      }
                                      type="date"
                                      placeholder="Start date* "
                                    />
                                  </Form.Group>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-8">
                                  <Form.Group controlId="formBasicPassword">
                                    <Form.Control
                                      type="date"
                                      value={reportParams.to}
                                      onChange={(e) =>
                                        setReportParam("to", e.target.value)
                                      }
                                      placeholder="End date* "
                                    />
                                  </Form.Group>
                                </div>
                              </div>

                              <Button className="btn request-btn" type="submit">
                                {reportParams.id
                                  ? "Update"
                                  : isGerm
                                    ? DE.report_text
                                    : EN.report_text}
                              </Button>
                              <p className="m-0 small" style={{ color: "gray" }}>
                                {isGerm ? DE.mandetory : EN.mandetory}
                              </p>
                            </Form>
                          ) : (
                            ""
                          )}
                        </Card.Body>
                      )}
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {capsFound && (
            <div className="w-300 capacities_content-page pb-0">
              <h5 onClick={(e) => setCapFound(false)}>
                <img
                  className="pointer"
                  width={10}
                  src={process.env.PUBLIC_URL + "assets/img/back.svg"}
                  alt="Generic placeholder"
                />{" "}
                {isGerm ? DE.search_results : EN.search_results}
              </h5>
              <div className="my-scroll min_height capacities_height">
                <CustomScrollbars
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  className="bottom-bar"
                >
                  {searchCapacities &&
                    searchCapacities.map((capacity, index) => (
                      <div className="content-capi">
                        <div className="capacities_content">
                          {capacity.logo ?
                            <img
                              width={50}
                              src={BASE_URL + capacity.logo}
                              alt="Generic placeholder"
                              style={{objectFit: "cover"}}
                            />
                            :
                            <img width="45" height="45" src="assets/img/Companies-No-Image.svg" alt="Generic placeholder" style={{ marginRight: 15 }} />
                          }
                          <div className="right_capacities">
                            <h4
                              className="pointer"
                              onClick={(e) => getCompany(capacity.companyId)}
                            >
                              {capacity.companyName}
                            </h4>
                            <p>Service: {capacity.services}</p>
                            <p>Branch:{capacity.buildingIndustryRole?.split(",")?.map((item, index) => (index ? ", " : "") + convertBranchRole(item, isGerm))}</p>
                            <p>
                              Location: {capacity.street}, {capacity.city},{" "}
                              {capacity.country}
                            </p>
                            <p>Radius: {capacity.circuit}Km</p>
                          </div>
                        </div>
                        <div className="timeframe">
                          <div className="row">
                            <div className="col-md-9">
                              <div className="timeframe-content">
                                <p>Timeframe:</p>
                                <p className="small">
                                  {moment(capacity.from).format(
                                    "DD. MMMM YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(capacity.to).format("DD. MMMM YYYY")}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3 pl-0">
                              <div className="frame-icon">
                                <a
                                  onClick={(e) => {
                                    followCompany(capacity.companyId);
                                  }}
                                  className={`svg_icon pointer ${capacity.followStatus
                                    ? "social-icon-active"
                                    : ""
                                    } ${followStatus ? "active-like-follow" : ""
                                    }`}
                                >
                                  <svg
                                    className="mr-1"
                                    width="22px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.81 20.9"
                                  >
                                    <path
                                      d="M295.14,269.16a1.28,1.28,0,0,0-1.32-1.23h-.91a5.11,5.11,0,0,1-8.76,0h-.91a1.27,1.27,0,0,0-1.31,1.23l-1.31,9a1.28,1.28,0,0,0,1.31,1.24h13.21a1.27,1.27,0,0,0,1.3-1.24Z"
                                      transform="translate(-280.17 -258.91)"
                                    />
                                    <path
                                      className="cls-1"
                                      d="M288.53,268.2a4.42,4.42,0,1,0-4.41-4.42A4.43,4.43,0,0,0,288.53,268.2Z"
                                      transform="translate(-280.17 -258.91)"
                                    />
                                    <line
                                      className="cls-2"
                                      x1="16.72"
                                      y1="0.75"
                                      x2="16.72"
                                      y2="3.47"
                                    />
                                    <line
                                      className="cls-2"
                                      x1="16.72"
                                      y1="3.47"
                                      x2="16.72"
                                      y2="6.18"
                                    />
                                    <line
                                      className="cls-2"
                                      x1="16.72"
                                      y1="3.47"
                                      x2="14.08"
                                      y2="3.47"
                                    />
                                    <line
                                      className="cls-2"
                                      x1="19.36"
                                      y1="3.47"
                                      x2="16.72"
                                      y2="3.47"
                                    />
                                  </svg>
                                </a>
                                <a href={`mailto:${capacity.companyEmail}`}>
                                  <img
                                    width={27}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/img/e-mail-grey.svg"
                                    }
                                    alt="Generic placeholder"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </CustomScrollbars>
              </div>
            </div>
          )}
        </Animated>
      </Layout>
    </React.Fragment>
  );
}

export default FreeCapacity;
