import React from 'react';
import './Loading.css'; // Make sure to create this CSS file
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';

const Loading = ({isGerm}) => {
  return (
    <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0, zIndex: 1000, background: '#111B22' }}>
      <div className="loading-container">
        <div className="spinner"></div>
        <p className="loading-text">{isGerm ? DE.Loading : EN.Loading}</p>
      </div>
    </div>
  );
};

export default Loading;
