import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import LanguageContext from "../../../helper/LanguageContext";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { KJUUP_COLORS } from "../../../helper/constants";

function CompanyRoles(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const isKjuup = props.isKjuup
  const [display, setDisplay] = useState(null);
  const [search, setSearch] = useState("");
  const handleClass = (index) => {
    console.log(display);
    if (display === index) {
      setDisplay(null);
    } else {
      setDisplay(index);
    }
  };
  const [selectRoles, setSelectRoles] = useState([]);
  const addRoles = async (value) => {
    let roles = [];
    roles = selectRoles;
    let newRoles = [];
    const exist = roles.includes(value);
    if (selectRoles.length < 1 || !exist) {
      roles.push(value);
      setSelectRoles(roles);
    } else {
      newRoles = roles.filter((role) => role !== value);
      setSelectRoles(newRoles);
    }
  };
  const [positions, setPositions] = useState(props.position);
  useEffect(() => {
    if (props.position) {
      let temp = [...props.position];
      temp = temp.map(item => {
        if (isGerm)
          item.branches = item.branches.sort((a, b) => a.name_de.localeCompare(b.name_de));
        else
          item.branches = item.branches.sort((a, b) => a.name_en.localeCompare(b.name_en));
        return item;
      });
      setPositions(props.position)
    }
  }, [props.position])
  const allPositions = props.position;
  const sendRoles = () => {
    props.selectPosition(selectRoles);
    props.hideRoles();
  };

  const searchRole = async (text) => {
    setSearch(text);
    console.log(typeof text);

    if (text !== "") {
      let filterPositions = allPositions;
      let positionIndex = isGerm
        ? await positions.findIndex(
          (item) => item.name_de.toLowerCase() == text.toLowerCase()
        )
        : await positions.findIndex(
          (item) => item.name_en.toLowerCase() == text.toLowerCase()
        );

      if (positionIndex !== -1) {
        console.log(positionIndex);
        filterPositions = [allPositions[positionIndex]];
        console.log(filterPositions);
        setDisplay(null);
      } else {
        filterPositions = await allPositions.map((item) => {
          console.log(positionIndex);
          let filterRoles = isGerm
            ? item.branches.filter((i) =>
              i.name_de.toLowerCase().includes(text.toLowerCase())
            )
            : item.branches.filter((i) =>
              i.name_en.toLowerCase().includes(text.toLowerCase())
            );
          return { ...item, branches: filterRoles };
        });
        filterPositions = filterPositions.filter(
          (position) => position.branches.length > 0
        );
        if (filterPositions.length > 0) {
          setDisplay(filterPositions[0].id);
          console.log(filterPositions[0].id);
        }
      }
      setPositions(filterPositions);
    } else {
      setDisplay(null);
      setPositions(allPositions);
    }
  };
  return (
    <React.Fragment>
      <div
        class="company_section company_list setting_profile register_role_option"
        style={{ maxHeight: "356px" }}
      >
        <div className="company_header">
          <div class="heading_icon"></div>
        </div>
        <div className="search">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Control
              autocomplete="off"
              value={search}
              type="search"
              placeholder={isGerm ? DE.search : EN.search}
              onChange={(e) => {
                e.preventDefault();
                searchRole(e.target.value);
              }}
            />
          </Form.Group>
          <img
            src={process.env.PUBLIC_URL + "/assets/img/search.svg"}
            alt="Generic placeholder"
          />
        </div>
        <div className="setting_accrd">
          <Accordion className="max-h-position" style={{ maxHeight: "250px" }}>
            {positions &&
              positions.map((position, index) => (
                <Card key={index}>
                  <Accordion.Toggle
                    key={index}
                    as={Card.Header}
                    variant="link"
                    eventKey={position.id}
                    onClick={(e) => handleClass(position.id)}
                  >
                    {isGerm ? position.name_de : position.name_en}
                    {display === position.id && (
                      <BsChevronDown className="acr_up" style={{ fill: isKjuup ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }} />
                    )}
                    {display !== position.id && (
                      <BsChevronRight className="acr_down" style={{ fill: isKjuup ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }} />
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    className={display === position.id ? "show" : ""}
                    eventKey={position.id}
                  >
                    <Card.Body>
                      <div className="role-options">
                        <div className="role-main">
                          {position.branches.map((roles, key) => (
                            <label key={key} className={isKjuup ? 'role-label-internal' : "role-label"}>
                              <i>{isGerm ? roles.name_de : roles.name_en}</i>
                              <input
                                value={isGerm ? roles.name_de : roles.name_en}
                                onChange={(e) => {
                                  addRoles(e.target.value);
                                }}
                                type="checkbox"
                              />
                              <span className={isKjuup ? "checkmark-label-internal" : "checkmark-label"} />
                            </label>
                          ))}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            {positions.length <= 0 && (
              <Card className="text-center">
                <p className="text-muted">No Match Record Found!</p>
              </Card>
            )}
          </Accordion>
        </div>
        <div className={isKjuup ? "bottom-button-internal" : "bottom-button"}>
          <a
            className="pointer"
            onClick={(e) => {
              e.preventDefault();
              props.hideRoles();
            }}
          >
            {isGerm ? DE.add_company_cancel : EN.cancel}
          </a>
          <a className="pointer" onClick={sendRoles}>
            {isGerm ? DE.ok : EN.ok}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyRoles;
