import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { DE } from "../Languages/DE";
import { EN } from "../Languages/EN";
import LanguageContext from "../helper/LanguageContext";

function RegisterRole(props) {
  const [display, setDisplay] = useState(null);
  const [search, setSearch] = useState("");
  const handleClass = (index) => {
    if (display === index) {
      setDisplay(null);
    } else {
      setDisplay(index);
    }
  };
  const [selectRoles, setSelectRoles] = useState([]);

  const addRoles = async (value, roleData) => {
    let roles = [];
    roles = selectRoles;
    let newRoles = [];
    const exist = roles.includes(roleData?.name_en);
    if (selectRoles.length < 1 || !exist) {
      roles.push(roleData?.name_en);
      setSelectRoles(roles);
      if (roles.length > 0) {
        props.setRoles && props.setRoles(true);
      }
    } else {
      newRoles = roles.filter((role) => role !== roleData?.name_en);
      if (newRoles.length < 1) {
        props.setRoles && props.setRoles(false);
      }
      setSelectRoles(newRoles);
    }
  };
  const [positions, setPositions] = useState(props.position);
  const allPositions = props.position;
  const sendRoles = () => {
    props.selectPosition(selectRoles);
    props.hideRoles();
  };


  useEffect(() => {
    if (props.position) {
      let temp = [...props.position];
      temp = temp.map(item => {
        if (props.isGerms)
          item.Roles = item.Roles.sort((a, b) => a.name_de.localeCompare(b.name_de));
        else
          item.Roles = item.Roles.sort((a, b) => a.name_en.localeCompare(b.name_en));
        return item;
      });
      setPositions(props.position)
    }
  }, [props.position, props.isGerms])

  const searchRole = async (text) => {
    setSearch(text);
    console.log(typeof text);

    if (text !== "") {
      let filterPositions = allPositions;
      let positionIndex = await positions.findIndex(
        (item) => props.isGerms ? item.name_de.toLowerCase() == text.toLowerCase() : item.name_en.toLowerCase() == text.toLowerCase()
      );

      if (positionIndex !== -1) {
        filterPositions = [allPositions[positionIndex]];
        console.log(filterPositions);
        setDisplay(null);
      } else {
        filterPositions = await allPositions.map((item) => {
          let filterRoles = item.Roles.filter((i) =>
            props.isGerms ? i.name_de.toLowerCase().includes(text.toLowerCase()) : i.name_en.toLowerCase().includes(text.toLowerCase())
          );
          return { ...item, Roles: filterRoles };
        });
        filterPositions = filterPositions.filter(
          (position) => position.Roles.length > 0
        );
        console.log(filterPositions);
        if (filterPositions.length > 0) {
          setDisplay(filterPositions[0].id);
          console.log(filterPositions[0].id);
        }
      }
      setPositions(filterPositions);
    } else {
      setDisplay(null);
      setPositions(allPositions);
    }
  };

  return (
    <React.Fragment>
      <div class="company_section company_list setting_profile register_role_option">
        <div className="company_header">
          <div class="heading_icon"></div>
        </div>
        <div className="search">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Control
              value={search}
              type="search"
              placeholder={props.isGerms ? DE.search : EN.search}
              onChange={(e) => {
                e.preventDefault();
                searchRole(e.target.value);
              }}
            />
          </Form.Group>
          <img
            src={process.env.PUBLIC_URL + "assets/img/search.svg"}
            alt="Generic placeholder"
          />
        </div>
        <div className="setting_accrd">
          <Accordion className="max-h-position">
            {positions &&
              positions.map((position, index) => (
                <Card>
                  <Accordion.Toggle
                    key={index}
                    as={Card.Header}
                    variant="link"
                    eventKey={position.id}
                    onClick={(e) => handleClass(position.id)}
                  >
                    {props.isGerms ? position.name_de : position.name_en}
                    {display === position.id && (
                      <BsChevronDown className="acr_up" />
                    )}
                    {display !== position.id && (
                      <BsChevronRight className="acr_down" />
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse
                    className={display === position.id ? "show" : ""}
                    eventKey={position.id}
                  >
                    <Card.Body>
                      <div className="role-options">
                        <div className="role-main">
                          {position.Roles.map((roles, key) => (
                            <label key={key} className="role-label">
                              <i>
                                {props.isGerms ? roles.name_de : roles.name_en}
                              </i>
                              <input
                                value={
                                  props.isGerms ? roles.name_de : roles.name_en
                                }
                                onChange={(e) => {
                                  addRoles(e.target.value, roles);
                                }}
                                type="checkbox"
                              />
                              <span className="checkmark-label" />
                            </label>
                          ))}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            {positions.length <= 0 && (
              <Card className="text-center">
                <p className="text-muted">No Match Record Found!</p>
              </Card>
            )}
          </Accordion>
        </div>
        <div className="bottom-button">
          <a
            className="pointer"
            onClick={(e) => {
              e.preventDefault();
              props.hideRoles();
            }}
          >
            {props.isGerms ? DE.cancel : EN.cancel}
          </a>
          <a className="pointer" onClick={sendRoles}>
            {props.isGerms ? DE.ok : EN.ok}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegisterRole;
