import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from "./ProjectMarker";
import {Form} from "react-bootstrap";
import {DE} from "../../../Languages/DE";
import {EN} from "../../../Languages/EN";
import PlacesAutocomplete, {geocodeByAddress, getLatLng,} from 'react-places-autocomplete';

class ProjectMapComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: null,
            selectedPlace: {},
            markers: [],
            address: '',
            mapCenter: {
                lat: 28.626440,
                lng: 77.394814
            }
        }
    }

    componentDidMount() {
        console.log(this.props)
    }

    static defaultProps = {
        center: {
            lat: 28.626440,
            lng: 77.394814
        },
        zoom: 11,

    }

    setMarker = id => {
        this.setState({activeMarker: id})
    }

    handleChange = address => {
        this.setState({address: address})
    };


    handleSelect = address => {
        this.setState({address: address})
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('Success', latLng);

                // update center state
                this.setState({mapCenter: latLng});
            })
            .catch(error => console.error('Error', error));
    };

    setCompany = details => {
        this.props.setSuggestion(details)
        this.props.handleClose(false)
        console.log(details)
    }

    handleCloseMap = () => {
        this.props.handleClose(false)
    }


    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{height: '100vh', width: '100%'}}>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                >
                    {
                        ({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                            <div className="map_div">
                                <Form.Control
                                    {...getInputProps({
                                        placeholder: this.context.isGerm ? DE.search : EN.search,
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                            : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span className="small">{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    }
                </PlacesAutocomplete>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q"}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    center={{
                        lat: this.state.mapCenter.lat,
                        lng: this.state.mapCenter.lng
                    }}
                >
                    {
                        this.props.buildings &&
                        this.props.buildings.map((marker, index) => (
                            <Marker handleClose={this.handleCloseMap} setCompany={this.setCompany} hideMarker={this.handleShowMarker} key={index} lat={marker.lat} lng={marker.long}
                                    marker={marker}
                                    activeMarker={this.state.activeMarker}
                                    setActiveMarker={this.setMarker}
                                    name={marker.name}/>
                        ))
                    }
                </GoogleMapReact>
            </div>
        );
    }
}

export default ProjectMapComponent;
