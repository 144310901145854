export const MONTH_DE = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
]

export const MONTH_EN = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
]

export function DateFormatter(date, isGerm) {
    let _dt = new Date(date)
    let day = _dt.getDate().toString().length == 1 ? ('0' + _dt.getDate().toString()) : _dt.getDate()
    let month = isGerm ? MONTH_DE[_dt.getMonth()] : MONTH_EN[_dt.getMonth()]
    let year = _dt.getFullYear()
    return day + " " + month + ', ' + year
}