import React, { useEffect, useState } from "react";
import { GROUPS, OPEN_CHATS } from "../../store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE_URL } from "../../helper/constants";
import { Scrollbars } from "react-custom-scrollbars";
import "./SingleChat.css";
import Iframe from "react-iframe";
import FileViewer from "react-file-viewer";
import { Document, Page, pdfjs } from "react-pdf";
import { AiOutlineFullscreen, HiDownload } from "react-icons/all";
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    overflowX: "hidden",
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function DocViewer(props) {
  const [fileUrl, setFileUrl] = useState("");
  const [onlyfileUrl, setOnlyFileUrl] = useState("");

  const [fileType, setFileType] = useState("");

  useEffect(() => {
    console.log(props.chat);
    const ft = props.chat.attachedFileName?.split(".").pop().trim();
    setFileType(ft);
    console.log(ft);
    let file = BASE_URL + props.chat?.fileUrl[0];
    console.log("file", file);
    switch (ft) {
      case "doc":
        setFileUrl(
          "https://docs.google.com/viewer?url=" + file + "&embedded=true"
        );
        setOnlyFileUrl(file);
        break;
      case "docx":
        setFileUrl(
          "https://docs.google.com/viewer?url=" + file + "&embedded=true"
        );
        setOnlyFileUrl(file);
        break;
      case "xls":
        setFileUrl(
          "https://docs.google.com/viewer?url=" + file + "&embedded=true"
        );
        setOnlyFileUrl(file);
        break;
      case "xlsx":
        setFileUrl(
          "https://docs.google.com/viewer?url=" + file + "&embedded=true"
        );
        setOnlyFileUrl(file);
        break;
      case "pdf":
        file = BASE_URL + props.chat?.fileUrl;
        setFileUrl(
          "https://docs.google.com/viewer?url=" + file + "&embedded=true"
        );
        setOnlyFileUrl(file);
        break;
      case "txt":
        file = BASE_URL + props.chat?.fileUrl;
        let tempFile = file?.replace(".txt", ".pdf");
        console.log("tempFile=>", tempFile);
        setFileUrl(
          "https://docs.google.com/viewer?url=" + tempFile + "&embedded=true"
        );
        setOnlyFileUrl(tempFile);
        break;
      default:
        const f = file;
        console.log(f);
        setFileUrl(
          "https://docs.google.com/viewer?url=" + file + "&embedded=true"
        );
        setOnlyFileUrl(file);
        break;
    }
  }, []);
  const onError = () => {
    console.log("error");
  };
  console.log("fileUrl=>", fileUrl);
  return (
    <React.Fragment>
      <div className="w-300 my_project pt-0 immoanimation-enter-done px-2" style={{height:"93vh"}}>
        <div className="main_heading">
          <a className="pointer" onClick={props.handleClose}>
            <img
              width={10}
              src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
              alt="Generic placeholder"
            />
          </a>
          {fileType === "doc" || fileType === "docx"
            ? "Doc"
            : fileType === "xlsx"
            ? "XSL"
            : fileType === "txt"
            ? "TXT"
            : fileType === "pdf"
            ? "PDF"
            : fileType}{" "}
          File
          <div />
        </div>
        <div className="wscroll right_wscroll h-100">
          <CustomScrollbars
            autoHide
            autoHideTimeout={200}
            autoHideDuration={200}
            className="bottom-bar"
          >
            <div className="doc-file">
              {fileType === "txt" || fileType === "doc" ? (
                <Iframe
                  src={fileUrl}
                  download={props.chat.attachedFileName}
                  title="file"
                  width="100%"
                  height="600"
                />
              ) : fileType === "pdf" ? (
                <Document
                  file={{
                    url: onlyfileUrl,
                  }}
                  // width="100%"
                  // height="600"
                  // onLoadSuccess={(onDocumentLoadSuccess)}
                >
                  <Page pageNumber={1} />
                </Document>
              ) : (
                <FileViewer
                  width="100"
                  height="600"
                  fileType={fileType}
                  filePath={onlyfileUrl}
                  onError={onError}
                />
              )}
              <button
                style={{
                  position: "absolute",
                  top: 10,
                  right: "20px",
                  border: "0",
                  background: "#747474",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  window.open(fileUrl, "_blank");
                }}
              >
                <AiOutlineFullscreen
                  color="#fff"
                  width={30}
                  height={30}
                  style={{ width: "40px", height: "40px" }}
                />
              </button>
              <button
                style={{
                  position: "absolute",
                  top: 55,
                  right: "20px",
                  border: "0",
                  background: "#747474",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  axios({
                    url: onlyfileUrl,
                    method: "GET",
                    responseType: "blob",
                  }).then((response) => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${props?.chat?.attachedFileName?.split(".").shift().trim()}.${fileType}`);
                    document.body.appendChild(link);
                    link.click();
                  });
                }}
              >
                <HiDownload
                  color="#fff"
                  width={30}
                  height={30}
                  style={{ width: "40px", height: "40px" }}
                />
              </button>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { openChats, groups } = state;
  return {
    groups: groups,
    openChats: openChats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChatsList: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DocViewer));
