import React, { useContext } from 'react'
import { FiMoreHorizontal } from 'react-icons/fi'
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';

export default function InviteExternalComponentPOL({ onClick, color, id }) {
    const { isGerm } = useContext(LanguageContext);
    return (
        <div onClick={() => onClick()} style={{ display: "flex", flexDirection: "column", }}>
            <div style={{ backgroundColor: color ? '#fff' : "#F7F7F7", marginBottom: 10, marginTop: 10, alignSelf: "center", padding: 7, borderRadius: 10, paddingTop:5 }}>
                <div style={{ textAlign: "center", fontSize: 18, fontWeight: 'bold', marginBottom: 3 }}>{isGerm ? DE.ll_nkk : EN.ll_nkk}</div>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                    <img
                        src={require('../Icons/Messages.png').default}
                        alt="P"
                        style={{ height: 30, width: 30 }}
                        id={id}
                    />
                    <img
                        src={require('../Icons/Teams.png').default}
                        alt="P"
                        style={{ height: 30, width: 30, marginLeft: 10, marginRight: 10 }}
                        id={id}
                    />
                    <img
                        src={require('../Icons/Mail.png').default}
                        alt="P"
                        style={{ height: 30, width: 30, marginRight: 10 }}
                        id={id}
                    />
                    <FiMoreHorizontal height={30} width={30} size={20} />
                </div>
            </div>
        </div>
    )
}
