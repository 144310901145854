import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import LanguageContext from '../../helper/LanguageContext';
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from 'react-custom-scrollbars';
import { convertPosition } from '../../helper/convertor';

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(240, 132, 56, 0.8)'
    };
    return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

function Companies(props) {
    let data = props.location.state
    console.log(data)
    const { isGerm } = React.useContext(LanguageContext)
    const history = useHistory();
    const [companies, setCompanies] = useState([])
    const getCompanies = async () => {
        const { data } = await callAPI(`/company/my`, {}, 'GET')
        if (data.status) {
            const companies = data.companies.filter(company => (company.position == 'COMPANY ADMIN') || (company.role === 'ROLE_COMPANY_DEPARTMENT_ADMIN'))
            setCompanies(companies)
            // if (data && data.from) {
            //     let ind =companies.findIndex(e => e.id == data.companyId)
            //     if (companies && companies.length && ind != -1) {
            //         let TT = companies[ind]
            //         setCompanyDetails(TT);
            //         setCompanyId(TT.id);
            //         setArticleName(TT.name);
            //         setshowSingle(true)
            //         setShowList(false)
            //     }
            // }
            // else {
            //     if (CompanyArticleState?.companyArticle && CompanyArticleState?.companyArticle.length && CompanyArticleState?.companyArticle.length == 1) {
            //         let TT = CompanyArticleState?.companyArticle[0]
            //         setCompanyDetails(TT);
            //         setCompanyId(TT.id);
            //         setArticleName(TT.name);
            //         setshowSingle(true)
            //         setShowList(false)
            //     }
            //     else {
            //         setshowSingle(false)
            //         setShowList(true)
            //     }
            // }
        }
    }
    useEffect(() => {
        getCompanies();
    }, [])

    const getCompanyDetails = (id) => {
        history.push({
            pathname: '/company-details/' + id,
            state: { id: id }
        })
    }

    return (
        <React.Fragment>
            <Layout>
                <div class="company_section w-300 company_list">
                    <div className="company_border" style={{ borderBottom: "1px solid #DFDFDF" }}>
                        <div class="heading_icon m-0">
                            <Link to="/articles" className="main_heading"><img width={10}
                                src={process.env.PUBLIC_URL + "/assets/img/back.svg"} />{isGerm ? DE.company : EN.company}
                            </Link>
                        </div>
                    </div>
                    <div className="company_list_ver">
                        <div className="my-scroll min_height m-0">
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}
                                className="bottom-bar">
                                {
                                    companies &&
                                    companies.map(company => (
                                        company.isKjuup ?
                                            <div className="bg-purple" key={company.id}>
                                                <div className="profile-flied m-0">
                                                    <Link to={
                                                        {
                                                            pathname: `/add-company-article/${company.id}`,
                                                            state: { from: "external", companyId: company.id }
                                                        }
                                                    }
                                                        style={{ color: '#212529' }}>
                                                        <div className="profile_name">
                                                            <h3 className="pointer"
                                                                onClick={e => getCompanyDetails(company.id)}>{company.name}</h3>
                                                            <p>{company.street}, {company.postCode}, {company.city}</p>
                                                            {company.owner ?
                                                                <p>{isGerm ? DE.owner_company_admin : EN.owner_company_admin}<span
                                                                    className="theme-color"> {company.owner}</span>
                                                                </p> : ''}
                                                            <p>{company.branch}</p>
                                                            <p>{isGerm ? DE.my_position : EN.my_position} {convertPosition(isGerm,company.position)}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    ))
                                }
                                {
                                    !companies &&
                                    <div className="bg-purple">
                                        <p className="text-center">Record not found!</p>
                                    </div>
                                }
                            </CustomScrollbars>
                        </div>
                    </div>
                </div>
            </Layout>

        </React.Fragment>
    );
}

export default Companies;
