import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import _ from "lodash";
import { Form } from "react-bootstrap";
import { KJUUP_COLORS } from '../helper/constants';
import { Discuss, Rings } from 'react-loader-spinner'
import { Scrollbars } from 'react-custom-scrollbars';

class CityListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            street: '',
            isSeleted: false
        };
    }


    static getDerivedStateFromProps(props, state) {
        if (state.address !== props.defaultValue) {
            console.log("getDerivedStateFromProps")
            return { address: props.defaultValue }
        }
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.address !== this.state.address) {
    //         if(!this.state.isSeleted){
    //             // Geocode.fromAddress(this.state.address).then(
    //             //     (results) => {
    //             //       console.log("results", results);
    //             //       const { lat, lng } = results.results[0].geometry.location;

    //             //       let country;
    //             //       console.log("lat", lat, lng);
    //             //       for (var i = 0; i < results.results[0].address_components.length; i++) {
    //             //         for (
    //             //           var j = 0;
    //             //           j < results.results[0].address_components[i].types.length;
    //             //           j++
    //             //         ) {
    //             //           if (
    //             //             results.results[0].address_components[i].types[j] == "country"
    //             //           ) {
    //             //              country = results.results[0].address_components[i]?.long_name;
    //             //           }
    //             //         }
    //             //       }
    //             //       console.log("country",country)
    //             //       this.props.setParam('lat', lat)
    //             //       this.props.setParam('long', lng)
    //             //       this.props.setParam('country', country)
    //             //     },
    //             //     (error) => {
    //             //       console.error(error);
    //             //     }
    //             //   );
    //         }
    //         console.log("Update called")
    //         // if()
    //       // Now fetch the new data here.
    //     }
    //   }

    handleChange = address => {
        if (this.props.custom) {
            this.props.setVal(address)
            this.props.setSelectedAddress && this.props.setSelectedAddress(false)
        }
        else {
            const add = address?.split(',')
            this.setState({ address: add[0] });
            this.props.setParam('street', add[0])
            if (this.props.isFreeCapacity) {
                console.log(this.props.isFreeCapacity)
                this.props.setParam('city', add[0])
            }
            this.props.setSelectedAddress && this.props.setSelectedAddress(false)
        }
    };

    handleSelect = address => {
        console.log(address)
    };

    setAddress = (address_components) => {
        console.log(address_components)
        const prop = this.props
        this.props.setParam('country', 'india')
        _.each(address_components, function (component) {
            _.each(component.types, function (type) {
                if (type === 'locality') {
                    prop.setParam('city', component.long_name)
                }
                if (type === 'country') {
                    prop.setParam('country', component.long_name)
                }
                if (type === 'postal_code') {
                    prop.setParam('postCode', component.long_name)
                }
            })
        })
    }

    CustomScrollbars = props => (
        <Scrollbars
            renderThumbHorizontal={this.renderThumb}
            renderThumbVertical={this.renderThumb}
            {...props}
        />
    );

    renderThumb = ({ style, ...props }) => {
        const thumbStyle = {
            borderRadius: 6,
            backgroundColor: 'rgba(240, 132, 56, 0.8)'
        };
        return <div className="thumb-style" style={{ ...style, ...thumbStyle }} {...props} />;
    };


    _renderSuggesstion = ({ suggestions, getSuggestionItemProps, loading }) => {
        this.props.renderSuggesstion(suggestions)
    }

    render() {
        return (
            <PlacesAutocomplete
                value={this.props.val}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                highlightFirstSuggestion={true}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Form.Group>
                        <Form.Control
                            {...getInputProps({
                                placeholder: this.props.placeHolder,
                                className: 'location-search-input',
                                disabled: this.props.suggestionLoaded,
                                autoComplete: 'new-password',
                                type: 'text',
                                onFocus:this.props.onFocus,
                                onBlur:this.props.onBlur
                            })}
                        />
                        <img
                            src={process.env.PUBLIC_URL + "assets/img/search.svg"}
                            alt="Generic placeholder"
                        />
                        {this._renderSuggesstion({ suggestions, getSuggestionItemProps, loading })}
                        {/* <div className="autocomplete-dropdown-container" style={{ border: suggestions?.length > 0 ? "1px solid #ddd" : 0 }}>
                            <div style={{ borderWidth: suggestions?.length > 0 ? "1px solid #ddd" : 0, overflow: "scroll", height: suggestions?.length > 0 ? "250px" : "0" }}>
                                {loading &&
                                    <div
                                        style={{ position: "absolute", backgroundColor: "white", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <Rings
                                            height="100"
                                            width="100"
                                            color={KJUUP_COLORS.base_color}
                                            radius="6"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="rings-loading"
                                        />
                                    </div>}
                                {suggestions.map(suggestion => {
                                    const style = suggestion.active
                                        ? { backgroundColor: KJUUP_COLORS.base_color, cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                // className,
                                                style,
                                            })}
                                        >
                                            <span className="small" style={{ color: suggestion.active ? "white" : "#000" }}>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div> */}
                    </Form.Group>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default CityListComponent;