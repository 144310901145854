import React from "react";
import { KJUUP_COLORS } from "../../helper/constants";
import moment from "moment";
import { ReactComponent as GoogleMap } from "../../Icons/GoogleMap.svg";

const LinkSection = ({ handleScroll, urls, props, isBuilding }) => {
  return (
    <div
      onScroll={handleScroll}
      className="wscroll right_wscroll"
      style={{ flex: 1 }}
    >
      <div className="links-content mt-1">
        {urls?.map((chat, index) => (
          <div className="row m-0" key={index}>
            {chat?.items?.length > 0 ?
              <div className="col-12 text-center">
                <div className="date_chat media_date">
                  <p
                    style={{
                      color: props.isInternal
                        ? KJUUP_COLORS.internal
                        : KJUUP_COLORS.base_color,
                    }}
                  >
                    {moment(chat.createdAt).format("MMMM Y")}
                  </p>
                </div>
              </div>
              :
              <></>
            }
            {chat?.items?.map((item, itemIndex) =>
              item?.messageType === "map" ? (
                <div
                  className="row m-0 mt-2 w-100"
                  style={{ background: "#f1f1f1" }}
                >
                  <div className="col-2 p-2">
                    <GoogleMap style={{ width: "100%" }} />
                  </div>

                  {isBuilding ? (
                    <div key={itemIndex} className="col-10 p-2">
                      <a
                        className="theme-color"
                        target="_blank"
                        href={item.fileUrl}
                      >
                        {item.fileUrl}
                      </a>
                    </div>
                  ) : (
                    <div
                      key={itemIndex}
                      className="col-10 p-2"
                      dangerouslySetInnerHTML={{
                        __html: item.message,
                      }}
                    ></div>
                  )}
                </div>
              ) : (
                <div
                  className="row m-0 mt-2 w-100"
                  style={{ background: "#f1f1f1" }}
                >
                  <div
                    key={itemIndex}
                    className="col-12 p-2"
                    dangerouslySetInnerHTML={{
                      __html: isBuilding ? item.fileUrl : item.message,
                    }}
                  ></div>
                </div>
              )
            )}
          </div>
        ))}
        {urls?.length <= 0 && (
          <div className="no_media">
            <img
              width={90}
              src={
                process.env.PUBLIC_URL +
                (props.isInternal
                  ? "/assets/img/no-links-internal.svg"
                  : "/assets/img/no-links.svg")
              }
              alt="Generic placeholder"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkSection;
