import React from 'react';
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { KJUUP_COLORS } from '../../helper/constants';

class CreateArticleChoose extends React.Component {
    static contextType = LanguageContext;
    render() {
        let { close, companyId, deptLocationId } = this.props
        return (

            <div class="w-300" style={{ position: 'absolute', height: "100%", width: "100%" }}>
                <div className="main_heading" onClick={() => close()} style={{ color: KJUUP_COLORS.internal }}>
                    <img width={10} src={process.env.PUBLIC_URL + "/assets/img/backx.svg"} />
                    {this.context.isGerm ? DE.cancel : EN.cancel}
                </div>
                {/* <div className="post_notification">
                            <div className="my_post">
                                <Link to="/add-personal-article">
                                    <img style={{color:"#f08438"}} src={process.env.PUBLIC_URL + "/assets/img/create-article.svg"}/>{this.context.isGerm ? DE.personal_article : EN.personal_article }
                                </Link>
                            </div>
                        </div> */}
                <div className="post_notification">
                    <div className="my_post" style={{ color: KJUUP_COLORS.base_color }}>
                        <Link style={{ color: KJUUP_COLORS.base_color }}
                            to={{
                                pathname: `/add-company-article/${companyId}`,
                                state: { deptLocationId, companyId: companyId }
                            }}
                        >
                            <img color="#f08438" src={process.env.PUBLIC_URL + "/assets/img/CreateArticle.svg"} style={{ marginRight: 10 }} />
                            {this.context.isGerm ? DE.new_article_selection : EN.new_article_selection}
                        </Link>
                    </div>
                    <div className="my_post" style={{ color: KJUUP_COLORS.internal }}>
                        <Link style={{ color: KJUUP_COLORS.internal }} to={{
                            pathname: `/add-company-article/${companyId}`,
                            state: { from: "internal", articleType: 1, companyId: companyId, deptLocationId }
                        }}>
                            <img color="#f08438" src={process.env.PUBLIC_URL + "/assets/img/CreateArticleGreen.svg"} style={{ marginRight: 10 }} />
                            {this.context.isGerm ? DE.new_article_selection_internal : EN.new_article_selection_internal}
                        </Link>
                    </div>
                </div>
            </div>

        );
    }
}

export default CreateArticleChoose;