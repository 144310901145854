import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import { BASE_URL, goBack } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { Positions } from "../../Languages/vars/Positions";
import { ReactComponent as AddSvg } from "../../Icons/Add.svg";
import { Animated } from "react-animated-css";
import { convertRole } from "../../helper/convertor";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function Company(props) {

  const { isGerm } = React.useContext(LanguageContext);
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [employeePosition, setEmployeePosition] = useState();
  const [showPositionDialog, setPositionDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const hidePositionDialog = () => setPositionDialog(false);
  const [company, setCompany] = useState({});
  const positions = Positions;
  const getCompanies = async () => {
    const { data } = await callAPI(
      `/company/getAllRegCompaniesForHome`,
      {
        page,
        limit,
        lang: "en",
      },
      "GET"
    );
    if (data.status) {
      setCompanies(data.companies);
      setAllCompanies(data.companies);
    }
  };
  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanyDetails = (id) => {
    history.push({
      pathname: "/foreign-company-details/" + id,
      state: { id: id },
    });
  };
  const followCompany = async (id) => {
    const { data } = await callAPI(
      `/company/${id}/follow`,
      {
        id: id,
      },
      "GET"
    );
    $crud.notify({
      type: data.status ? "success" : "error",
      message: data.message,
    });
    getCompanies();
  };
  const companyFilter = (value) => {
    console.log(value);
    setSearch(value);
    const filterCompanies = companies.filter((company) => {
      if (value === "") {
        return company;
      } else if (company.name.toLowerCase().includes(value.toLowerCase())) {
        return company;
      }
    });
    if (value === "") {
      console.log(allCompanies);
      setCompanies(allCompanies);
    } else {
      setCompanies(filterCompanies);
    }
    console.log(filterCompanies);
  };
  const handleSubmit = async () => {
    console.log(employeePosition);
    if (!employeePosition) {
      await $crud.notify({
        type: "error",
        message: "Please select position",
      });
    } else {
      const params = {
        name: company.name,
        email: company.email,
        corporateForm: company.corporateForm,
        branch: company.branch,
        street: company.street,
        postCode: company.postCode,
        city: company.city,
        country: company.country,
        lat: company.lat,
        long: company.long,
        position: employeePosition,
      };
      console.log(params);
      const { data } = await callAPI("/company", params, "POST");
      setPositionDialog(false);
      $crud.notify({
        type: data.status ? "success" : "error",
        message: data.message,
      });
      getCompanies();
    }
  };
  const [visible, setVisible] = useState(true);
  const handleBack = () => {
    setVisible(false);
    setTimeout(() => {
      goBack();
    }, 1000);
  };
  return (
    <React.Fragment>
      <Layout>
        <Animated
          animationIn="slideInLeft"
          animationOutDuration={800}
          isVisible={true}
        >
          <div class="company_section w-300 company_list">
            <div className="company_header company_border">
              <div class="heading_icon">
                <div>
                  <a onClick={handleBack} className="main_heading pointer">
                    {" "}
                    <img
                      width={10}
                      src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                    />
                    {isGerm ? DE.company_list : EN.company_list}
                  </a>
                </div>
                <div className="icons_heading">
                  <Link to="/add-company">
                    <img
                      width={18}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/Add-Company-PP.svg"
                      }
                    />
                  </Link>
                  <a href="#">
                    <img
                      width={18}
                      src={process.env.PUBLIC_URL + "/assets/img/Location.svg"}
                    />
                  </a>
                </div>
              </div>
              <div className="search">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Control
                    autocomplete="off"
                    type="search"
                    placeholder={isGerm ? DE.search : EN.search}
                    onChange={(e) => companyFilter(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="my-scroll profile_height m-0">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                className="bottom-bar"
              >
                <div className="company_list_ver">
                  {companies &&
                    companies.map((company) => (
                      <div className="bg-purple" key={company.id}>
                        <div className="row">
                          <div className="col-md-5 pr-0">
                            <div className="user_profile">
                              <div className="user_img">
                                <a href="#">
                                  <img
                                    width={55}
                                    height={55}
                                    src={
                                      company.logo
                                        ? BASE_URL + company.logo
                                        : process.env.PUBLIC_URL +
                                          "assets/img/Companies-No-Image.svg"
                                    }
                                    alt="Generic placeholder"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 pl-0">
                            <div className="follow">
                              <div className="follow_item text-center">
                                <h3>{company.followerCount}</h3>
                                <Link to={`/company-followers/${company.id}`}>
                                  <p>{isGerm ? DE.followers : EN.followers}</p>
                                </Link>
                              </div>
                              <div className="follow_item text-center">
                                <h3>{company.articleCount}</h3>
                                <p>{isGerm ? DE.article : EN.article}</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 text-right">
                            <a
                              onClick={(e) => {
                                followCompany(company.id);
                              }}
                              className={
                                company.followStatus
                                  ? "svg_icon pointer active-like-follow"
                                  : "svg_icon pointer"
                              }
                            >
                              <svg
                                className="mr-1"
                                width="22px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 19.81 20.9"
                              >
                                <path
                                  d="M295.14,269.16a1.28,1.28,0,0,0-1.32-1.23h-.91a5.11,5.11,0,0,1-8.76,0h-.91a1.27,1.27,0,0,0-1.31,1.23l-1.31,9a1.28,1.28,0,0,0,1.31,1.24h13.21a1.27,1.27,0,0,0,1.3-1.24Z"
                                  transform="translate(-280.17 -258.91)"
                                />
                                <path
                                  className="cls-1"
                                  d="M288.53,268.2a4.42,4.42,0,1,0-4.41-4.42A4.43,4.43,0,0,0,288.53,268.2Z"
                                  transform="translate(-280.17 -258.91)"
                                />
                                <line
                                  className="cls-2"
                                  x1="16.72"
                                  y1="0.75"
                                  x2="16.72"
                                  y2="3.47"
                                />
                                <line
                                  className="cls-2"
                                  x1="16.72"
                                  y1="3.47"
                                  x2="16.72"
                                  y2="6.18"
                                />
                                <line
                                  className="cls-2"
                                  x1="16.72"
                                  y1="3.47"
                                  x2="14.08"
                                  y2="3.47"
                                />
                                <line
                                  className="cls-2"
                                  x1="19.36"
                                  y1="3.47"
                                  x2="16.72"
                                  y2="3.47"
                                />
                              </svg>
                            </a>
                            <AddSvg
                              onClick={(e) => {
                                setPositionDialog(true);
                                setEmployeePosition("");
                                setCompany(company);
                              }}
                              className="mt-1 mr-1 pointer custom-svg"
                              height={21}
                            />
                          </div>
                        </div>
                        <div className="profile-flied p-0">
                          <div className="profile_name">
                            <h3
                              className="pointer"
                              onClick={(e) => getCompanyDetails(company.id)}
                            >
                              {company.name}
                            </h3>
                            {company.owner ? (
                              <p>{isGerm? DE.owner_company_admin_new: EN.owner_company_admin_new} : {company.owner}</p>
                            ) : (
                              ""
                            )}
                            <p>{convertRole(company.branch,isGerm)}</p>
                            <p>
                              {company.street}, {company.postCode},{" "}
                              {company.city}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </Animated>
        <Modal
          show={showPositionDialog}
          onHide={hidePositionDialog}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{isGerm ? DE.your_position_within_company : EN.your_position_within_company}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup variant="flush">
              {positions.map((position) => (
                <div
                  onClick={(e) => setEmployeePosition(position.name_en)}
                  key={position.id}
                  className="form-check p"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id={position.id}
                    value={position.name_en}
                  />
                  <label
                    className="form-check-label pointer"
                    htmlFor={position.id}
                  >
                    {isGerm ? position.name_de : position.name_en}
                  </label>
                </div>
              ))}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hidePositionDialog}>
            {isGerm ? DE.close_modal: EN.close_modal}
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </React.Fragment>
  );
}

export default Company;
