import { Media } from "react-bootstrap";
import { BASE_URL } from "../../helper/constants";
import { Skeleton } from "@material-ui/lab";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import React, { useEffect, useState } from "react";
import LanguageContext from "../../helper/LanguageContext";
import moment from "moment";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useHistory } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { useSelector } from "react-redux";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyDeselected.svg";
import { useCurrentUserProfileData } from "../../helper/UserFactory";
import {
  convertBranchRole,
  convertHtmlString,
  tempFunction,
} from "../../helper/convertor";
import PlayerFeed from "../../components/PlayerFeed";
import ReadMores from "../../components/ReadMores";
import RCPlayer from "../../components/RCPlayer";
const extractUrls = require("extract-urls");

export function CompanyFeed({
  isInternal,
  article,
  index,
  expandArticle,
  reloadArticle,
  handleFollow,
  viewArticleDetail,
  handleLike,
  isDetails = false,
}) {
  const { isGerm } = React.useContext(LanguageContext);
  const [likeStatus, setLikeStatus] = useState(false);
  const [followStatus, setFollowStatus] = useState(false);
  const [_Link, _setLink] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [showPlayer, setShowPlayer] = useState(false);
  const [initialIndex, setInitalIndex] = useState(0);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const likeArticle = async (article) => {
    setLikeStatus(true);
    if (user?.id !== article.postedById) {
      const { data } = await callAPI(
        `/article/${article.id}/like`,
        {
          id: article.id,
          emoji: 6,
          lang: "en",
        },
        "GET"
      );
      handleLike(article.id);
    } else {
    }
    setTimeout(() => {
      setLikeStatus(false);
    }, 1000);
  };
  const followArticleUser = async ({
    id,
    followStatus,
    postedById,
    createdById,
    createdBy,
    userId,
  }) => {
    setFollowStatus(true);
    if (isInternal) {
      if (user?.id !== postedById) {
        const { data } = await callAPI(
          `/article/${id}/followUser`,
          {
            id: id,
          },
          "GET"
        );
        await handleFollow(userId, followStatus, postedById);
        console.log(data);
      } else {
      }
      setTimeout(() => {
        setFollowStatus(false);
      }, 1300);
    } else {
      setFollowStatus(true);
      if (user?.id !== postedById) {
        const { data } = await callAPI(
          `/article/${id}/follow`,
          {
            id: id,
          },
          "GET"
        );
        await handleFollow(id, followStatus, postedById);
        console.log(data);
      } else {
      }
      setTimeout(() => {
        setFollowStatus(false);
      }, 1300);
    }
  };

  const getCompany = (createdById) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const profile = useCurrentUserProfileData();
    const { companies } = profile;
    if (companies.length > 0) {
      const getId = companies.find((c) => c.id === createdById);
      if (getId) {
        history.push({
          pathname: `/company-details/${getId.id}`,
          state: article.id,
        });
      } else {
        history.push({
          pathname: `/foreign-company-details/${createdById}`,
          state: article.id,
        });
      }
    } else {
      history.push({
        pathname: `/foreign-company-details/${createdById}`,
        state: article.id,
      });
    }
  };

  const viewFullScreen = async () => {
    viewArticleDetail(article);
  };
  const [articleBranch, setArticleBranch] = React.useState([]);
  React.useEffect(() => {
    setArticleBranch(convertBranchRole(article.buildingIndustryRole, isGerm));
  }, [article]);

  useEffect(() => {
    if (article?.description && typeof article?.description === "string") {
      // let dec = article?.description.replace(/<[^>]*>/g, '')
      let datax = extractUrls(article?.description);
      datax =
        datax && Array.isArray(datax)
          ? datax?.map((e) => {
              return e.replace("&quot", "");
            })
          : datax;
      // const data = extractUrls(route.params.description.replace(/<[^>]*>/g, ''));
      let data = [...new Set(datax)];
      let all = [];
      if (data && data.length > 0) {
        for (let obj of data) {
          let x = {
            href: obj,
          };
          let lnk = obj;
          if (lnk.match("vimeo")) {
            x.videoType = "vimeo";
            all.push(x);
          }
          if (lnk.match("youtube") || lnk.match("youtu")) {
            x.videoType = "youtube";
            all.push(x);
          }
        }
        if (all.length > 0) {
          if (article.uploadUrl && article.uploadUrl.length > 0)
            _setLink([...all, ...article.uploadUrl]);
          else _setLink([...all]);
        } else if (article.uploadUrl && article.uploadUrl.length > 0) {
          _setLink([...article.uploadUrl]);
        }
      } else {
        if (article.uploadUrl && article.uploadUrl.length > 0) {
          _setLink([...article.uploadUrl]);
        }
      }
    } else if (article.uploadUrl && article.uploadUrl.length > 0) {
      _setLink([...article.uploadUrl]);
    }
  }, [article]);

  return (
    <div className="feed-details">
      <Media>
        {!isInternal ? (
          <img
            width={50}
            height={50}
            className="mr-3 news_profile"
            src={
              article.avatar
                ? BASE_URL + article.avatar
                : process.env.PUBLIC_URL + "/assets/img/Companies-No-Image.svg"
            }
            alt="Generic placeholder"
            style={{objectFit: "cover"}}
          />
        ) : (
          <img
            width={50}
            height={50}
            className="mr-3 news_profile"
            src={
              article.postedByImage
                ? BASE_URL + article.postedByImage
                : process.env.PUBLIC_URL + "assets/img/head.svg"
            }
            alt="Generic placeholder"
            style={{objectFit: "cover"}}
          />
        )}
        <Media.Body>
          <div className="authore_name">
            {article.id ? (
              <h5
                style={{ whiteSpace: "pre-wrap !important" }}
                onClick={(e) => getCompany(article.createdById)}
              >
                {article.userFullName || article.createdBy}
              </h5>
            ) : (
              <Skeleton variant="text" />
            )}
          </div>
          {!isInternal ? (
            <div className="w-100">
              {article.postedByName ? (
                <h6>
                  {/* {isGerm ? DE.by : EN.by}{" "} */}
                  <span className="text-73" style={{color:"#000"}}>
                    {article.postedByName ? (
                      article.postedByName
                    ) : (
                      <Skeleton variant="text" />
                    )}
                  </span>{" "}
                  -
                  <span>
                    {" "}
                    {article.id ? articleBranch : <Skeleton variant="text" />}
                  </span>
                </h6>
              ) : (
                <Skeleton variant="text" />
              )}

              <div className="w-100">
                {article.product ? (
                  <h6 className="product_user">
                    Product: <span>{article.product}</span>
                  </h6>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="w-100">
              {article.id ? (
                <h6>
                  <span>
                    {" "}
                    {article.id ? articleBranch : <Skeleton variant="text" />}
                  </span>
                </h6>
              ) : (
                <Skeleton variant="text" />
              )}

              <div className="w-100">
                {article.product ? (
                  <h6 className="product_user">
                    Product: <span>{article.product}</span>
                  </h6>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </Media.Body>
      </Media>
      {article.id ? (
        <p className="m-0 mt-2 mb-2">
          <Link
            style={{ color: "#212529", fontSize: 13 }}
            to={{
              pathname: `/feed-detail/${article.id}`,
              state: { isInternal },
            }}
            className="font-weight-bold"
          >
            {article.title}
          </Link>
        </p>
      ) : (
        <Skeleton variant="text" />
      )}
      {article?.description ? (
        <p className="small" style={{ color: "#212529" }}>
          <ReadMores>{article?.description}</ReadMores>
        </p>
      ) : (
        <></>
      )}
      {/* {_Link && _Link.length > 0 && */}
      <PlayerFeed
        Links={_Link}
        article={article}
        onPress={(data, index) => {
          setModalData(data);
          setInitalIndex(index);
          setShowPlayer(true);
        }}
      />
      {showPlayer ? (
        <RCPlayer
          on
          Links={modalData}
          initialIndex={initialIndex}
          closeModal={() => {
            setModalData([]);
            setInitalIndex(0);
            setShowPlayer(false);
          }}
        />
      ) : (
        <></>
      )}
      {/* } */}
      <div className="date-follow">
        <p>{moment(article.createdAt).format("D MMMM HH:MM")}</p>
        <Link to={`/people-likes/${article.id}`} className="follow-img">
          {article.likes &&
            article.likes.map((like, i) =>
              i >= 3 ? (
                <></>
              ) : like.avatar ? (
                <img
                  key={i}
                  src={BASE_URL + like.avatar}
                  alt="Generic placeholder"
            style={{objectFit: "cover"}}
                />
              ) : (
                <div
                  key={i}
                  className="svg_icon news_profile"
                  style={{ display: "inline-block" }}
                >
                  <svg
                    width="14px"
                    height="14px"
                    id="Layer_4"
                    data-name="Layer 4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 39.54 39.54"
                  >
                    <path
                      className="cls-1"
                      d="M271.14,229.57l-3.12,1.5a19.26,19.26,0,0,0,13.16,5.26h.17a19.28,19.28,0,0,0,12.32-4.53c-1.32-.59-4.69-2.23-4.69-2.23s-.17-2.74-1.19-2.9l.17-1.85a13.45,13.45,0,0,0,1.19-4.18s1.19,1,1.62-1.21,1-6.19-.85-5.23a22.92,22.92,0,0,0,0-5.14c-.34-1.93-2.08-7.88-9.9-7.88s-9.57,5.95-9.91,7.88a22.69,22.69,0,0,0,.05,5.14c-1.87-1-1.28,3.06-.85,5.23s1.61,1.21,1.61,1.21a13.45,13.45,0,0,0,1.19,4.18l.17,1.85C271.3,226.83,271.14,229.57,271.14,229.57Z"
                      transform="translate(-261.48 -197.24)"
                    />
                    <circle className="cls-2" cx="19.77" cy="19.77" r="19.32" />
                  </svg>
                </div>
              )
            )}

          <span>{article.likeCount ? article.likeCount : ""}</span>
        </Link>
        {article.isEmojiUsed && (
          <SmilyIcon
            width={20}
            className={`${
              isInternal ? "smily-icon-internal" : "smily-icon"
            } active float-right`}
          />
        )}
      </div>
      <div
        className={
          !isInternal
            ? user?.id === article.postedById
              ? "date-follow no-border like_folo no-hover-effect noHover"
              : "date-follow no-border noHover"
            : user?.id === article.postedById
            ? "date-follow no-border-internal like_folo no-hover-effect noHover"
            : "date-follow no-border-internal noHover"
        }
      >
        <a
          onClick={(e) => {
            likeArticle(article);
          }}
          className={`svg_icon pointer ${
            article.likeStatus
              ? isInternal
                ? "social-icon-active_internal"
                : "social-icon-active"
              : ""
          } ${
            likeStatus && user?.id !== article.postedById
              ? "bouncing-animation"
              : ""
          }`}
        >
          <svg
            className="mr-1"
            width="17px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.26 15.9"
          >
            <path
              class="cls-1"
              d="M146.53,100H144.2a.59.59,0,0,0-.6.59v6.92a.59.59,0,0,0,.6.59h2.33a.59.59,0,0,0,.6-.59v-6.92A.59.59,0,0,0,146.53,100Z"
              transform="translate(-143.15 -92.63)"
            />
            <path
              class="cls-1"
              d="M156.94,98.05h-3.27V94.72c0-.58-.64-1.64-1.15-1.64H151.3c-.23,0-.51.45-.51,1.19v2a6.17,6.17,0,0,1-1.06,2.21c-.45.61-1.35,1.15-1.35,2v5.9c0,.57.48,1.31,1.73,1.31h5.58c.77,0,1.38-.13,2.15-1.86a8.5,8.5,0,0,0,1.12-3.91V99.56C159,98.82,158.32,98.05,156.94,98.05Z"
              transform="translate(-143.15 -92.63)"
            />
          </svg>
          {isGerm ? DE.like : EN.like}
        </a>
        <a
          onClick={(e) => {
            followArticleUser(article);
          }}
          className={`svg_icon pointer ${
            (
              isInternal
                ? article.userFollowStatus
                : isInternal
                ? article.userFollowStatus
                : article.followStatus
            )
              ? isInternal
                ? "social-icon-active_internal"
                : "social-icon-active"
              : ""
          }${
            followStatus && user?.id !== article.postedById
              ? "bouncing-animation"
              : ""
          }`}
        >
          <svg
            className="mr-1"
            width="17px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.08 15.9"
          >
            <path
              class="cls-1"
              d="M280.29,283.57a1,1,0,0,0-1-.92h-.13a4.85,4.85,0,0,1-3.83,1.85,4.75,4.75,0,0,1-3.78-1.85h-.19a1,1,0,0,0-1,.92l-1,6.72a1,1,0,0,0,1,.93h9.9a1,1,0,0,0,1-.93Z"
              transform="translate(-268.95 -275.77)"
            />
            <path
              class="cls-1"
              d="M275.34,282.85a3.32,3.32,0,1,0-3.31-3.32A3.32,3.32,0,0,0,275.34,282.85Z"
              transform="translate(-268.95 -275.77)"
            />
            <line class="cls-2" x1="12.65" y1="0.67" x2="12.65" y2="2.71" />
            <line class="cls-2" x1="12.65" y1="2.71" x2="12.65" y2="4.75" />
            <line class="cls-2" x1="12.65" y1="2.71" x2="10.67" y2="2.71" />
            <line class="cls-2" x1="14.63" y1="2.71" x2="12.65" y2="2.71" />
          </svg>
          {(isInternal ? article.userFollowStatus : article.followStatus) ? (
            <span> {isGerm ? DE.following : EN.following}</span>
          ) : (
            <span> {isGerm ? DE.follow : EN.follow}</span>
          )}
        </a>
      </div>
    </div>
  );
}
