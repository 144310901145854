import React, { useState, useEffect } from 'react'
import styles from './ImagesWithVid.css'
import { BASE_URL } from '../helper/constants';
import { useDispatch } from 'react-redux';
import { SET_VIEWER_IINITAL_INDEX, SET_VIEWER_IMAGES } from '../store';
import ReactPlayer from 'react-player'


function ImagesWithVideos({ data, isC }) {
    const [onlyFour, setOnlyFour] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data && data.length > 0) {
            if (data.length >= 4) {
                setOnlyFour(data.slice(1, 4));
            } else {
                setOnlyFour(data);
            }
        }
    }, [data]);

    const onPress = (s, index) => {
        dispatch({ type: SET_VIEWER_IINITAL_INDEX, index: index })
        dispatch({ type: SET_VIEWER_IMAGES, ViewerImages: data })
    }

    const _renderVideoWithPreview = (val) => {
        if (val && val.href)
            return <ReactPlayer
                light={true}
                url={val.href}
                controls={false}
                muted={true}
                height={'100%'}
                width={'100%'}
                style={{ backgroundColor: "#fff" }}
                playIcon={<></>}
            />
    }

    const _renderVideoPlayerPlaceholder = (val, type) => {
        return (
            <div
                onClick={() => {
                    onPress(data, 0)
                    // navigation.navigate('PreviewVideoImages', { index: 0, media: data });
                }}
                style={{
                    height: (type == 1 || type == 2) ? 250 : "100%",
                    width: "100%",
                    position: "relative",
                    backgroundColor: "#fff"
                }}
            >
                {_renderVideoWithPreview(val, type)}
                <div style={{ alignItems: "center", zIndex: 10, position: "absolute", display: "flex", flexDirection: "column", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <div style={{ flex: 1 }} />
                    <img style={{ height: 30, width: 30 }} src={process.env.PUBLIC_URL + '/assets/img/DN4X.png'} />
                    <div style={{ flex: 1 }} />
                </div>
            </div>
        );
    };

    return (
        <div style={{}}>
            {data.length > 0 &&
                (data.length > 3 ? (
                    <div style={{ display: 'flex', height:'40vh' }}>
                        <div style={{ flex: 6, display: "flex" }}>
                            {data[0].videoType ? (
                                _renderVideoPlayerPlaceholder(data[0])
                            ) : (
                                <div
                                    onClick={() => {
                                        onPress(data, 0)
                                    }}
                                    style={{ flex: 1 }}
                                >
                                    <img
                                        style={{ height: '100%', width: '100%', objectFit: "cover" }}
                                        src={data[0].local || isC ? data[0].url : BASE_URL + (data[0].href ? data[0].href : (data[0].local || isC ? data[0].url : data[0].fileUrl))}
                                        alt="image"
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{ flex: 4, marginLeft: 3, display: "flex", flexDirection: "column" }}>
                            {onlyFour.map((val, index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        onPress(data, index+1)
                                    }}
                                    style={{ height: '32.7%', marginTop: index === 0 ? 0 : 3, position: 'relative' }}
                                >
                                    {val.videoType ? (
                                        <div
                                            onClick={() => {
                                                // navigation.navigate('PreviewVideoImages', { index: 0, media: data });
                                            }}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                position: "relative"
                                            }}
                                        >
                                            <ReactPlayer
                                                light={true}
                                                url={val.href}
                                                controls={false}
                                                muted={true}
                                                height={'100%'}
                                                width={'100%'}
                                                playIcon={<></>}
                                            />
                                            {/* <img style={{ height: "100%", width: "100%" }} src={process.env.PUBLIC_URL + '/assets/img/BG-Video-Feed.png'} />
                                             */}
                                            {/* <PlayCirIcon height={40} width={40} /> */}
                                            <div style={{ alignItems: "center", zIndex: 10, position: "absolute", display: "flex", flexDirection: "column", top: 0, left: 0, right: 0, bottom: 0 }}>
                                                <div style={{ flex: 1 }} />
                                                <img style={{ height: 20, width: 20 }} src={process.env.PUBLIC_URL + '/assets/img/DN4X.png'} />
                                                <div style={{ flex: 1 }} />
                                            </div>
                                        </div>
                                    ) : (
                                        <img
                                            style={{ height: '100%', width: '100%', objectFit: "cover" }}
                                            src={val.local || isC ? val.url : BASE_URL + (val.href ? val.href : (val.local || isC ? val.url : val.fileUrl))}
                                            alt="image"
                                        />
                                    )}
                                    {index === onlyFour.length - 1 && data.length - 4 > 0 && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                                width: '100%',
                                                height: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderColor: '#DFDFDF',
                                                textAlign: 'center',
                                                justifyItems: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <span style={{ color: 'white', fontSize: '4.5vh', fontWeight: "bold" }}>
                                                +{data.length - 4}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    data.length == 3 ?
                        <div style={{ display: 'flex', height:'35vh' }}>
                            <div style={{ flex: 6, display: "flex" }}>
                                {data[0].videoType ? (
                                    _renderVideoPlayerPlaceholder(data[0], 3)
                                ) : (
                                    <div
                                        style={{ flex: 1 }}
                                        onClick={() => {
                                            onPress(data, 0)
                                        }}
                                    >
                                        <img
                                            style={{ height: '100%', width: '100%', objectFit: "cover" }}
                                            src={data[0].local || isC ? data[0].url : BASE_URL + (data[0].href ? data[0].href : (data[0].local || isC ? data[0].url : data[0].fileUrl))}
                                            alt="image"
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ flex: 4, marginLeft: 3, display: "flex", flexDirection: "column" }}>
                                {data.map((val, index) => (
                                    index != 0 ?
                                        <div
                                            key={index}
                                            onClick={() => {
                                                onPress(data, index+1)
                                            }}
                                            style={{ height: "49.7%", marginTop: index === 1 ? 0 : 3 }}
                                        >
                                            {val.videoType ? (
                                                <div
                                                    onClick={() => {
                                                        // navigation.navigate('PreviewVideoImages', { index: 0, media: data });
                                                    }}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        position: "relative"
                                                    }}
                                                >
                                                    {/* <img style={{ height: "100%", width: "100%" }} src={process.env.PUBLIC_URL + '/assets/img/BG-Video-Feed.png'} /> */}
                                                    <ReactPlayer
                                                        light={true}
                                                        url={val.href}
                                                        controls={false}
                                                        muted={true}
                                                        height={'100%'}
                                                        width={'100%'}
                                                        playIcon={<></>}
                                                    />
                                                    {/* <PlayCirIcon height={40} width={40} /> */}
                                                    <div style={{ alignItems: "center", zIndex: 10, position: "absolute", display: "flex", flexDirection: "column", top: 0, left: 0, right: 0, bottom: 0 }}>
                                                        <div style={{ flex: 1 }} />
                                                        <img style={{ height: 20, width: 20 }} src={process.env.PUBLIC_URL + '/assets/img/DN4X.png'} />
                                                        <div style={{ flex: 1 }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <img
                                                    style={{ height: '100%', width: '100%', objectFit: "cover" }}
                                                    src={val.local || isC ? val.url : BASE_URL + (val.href ? val.href : (val.local || isC ? val.url : val.fileUrl))}
                                                    alt="image"
                                                />
                                            )}
                                            {index === onlyFour.length - 1 && data.length - 4 > 0 && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                        width: '100%',
                                                        padding: 5,
                                                        height: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderColor: '#DFDFDF'
                                                    }}
                                                >
                                                    <span style={{ color: 'white', fontSize: '4vh' }}>
                                                        +{data.length - 4}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <></>
                                ))}
                            </div>
                        </div>
                        :
                        data.length == 2 ?
                            <div style={{ display: 'flex', height: "35vh" }}>
                                {data.map((val, index) => (
                                    <div key={index} style={{ flex: 1, display: "flex", marginLeft: index == 0 ? 0 : 3 }}>
                                        {val.videoType ? (
                                            _renderVideoPlayerPlaceholder(val, 2)
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    onPress(data, index)

                                                }}
                                                style={{ flex: 1 }}
                                            >
                                                <img
                                                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                                    src={val.local || isC ? val.url : BASE_URL + (val.href ? val.href : (val.local || isC ? val.url : val.fileUrl))}
                                                    alt="image"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            :
                            <div onClick={() => onPress(data, 0)} style={{}}>
                                {data[0].videoType ? (
                                    _renderVideoPlayerPlaceholder(data[0], 1)
                                ) : (
                                    <img
                                        style={{ width: '100%', objectFit: "contain" }}
                                        src={data[0].local || isC ? data[0].url : BASE_URL + (data[0].href ? data[0].href : (data[0].local || isC ? data[0].url : data[0].fileUrl))}
                                        alt="image"
                                    />
                                )}
                            </div>
                ))}
        </div>
    )
}



export default ImagesWithVideos