import React, { memo, useMemo, useState } from "react";
import { convertHtmlString } from "../helper/convertor";
import LanguageContext from "../helper/LanguageContext";
import { DE } from "../Languages/DE";
import { EN } from "../Languages/EN";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = (e) => {
        e.preventDefault();
        setIsReadMore(!isReadMore);
    };
    const { isGerm } = React.useContext(LanguageContext);
    const regex = /(<a[^>]*>.*?<\/a>)|(https?:\/\/[^\s<>\"]+)/g;
    text?.replace(regex, (match, p1, p2) => {
        // p1 will contain the matched <a> tag if it exists
        // p2 will contain the URL if it exists
        if (p1) {
            // If <a> tag exists, return it as it is
            return p1;
        } else {
            // If not, wrap the URL in <a> tag
            return `<a href="${p2}" target="_blank">${p2}</a>`;
        }
    })
    return (
        <p
            className="text"
            style={{
                wordWrap: "break-word",
                overflowWrap: "break-word"
            }}
        >
            {isReadMore && text ?
                <span className="m-0" dangerouslySetInnerHTML={{ __html: convertHtmlString(text?.slice(0, 150)?.replace(/\n/g, "<br />")?.replace(/\\\//g, "")) }}></span>
                :
                <span className="m-0" dangerouslySetInnerHTML={{ __html: convertHtmlString(text?.replace(/\n/g, "<br />")?.replace(/\\\//g, "")) }}></span>
            }
            {text?.length > 200 ?
                <span style={{}} className="see-more pointer theme-color"
                    onClick={toggleReadMore}
                >{isReadMore ? (isGerm ? DE.see_more : EN.see_more) : (isGerm ? DE.see_less : EN.see_less)}</span>
                :
                <></>}
        </p>
    );
};

export default memo(ReadMore)