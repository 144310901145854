import { AES, enc } from "crypto-js";

// Encrypt a message using the secret key
export const encryptMessage = (message, secretKey) => {
  return secretKey && AES.encrypt(message, secretKey).toString();
};
// Decrypt a message using the secret key
export const decryptMessage = (ciphertext, secretKey) => {
  return secretKey && AES.decrypt(ciphertext, secretKey)?.toString(enc?.Utf8);
};


export const emojiUnicode=(emoji)=> {
  let hex = emoji.codePointAt(0).toString(16)
  return "0x" + hex
};

export const emojiDecode=(emoji)=> {
  let emj = String.fromCodePoint(emoji)
  return emj
};
