import React, { useState, useEffect } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import {
  BsChevronDown,
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { $crud } from "../helper/CrudFactory";
import { BranchPosition } from "../Languages/vars/BranchPosition";
import { LoadingDots } from "../LoadingDots/LoadingDots";
import { SET_USER, store } from "../store";
import RegisterRole from "./RegisterRole";
import { DE } from "../Languages/DE";
import { EN } from "../Languages/EN";
import LanguageContext from "../helper/LanguageContext";
import { callAPI } from "../helper/apiUtils";
import { convertBranchRole } from "../helper/convertor";
import { SecurityPIN } from "./SecurityPIN";
import { TOGGLE_DRAWER } from "../helper/constants";
import { useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { Scrollbar } from 'react-scrollbars-custom';

function Register(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [isGerms, setIsGerm] = useState(true);
  const [language, setLanguage] = React.useState("DE");
  const [token, setToken] = useState("")
  const [isKjuupPlus, setIsKjuupPlus] = useState(false)
  const [branchData, setBranchData] = useState(null)
  const [showPIN, setShowPIN] = useState(false)
  const dispatch = useDispatch()
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const token = query.get("token") || undefined;
  const [params, setParams] = useState({
    firstName: "",
    lastName: "",
    password: "",
    lang: isGerms ? "de" : "en",
    buildingIndustryRole: "Others",
    areaOfActivity: "abc",
    fcmId: "rewszsdfswfw",
    token,
    username: "",
    kjupp: isKjuupPlus ? 1 : 0,
    isKjuupPlus: isKjuupPlus
  });
  const [viewPassword, setViewPassword] = useState(false);
  const [roleSelected, setRoleSelected] = useState(false);
  const [showRoles, SetShowRoles] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    buildingIndustryRole: "",
    areaOfActivity: "",
    fcmId: "",
    username: "",
    kjupp: isKjuupPlus ? 1 : 0,
    isKjuupPlus: isKjuupPlus
  });
  const position = BranchPosition;
  const [isInvalid, setIsInvalid] = useState(false);
  const [popup, setPopup] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState("");
  const [user, setUser] = useState("");
  const setParam = (name, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
      };
    });
  };
  useEffect(() => {
    if (!branchData)
      setParams({ ...params, token: token, isKjuupPlus: isKjuupPlus, kjupp: isKjuupPlus ? 1 : 0 })
    else {
      if (branchData.deptLocation && branchData.deptLocation != "null") {
        setParams({ ...params, token: token, isKjuupPlus: isKjuupPlus, kjupp: isKjuupPlus ? 1 : 0, deptLocation: branchData.deptLocation, isImportCompany: (branchData.isImportCompany == "1" || branchData.isImportCompany == 1) ? true : false })
      }
      else {
        setParams({ ...params, token: token, isKjuupPlus: isKjuupPlus, kjupp: isKjuupPlus ? 1 : 0, isImportCompany: (branchData.isImportCompany == "1" || branchData.isImportCompany == 1) ? true : false })
      }
    }
  }, [token, isKjuupPlus, branchData])

  useEffect(() => {
    window.branch.init('key_live_oo9jqKxBpFc8WHQIPwZikihiCCd4oaEQ', function (err, data) {
    });
    window.branch.data(function (err, data) {
      setToken(data?.data_parsed?.registraTionToken)
      setIsKjuupPlus((data?.data_parsed?.isKjuupPlus == "true" || data?.data_parsed?.isKjuupPlus == true) ? true : false)
      setBranchData(data.data_parsed)
      console.log('--------------->branch data', data.data_parsed);
    });
    window.branch.first(function (err, data) {

    });
  }, [])

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    let registerLang = localStorage.getItem("regiLang");
    if (registerLang) {
      setIsGerm(registerLang == 2 ? true : false);
      setLanguage(registerLang == 2 ? "DE" : "EN");
    }
    else {
      if (userLang === "de") {
        localStorage.setItem("switch", "2");
        setIsGerm(true);
        setLanguage("DE");
      } else {
        localStorage.setItem("switch", "1");
        setIsGerm(false);
        setLanguage("EN");
      }
    }
  }, []);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    //Role
    if (!params.buildingIndustryRole) {
      formIsValid = false;
      errors["buildingIndustryRole"] = isGerms
        ? DE.invalid_fiels
        : EN.invalid_fiels;
    }
    //Firstname
    if (!params.firstName) {
      formIsValid = false;
      errors["firstName"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!params.lastName) {
      formIsValid = false;
      errors["lastName"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!params.email) {
      formIsValid = false;
      errors["email"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    const passwordRegex = /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+|}{:"?><,./;'\[\]\\\-]).{12,}/;
    if (!params.password || !passwordRegex.test(params.password)) {
      formIsValid = false;
      errors["password"] = isGerms ? DE.pass_error : EN.pass_error;
    }
    setErrors(errors);
    return formIsValid;
  };
  const handleValidationx = () => {
    let errors = {};
    let formIsValid = true;
    //Role
    if (!params.buildingIndustryRole) {
      formIsValid = false;
      errors["buildingIndustryRole"] = isGerms
        ? DE.invalid_fiels
        : EN.invalid_fiels;
    }
    //Firstname
    if (!params.firstName) {
      formIsValid = false;
      errors["firstName"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!params.lastName) {
      formIsValid = false;
      errors["lastName"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!params.username) {
      formIsValid = false;
      errors["username"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    if (!params.password) {
      formIsValid = false;
      errors["password"] = isGerms ? DE.invalid_fiels : EN.invalid_fiels;
    }
    setErrors(errors);
    return formIsValid;
  };
  const history = useHistory();

  const register = async () => {
    if ((!isKjuupPlus && !params.isImportCompany)) {
      setLoading(true);
      if (handleValidation()) {
        try {
          var userLang = isGerms ? "de" : "en";
          params.lang = userLang;
          const data = await $crud.post("/auth/register", params);
          console.log("data=>", data);
          if (!data.status) {
            setInvalidMsg(data.message);
            setIsInvalid(true);
          } else {
            await showPop(data);
          }
        } catch (e) {
          console.log("data=>", e);
          setInvalidMsg(e.message);
          setIsInvalid(true);
        }
      }
      setLoading(false);
    }
    else {
      if (handleValidationx()) {
        history.push({ pathname: "/security-pin", state: { ...params, isRegister: true } });
      }
      // history.push("/security-pin");
    }
  };

  const showPop = async (data) => {
    setPopup(true);
    await setData(data);
  };
  const setData = async ({ token, user, badge }) => {
    await localStorage.setItem("token", token);
    var userLang = isGerms ? "de" : "en";
    let filterUser = { ...user, language: userLang };
    console.log("filterUser=>", filterUser);
    localStorage.setItem("user", JSON.stringify(filterUser));
    localStorage.setItem("badge ", JSON.stringify(badge));
    let PROJECT = isGerms ? DE.project : EN.project;
    let OTHERS = isGerms ? DE.Others : EN.Others;
    // var form = new FormData();
    let params = {
      name: PROJECT + " " + filterUser?.lastName,
      buildingType: OTHERS,
      street: "Niebuhrstraße",
      postCode: "53113",
      city: "Bonn",
      country: "Germany",
      lat: 50.7262322,
      long: 7.10846,
      houseNo: "52",
      isFirstProject: true,
      isHidden: true
    };
    const { data } = await callAPI("/building", params, "POST");
    console.log("project=>data", data);
    await setUser(filterUser);
    await localStorage.removeItem("regiLang");
  };

  const changeRole = (roles) => {
    setRoleSelected(false);
    const selectedRoles = roles.join(",");
    setParam("buildingIndustryRole", selectedRoles);
  };
  const handleClose = async () => {
    setPopup(false);
    store.dispatch({
      type: SET_USER,
      user: user,
    });
    dispatch({ type: TOGGLE_DRAWER, open: true })
    history.push("/articles");
  };

  const handleChangeLanguage = async (e) => {
    SetShowRoles(false);
    console.log("e.target.value=>", e.target.value);
    let lang = e.target.value;
    lang === "DE"
      ? localStorage.setItem("switch", "2")
      : localStorage.setItem("switch", "1");
    setIsGerm(!isGerms);
    setLanguage(lang);
    lang === "DE"
      ? localStorage.setItem("regiLang", "2")
      : localStorage.setItem("regiLang", "1");
  };
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "rgba(240, 132, 56, 0.8)",
    };
    return (
      <div
        className="thumb-style"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };
  const CustomScrollbars = (props) => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );

  return (
    <React.Fragment>
      {/* <CustomScrollbars
        autoHide
        autoHideTimeout={500}
        autoHideDuration={200}
        className="bottom-bar"
      > */}
      <Scrollbar>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center" }}>
          <div className="app_icon">
            <Card.Body style={{ padding: "0", display: "flex", justifyContent: "center" }} >
              <Form.Group>
                <Form.Control
                  value={language}
                  onChange={(e) => {
                    handleChangeLanguage(e);
                  }}
                  as="select"
                >
                  <option value="EN">{isGerms ? DE.english : EN.english}</option>
                  <option value="DE">{isGerms ? DE.german : EN.german}</option>
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </div>
          <div className="w-300 sign_in_transprent" style={{ paddingTop: "0px" }}>
            <div className="w-300 add_company login_singup">
              <div className="heading">
                <h2>
                  <img
                    height="47px"
                    src={
                      process.env.PUBLIC_URL + (isKjuupPlus ? "/assets/img/KjuupPlus.png" : "/assets/img/KJUUP-Schrift-Icon.png")
                    }
                  />
                </h2>
              </div>
              {(!isKjuupPlus && !params.isImportCompany) ?
                <div className="sub_heading">
                  <h3>{isGerms ? DE.registration : EN.registration}</h3>
                </div>
                :
                <div className="sub_heading">
                  <h3>{isGerms ? DE.register_plus : EN.register_plus}</h3>
                </div>
              }
              <div className="form-bottom">
                <Form
                  className="add_company_from"
                  onSubmit={(e) => {
                    e.preventDefault();
                    register();
                  }}
                >
                  {/* <Form.Group
                    className={
                      showRoles
                        ? "select_coustom position-relative theme-border"
                        : "select_coustom position-relative gray-border"
                    }
                  >
                    <a
                      style={{ minHeight: "38px", border:'none' }}
                      className="register_role_XX"
                      onClick={(e) => {
                        e.preventDefault();
                        SetShowRoles(!showRoles);
                      }}
                    >
                      {!params.buildingIndustryRole && (
                        <span>
                          {isGerms
                            ? DE.role_in_the_building_industry
                            : EN.role_in_the_building_industry}
                          *{" "}
                        </span>
                      )}
                      {params.buildingIndustryRole && (
                        <span style={{marginRight:20}}>
                          {convertBranchRole(
                            params.buildingIndustryRole,
                            isGerms
                          )}
                        </span>
                      )}
                    </a>
                    <BsChevronDown />
                    {errors.buildingIndustryRole && (
                      <small className="pl-2 text-danger">
                        {errors.buildingIndustryRole}
                      </small>
                    )}
                    <div className="profile-edit-roles">
                      {showRoles && (
                        <RegisterRole
                          setRoles={setRoleSelected}
                          hideRoles={(e) => {
                            SetShowRoles(false);
                          }}
                          selectPosition={changeRole}
                          position={position}
                          isGerms={isGerms}
                        />
                      )}
                    </div>
                  </Form.Group> */}
                  {/* <div style={{width:"99%" }}>
                      <Form.Group>
                        <Form.Control
                          value={params.title}
                          onChange={(e) => setParam("title", e.target.value)}
                          as="select"
                        >
                          <option value="">
                            {isGerms ? DE.title : EN.title}
                          </option>
                          <option value="Dipl.-Ing.">
                            {isGerms ? DE.dep_ing : EN.dep_ing}
                          </option>
                          <option value="Dr.">{isGerms ? DE.dr : EN.dr}</option>
                          <option value="Dr.-Ing.">
                            {isGerms ? DE.dr_ing : EN.dr_ing}
                          </option>
                          <option value="Dipl.-Ing. Architect">
                            {isGerms ? DE.dep_ing_arc : EN.dep_ing_arc}
                          </option>
                          <option value="Prof.">
                            {isGerms ? DE.prof : EN.prof}
                          </option>
                          <option value="Dipl.-Kfm.">
                            {"Dipl.-Kfm."}
                          </option>
                          <option value="Dipl.-Kff.">
                            {"Dipl.-Kff."}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </div>  */}

                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <Form.Group>
                        <Form.Control
                          value={params.firstName}
                          onChange={(e) => setParam("firstName", e.target.value)}
                          type="text"
                          placeholder={`${isGerms ? DE.first_name : EN.first_name
                            }*`}
                        />
                        {errors.firstName && (
                          <small className="pl-2 text-danger">
                            {errors.firstName}
                          </small>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-md-6 pl-1">
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control
                          value={params.lastName}
                          onChange={(e) => setParam("lastName", e.target.value)}
                          type="text"
                          placeholder={`${isGerms ? DE.last_name : EN.last_name
                            }*`}
                        />
                        {errors.lastName && (
                          <small className="pl-2 text-danger">
                            {errors.lastName}
                          </small>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    {(!isKjuupPlus && !params.isImportCompany) ?
                      <Form.Control
                        value={params.email}
                        onChange={(event) => {
                          setParam("email", event.target.value)
                          // Regular expression to check if the entered text resembles an email address
                        }}
                        type="text"
                        placeholder={`${isGerms ? DE.email : EN.email}*`}
                      />
                      :
                      <Form.Control
                        value={params.username}
                        onChange={(event) => {
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          // If the entered text matches the email regex, prevent further input
                          if (emailRegex.test(event.target.value)) {
                            // Optionally, you can provide feedback to the user that email address is not allowed
                            setErrors({ ...errors, username: isGerm ? DE.prevent_email : EN.prevent_email });
                            event.preventDefault();
                          }
                          else {
                            setErrors({ ...errors, username: null });
                            setParam("username", event.target.value)
                          }
                        }}
                        type="text"
                        placeholder={`${isGerms ? DE.username : EN.username}`}
                      />
                    }
                    {errors.username && (
                      <small className="pl-2 text-danger">{errors.username}</small>
                    )}
                    {errors.email && (
                      <small className="pl-2 text-danger">{errors.email}</small>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type={viewPassword ? "text" : "password"}
                      value={params.password}
                      onChange={(e) => setParam("password", e.target.value)}
                      placeholder={`${isGerms ? DE.password : EN.password}**`}
                    />
                    {!viewPassword && (
                      <BsFillEyeSlashFill
                        className="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setViewPassword(true);
                        }}
                      />
                    )}
                    {viewPassword && (
                      <BsFillEyeFill
                        className="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setViewPassword(false);
                        }}
                      />
                    )}
                    {errors.password && (
                      <small className="pl-2 text-danger">
                        {errors.password}
                      </small>
                    )}
                    <p style={{ fontSize: "12px", marginLeft: 5, fontStyle: "italic", color: "gray" }}>{isGerms ? DE.pass_minimum : EN.pass_minimum}</p>
                  </Form.Group>
                  <p style={{ fontSize: "12px", marginLeft: 5, fontStyle: "italic", color: "gray" }}>*{isGerms ? DE.mandatory_fiels : EN.mandatory_fiels}</p>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginBottom: 12 }}>
                    <div style={{ flex: 1 }} />
                    <div>
                      <div style={{ fontWeight: '600', color: "grey", fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: isGerms ? 'Hardware Anforderungen:<br/>' : "Hardware Requirements:<br/>" }} />
                      <div
                        style={{ fontSize: "12px", color: "gray" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            !isGerms ?
                              `•  4GB RAM<br/>• CPU speed min. 2GHz<br/>• Windows 7 or higher / all macOS versions`
                              :
                              `•	4GB RAM<br/>•	CPU Geschwindigkeit min. 2GHz<br/>•	Windows 7 oder höher / alle Varianten macOS`
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }} />
                  </div>
                  <Button
                    disabled={loading}
                    className="btn request-btn"
                    type="submit"
                    style={{ marginTop: 0, marginBottom: 5 }}
                  >
                    {loading ? (
                      <LoadingDots />
                    ) : isGerms ? (
                      DE.register
                    ) : (
                      EN.register
                    )}
                  </Button>
                  {isInvalid && (
                    <p className="text-center small text-danger">{invalidMsg}</p>
                  )}
                </Form>
                <div style={{ textAlign: "center", marginTop: 0 }}>
                  <a
                    href={isKjuupPlus ? "https://kjuup-plus.de/impressum-agb" : 'https://kjuup.de/impressum-agb'}
                    target="_blank"
                    style={{ color: "#737373", fontSize: "14px" }}
                  >
                    {isGerms ? DE.imprint_gtc : EN.imprint_gtc}
                  </a>
                </div>
                <p className="mandatory term_use" style={{ marginBottom: 5, marginTop: 5 }}>
                  {isGerms
                    ? DE.when_register_you_agree
                    : EN.when_register_you_agree}{" "}
                  <a href={isKjuupPlus ? "https://kjuup-plus.de/nutzungsbedingungen" : 'https://kjuup.de/nutzungsbedingungen'} target="_blank">
                    {isGerms ? DE.term_of_use : EN.term_of_use}
                  </a>{" "}
                  {isGerms ? DE.to : EN.to} {isGerms ? DE.in_our : EN.in_our}{" "}
                  <a href={isKjuupPlus ? "https://kjuup-plus.de/datenrichtlinien" : 'https://kjuup.de/datenrichtlinien'} target="_blank">
                    {isGerms ? DE.data_policy : EN.data_policy}
                  </a>{" "}
                  {isGerms ? DE.they_find_out : EN.they_find_out}{" "}
                  {isGerms ? DE.we_provide_store_data : EN.we_provide_store_data}
                </p>
                <div className="register_link" style={{ padding: 0, marginBottom: 0 }}>
                  <p>{isGerms ? DE.have_an_account : EN.have_an_account}</p>
                  <Link to="/">{isGerms ? DE.sign_in : EN.sign_in}</Link>
                </div>
              </div>
            </div>
            <div className="app_icon" style={{ marginTop: 0 }}>
              <h2>{isGerms ? DE.get_app : EN.get_app}</h2>
              <div className="app_img" style={{ marginTop: 0 }}>
                <a
                  href="https://apps.apple.com/de/app/immosnapp/id1544915778"
                  target="_blank"
                >
                  <img src={process.env.PUBLIC_URL + "/assets/img/ios.svg"} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=de.jft.immosnapp"
                  target="_blank"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/playstore.png"}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Scrollbar>
      {/* </CustomScrollbars> */}
      <Modal show={popup} onHide={handleClose}>
        <Modal.Body>
          {isGerms ? DE.register_confirm_popup : EN.register_confirm_popup}
          {/* Please confirm your email address by clicking the link in the email
            within the next 48 hours.  */}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            {isGerms ? DE.cancel : EN.cancel}
          </Button>
          <Button size="sm" variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Register;
