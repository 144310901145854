import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import { Link, useHistory, useLocation } from "react-router-dom";
import { string } from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import { $crud } from '../helper/CrudFactory';
import { callAPI } from '../helper/apiUtils';
import { SET_USER, SET_USER_LOGIN_TOKEN, store } from '../store';
import { useDispatch } from 'react-redux';
import { TOGGLE_DRAWER } from '../helper/constants';
import { $user } from '../helper/UserFactory';


function SecurityPIN(props) {
    const [otp, setOtp] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [msg, setMsg] = useState('')
    const [errorModal, setShowModalError] = useState(false)
    const [isGerms, setIsGerm] = useState(true);
    const history = useHistory();
    const [isInvalid, setIsInvalid] = useState(false);
    const [invalidMsg, setInvalidMsg] = useState("");
    const [user, setUser] = useState("");
    const dispatch = useDispatch()
    let state = history.location.state
    const [loading, setLoading] = useState(false);

    const handleChange = (otp) => {
        setInvalidMsg('');
        setIsInvalid(false);
        setOtp(otp)
    };
    useEffect(() => {
        console.log(state)
        let registerLang = localStorage.getItem("regiLang");
        if (registerLang) {
            setIsGerm(registerLang == 2 ? true : false);
        }
    }, [])
    const _handleConfirm = async () => {
        if (state.isRegister) {
            try {
                console.log({ ...state, userPin: otp })
                setLoading(true)
                state.lang = localStorage.getItem("regiLang") == 2 ? "de" : "en";
                console.log(state)
                const data = await $crud.post("/auth/registerKjuupUser", { ...state, userPin: otp });
                if (!data.status) {
                    setInvalidMsg(data.message);
                    setIsInvalid(true);
                    setLoading(false)
                } else {
                    await localStorage.setItem("token", data.token);
                    var userLang = isGerms ? "de" : "en";
                    let filterUser = { ...data.user, language: userLang };
                    console.log("filterUser=>", filterUser);
                    localStorage.setItem("user", JSON.stringify(filterUser));
                    let PROJECT = isGerms ? DE.project : EN.project;
                    let OTHERS = isGerms ? DE.Others : EN.Others;
                    // var form = new FormData();
                    let params = {
                        name: PROJECT + " " + filterUser?.lastName,
                        buildingType: OTHERS,
                        street: "Niebuhrstraße",
                        postCode: "53113",
                        city: "Bonn",
                        country: "Germany",
                        lat: 50.7262322,
                        long: 7.10846,
                        houseNo: "52",
                        isFirstProject: true,
                        isHidden: true
                    };
                    const datax = await callAPI("/building", params, "POST");
                    await localStorage.removeItem("regiLang");
                    store.dispatch({
                        type: SET_USER,
                        user: data.user,
                    });
                    setLoading(false)
                    dispatch({ type: TOGGLE_DRAWER, open: true })
                    history.replace({ pathname: "/articles", state: {} });
                    // history.push('/articles');
                }
            } catch (e) {
                setInvalidMsg(e.message);
                setIsInvalid(true);
                setLoading(false)
            }
        }
        else {
            try {
                setLoading(true)
                const data = await $crud.post("/auth/verifyPin", { username: state.username, userPin: otp, language: isGerms ? "de" : "en" });
                if (data.status) {
                    setLoading(false)
                    let tokenx = localStorage.getItem("firebaseToken")
                    let loginData = { username: state.username, password: state.password };
                    $user.login({ ...loginData, fcmId: tokenx }).then(async (res) => {
                        if (!res.status) {

                        } else {
                            localStorage.setItem(
                                "switch",
                                res?.user?.language === "de" ? "2" : "1"
                            );
                            localStorage.removeItem("regiLang")
                            dispatch({ type: SET_USER_LOGIN_TOKEN, status: res.token });
                            history.push("/articles");
                        }
                    });
                    // setShowModal(true)
                }
                else {
                    setLoading(false)
                    setMsg(data.message)
                    setShowModalError(true)
                }
            } catch (err) {
                setLoading(false)
                setMsg(err.message)
                setShowModalError(true)
            }
        }
    }
    return (
        <div className='w-300 border border-2 border-#303030' style={{ height: "90vh", display: "flex", flexDirection: "column", marginTop: 0, paddingTop: 0, }}>
            <div style={{ flex: 1 }}>
                <div style={{ display: "flex", flexDirection: "row", backgroundColor: "white", paddingTop: 10, paddingLeft: 10 }}>
                    <div>
                        <a
                            className="pointer"
                            onClick={() => history.goBack()}
                        >
                            <img
                                width={10}
                                src={
                                    process.env.PUBLIC_URL + "/assets/img/back.svg"
                                }
                                alt="Generic placeholder"
                            />
                        </a>
                    </div>
                    <div style={{ flex: 1, paddingLeft: 10 }}>
                        <p>{isGerms ? state.isResetAccount ? DE.rac : DE.register : state.isResetAccount ? EN.rac : EN.register}</p>
                    </div>
                </div>
            </div>
            <div style={{ alignSelf: "center" }}>
                <div style={{ marginBottom: 40, textAlign: "center" }}>
                    <p style={{ fontSize: '18px' }}>{isGerms ? DE.register_pin_text : EN.register_pin_text}</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        isInputNum
                        numInputs={6}
                        separator={<span>{` -- `}</span>}
                        inputStyle={{ height: 40, width: 40 }}
                    />
                </div>
                {otp.length == 6 ?
                    <Button
                        disabled={loading}
                        className="btn request-btn"
                        onClick={_handleConfirm}
                    >
                        {loading ? (
                            <LoadingDots />
                        ) : isGerms ? (
                            DE.confirm
                        ) : (
                            EN.confirm
                        )}
                    </Button>
                    :
                    <></>
                }
                {isInvalid && (
                    <p className="text-center small text-danger">{invalidMsg}</p>
                )}
            </div>
            <div style={{ flex: 1 }} />
            <Modal show={showModal} onHide={() => { }}>
                <Modal.Body>{isGerms ? DE.ac_ss : EN.ac_ss}</Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="primary" onClick={() => {
                        setShowModal(false)
                        history.goBack()
                    }}>
                        {isGerms ? DE.ok : EN.ok}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={errorModal} onHide={() => { }}>
                <Modal.Body>{msg}</Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="primary" onClick={() => {
                        setShowModalError(false)
                    }}>
                        {isGerms ? DE.ok : EN.ok}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SecurityPIN