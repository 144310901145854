import React, { useEffect, useState } from "react";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import LanguageContext from "../../../helper/LanguageContext";

export default function ArticleFilter({
  handleClose,
  setParam,
  followersValue,
  followingsValue,
  filter,
  handleClick
}) {
  const { isGerm } = React.useContext(LanguageContext);
  const [followers, setFollowers] = useState(false);
  const [followings, setFollowings] = useState(false);
  const setBuildingTypes = async () => {
    await handleClick && handleClick()
    await setParam("followings", followings);
    await setParam("followers", followers);
    handleClose();
    filter();
  };

  useEffect(() => {
    setFollowers(followersValue);
    setFollowings(followingsValue);
  }, []);

  return (
    <div className="backdrop">
      <div className="role_popup shadow-sm">
        <h4>{isGerm ? DE.filter_by : EN.filter_by}</h4>
        <div
          className="role_data"
          style={{ minHeight: 100, overflow: "hidden" }}
        >
          <div className="list-group">
            <label className="role-label" style={{ fontWeight: 600 }}>
              {isGerm ? DE.followings : EN.followings}
              <input
                checked={followings}
                value={followings}
                onClick={(e) => setFollowings(!followings)}
                type="checkbox"
              />
              <span className="checkmark-label" />
            </label>
          </div>
          <div className="list-group">
            <label className="role-label" style={{ fontWeight: 600 }}>
              {isGerm ? DE.followers : EN.followers}
              <input
                checked={followers}
                value={followers}
                onClick={(e) => setFollowers(!followers)}
                type="checkbox"
              />
              <span className="checkmark-label" />
            </label>
          </div>
        </div>
        <div className="bottom-button">
          <a className="pointer" onClick={handleClose}>
            {" "}
            {isGerm ? DE.cancel : EN.cancel}
          </a>
          <a className="pointer" onClick={setBuildingTypes}>
            {" "}
            {isGerm ? DE.ok : EN.ok}
          </a>
        </div>
      </div>
    </div>
  );
}
