import React, { useEffect, useRef, useState } from 'react';
import { GROUPS, OPEN_CHATS } from "../../store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { $user } from "../../helper/UserFactory";
import { BASE_URL, KJUUP_COLORS } from "../../helper/constants";
import LanguageContext from '../../helper/LanguageContext';
import SimpleImageSlider from "react-simple-image-slider";
import Modal from 'react-bootstrap/Modal'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const user = $user.get();

function AllImages(props) {
    let { images } = props
    const { isGerm } = React.useContext(LanguageContext);
    const [img, setImg] = useState([])
    useEffect(() => {
        if (images && images.length > 0) {
            let xx = images.map((e) => {
                return {
                    // src: e.local ? e.fileUrl[0] : BASE_URL + e.fileUrl[0],
                    // url: e.local ? e.fileUrl[0] : BASE_URL + e.fileUrl[0],
                    // original: e.local ? e.fileUrl[0] : BASE_URL + e.fileUrl[0],
                    // thumbnaile: e.local ? e.fileUrl[0] : BASE_URL + e.fileUrl[0],
                    image: e.local ? e.fileUrl[0] : BASE_URL + e.fileUrl[0]
                }
            })
            setImg([...xx])
            // galleryRef.current.fullScreen();
        }
    }, [images])

    const galleryRef = useRef(null);

    useEffect(() => {
        const handleFullscreenChange = () => {
            let tt = !!document.fullscreenElement
            if (!tt) {
                props.handleClose()
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
       <></>
        // <Modal show={true} size='xl' style={{ margin: 0, padding:0}}>
        //     <Modal.Header closeButton onClick={() => props.handleClose()} />
        //     <div style={{}}>
        //         {/* <ImageGallery
        //             items={img}
        //             showPlayButton={false}
        //             isFullscreen={true}
        //             ref={galleryRef}
        //         /> */}
        //         {img.length > 0 ?
        //             <SliderImage
        //                 data={img}
        //                 width="500px"
        //             // showDescription={true}
        //             // direction='left'
        //             />
        //             :
        //             <></>
        //         }
        //     </div>
        // </Modal>
    );
}

const mapStateToProps = (state) => {
    const { openChats, groups } = state;
    return {
        groups: groups,
        openChats: openChats,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateGroups: groups => dispatch({ type: GROUPS, groups: groups }),
        updateOpenChatsList: chats => dispatch({ type: OPEN_CHATS, openChats: chats }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllImages));