import { Languages } from "../Languages/TranslateLanguage";
import axios from "axios";
import {
  decryptMessage,
  encryptMessage,
} from "../utils/encryptDecryptFunction";

const TranslateDialog = ({
  handleClose,
  chat,
  handleTranslateMessage,
  secretKey,
}) => {
  const handleTranslateChat = async (lng) => {
    console.log(lng);
    const message = secretKey
      ? decryptMessage(chat.message, secretKey)
      : chat.message;
    let originalMessage = message;
    let chatMessage = {};
    if (chat.message) {
      const {
        data: { data },
      } = await axios.get(
        `https://translation.googleapis.com/language/translate/v2?target=${lng}&key=AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q&q=${message}`
      );
      if (chat.originalMessage === "") {
        chatMessage = {
          ...chat,
          originalMessage: secretKey
            ? encryptMessage(originalMessage, secretKey)
            : originalMessage,
          messageLng: lng,
          message: secretKey
            ? encryptMessage(data.translations[0].translatedText, secretKey)
            : data.translations[0].translatedText,
        };
      } else {
        chatMessage = {
          ...chat,
          messageLng: lng,
          message: secretKey
            ? encryptMessage(data.translations[0].translatedText, secretKey)
            : data.translations[0].translatedText,
        };
      }
      handleTranslateMessage(chatMessage);
    }
    handleClose();
  };

  const showOriginalMessage = () => {
    const chatMessage = {
      ...chat,
      originalMessage: chat.originalMessage,
      messageLng: "en",
      message: chat.originalMessage,
    };
    handleTranslateMessage(chatMessage);
    handleClose();
    handleClose();
  };

  return (
    <div className="backdrop">
      <div className="role_popup shadow-sm click_plus">
        <div
          className="p-2"
          style={{ borderBottom: "2px solid #ddd", textAlign: "center" }}
        >
          <strong>Translate</strong>
        </div>
        <div className="role_data translate-dialog">
          <ul className="list-group">
            <li className="pointer">
              <div className="role-label" onClick={showOriginalMessage}>
                <i>Show Original</i>
                <input name="lang" type="checkbox" />
                <span style={{ width: 20 }} className="checkmark-label" />
              </div>
            </li>
            {Languages.map((language, index) => (
              <li className="pointer" key={index}>
                <div
                  onClick={() => handleTranslateChat(language.lng)}
                  className="role-label"
                >
                  <i>{language.name}</i>
                  <input name="lang" value={language.lng} type="checkbox" />
                  <span style={{ width: 20 }} className="checkmark-label" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TranslateDialog;
