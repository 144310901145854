import React from 'react'
import LanguageContext from '../helper/LanguageContext';
import { DE } from '../Languages/DE';
import { EN } from '../Languages/EN';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { KJUUP_COLORS } from '../helper/constants';

function InvitationProjectsCard({ pendingRequests, onClick }) {
    const { isGerm } = React.useContext(LanguageContext);
    return (
        <div onClick={onClick} style={{ cursor: "pointer", padding: 5, backgroundColor: "#F7F7F7", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>
                <span
                    className="position-relative mr-2 ml-1 project-notification-request"
                >
                    <IoIosNotificationsOutline
                        size={30}
                        color="#737373"
                        className="mt-1 pointer"
                    />
                    {pendingRequests > 0 ? (
                        <div style={{ position: "absolute", top: "-5px", left: "8px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 5, height: "20px", minWidth: "20px", borderRadius: 100, backgroundColor: KJUUP_COLORS.base_color }}>
                            <span style={{ color: "white", fontWeight: 'bold', fontSize: "12px" }}>{pendingRequests}</span>
                        </div>
                    ) : (
                        ""
                    )}
                </span>
            </div>
            <div style={{ fontSize: "16x", fontWeight: "600" }}>
                {isGerm ? DE.request_confirmation : EN.request_confirmation}
            </div>
        </div>
    )
}

export default InvitationProjectsCard