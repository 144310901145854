export const DE = {
  info_icon:"Sobald der Raum als abgeschlossen markiert wird, können keine neuen Mitglieder mehr beitreten oder hinzugefügt werden, und alle Chat-Gruppen werden deaktiviert. Der Raum sowie alle Chat-Gruppen und Daten bleiben im deaktivierten Zustand weiterhin zugänglich.",
  mark_is_done:"Möchten Sie den Raum wirklich als abgeschlossen markieren? Sobald der Raum als abgeschlossen markiert wird, können keine neuen Mitglieder mehr beitreten oder hinzugefügt werden, und alle Chat-Gruppen werden deaktiviert. Der Raum sowie alle Chat-Gruppen und Daten bleiben im deaktivierten Zustand weiterhin zugänglich.",
  mark_not_done:"Möchten Sie den Raum wirklich wieder öffnen? Sobald der Raum wieder offen ist, können neuen Mitglieder beitreten oder hinzugefügt werden, und alle Chat-Gruppen werden reaktiviert.",
  mark_as_completed:"Als abgeschlossen markieren",
  room_completed:"Raum abgeschlossen",
  room_members: "Mitglieder des Raums",
  request_to_join_a_room: 'Anfrage zum Beitritt beim Raum',
  update_text: "Ein neues Update ist verfügbar. Klicken Sie auf Neustart um das Update zu installieren.",
  restart:"Neustart",
  update_availble: "Update verfügbar",
  Loading:"Läd...",
  logout: "Ausloggen",
  company_list: "Unternehmensliste",
  followers: "Abonnenten",
  followings: "Abonniert",
  article: "Artikel",
  articlen: "Artikeln",
  invite_user_to_confirm_service:
    "Laden Sie Teilnehmer*innen von Kjuup dazu ein Ihre Leistungen zu bestätigen.",
  companies: "Unternehmen",
  one_to_one_chat: " Eins zu Eins Chat",
  profile: "Profil",
  company: "Unternehmen",
  home: "Zuhause",
  contact: "Kontakt",
  company_contact: "Kontakt",
  add: "hinzufügen",
  add_company: "Unternehmen hinzufügen",
  add_project: "Projekt hinzufügen",
  capacities: "Kapazitäten",
  work_area: "Arbeitsbereich",
  of: "von",
  company_services: "Unternehmensleistungen",
  profile_company_services: "Unternehmensleistungen",
  deselect_all: "Alle deaktivieren",
  company_details_service: "Unternehmensleistungen",
  website: "Webseite",
  search_contacts: "Kontakte suchen",
  add_or_join_a_company: "Unternehmen hinzufügen oder beitreten",
  follow: "Abonnieren",
  mutual_contact: "gemeinsamer Kontakt",
  mutual_contacts: "gemeinsame Kontakte",
  following: "Abonniert",
  like: "Gefällt mir",
  company_contacts: "Unternehmenskontakt",
  profile_company_contacts: "Unternehmenskontakt",
  leave_the_company: "Das Unternehmen verlassen",
  news: "Mitteilungen",
  earlier: "Früher",
  action: "Aktion",
  another_action: "Eine andere Aktion",
  something_else: "etwas anderes",
  search: "Suche",
  contacts: "Kontakte",
  suggestions: "Vorschläge",
  project_list: "Projektliste",
  search_results: "Suchergebnisse",
  add_or_join_project: "Raum hinzufügen oder beitreten",
  details: "Details",
  map: "Karte",
  or: "Oder",
  name: "Name*",
  street: "Straße*",
  house_number: "Hausnr.",
  postal_code: "Postleitzahl",
  city: "Stadt*",
  opened_by: "Geöffnet von:",
  project_No: "Projekt-nr: ",
  building_type: "Gebäudetyp*",
  building_types: "Gebäudetyp",
  add_join: "Beitreten/Hinzufügen",
  mandatory_fiels: "Pflichtfeld",
  invalid_fiels: "Pflichtfeld",
  add_or_join_company: "Unternehmen hinzufügen oder beitreten",
  edit_company_details: "Unternehmensinformationen bearbeiten",
  name_of_the_company: "Name",
  branch_of_the_building_industry: "Branche der Bauindustrie",
  corporate_form: "Gesellschaftsform",
  position_within_company: "Position im Unternehmen",
  request_confirmation: "Ausstehende Raum-Einladungen",
  company_e_mail: "Unternehmens E-Mail",
  type_of_filter: "Typ zum Filtern ...",
  setting: "Rahmen",
  dashboard: "Dashboard",
  settings: "Einstellungen",
  views: "ansichten",
  times_seen: "mal gesehen",
  in_search: "in der Suche",
  reset_password: "Passwort zurücksetzen",
  submit: "Bestätigen",
  password: "Passwort",
  email: "E-Mail",
  confirm_password: "Passwort bestätigen",
  reset_email: "E-Mail zurücksetzen",
  update: "Aktualisieren",
  new_email: "Neue E-Mail",
  my_position: "Meine Position:",
  owner_company_admin: "Inhaber-Firmenadministrator :",
  owner_company_admin_new: "Unternehmensadmin",
  co_owner: "Mitinhaber/in",
  managing_director: "Geschäftsführer/in",
  associate: "Partner/in",
  secretary: "Sekretär/in",
  your_position_within_company: "Wählen Sie bitte Ihre Position im Unternehmen",
  employee: "Mitarbeiter/in",
  project_manage: "Projektmanager/in",
  delete_profile: "Profil löschen?",
  auto_chat_translation: "Automatische Chat-Übersetzung",
  language: "Sprache",
  english: "Englisch",
  german: "Deutsch",
  change_language: "Sprache ändern",
  my_companies: "Meine Unternehmen",
  employees: "Mitarbeiter/innen",
  invite_with_a: "Zu Kjuup einladen",
  add_member: "Mitglied hinzufügen",
  add_user: "Teilnehmer*in",
  send_location: "Standort senden",
  assign_admin_rights: "Adminrechte anpassen",
  deactivate_company: "Firma deaktivieren",
  no_user_found: "Keine Nutzer gefunden!",
  remove_employees: "Mitarbeiter/in entfernen",
  translation_on_building_projects: "Übersetzungsfunktion in Räumen",
  reported_capacities: "Angebotene Leistungen",
  see_more: "...mehr",
  see_less: "...weniger",
  up_to_50m_exact: "Bis zu 50m genau",
  invite_with_link: "Zu Kjuup einladen",
  share_link: "Einen Link teilen",
  share_company_link: "Link teilen",
  approve: "Bestätigen!",
  qr_code: "QR-Code",
  copy_link: "Link kopieren",
  cancel_link: "Link abbrechen",
  who_has_confirmed_services: "Wer hat Ihre Leistungen bestätigt?",
  add_services: "Unternehmensleistungen hinzufügen",
  save_text: "speichern",
  share_on: "Teilen auf:",
  link_copied: "Link kopiert!",
  email_copied: "Email kopiert!",
  personal_article: "Persönlicher Artikel",
  comapny_article: "Firmenartikel",
  characters_remaining: "verbleibende Zeichen",
  artical_text: "Artikel verfassen...",
  optional_information: "Optionale Information",
  project: "Raum",
  projects: "Projekte...",
  projectss: "Räume",
  abort: "Verwerfen",
  preview: "Vorschau",
  post: "Veröffentlichen",
  remove: "Entfernen",
  free_capacity: "Auftragsportal",
  current_status_overview: "Übersicht über den aktuellen Status",
  report_capacities: "Leistungen anbieten",
  report_text: "Anbieten",
  search_capacities: "Auftragnehmer finden",
  find: "Finden",
  add_project_user: "Projektbenutzer hinzufügen",
  media: "MEDIEN",
  links: "LINKS",
  docs: "DOKS",
  featured_products: "Ausgewählte Produkte...",
  title: "Titel",
  select_profile_image: "Wählen Sie Profilbild",
  feed_details: "Feed-Details",
  add_employee: "Mitarbeiter/in hinzufügen",
  my_projects: "Meine Projekte",
  cancel: "Abbrechen",
  add_company_cancel: "Abbrechen",
  continue: "Fortsetzen",
  role_in_the_building_industry: "Branche",
  close: "Schließen",
  close_modal: "Abbrechen",
  services: "Unternehmensleistungen",
  request_to_join_a_company: "Anfrage zum Beitritt beim Unternehmen",
  wants_to_join_the_company: "möchte dem Unternehmen",
  als: "als",
  join: "beitreten",
  filter_by: "Filtern nach",
  ok: "OK",
  latest: "Aktuell",
  weekly_statistics: "Wöchentliche Statistik",
  time: "Zeit",
  in_searches_in_past_week: " in Suchanfragen erschienen.",
  is_following_you: "hat sie abonniert.",
  has_liked_your_article: "gefällt ihr Artikel.",
  has_published_a_new_article: " hat einen neuen Artikel veröffentlicht.",
  as: "wie",
  has_joined_the: "ist dem Unternehmen",
  has_looked_at_your_profile: "hat sich ihr Profil angesehen.",
  you: "Sie",
  have_joined_the: "haben sich dem Projekt",
  building_project: " Bauprojekt.",
  all_views: "Alle Ansichten",
  you_have_been_found: "Sie wurden in der letzten Woche",
  all_profile_views: "Alle Profilansichten",
  mutual_company: "gemeinsame Firma",
  mutual_companys: "gemeinsame Firmen",
  you_have_appeared: " Sie sind in der letzten Woche",
  your_request_at_the_company: "Ihre Anfrage bei der Firma",
  has_been_confirmed: "wurde bestätigt.",
  improve_profile: "Profil verbessern",
  choose_existing_contact_for_single_chat:
    "Wählen Sie einen vorhandenen Kontakt für einen einzelnen Chat:",
  in_order_to_add_new_contacts:
    "Um neue Kontakte hinzuzufügen, suchen Sie bitte auf der Profilseite nach neuen Kontakten.",
  times_in_searches_in_the_last_week:
    "Mal in Suchanfragen gefunden. Aktualisieren Sie Ihr Profil und fügen Sie weitere Details hinzu, um leichter gefunden zu werden.",
  appear_more_often_in_searches: "Erscheinen Sie häufiger in Suchanfragen",
  times_you_have_appeared_in_searches:
    "Mal sind Sie in Suchanfragen erschienen",
  select_with_which_company_you_want_to_connect_to_the_project:
    "Wählen Sie aus, mit welchem Unternehmen Sie eine Verbindung zum Raum herstellen möchten.",
  select_with_which_company_you_want_to_connect_to_the_chat:
    "Wählen Sie aus, mit welcher Firma Sie eine Verbindung zum Chat herstellen möchten.",
  share_user: "Benutzer teilen",
  contact_sender_message:
    "Teilen Sie diesen Link, um ein/mehrere Nicht-Mitglied/er von Kjuup zu Kjuup einzuladen. Dieser Link kann von mehreren Personen zur Registrierung genutzt werden.",
  project_sender_message:
    "Teilen Sie diesen Link, um ein Nicht-Mitglied von Kjuup zum Raum project name einzuladen. Diesen Link können mehrere Personen zur Registrierung und zum Beitritt beim Raum in Kjuup nutzen.",
  company_sender_message:
    "Teilen Sie diesen Link, um ein Nicht-Mitglied von Kjuup zum Unternehmen company name einzuladen. Diesen Link können mehrere Personen zur Registrierung und zum Beitritt beim Unternehmen in Kjuup nutzen.",
  contact_receivers_message:
    "Einladungslink, um Kjuup beizutreten. Bitte klicken Sie auf den Link, um sich bei Kjuup zu registrieren.",
  iphone_recivers_message:
    "Falls Sie Kjuup über den Apple App Store installieren, müssen Sie nach dem Download noch diesen Link klicken, um sich registrieren zu können.",
  iphone: "iPhone:",
  project_receivers_message:
    "Einladungslink zum Raum project name in Kjuup. Bitte klicken Sie auf den Link, um sich bei Kjuup zu registrieren und dem Raum project name beizutreten.",
  company_receivers_message:
    "Einladungslink zum Unternehmen company name in Kjuup. Bitte klicken Sie auf den Link, um sich bei Kjuup zu registrieren und dem Unternehmen company name beizutreten.",
  sign_in: "Anmelden",
  enter_your_email_add: "E-Mail eingeben",
  forgot_password: "Passwort vergessen",
  dont_have_account: "Sie haben kein Konto ?",
  register: "Registrieren",
  get_app: "Hol dir die App",
  registration: "Registrierung",
  dep_ing: "Dipl.-Ing.",
  dr: "DR.",
  dr_ing: "Dr.-Ing.",
  dep_ing_arc: "Dipl.-Ing. Architekt",
  prof: "Prof.",
  first_name: "Vorname",
  last_name: "Nachname",
  have_an_account: "Ein Konto haben ?",
  when_register_you_agree: "Indem Sie sich registrieren, stimmen Sie unseren",
  term_of_use: "Nutzungsbedingungen",
  in_our: "In unseren",
  to: "zu.",
  data_policy: "Datenrichtlinien",
  they_find_out: "erfahren Sie,",
  we_provide_store_data:
    "wie wir Informationen erfassen, verarbeiten und speichern. ",
  leaving_company_text:
    "Wollen Sie das Unternehmen wirklich verlassen? Wenn Sie das Unternehmen verlassen, verlassen Sie auch alle mit dem Unternehmen verbundenen Räume und Chat Gruppen. Sie können diesen jederzeit erneut mit einem anderen Unternehmen beitreten.",
  delete_profile_text:
    "Wollen Sie Ihr Profil wirklich löschen? Wenn Sie Ihr Profil löschen werden alle Verbindungen zu Unternehmen, Projekten und Chats entfernt. Sie können jederzeit ein neues Profil anlegen.",
  confirm: "Bestätigen",
  requet_to_leave: "Unternehmen verlassen",
  choose: "Wählen Sie",
  your_add_member: "Mitglied hinzufügen",
  if_you: "wenn Sie",
  can_not_find: "Ihren Kontakt hier nicht finden können.",
  please_wait: "Warten Sie mal..",
  imprint_gtc: "Impressum/AGB",
  register_confirm_popup:
    "Bitte bestätigen Sie die E-Mail innerhalb von 48 Stunden.",
  PENDING_GROUP_USER:
    "erhält Nachrichten erst nachdem er/sie die Einladung zum Raum bestätigt hat. Sobald er/sie die Einladung bestätigt oder abgelehnt hat wird dies hier angezeigt.",
  PENDING: "Bestätigung der Einladung zum Raum ausstehend",
  denied_group_chat:
    " hat die Einladung zum Raum abgelehnt und die Chat Gruppe verlassen.",
  joined_group_chat: " ist der Chat Gruppe beigetreten.",
  you_joined_group_chat: "Sie sind der Chat Gruppe beigetreten.",
  Others: "Andere",
  LEFT_GROUP: "hat die Chat Gruppe verlassen.",
  YOU_LEFT_GROUP: "haben die Chat Gruppe verlassen.",
  days: "Tage",
  day: "Tag",
  hour: "Std.",
  hide_project: "Raum verbergen",
  hide_project_popup_text:
    "Wenn Sie Räume verbergen, können diese in Kjuup nicht mehr gefunden werden. Nur Mitglieder des Raums können weitere Mitglieder einladen.",
  branch: "Branche",
  photo_video_media_lib: "Foto- und Videomediathek",
  document: "Dokument",
  location_meeting: "Standort",
  all_member_of_project: "Alle Mitglieder des Projekts",
  pending_request: "Ausstehende Raum-Einladungen",
  no_pending_request: "Keine ausstehenden Einladungen zu Räumen",
  news_feed: "News-Feed",
  user_info: "Gruppen Info",
  leave_project: "Raum verlassen",
  media_link_docs: "Medien, Links und Doks",
  search_chat: "Chat Gruppe durchsuchen",
  export_chat_group: "Chat Gruppe exportieren",
  leave_chat_group: "Chat Gruppe verlassen",
  add_user_project: "Teilnehmer*in hinzufügen",
  me: "Ich",
  request_confirmation: 'Ausstehende Raumeinladungen',
  max_open_one_to_one:
    "Derzeit können max. 2 Einzelchats parallel im oberen - und im unteren Bereich geöffnet werden. Insgesamt können max. 4 Einzelchats geöffnet werden.",
  are_you_sure_you_want_leave_chat:
    "Wollen Sie die Chat Gruppe wirklich verlassen?",
  create_group: "Gruppe erstellen",
  group_name: "Gruppenname",
  by: "Von:",
  mandetory: "*Pflichtfeld",
  delteed: "hat sein/ihr Profil gelöscht und die Chat Gruppe verlassen.",
  unhidden_message:
    "Wenn Sie Räume sichtbar machen, können diese in Kjuup von allen Kjuup Mitgliedern gefunden werden.",
  account_deleted: "Account wurde gelöscht.",
  refuse_company:
    "Sind Sie sicher, dass Sie die Anfrage zum Beitritt beim Unternehmen ablehnen möchten?",
  follow_additional: "Weitere Teilnehmer*innen abonnieren",
  f_add:
    "Weitere Teilnehmer*innen in Kjuup abonnieren, um mit ihnen per Direktnachricht zu kommunizieren.",
  c_inv: "Unternehmenseinladung(en)",
  invited_o_Company: "Sie wurden zum Unternehmen",
  refuse_companyX:
    "Sind Sie sicher, dass Sie die Einladung zum Unternehmen ablehnen möchten?",
  a_Da: "Konto wurde deaktiviert.",
  has_deactivated: "hat sein/ihr Profil deaktiviert.",
  reactive: "hat sein/ihr Profil reaktiviert.",
  email_username: "E-Mail/Benutzername*",
  kjuup_note:
    "*Registrierung mit Benutzername nur für Teilnehmer/innen von Kjuup+ möglich.",
  username: "Benutzername*",
  sPinText:
    "Bitte erstellen Sie Ihren persönlichen 6-stelligen Pin. Der Pin wird benötigt, falls Sie Ihr Passwort vergessen und wird als Kontobestätigung vorausgesetzt.",
  DeleteKjuupPlusText:
    "Wollen Sie Ihr Profil wirklich löschen? Wenn Sie Ihr Profil löschen werden alle Verbindungen zu Unternehmen, Projekten und Chats entfernt.\n\nSie können Ihr Profil innerhalb von 48 Stunden reaktivieren, indem Sie sich mit Ihrem Benutzernamen, Passwort und Pin bei Kjuup anmelden. ",
  reactive_text:
    "Ihr Konto wurde gelöscht. Bitte klicken Sie auf Reaktivieren um Ihr Konto zu reaktivieren.",
  rac: "Reaktivieren",
  ac_ss: "Konto wurde erfolgreich reaktiviert.",
  not_valid_pin: "Pin ungültig",
  only_for_kjuup:
    "*Password zurücksetzen via Benutzername und Pin nur für Teilnehmer/innen von Kjuup+ möglich.",
  resetPIN: "PIN zurücksetzen",
  enter_current_pin: "Bitte geben Sie Ihren aktuellen PIN ein",
  enter_new: "Bitte neuen PIN eingeben",
  pin_to_confirm: "PIN eingeben, um zu bestätigen.",
  change_username: "Benutzernamen zurücksetzen",
  pp_not_match: "Passwort und Passwortbestätigung stimmen nicht überein",
  reactive_itself: "Sie haben Ihr Profil reaktiviert.",
  has_deactivated_itself: "Sie haben Ihr Profil deaktiviert.",
  more_than_two:
    "Derzeit können Direktnachrichten mit max. 2 Personen parallel ausgeführt werden.",
  Chats: "Direktnachrichten",
  only_in_mobileL:
    "Native Videos können derzeit nur via mobiler App hochgeladen und geposted werden. Verwenden Sie jederzeit gerne Video-Links von Youtube oder Vimeo.",
  register_pin_text:
    "Wählen Sie bitte Ihren persönlichen 6-stelligen PIN. Dieser wird beim Zurücksetzen einzelner Kontodetails benötigt.",
  internal_project: "Intern",
  external_projects: "Extern",
  in_deacticted: "Interner Bereich ist deaktiviert.",
  new_article_selection: "Artikel für externen News-Feed erstellen",
  new_article_selection_internal: "Unternehmensinternen Artikel erstellen",
  active_trans:
    "Wollen Sie die automatische Übersetzung aktivieren? Alle eingehenden Nachrichten in Chats werden dann automatisch in die Sprache des Browsers übersetzt.\nNur in Chat Gruppen auf Projekten verfügbar.",
  disable_trans: "Wollen Sie die automatische Übersetzung deaktivieren?",
  allow_access_chat: "Kompletten Chatverlauf anzeigen",
  toggle_chat_history_on:
    "Wollen Sie wirklich, dass der/die Teilnehmer/in den kompletten Chatverlauf einsehen kann?",
  toggle_chat_history_off:
    "Wollen Sie wirklich, dass der/die Teilnehmer/in den Chatverlauf erst ab dem Zeitpunkt des Beitritts einsehen kann?",
  check_selected_settings:
    "Bitte die Einstellungen zum Chatverlauf bei allen ausgewählten Teilnehmer/innen kontrollieren!",
  chat_from_start_have:
    "Sie sind der Chat Gruppe beigetreten. Der Chatverlauf wurde mit Ihnen geteilt.",
  chat_from_start_has:
    "ist der Chat Gruppe beigetreten. Der Chatverlauf wurde mit ihm/ihr geteilt.",
  Address: "Address",
  read_by: "Gelesen von",
  deliver_to: "Zugestellt an",
  remaining: "ausstehend",
  message_info: "Nachrichteninfo",
  request_to_join_only: "Teilnahme anfragen",
  unfollow: "Entfernen",
  company_username: "Benutzername / Unternehmensname",
  month: [
    "Jan",
    "Feb",
    "März",
    "Apr",
    "Mai",
    "Juni",
    "Juli",
    "Aug",
    "Sept",
    "Okt",
    "Nov",
    "Dez",
  ],
  remove_my_admin_right: "Meine Adminrechte entfernen",
  makeEmp:
    "Wollen Sie dem/der Teilnehmer*in wirklich Adminrechte beim Unternehmen entziehen?",
  makeAdmin:
    "Wollen Sie dem/der Teilnehmer*in wirklich Adminrechte für das Unternehmen zuweisen?",
  remove_my_self:
    "Wollen Sie sich wirklich Adminrechte beim Unternehmen entziehen?",
  remove_member: "Mitarbeiter/in entfernen",
  do_you_really_want_to_remove:
    "Wollen Sie den/die Mitarbeiter/in username wirklich aus dem Unternehmen entfernen?",
  dep_loc: "Abteilung/Standort/Team",
  depAdmin: "Abteilungsadmin",
  add_remove_admin: "Unternehmensadmin hinzufügen/entfernen",
  add_remove_dep_admin: "Abteilungsadmin hinzufügen/entfernen",
  remove_employees_dept: "Mitarbeiter/in entfernen aus",
  want_to_join_dep:
    "Username möchte Abteilung/Standort/Team depname vom Unternehmen companyname beitreten.",
  you_invited:
    "Sie wurden zu Abteilung/Standort/Team depname beim Unternehmen companyname eingeladen.",
  dep_remove:
    "Wollen Sie dem/der Teilnehmer*in wirklich Adminrechte für die Abteilung / Standort entziehen?",
  add_dep:
    "Wollen Sie dem/der Teilnehmer*in wirklich Adminrechte für die Abteilung / Standort zuweisen?",
  my_self_dep_remove:
    "Wollen Sie sich wirklich die Rechte als Abteilungsadmin entziehen?",
  company_sender_message_dep:
    "Einladungslink zu depname im Unternehmen company name in Kjuup. Bitte klicken Sie auf den Link, um sich bei Kjuup zu registrieren und dem Unternehmen company name beizutreten.",
  invite_all: "Alle Mitarbeiter/innen des Unternehmens einladen",
  invite_all_members: "Alle Teilnehmer/innen einladen",
  mems: "Alle weiteren Teilnehmer/innen",
  member: "Mitglied",
  admin: "Admin",
  remove_project_admin:
    "Wollen Sie das Mitglied wirklich aus dem Raum entfernen?",
  remove_my_self_project: "Wollen Sie sich wirklich Adminrechte entziehen?",
  remove_member_admin_right:
    "Wollen Sie dem Admin wirklich Adminrechte entziehen?",
  add_member_admin_right:
    "Wollen Sie dem Mitglied wirklich Adminrechte zuweisen?",
  add_remove_Poss: "Admin hinzufügen/entfernen",
  forward: "Weiterleiten",
  edit: "Editieren",
  only_kjuup_plus_can: "Nur Kjuup+ Unternehmen können Artikel posten.",
  add_images: "Bilder hinzufügen",
  Activity: "Aktivität",
  latest_messages: "Letzte Nachrichten",
  hours: "Std.",
  minute: "Minuten",
  seconds: "Sekunde",
  days: "Tage",
  yesterday: "Gestern",
  last_week: "Letzte Woche",
  next_week: "Nächste Woche",
  Last_month: "Letzter Monat",
  All: "Alle",
  register_plus: "Registrierung Plus",
  select_files: "Medien auswählen",
  monthsFullName: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  company_deploc_receivers_message:
    "Einladungslink zu department im Unternehmen company name in Kjuup. Bitte klicken Sie auf den Link, um sich bei Kjuup zu registrieren und dem Unternehmen company name beizutreten.",
  no_news: "Keine Mitteilungen vorhanden",
  reminder: "Erinnerungen",
  np_reminder: "Keine Erinnerungen",
  move_to_reminder: "In Erinnerungen speichern",
  remove_reminder:
    "Wollen Sie die Nachricht wirklich von der Liste Ihrer Erinnerungen entfernen?",
  remove_all_reminder: "Alle Erinnerungen entfernen",
  remove_all_confirm:
    "Wollen Sie wirklich alle Nachrichten von der Liste Ihrer Erinnerungen entfernen?",
  remove_from_reminder: "Von Erinnerungen entfernen",
  prevent_email: "Eine E-Mail kann nicht als Benutzername angegeben werden.",
  allow_noti: "Bitte erlauben Sie Benachrichtigungen.",
  join_cap: "Beitreten",
  pass_minimum:
    "**Passwort muss 12 Zeichen inkl. Klein-, Großbuchstabe, Nummer und Sonderzeichen beinhalten.",
  pass_error: "Bitte Passwortanforderungen überprüfen",
  frequent: "Zuletzt verwendet",
  people: "Smileys und Emotionen",
  people_that_like_article: "Personen, denen Ihr Artikel gefällt.",
  people_who_like_article: "Personen, denen der Artikel gefällt.",
  message_delete: "Diese Nachricht wurde gelöscht.",
  link_invite: "Per Link einladen",
  ll_nkk: "Zu Kjuup einladen",
  selected: "Ausgewählt",
  is_active: "Aktiv",
  inactive: "Inaktiv",
  remove_user_l: "Entfernen",
  link_expire: "Jeder generierte Link ist 72 Stunden gültig.",
  company_language: "Unternehmenssprache",
  company_language_info:
    "Die Sprachauswahl wird für alle E-Mail Bestätigungen, die das Unternehmen betreffen, verwendet.",
  s_image: "Bild",
  m_image: "Bilder",
  removeMsg: "Tippen zum Entfernen",
  block: 'Blockieren',
  unblock: 'Entblocken',
  blocks: 'Wollen Sie den/die Benutzer*in wirklich blockieren?',
  unblocks: 'Wollen Sie den/die Benutzer*in wirklich entblocken?',
  bolcked_click_un_block:
    'Sie haben den Kontakt blockiert. Klicken Sie hier um ihn zu entblocken.',
  you_have_unblocked: 'Sie haben den Kontakt entblockt.',
  you_have_blocked: 'Sie haben den Kontakt blockiert.',
  unblock_to_send:
    'Entblocken Sie den/die Benutzer*in um Nachrichten zu senden.',
  back: 'Zurück',

};
