import React from "react";
import LanguageContext from "../../../helper/LanguageContext";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";

const style = {
    marginBottom: 0,
    fontSize: '14px',
    backgroundColor: '#f5f5f5',
    padding: "13px 15px",
    fontWeight: 400
}

export default function CompanyPositionDialog({ handleClose, setParam, positions, setSelectName, isKjuup }) {
    const { isGerm } = React.useContext(LanguageContext)
    const handleChangeValue = async (cform, value) => {
        setParam('position', value);
        setSelectName(cform)
        handleClose();
    }
    return (
        <div className="role_popup shadow-sm theme-border">
            <h6 style={style}>{isGerm ? DE.position_within_company : EN.position_within_company}</h6>
            <div className="role_data mb-0">
                <ul className="list-group">
                    {
                        positions.map(cform =>
                            <div key={cform.id}>
                                <li key={cform.id} className="list-group-item p-1 pl-2" style={{ color: "black" }}>
                                    <div className="">
                                        <label className={isKjuup?'role-label-internal p-1': "role-label p-1"}>
                                            <span
                                                className="font-weight-bold">{isGerm ? cform.name_de : cform.name_en}</span>
                                            <input className="form-check-input" type="radio" name="exampleRadios"
                                                id={cform.id}
                                                onChange={e => handleChangeValue(cform, isGerm ? cform.name_de : cform.name_en)}
                                                value={isGerm ? cform.name_de : cform.name_en} />
                                            <span className={isKjuup ? "checkmark-label-internal" : "checkmark-label"} />
                                        </label>
                                    </div>
                                </li>
                            </div>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
