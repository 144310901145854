import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { Link, useHistory } from "react-router-dom";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { callAPI } from "../../helper/apiUtils";
import { $crud } from "../../helper/CrudFactory";


function AddServices(props) {
    const { isGerm } = useContext(LanguageContext)
    const [service, setService] = useState('')
    const history = useHistory();
    const [isKjuup, setIsKjuup] = useState([])
    useEffect(() => {
        if (props.location.state) {
            setIsKjuup(props.location.state.isKjuup)
        }
    }, [props])
    const addService = async () => {
        const companyId = props.match.params.id
        if (service) {
            const { data } = await callAPI(`/company/${companyId}/service`, {
                'services[5]': service
            }, 'POST')
            console.log(data)
            if (data.status) {
                setService('')
                history.push({
                    pathname: `/company-details/${companyId}`
                })
            } else {
                $crud.notify({
                    type: 'error',
                    message: data.message
                })
            }
        } else {
            $crud.notify({
                type: 'error',
                message: 'Please enter service name'
            })
        }
    }

    return (
        <React.Fragment>
            <Layout>
                <div class="w-300 following follower">
                    <div className={isKjuup ? "main_heading main_heading-internal" : "main_heading"}>
                        <Link to={`/company-details/${(props.match.params.id)}`}>
                            <img width={10} src={process.env.PUBLIC_URL + (isKjuup?"/assets/img/backx.svg": "/assets/img/back.svg")} />
                        </Link>
                        {isGerm ? DE.add_services : EN.add_services}
                    </div>
                    <div className="row m-0">
                        <div className="col-md-12 p-2">
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label className="">{isGerm ? DE.services : EN.services}</Form.Label>
                                <Form.Control value={service} onChange={e => setService(e.target.value)} type="text"
                                    style={{ height: 38, backgroundColor: '#f7f7f7' }} />
                            </Form.Group>
                        </div>
                        <div className="col-md-12 p-2">
                            <Button onClick={addService} className={isKjuup ? "btn request-btnx" : "btn request-btn"} type="submit">
                                {isGerm ? DE.save_text : EN.save_text}
                            </Button>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
}

export default AddServices;