import React, { Component, createRef } from "react";
import { Button, Form, Media } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import { $user } from "../../helper/UserFactory";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { callAPI } from "../../helper/apiUtils";
import { EN } from "../../Languages/EN";
import { $crud } from "../../helper/CrudFactory";
import { ReactComponent as SmilyIcon } from "../../Icons/SmilyDeselected.svg";
import { BASE_URL } from "../../helper/constants";
import { Skeleton } from "@material-ui/lab";
import Carousel from "react-multi-carousel";
import { Scrollbars } from "react-custom-scrollbars";
import { BsArrowLeft, BsArrowRight, BsFillXCircleFill } from "react-icons/all";
import { BsChevronDown } from "react-icons/bs";
import ProjectListDialog from "./Dialogs/ProjectListDialog";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { convertHtmlString, urlify } from "../../helper/convertor";
import IconButton from "@material-ui/core/IconButton";
import PlayerFeed from "../../components/PlayerFeed";
const extractUrls = require("extract-urls");

const user = $user.get();

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

class EditPersonalArticle extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.imageInputRef = createRef(null);
    this.state = {
      buildings: [],
      chars_left: 60,
      article: {
        editorState: "",
        id: "",
        title: "",
        description: "",
        isEmojiUsed: false,
        uploadType: "",
        file: [],
        prevFiles: [],
        link: "",
        buildingId: "",
        product: "",
      },
      expand: false,
      preview: false,
      profile: {},
      viewProject: false,
      prevFiles: [],
      _Link: [],
      serverImages: []
    };
  }

  getDetails = async () => {
    const id = this.props.match.params.id;
    const { data } = await callAPI(`/article/${id}`, {}, "GET");
    let article = data.article[0];
    console.log("data", data);
    const html = article.description
      ? convertHtmlString(article.description)
      : article.description;
    let contentBlock;
    if (html) {
      contentBlock = htmlToDraft(html);
    }
    console.log(article)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      const imageFiles = article.uploadUrl ? article.uploadUrl.map((each) => each.fileUrl) : []

      this.setState((state) => {
        return {
          ...state,
          article: {
            id: article.id,
            title: article.title,
            isEmojiUsed: article.isEmojiUsed ?? false,
            uploadType: article.uploadType,
            prevFiles: imageFiles,
            file: article.uploadUrl,
            link: article.link,
            buildingId: article.buildingId,
            product: article.product && article.product !== "null" ? article.product : "",
            serverImages: article.uploadUrl
          },
          editorState,
          chars_left: 60 - article.title.length,
        };
      });
      this.setParam(
        "description",
        urlify(
          convertHtmlString(
            article.description
              ?.replaceAll("<p", " <p")
              ?.replaceAll("</p", " </p")
          )
        )
      );
      setTimeout(() => {
        this._checkForLink(article.description)
      }, 500)
    }
  };



  toggleSmily = (value) => {
    this.setState({ article: { ...this.state.article, isEmojiUsed: !value } });
  };

  expandArticle = () => {
    this.setState({ expand: true });
  };

  removeImage = (file, index) => {
    const article = this.state.article;
    console.log(index, article);
    if (article.prevFiles.length > 0) {
      this.setState({ prevFiles: [] });
      article.prevFiles = [];
    } else {
      const files = article.file.splice(index + 1, 1);
      console.log(files);
      article.file = [];
    }
    console.log(article);
    this.setState({ article: article });
  };

  removeAllFields = async () => {
    if (this.state.article.title) {
      await $crud.confirm({
        title: "Confirm",
        textContent: `Are you sure you want to clear all this article?`,
        options: {
          ok: "OK",
          cancel: "Cancel",
        },
      });
      this.setState({
        article: {
          title: "",
          description: "",
          isEmojiUsed: false,
          uploadType: "",
          file: [],
          link: "",
          buildingId: "",
          product: "",
        },
        chars_left: 60,
      });
    }
  };

  setParam = (key, value) => {
    this.setState((state) => {
      return {
        ...state,
        article: {
          ...state.article,
          [key]: value,
        },
      };
    });
  };

  handleWordCount = async (event) => {
    const charCount = event.target.value.length;
    this.setState({
      chars_left: 60 - charCount,
    });
    await this.setParam("title", event.target.value);
  };

  get = async () => {
    const { data } = await callAPI("/building/my", {}, "GET");
    this.setState({
      buildings: data.buildings,
    });

    console.log(data);
  };

  componentDidMount() {
    this.get();
    this.getProfile();
    this.getDetails();
  }

  getProfile = async () => {
    const { data } = await callAPI(`/user/profile`, {}, "GET");
    console.log(data);
    this.setState({ profile: data.profile });
  };

  getPreview = (file) => {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        file.url = fileReader.result;
        resolve(file);
      };
    });
  };

  choseFiles = async (e) => {
    e.preventDefault();
    let file = await $crud.chooseFile();
    const { article } = this.state
    article.prevFiles = []
    file.fileUrl = file.url
    file.local = true
    let x = [...article.file, file]
    article.file = [...x]
    this.setState({ prevFiles: [] })
    this.setState({ article: article })
    this.setState({ _Link: [...new Set([...this.state._Link, ...x])] })
  };

  handlePreview = () => {
    if (!this.state.article.title) {
      $crud.notify({
        type: "error",
        message: "Please enter article title first.",
      });
    } else {
      this.setState({ preview: true });
    }
  };

  getBase64Files = async (images) => {
    if (images.length > 0) {
      images.map((image) => {
        this.toDataURL(BASE_URL + image.fileUrl, (u) => {
          const file = this.dataURLtoFile(u, "file.png");
          this.setState((state) => {
            return {
              ...state,
              prevFiles: [...state.prevFiles, file],
            };
          });
        });
      });
    }
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl?.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  toDataURL = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  };

  postArticle = async () => {
    const { article } = this.state;
    console.log("article", article);
    console.log(this.state.prevFiles);
    let formData = new FormData();
    formData.append("id", article.id);
    formData.append("title", article.title);
    formData.append("description", article.description);
    formData.append("isEmojiUsed", article.isEmojiUsed);
    formData.append(
      "uploadType",
      (article.file && article.file.length > 0) ||
        (this.state.prevFiles && this.state.prevFiles.length > 0)
        ? "media"
        : "none"
    );
    formData.append("link", "");
    formData.append("buildingId", article.buildingId || "");
    formData.append("product", article.product);
    let fileData = "";
    if (article.file && article.file.length > 0) {
      fileData = article.file;
    }
    if (this.state.prevFiles && this.state.prevFiles.length > 0) {
      fileData = this.state.prevFiles;
    }
    delete article?.prevFiles;
    // formData.append("file", fileData);
    // fileData?.forEach((file, index) => {
    //   formData.append("file", file);
    // });
    let _serverIm = []
    let _localIm = []
    if (article.file) {
      for (let obj of article.file) {
        if (obj.local) {
          _localIm.push(obj)
        }
        else {
          _serverIm.push(obj)
        }
      }
    }
    if (_serverIm.length > 0)
      formData.append('fileList', JSON.stringify(_serverIm))
    else {
      formData.append('fileList', JSON.stringify([]))
    }
    _localIm.map(item => {
      formData.append('file', item);
    })

    $crud.post("/article/updateArticle", formData).then((res) => {
      $crud.notify({
        type: res.status ? "success" : "error",
        message: res.message,
      });
      if (res.status) {
        this.props.history.push({
          pathname: `/profile`,
        });
      }
    });
  };
  onEditorStateChange = (editorState) => {
    let currentContentAsHTML = JSON.stringify(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    this.setState((state) => {
      return {
        ...state,
        article: {
          ...state.article,
          description: currentContentAsHTML,
        },
        editorState,
      };
    });
    this.setParam(
      "description",
      urlify(
        convertHtmlString(
          currentContentAsHTML
            ?.replaceAll("<p", " <p")
            ?.replaceAll("</p", " </p")
        )
      )
    );
    this.setState({ _Link: [] }, () => {
      this._checkForLink(currentContentAsHTML)
    })
  };

  onInputClick = (event) => {
    event.target.value = "";
  };

  _checkForLink = (currentContentAsHTML) => {
    console.log(currentContentAsHTML)
    let { article } = this.state
    let datax = extractUrls(currentContentAsHTML);
    let data = [...new Set(datax)];
    let all = []
    if (data && data.length > 0) {
      for (let obj of data) {
        let x = {
          href: obj
        }
        let lnk = obj
        if (lnk.match('vimeo')) {
          x.videoType = 'vimeo'
          all.push(x)
        }
        if (lnk.match('youtube') || lnk.match('youtu')) {
          x.videoType = 'youtube'
          all.push(x)
        }
      }
      if (all.length > 0) {
        this.setState({ _Link: [] }, () => {
          this.setState({ _Link: [...new Set([...all, ...((article?.file && article?.file.length) ? article?.file : [])])] })
        })
      }
      else if (article.file && article.file.length > 0) {
        this.setState({ _Link: [] }, () => {
          this.setState({ _Link: [...new Set(article?.file)] })
        })
      }
    }
    else {
      if (article.file && article.file.length > 0) {
        this.setState({ _Link: [] }, () => {
          this.setState({ _Link: [...new Set(article.file)] })
        })
      }
      else
        this.setState({ _Link: [] })
    }
  }

  render() {
    let { _Link, article } = this.state
    return (
      <React.Fragment>
        <Layout>
          <div className="w-300 following add_article add_article_personal">
            <CustomScrollbars
              autoHide
              autoHideTimeout={500}
              autoHideDuration={200}
              className="bottom-bar"
            >
              {!this.state.preview && (
                <div>
                  <div className="main_heading">
                    <Link to="/profile">
                      <img
                        className="mr-0"
                        width={10}
                        src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                      />
                    </Link>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/create-article.svg"
                      }
                    />
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="article_form">
                    <div className="form-bottom">
                      <Form className="add_company_from">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            rows={1}
                            maxLength="60"
                            value={this.state.article.title}
                            onChange={this.handleWordCount}
                            placeholder={
                              this.context.isGerm ? DE.title : EN.title
                            }
                          />
                          <Form.Text className="text-muted">
                            {" "}
                            {this.state.chars_left}{" "}
                            {this.context.isGerm
                              ? DE.characters_remaining
                              : EN.characters_remaining}
                          </Form.Text>
                        </Form.Group>
                        {/* <div className="row">
                          {this.state.article?.file?.length > 0 && (
                            <div
                              className="col-lg-12 p-2 position-relative"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                overflow:
                                  this.state.article.file.length > 2
                                    ? "auto"
                                    : "",
                                maxHeight:
                                  this.state.article.file.length > 2
                                    ? "240px"
                                    : "",
                              }}
                            >
                              <BsFillXCircleFill
                                size={20}
                                className="pointer position-absolute"
                                style={{ right: 12, top: 12 }}
                                color="red"
                                onClick={(e) => this.removeImage()}
                              />
                              {this.state.article?.file?.map((each) => (
                                <img
                                  className={
                                    this.state.article.file.length >= 2
                                      ? "upload-multi-group-image"
                                      : ""
                                  }
                                  src={each.url}
                                  key={each?.name}
                                  alt={each?.name}
                                />
                              ))}
                            </div>
                          )}
                        </div> */}
                        {/* <div className="row">
                          {this.state.article?.prevFiles?.length > 0 && (
                            <div
                              className="col-lg-12 p-2 position-relative"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                overflow:
                                  this.state.article.prevFiles.length > 2
                                    ? "auto"
                                    : "",
                                maxHeight:
                                  this.state.article.prevFiles.length > 2
                                    ? "240px"
                                    : "",
                              }}
                            >
                              {this.state.article.prevFiles.map((f, index) => (
                                <>
                                  <BsFillXCircleFill
                                    size={20}
                                    className="pointer position-absolute"
                                    style={{ right: 12, top: 12 }}
                                    color="red"
                                    onClick={(e) => this.removeImage(f, index)}
                                  />
                                  <img
                                    src={f ? BASE_URL + f : f.url}
                                    className={
                                      this.state.article.prevFiles.length >= 2
                                        ? "upload-multi-group-image"
                                        : ""
                                    }
                                    alt="article-img"
                                  />
                                </>
                              ))}
                            </div>
                          )}
                        </div> */}
                        <Form.Group controlId="formBasic">
                          <div className="form-icons">
                            <a href="#">
                              <img onClick={e => this.choseFiles(e)} width={20}
                                src={process.env.PUBLIC_URL + "/assets/img/Add.svg"} />
                            </a>
                            <SmilyIcon
                              className={this.state.article.isEmojiUsed ? 'smily-icon active' : 'smily-icon'}
                              onClick={e => this.toggleSmily(this.state.article.isEmojiUsed)}
                              width={20} />
                          </div>

                          <Editor
                            stripPastedStyles={true}
                            editorState={this.state.editorState}
                            toolbar={{
                              options: ["inline", "link", "embedded"],
                              inline: {
                                inDropdown: false,
                                options: ["bold", "italic", "underline"],
                              },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                            }}
                            editorClassName="demo-editor"
                            toolbarClassName="toolbar-class custom-richtext"
                            onEditorStateChange={this.onEditorStateChange}
                          />
                          {/* <Form.Control as="textarea" placeholder="text..."
                                                                  value={this.state.article.description}
                                                                  onChange={e => this.setParam('description', e.target.value)}/> */}
                        </Form.Group>
                        {_Link && _Link.length > 0 &&
                          <PlayerFeed
                            Links={_Link}
                            article={article}
                            isC
                            removeImage={(i) => {
                              let { article } = this.state
                              let ind = article.file.findIndex(e => e.fileUrl == _Link[i].fileUrl)
                              if (ind !== -1) {
                                let x = { ...article }
                                x.file.splice(ind, 1)
                                console.log(x)
                                this.setState({ article: x }, () => {
                                  this._checkForLink(article.description)
                                })
                              }
                            }}
                          />
                        }
                        <div className="title_icon">
                          <h4>
                            {this.context.isGerm
                              ? DE.optional_information
                              : EN.optional_information}
                          </h4>
                        </div>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            value={this.state.article.product}
                            onChange={(e) =>
                              this.setParam("product", e.target.value)
                            }
                            type="text"
                            placeholder={
                              this.context.isGerm
                                ? DE.featured_products
                                : EN.featured_products
                            }
                          />
                        </Form.Group>
                        <Form.Group className="select_coustom border-0">
                          <a
                            className="register_role pointer text-truncate position-relative"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ viewProject: true });
                            }}
                          >
                            {!this.state.article.buildingId && (
                              <span>
                                {this.context.isGerm
                                  ? DE.projects
                                  : EN.projects}{" "}
                              </span>
                            )}
                            {this.state.article.buildingId && (
                              <span>{this.state.article.buildingName}</span>
                            )}
                            <BsChevronDown />
                          </a>
                        </Form.Group>

                        <div className="button-group-3">
                          <Button
                            className={
                              this.state.article.title
                                ? "btn request-btn"
                                : "btn request-btn disable-btn"
                            }
                            type="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push({
                                pathname: `/profile`,
                              });
                            }}
                          >
                            {this.context.isGerm ? DE.abort : EN.abort}
                          </Button>
                          <Button
                            className={
                              this.state.article.title
                                ? "btn request-btn"
                                : "btn request-btn disable-btn"
                            }
                            onClick={this.handlePreview}
                            type="button"
                          >
                            {" "}
                            {this.context.isGerm ? DE.preview : EN.preview}
                          </Button>
                          <Button
                            className="btn request-btn"
                            onClick={() => this.postArticle()}
                          >
                            {this.context.isGerm ? DE.update : EN.update}<BsArrowRight size={20} />
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
              {this.state.preview && (
                <div>
                  <div className="p-2">
                    <Media>
                      {this.state.profile?.avatar && (
                        <img
                          width={50}
                          height={50}
                          style={{ borderRadius: 5 }}
                          className="mr-3 news_profile"
                          src={
                            this.state.profile.avatar
                              ? BASE_URL + this.state.profile.avatar
                              : process.env.PUBLIC_URL +
                              "assets/img/profile.png"
                          }
                          alt="Generic placeholder"
                        />
                      )}
                      {!this.state.profile?.avatar && (
                        <div className="svg_icon mr-3 news_profile">
                          <svg
                            width="50px"
                            height="50px"
                            viewBox="0 0 99.84 99.83"
                          >
                            <path
                              className="prf1"
                              d="M318.68,147.82l2.2-2.17c-6.17-2.82-12.74-4.33-15.94-5.74S293,134.24,293,134.24s-.42-6.86-3-7.26l.43-4.64s3-6.45,3-10.48c0,0,3,2.42,4.05-3s2.56-15.51-2.13-13.1c0,0,1-8,.11-12.89s-5.22-19.74-24.82-19.74-24,14.91-24.81,19.74.1,12.89.1,12.89c-4.68-2.41-3.19,7.66-2.13,13.1s4,3,4,3c0,4,3,10.48,3,10.48l.43,4.64c-2.56.4-3,7.26-3,7.26s-8.72,4.26-11.92,5.67c-2.76,1.22-3.54,3.29-9.82,6.16a13.32,13.32,0,0,0,6.9,4.52,16.83,16.83,0,0,0,4.41.6h34l36.59-.22c1.8,0,5.46-.25,7.59-1.24.33-.21,2.27-1.67,2.58-1.91"
                              transform="translate(-223.59 -52.87)"
                            />
                            <rect
                              className="prf2"
                              x="1.5"
                              y="1.5"
                              width="96.84"
                              height="96.83"
                              rx="10.49"
                              ry="10.49"
                            />
                          </svg>
                        </div>
                      )}
                      <Media.Body>
                        <div className="w-100 authore_name personal_article">
                          {this.state.profile.fullName ? (
                            <h6>
                              {this.state.profile.fullName ? (
                                this.state.profile.fullName
                              ) : (
                                <Skeleton variant="text" />
                              )}
                              {this.state.profile.id ? (
                                <span
                                  className="text-muted"
                                  style={{ fontSize: 13 }}
                                >
                                  {this.state.profile.buildingIndustryRole
                                    ? ` | ${this.state.profile.buildingIndustryRole}`
                                    : ""}
                                </span>
                              ) : (
                                <Skeleton variant="text" />
                              )}
                            </h6>
                          ) : (
                            <Skeleton variant="text" />
                          )}
                        </div>
                        <div className="w-100 personal_article_2">
                          {this.state.article.product ? (
                            <h6>
                              Product:{" "}
                              <span
                                className="text-muted"
                                style={{ fontSize: 12 }}
                              >
                                {this.state.article.product}
                              </span>
                            </h6>
                          ) : (
                            ""
                          )}
                        </div>
                      </Media.Body>
                    </Media>
                    <strong
                      className="mb-2 mt-2 d-block"
                      style={{ color: "#212529", fontSize: 13 }}
                    >
                      {this.state.article.title}
                    </strong>
                    <p className="small" style={{ color: "#212529" }}>
                      {this.state.expand && this.state.article.description ? (
                        <span
                          className="m-0"
                          dangerouslySetInnerHTML={{
                            __html: convertHtmlString(
                              this.state.article.description
                            ),
                          }}
                        ></span>
                      ) : !this.state.article?.description ? (
                        <span
                          className="m-0"
                          dangerouslySetInnerHTML={{
                            __html: convertHtmlString(
                              this.state.article.description
                            ),
                          }}
                        ></span>
                      ) : (
                        <span
                          className="m-0"
                          dangerouslySetInnerHTML={{
                            __html: convertHtmlString(
                              this.state.article?.description
                            ),
                          }}
                        />
                      )}

                      {/* {
                                                ((!this.state.expand && (this.state.article.description)) && this.state.article.description.length > 100) &&
                                                <span className="pointer theme-color pl-3" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.expandArticle()
                                                }}>See More</span>
                                            } */}
                    </p>
                    {/* <div className="">
                      {this.state.article.file && (
                        <div className="">
                          <Carousel
                            additionalTransfrom={0}
                            arrows={this.state.article.file.length > 1}
                            centerMode={false}
                            className="p-0"
                            containerClass="container"
                            dotListClass=""
                            draggable={this.state.article.file.length > 1}
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                              desktop: {
                                breakpoint: {
                                  max: 3000,
                                  min: 1024,
                                },
                                items: 1,
                              },
                              mobile: {
                                breakpoint: {
                                  max: 464,
                                  min: 0,
                                },
                                items: 1,
                              },
                              tablet: {
                                breakpoint: {
                                  max: 1024,
                                  min: 464,
                                },
                                items: 1,
                              },
                            }}
                            showDots={this.state.article.file.length > 1}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                          >
                            {this.state.article.file.map((image, index) => (
                              <img
                                src={
                                  image.fileUrl
                                    ? BASE_URL + image.fileUrl
                                    : image.url
                                }
                                style={{
                                  display: "block",
                                  height: "100%",
                                  margin: "auto",
                                  width: "100%",
                                }}
                              />
                            ))}
                          </Carousel>
                        </div>
                      )}
                    </div>
                    <div className="">
                      {this.state.article.prevFiles && (
                        <div className="">
                          <Carousel
                            additionalTransfrom={0}
                            arrows={this.state.article.prevFiles.length > 1}
                            centerMode={false}
                            className="p-0"
                            containerClass="container"
                            dotListClass=""
                            draggable={this.state.article.prevFiles.length > 1}
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                              desktop: {
                                breakpoint: {
                                  max: 3000,
                                  min: 1024,
                                },
                                items: 1,
                              },
                              mobile: {
                                breakpoint: {
                                  max: 464,
                                  min: 0,
                                },
                                items: 1,
                              },
                              tablet: {
                                breakpoint: {
                                  max: 1024,
                                  min: 464,
                                },
                                items: 1,
                              },
                            }}
                            showDots={this.state.article.prevFiles.length > 1}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                          >
                            {this.state.article.prevFiles.map(
                              (image, index) => {
                                return (
                                  <img
                                    src={image ? BASE_URL + image : image.url}
                                    alt="article-img"
                                    style={{
                                      display: "block",
                                      height: "100%",
                                      margin: "auto",
                                      width: "100%",
                                    }}
                                  />
                                );
                              }
                            )}
                          </Carousel>
                        </div>
                      )}
                    </div> */}
                  </div>
                  {_Link && _Link.length > 0 &&
                    <PlayerFeed
                      Links={_Link}
                      article={article}
                    />
                  }
                  <div className="text-right p-2">
                    <SmilyIcon
                      className={
                        this.state.article.isEmojiUsed
                          ? "smily-icon active"
                          : "smily-icon"
                      }
                      width={20}
                    />
                  </div>
                  <div className="row justify-content-between m-0 p-2 button-group-3">
                    <Button
                      className="request-btn"
                      onClick={() => this.setState({ preview: false })}
                    >
                      <BsArrowLeft size={20} /> Back
                    </Button>
                    <Button
                      className="request-btn"
                      onClick={() => this.postArticle()}
                    >
                      {this.context.isGerm ? DE.update : EN.update}<BsArrowRight size={20} />
                    </Button>
                  </div>
                </div>
              )}
            </CustomScrollbars>
          </div>
          {this.state.viewProject && (
            <ProjectListDialog
              handleClose={() => this.setState({ viewProject: false })}
              setParam={this.setParam}
              buildings={this.state.buildings}
            />
          )}
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouter(EditPersonalArticle);
